import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Tool2 from '../../../assets/images/tools2.webp';

const CloseSection = () => {
  return (
    <Fade up>
      <div id="ClosePublicity">
        <Row className="bg-blue-close">
          <Col md={5} xs={12} className="img__closepublicity">
            <img alt="Herramienta" title="Herramienta" src={Tool2} width="100%" height="auto" />
          </Col>
          <Col md={7} xs={12} className="font-left__closepublicity">
            <h2>Más que un Software una comunidad de talleres</h2>
            <Link className="btn btn-primary-blue-outline__landing button-font w-30" to="/contact">
              Contratar
            </Link>
          </Col>
        </Row>
      </div>
    </Fade>
  );
};

export default CloseSection;
