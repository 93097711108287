import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../../services/yupCustomMethods';
import { FormikInput } from '../../../components';

const IncidentTypeForm = ({ action, errors, handleModalClose, onHide, submitVariant, touched }) => {
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';

  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="incidentType[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3">
        <Col md={2}>
          <Button className="btn mb-3" variant="primary" block onClick={handleModalClose}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { incidentType } = props;
  return { incidentType };
};

const validationSchema = Yup.object().shape({
  incidentType: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre para Tipo de Incidencia')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(IncidentTypeForm);
