import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import ProviderForm from './ProviderForm';
import { createProviderRequest } from '../../requests/providers';
import { sendAlert } from '../../actions/utils';
import GoBackButton from "../../components/GoBackButton";

class ProviderNew extends Component {
  state = {
    provider: {
      active: true,
      address: '',
      businessActivity: '',
      cellphone: '',
      communeId: '',
      contactsAttributes: [],
      email: '',
      nationalIdentification: '',
      legalName: '',
      name: '',
      notes: '',
      phone: '',
      regionId: '',
      website: ''
    }
  };

  handleCreateRequest = provider => {
    const { dispatch } = this.props;
    createProviderRequest({
      dispatch,
      params: snakeCaseKeys(provider),
      formData: true,
      successCallback: this.handleSuccessCreate,
      failureCallback: this.handleFailureCreate
    });
  };

  handleSuccessCreate = response => {
    const { dispatch, history } = this.props;
    const provider = response.data;

    dispatch(sendAlert({ kind: 'success', message: 'Proveedor creado correctamente' }));
    history.push(`/providers/${provider.id}`);
  };

  handleFailureCreate = error => {
    const { dispatch } = this.props;
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  render() {
    const { provider } = this.state;
    const { dispatch, user } = this.props;
    return (
      <>
        <Row>
          <Col md={{ span: 10, offset: 1 }} xs={12}>
            <Row className="mt-3 mb-3">
              <Col>
                <h2>Crear Proveedor</h2>
              </Col>
              <Col md={2}>
                <GoBackButton />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <ProviderForm
                  dispatch={() => dispatch}
                  provider={camelCaseRecursive(provider)}
                  action="new"
                  formRequest={this.handleCreateRequest}
                  country={user.companyCountry}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ProviderNew);
