/* eslint-disable no-alert */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { MoreVertical } from 'react-feather';
import { BasicDropdown, LinkBtn, SimpleCenteredModal } from '../../components';
import ProductInventoryDataTable from './ProductInventoryDataTable';
import columns from './Columns';
import { sendAlert } from '../../actions/utils';
import { createProductInventoryRequest, indexProductInventoriesRequest } from '../../requests/productInventories';
import ProductInventoryForm from './ProductInventoryForm';
import downloadFile from '../../services/utils';

const basicProductInventory = {
  categoryId: '',
  currentStock: '',
  minimumStock: '',
  potentialStock: '',
  productId: '',
  productCategoryId: ''
};

const ProductInventoryIndex = () => {
  const dispatch = useDispatch();
  const [modalBody, setModalBody] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [queries, setQueries] = useState({});

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Inventario creado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createProductInventoryRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  const handleModalCreate = () => {
    setModalBody(
      <ProductInventoryForm
        action="new"
        dispatch={dispatch}
        formRequest={handleCreateRequest}
        handleModalClose={handleModalClose}
        submitVariant="success"
        productInventory={basicProductInventory}
      />
    );
    setModalShow(true);
    setModalTitle('Activar Ítem');
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const downloadIndex = (format, params) => {
    indexProductInventoriesRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload
    });
  };

  return (
    <>
      <Row className="mt-4">
        <Col md={9}>
          <h2 className="text-uppercase">Inventario</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="primary" block to="/movements/new">
            Realizar Movimiento
          </LinkBtn>
        </Col>
        <Col md={1} style={{ paddingRight: 0 }}>
          <BasicDropdown
            small
            noArrow
            variant="link"
            classColor="text-dark"
            titleDrop={<MoreVertical />}
            items={[
              { key: '1', title: 'Exportar', onClick: () => downloadIndex('xlsx', queries) },
              { key: '2', title: 'Activar Ítem', onClick: handleModalCreate }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ProductInventoryDataTable
            columns={columns}
            moreData={moreData}
            setMoreData={setMoreData}
            queries={queries}
            setQueries={setQueries}
          />
        </Col>
      </Row>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
    </>
  );
};

export default ProductInventoryIndex;
