import basicCompany from '../../Company/basicCompany';

const basicProject = {
  id: '',
  branchOffice: {},
  client: {},
  clientId: '',
  checklistsAttributes: [],
  company: basicCompany,
  companyTaxRate: '',
  createdBy: {},
  formatedPromiseDate: '',
  incidents: [],
  mechanicProgressPercentage: '',
  mileage: '',
  notes: '',
  otState: '',
  parsedTotalDiscount: '',
  parsedMileage: '',
  parsedTotalPayments: '',
  parsedTotalPaymentsPercentage: '',
  parsedTotalPrice: '',
  parsedTotalPriceTax: '',
  parsedTotalTax: '',
  priceDiscount: '',
  percentageDiscount: '',
  projectDate: '',
  projectInvoicesAttributes: [],
  projectPaymentsAttributes: [],
  projectProgress: '',
  projectReviewsAttributes: [],
  projectVersions: [],
  projectWorksAttributes: [],
  projectAutopartsAttributes: [],
  receptionDate: '',
  receptionProgressPercentage: '',
  reviewDate: '',
  seller: {},
  sellerId: '',
  totalPrice: '',
  translatedOtState: '',
  translatedProjectProgress: '',
  translatedState: '',
  user: {},
  userId: '',
  vehicle: {},
  vehicleId: '',
  workProgressPercentage: ''
};

export default basicProject;
