import React from 'react';
import { FormikSelect } from '../../../../../app/components';

const FilterBySeller = ({ users, onFilterChange }) => {
  const handleSellerChange = data => {
    const sellerId = data ? data.value : '';
    onFilterChange({ sellerId });
  };

  return (
    <div className="filter-seller">
      <FormikSelect isClearable placeholder="Vendedor" options={users} onChange={handleSellerChange} />
    </div>
  );
};

export default FilterBySeller;
