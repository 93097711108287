import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { LinkBtn } from '../../components';
import { createUserRequest } from '../../requests/user';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import UserForm from './UserForm';

const basicUser = {
  active: true,
  avatarInfo: {},
  branchOfficeIds: [],
  branchOffices: [],
  email: '',
  lastName: '',
  name: '',
  password: '',
  passwordConfirmation: '',
  roleIds: [],
  roles: []
};

const UserNew = () => {
  const [user, setUser] = useState(basicUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessCreate = response => {
    setUser(camelCaseEmptyStringRecursive(response.data));
    dispatch(sendAlert({ kind: 'success', message: 'Usuario creado con éxito' }));
    history.push('/users');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const { avatar } = values.user;
    const sendParams = snakeCaseKeys(values);
    sendParams.user.avatar = avatar;

    createUserRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col md={{ span: 8, offset: 1 }}>
          <h2 className="text-uppercase">Crear Usuario</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="light-darker" block to="/users" className="cancel">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <UserForm user={user} action="new" submitVariant="success" formRequest={handleCreateRequest} />
        </Col>
      </Row>
    </>
  );
};

export default UserNew;
