import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ComponentDataTable from '../../components/Utils/DataTable';
import { sendAlert } from '../../actions/utils';
import { debounceIndexBulkUploadsRequest } from '../../requests/bulkUploads';
import { SimpleCenteredModal } from '../../components';
import { Table } from 'react-bootstrap';

const BulkUploadDataTable = props => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [bulkUploads, setBulkUploads] = useState([]);
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const { columns, moreData, setMoreData } = props;

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseBulkUploads = response.data;
    // const { amount: responseAmount } = response.data.metadata;
    setBulkUploads(responseBulkUploads);
    setAmount(responseBulkUploads.length);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexBulkUploadsRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    setCenteredModalShow(true);
    setModalTitle('Errores en la carga masiva');
    setModalBody(
      <div style={{ maxHeight: '400px', overflowY: 'scroll', display: 'block' }}>
        <Table>
          <thead>
            <tr>
              <td>Fila</td>
              <td>Errores</td>
            </tr>
          </thead>
          <tbody>
            {item.errors.map((error, key) => (
              <tr key={key}>
                <td>{error.row}</td>
                <td>
                  <ul>
                    {Object.entries(error.errors).map(([key, value]) => (
                      <li key={key}>
                        {key}: {value.join(', ')}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalTitle('');
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={bulkUploads}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
    </>
  );
};

export default BulkUploadDataTable;
