import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col } from 'react-bootstrap';
import PlanUserForm from './PlanUserForm';
import { SimpleCenteredModal } from '../../components';
import { sendContactFormRequest } from '../../requests/utils';
import { sendAlert } from '../../actions/utils';

const basicContact = {
  name: '',
  email: '',
  phone: '',
  comments: ''
};

const PlanCard = ({ plan }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const { price, name, highlight, description, content } = plan;
  const isFree = parseInt(price, 10) === 0;
  const innerHTMLContent = content.replace(/<p>/g, '<li className="plan-list-item">').replace(/<\/p>/g, '</li>');
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    handleModalClose();
  };

  const handleSuccessRequest = () => {
    dispatch(
      sendAlert({ kind: 'success', message: 'Se ha enviado un mail con sus datos. Será contactado a la brevedad!' })
    );
    handleModalClose();
  };

  const handleSubmitContact = values => {
    const {
      contact: { name: contactName, email, phone, comments },
      planName
    } = values;

    const formattedMessage = `${contactName} está interesado por el ${planName} y le gustaría recibir información adicional.
    Sus teléfono es ${phone}, y su email es ${email}.
    Comentarios extra de parte del usuario: "${comments || 'El usuario no ingresó ningún comentario'}"`;
    sendContactFormRequest({
      dispatch,
      params: {
        email,
        message: formattedMessage
      },
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleOpenModal = () => {
    setModalShow(true);
    setModalBody(<PlanUserForm contact={basicContact} planName={name} formRequest={handleSubmitContact} />);
  };

  return (
    <>
      <Col className={highlight ? 'plan-card plan-highlight' : 'plan-card'} md={3}>
        {highlight && (
          <div className="highlight-header">
            <p>{description}</p>
          </div>
        )}
        <div className="price-container">
          <p className="price">{isFree ? 'Sin COSTO' : price}</p>
          {!isFree && <p className="price-text">mensual (no inc. IVA)</p>}
        </div>
        <div className="price-division" />
        <div className="plan-name-container">
          <h2 className="plan-name">{name}</h2>
        </div>
        <div className="plan-list-container">
          <ul className="plan-list" dangerouslySetInnerHTML={{ __html: innerHTMLContent }} />
        </div>
        <div className="select-btn-container">
          <Button onClick={handleOpenModal} className="plans-selection-btn">
            Seleccionar plan
          </Button>
        </div>
      </Col>
      <SimpleCenteredModal
        title="Estoy interesado!"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
        size="md"
      />
    </>
  );
};

export default PlanCard;
