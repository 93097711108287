import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import imageComputerfirst from '../../../assets/images/organize-first.webp';
import imageComputersecond from '../../../assets/images/gestion-second.webp';
import imageComputerthird from '../../../assets/images/agenda-third.webp';

const BenefitsSection = () => {
  return (
    <>
      <div id="benefits">
        <Fade left>
          <Row className="landing-image-section row-section-benefits max-width-2000">
            <Col md={6} xs={12} className="image-right">
              <img
                className="lazyload"
                alt="Interfaz GestionCar Administrativo"
                title="Interfaz GestionCar Administrativo"
                src={imageComputerthird}
                width="90%"
                height="auto"
              />
            </Col>
            <Col md={6} xs={12}>
              <h3 className="mb-4">Organiza y administra tu taller</h3>
              <p>
                Olvídate del excel y las hojas. Fideliza a tu cliente con transparencia en los procesos, calidez y
                rapidez en tus respuestas.
              </p>
              <Link className="btn button-font btn-link-arrow" to="/contact">
                ¡Prueba gratis de 15 días!
              </Link>
            </Col>
          </Row>
        </Fade>

        <Fade right>
          <Row className="landing-image-section row-section-benefits max-width-2000">
            <Col md={6} xs={12} className="order-sm-2">
              <h3 className="mb-4">
                Agenda de citas, checklist de recepción y diagnóstico, inventarios, gestión de órdenes de trabajo y de
                compras.
              </h3>
              <p>
                Haz de tu taller un negocio escalable y rentable. Con los procesos correctos podrás atender más y mejor.
              </p>
              <Link className="btn button-font btn-link-arrow" to="/contact">
                ¡Prueba gratis de 15 días!
              </Link>
            </Col>
            <Col md={6} xs={12} className="image-left order-sm-1">
              <img
                className="lazyload"
                alt="Interfaz GestionCar Listado de clientes"
                title="Interfaz GestionCar Listado de clientes"
                src={imageComputersecond}
                width="90%"
                height="auto"
              />
            </Col>
          </Row>
        </Fade>

        <Fade left>
          <Row className="landing-image-section row-section-benefits max-width-2000">
            <Col md={6} xs={12} className="image-right">
              <img
                className="lazyload"
                alt="Interfaz GestionCar Agendamiento"
                title="Interfaz GestionCar Agendamiento"
                src={imageComputerfirst}
                width="90%"
                height="auto"
              />
            </Col>
            <Col md={6} xs={12}>
              <h3 className="mb-4">Notificación en tiempo real con tus clientes.</h3>
              <p>
                Vía mail o whatsapp mantén siempre informados a tus clientes del estado de sus órdenes de trabajo.
                Podrán consultar en tiempo real el avance de sus reparaciones. ¡Plataforma 100% web!
              </p>
              <Link className="btn button-font btn-link-arrow" to="/contact">
                ¡Prueba gratis de 15 días!
              </Link>
            </Col>
          </Row>
        </Fade>
      </div>
    </>
  );
};

export default BenefitsSection;
