import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Table } from 'react-bootstrap';
import { withFormik, Form } from 'formik';
import { Upload } from 'react-feather';
import * as Yup from 'yup';
import snakeCaseKeys from 'snakecase-keys';
import '../../../services/yupCustomMethods';
import { FormikInput, SimpleCenteredModal, DefaultModal, ModalConfirmationButton } from '../../../components';
import MovementDetailAdd from '../../MovementDetail/MovementDetailAdd';
import { createProductRequest, scanProductRequest } from '../../../requests/products';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import ProductForm from '../../Product/ProductForm';
import { sendAlert } from '../../../actions/utils';
import ManualMovementForm from '../ManualMovementForm';

const basicProduct = {
  categoryId: '',
  code: '',
  cost: '',
  description: '',
  id: '',
  inventoryable: true,
  providerCode: '',
  salePrice: '',
  sku: '',
  unit: '',
  productCategoryId: '',
  providerId: '',
  vehicleBrandId: '',
  vehicleModelId: ''
};

const ProductMovementForm = ({ errors, formRequest, setFieldValue, touched, values }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [movementType, setMovementType] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const { productMovement } = values;
  const { movementDetailsAttributes } = productMovement;
  const modelKey = 'productMovement[movementDetailsAttributes]';
  const dispatch = useDispatch();
  const history = useHistory();

  const addMovementDetail = newAttributes => {
    const newValues = [...movementDetailsAttributes, newAttributes];
    setFieldValue(modelKey, newValues);
  };

  const handleSuccessScan = ({ data: product }) => {
    const repeatedProductMovementDetail = movementDetailsAttributes.find(
      movementDetail => movementDetail.productSku === product.sku
    );
    const {
      categoryName: productCategoryName,
      code: productName,
      sku: productSku,
      id: productId,
      productCurrentStock
    } = camelCaseEmptyStringRecursive(product);

    if (repeatedProductMovementDetail) {
      if (repeatedProductMovementDetail.productId !== productId) {
        repeatedProductMovementDetail.productId = productId;
        repeatedProductMovementDetail.productName = productName;
        repeatedProductMovementDetail.productCategoryName = productCategoryName;
        repeatedProductMovementDetail.productCurrentStock = productCurrentStock;
      } else {
        repeatedProductMovementDetail.quantityToBeUpdated += 1;
      }
      setFieldValue(modelKey, movementDetailsAttributes);
    } else {
      addMovementDetail({
        productCategoryName,
        productName,
        productSku,
        productId,
        quantityToBeUpdated: 1,
        productCurrentStock
      });
    }
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const scanProduct = sku => {
    scanProductRequest({
      dispatch,
      params: {
        sku
      },
      successCallback: handleSuccessScan,
      failureCallback: handleFailureRequest
    });
  };

  const onKeyDownHandler = keyEvent => {
    if (keyEvent.keyCode === 13) {
      keyEvent.preventDefault();
      const scanInput = document.getElementById('scanProductInput');
      const sku = scanInput.value;
      scanInput.value = '';
      if (sku) {
        scanProduct(sku);
      }
    }
  };

  const handleModalClose = () => {
    setDefaultModalShow(false);
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
    setMovementType('');
  };

  const handleSuccessProductCreate = response => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Ítem creado con éxito' }));
    handleSuccessScan(response);
  };

  const handleCreateProductRequest = params => {
    createProductRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessProductCreate,
      failureCallback: handleFailureRequest
    });
  };

  const createProductModal = ({ productSku, salePrice }) => {
    basicProduct.sku = productSku;
    basicProduct.salePrice = salePrice;
    setModalTitle('CREAR ÍTEM');
    setModalShow(true);
    setModalBody(<ProductForm product={basicProduct} action="new" formRequest={handleCreateProductRequest} />);
  };

  const manualAddRequest = ({ product: { sku } }) => {
    handleModalClose();
    scanProduct(sku);
  };

  const handleModalConfirmationConfirm = () => {
    history.push(`/products`);
    handleModalClose();
  };

  const manualAddProduct = () => {
    setModalBody(
      <>
        <Row className="justify-content-end">
          <Col className="mb-3" md={3}>
            <ModalConfirmationButton
              handleModalConfirm={handleModalConfirmationConfirm}
              modalTitle="Confirmar ir a ítems"
              modalBody="Al realizar esta acción puede perder cambios no guardados. Confirmar para continuar"
              buttonText="Ver Ítems"
              variant="secondary"
              className="w-100"
            />
          </Col>
        </Row>
        <ManualMovementForm
          formRequest={manualAddRequest}
          handleModalClose={handleModalClose}
          submitVariant="success"
          product={basicProduct}
        />
      </>
    );
    setModalShow(true);
    setModalTitle('Agregar ítem manual');
  };

  const handleConfirmModal = () => {
    const newValues = { ...values, productMovement: { ...productMovement, movementType } };
    formRequest(newValues);
    handleModalClose();
  };

  const openConfirmModal = ({ label: movementTypeLabel, value: movementTypeValue }) => {
    setMovementType(movementTypeValue);
    setModalBody(
      <>
        <p>{`¿Estás seguro que deseas realizar este movimiento de ${movementTypeLabel}?`}</p>
      </>
    );
    setDefaultModalShow(true);
    setModalTitle('CONFIRMAR MOVIMIENTO');
  };

  return (
    <>
      <Form>
        <Row>
          <Col md={3}>
            <FormikInput
              autoComplete="off"
              id="scanProductInput"
              onFocus={e => {
                e.target.placeholder = 'Escaneando...';
              }}
              onBlur={e => {
                e.target.placeholder = 'Escanear';
              }}
              onKeyDown={onKeyDownHandler}
              placeholder="Escanear"
            />
          </Col>
          <Col md={3}>
            <Button variant="btn btn-light-darker" block onClick={manualAddProduct}>
              Agregar ítem manual
            </Button>
          </Col>
          <Col md={{ offset: 4, md: 2 }}>
            <Button
              variant="btn btn-primary"
              block
              onClick={() => openConfirmModal({ label: 'Toma de Inventario', value: 'stocktake' })}
            >
              Crear Toma Inventario <Upload width="15px" height="15px" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: '18%' }}>Categoría</th>
                <th style={{ width: '18%' }}>Nombre</th>
                <th style={{ width: '18%' }}>SKU</th>
                <th style={{ width: '12%' }}>Stock Sistema</th>
                <th style={{ width: '16%' }}>Stock en Inventario (Actual)</th>
                <th style={{ width: '16%' }}>Movimiento</th>
                <th style={{ width: '2%' }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <MovementDetailAdd
                createProductModal={createProductModal}
                setFieldValue={setFieldValue}
                movementDetailsAttributes={movementDetailsAttributes}
                modelKey={modelKey}
                touched={touched}
                errors={errors}
                values={values}
                fromStocktake
              />
            </tbody>
          </Table>
        </Row>
      </Form>
      <SimpleCenteredModal body={modalBody} title={modalTitle} show={modalShow} onHide={handleModalClose} closeButton />
      {defaultModalShow && (
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={defaultModalShow}
          handleClose={handleModalClose}
          handleConfirm={handleConfirmModal}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
    </>
  );
};

const setInitialValues = ({ productMovement }) => {
  return { productMovement };
};

const validationSchema = Yup.object().shape({
  productMovement: Yup.object().shape({
    movementType: Yup.string().nullable(),
    movementDetailsAttributes: Yup.array().of(
      Yup.object().shape({
        productId: Yup.string().required('Debes seleccionar un ítem'),
        quantity: Yup.number().required('Debes ingresar una cantidad')
      })
    )
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false,
  validateOnChange: false
})(ProductMovementForm);
