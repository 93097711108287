import React from 'react';
import { Accordion, Card, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChevronDown } from "react-feather";
import { Can } from "../../../config/abilityContext";
import columnsInvoiceMenu from "./ColumnsInvoiceMenu";

const InvoiceMenuMobile = ({ closeNavbar }) => {
  return (
    <Accordion className="responsive-navbar-accordion" defaultActiveKey="1">
      <Card className="accordion-card">
        <Accordion.Toggle className="nav-tab accordion-toggle responsive" as={Card.Header} eventKey="0">
          Facturación
          <ChevronDown className="ml-2" size={18} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="accordion-options-bg">
            {columnsInvoiceMenu.map((column, index) => (
              <Can I="read" a={column.can} key={index}>
                <Nav.Link as={Link} onClick={closeNavbar} to={column.path}>
                  {column.name}
                </Nav.Link>
              </Can>
            ))}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
export default InvoiceMenuMobile;