import React from 'react';
import { withFormik, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';

import basicProjectWorkHour from './basicProjectWorkHour';
import ProjectHoursAdd from './ProjectHoursAdd';
import projectWorkHoursSchema from './projectWorkHoursSchema';

const ProjectWorkHourForm = ({ onHide, submitVariant, touched, errors, users, projectWork }) => {
  const modelName = 'projectWork';
  return (
    <Form>
      <ProjectHoursAdd
        parentModel={modelName}
        errors={errors}
        touched={touched}
        users={users}
        projectWork={projectWork}
      />
      <Row className="d-flex justify-content-end mt-3">
        <Col md={2}>
          <Button className="btn mb-3" variant="primary" block onClick={onHide}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" variant={submitVariant}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ projectWork }) => {
  const hours = getIn(projectWork, ['projectWorkHoursAttributes']);

  return hours.length > 0
    ? {
        projectWork
      }
    : {
        projectWork: {
          ...projectWork,
          projectWorkHoursAttributes: [{ ...basicProjectWorkHour }]
        }
      };
};

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

const validationSchema = Yup.object().shape({
  projectWork: Yup.object().shape({
    projectWorkHoursAttributes: Yup.array().of(
      Yup.object().shape({
        ...projectWorkHoursSchema
      })
    )
  })
});

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProjectWorkHourForm);
