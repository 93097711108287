import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Form, getIn, Field } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import 'moment/locale/es';

import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { indexChecklistRequest } from '../../../requests/checklists';
import { FormikSelect } from '../../../components';

const ProjectSelectChecklistForm = ({ checklistTypeCode, setFieldValue, errors, touched, isSubmitting }) => {
  const [checklists, setChecklists] = useState([]);
  const dispatch = useDispatch();

  const fetchChecklists = () => {
    indexChecklistRequest({
      params: { q: { checklistTypeEq: checklistTypeCode } },
      dispatch,
      successCallback: response => setChecklists(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(fetchChecklists, []);

  return (
    <>
      <Form>
        <Row>
          <Col md={6}>
            <Field name={`checklistId`}>
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Checklist"
                  placeholder="Seleccionar..."
                  options={checklists.map(checklist => ({ value: checklist.id, label: checklist.name }))}
                  onChange={data => {
                    setFieldValue(field.name, data?.value);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Button block type="submit" disabled={isSubmitting}>
              Seleccionar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const validationSchema = Yup.object().shape({
  checklistId: Yup.string().required('Campo requerido')
});

const handleSubmit = (values, { props, setSubmitting, isSubmitting }) => {
  if (!isSubmitting) {
    setSubmitting(true);
    const { formRequest } = props;
    formRequest(values);
  }
};

export default withFormik({
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnChange: true
})(ProjectSelectChecklistForm);
