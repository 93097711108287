import React from 'react';
import { Field, getIn } from 'formik';
import { Card, Col } from 'react-bootstrap';
import * as Yup from 'yup';

import { FormikInput, UploadImage } from '../../../components';
import defaultImg from '../../../assets/images/default.png';

const CompanyForm = props => {
  const { errors, touched, setFieldValue, fromShow, company } = props;
  return (
    <>
      <Col md={6} className="mt-3">
        <Field name="company[nationalIdentification]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              disabled
              label="RUT Empresa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={fromShow ? { span: 2, offset: 1 } : 6} className={`mb-3 ${fromShow && 'img-align-center'}`}>
        {fromShow ? (
          <Card.Img src={company.businessLogo?.fileUrl || defaultImg} />
        ) : (
          <Field name="company[logo]">
            {({ field }) => (
              <UploadImage
                {...field}
                label="Logo de Empresa"
                name={company.businessLogo?.filename}
                imageUrl={getIn(company.businessLogo, 'fileUrl')}
                onChange={_avatar => setFieldValue(field.name, _avatar)}
                helpText="Formato sugerido 620x400px de máximo 10mb."
              />
            )}
          </Field>
        )}
      </Col>
      <Col md={6}>
        <Field name="company[name]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              disabled={fromShow}
              label="Nombre Empresa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name="company[address]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              disabled
              label="Dirección"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name="company[email]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              disabled
              label="Email"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name="company[phone]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              disabled
              label="Celular"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      {!fromShow && (
        <>
          <Col md={6}>
            <Field name="company[legalName]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  disabled
                  label="Razón Social"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[businessActivity]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  disabled
                  label="Giro"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </>
      )}
    </>
  );
};

export const yupCompany = Yup.object().shape({
  name: Yup.string().required('Debes ingresar un nombre de fantasía')
});

export default CompanyForm;
