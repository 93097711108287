import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const ProjectCard = ({ project }) => {
  const { id, belatedProject, client, createdBy, formatedPromiseDate, mechanicProgressPercentage, vehicle } = project;
  const { fullName } = client || {};
  const { email } = createdBy;
  const history = useHistory();
  const {
    user: { roleIds }
  } = useSelector(state => state.auth);

  const redirectToChecklist = () => {
    if (roleIds.includes(1) || roleIds.includes(7)) {
      history.push(`/projects/${id}`);
    } else {
      history.push(`/project_mechanic_reviews/${id}`);
    }
  };

  return (
    <Col md={4} className="custom-card-style mb-4 text-uppercase">
      <Card className="card-predefined-size border-secondary clickable" onClick={redirectToChecklist}>
        <Card.Body className="pr-2 pb-0 small">
          <Row className="body-row">
            <Col xs={11}>
              <Row className="body-info">
                <Col xs={6} className="mb-3">
                  <h3 className="font-weight-bold">OT #{id}</h3>
                </Col>
                <Col xs={6} className="mb-3">
                  <span>{email}</span>
                </Col>
                <Col xs={6}>
                  <span>Vehículo:</span> {vehicle?.vehicleBrandName} {vehicle?.vehicleModelName} {vehicle?.plate}
                </Col>
                <Col xs={6}>
                  <span>Cliente:</span> {fullName}
                </Col>
              </Row>
            </Col>
            <Col xs={1} className="p-0 tag-style bg-secondary" />
          </Row>
        </Card.Body>
        <Card.Footer className="pt-0 border-top-0 bg-white">
          <Row className="d-flex align-items-end">
            <Col xs={8} className={`small mb-2 pr-1 ${belatedProject ? 'text-danger' : ''}`}>
              <span className="font-weight-bold">Entrega:</span> {formatedPromiseDate} {belatedProject && '(atrasada)'}
            </Col>
            <Col xs={4} className="text-capitalize font-weight-bold pl-3 text-secondary">
              <p className="d-flex justify-content-between my-2">
                <span>Revisión</span> {mechanicProgressPercentage}
              </p>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default ProjectCard;
