import React from 'react';
import { Table } from 'react-bootstrap';

const PurchaseManagementDetailTable = ({ purchaseManagementDetails }) => (
  <Table responsive hover>
    <thead style={{ borderTop: 'hidden' }}>
      <tr>
        <th>OT</th>
        <th>Patente/Código</th>
        <th>Marca</th>
        <th>Modelo</th>
        <th>Año</th>
        <th>Cilindrada (CC)</th>
        <th>VIN</th>
        <th>Precio Venta</th>
      </tr>
    </thead>
    <tbody>
      {purchaseManagementDetails.map(({ vehicle, product, projectDetail }, index) => (
        <tr key={`pmd-table-${index.toString()}`}>
          <td>{projectDetail?.projectId || 'N/A'}</td>
          <td>{vehicle?.plate || 'N/A'}</td>
          <td>{vehicle?.vehicleBrandName || 'N/A'}</td>
          <td>{vehicle?.vehicleModelName || 'N/A'}</td>
          <td>{vehicle?.year || 'N/A'}</td>
          <td>{vehicle?.engineCapacity || 'N/A'}</td>
          <td>{vehicle?.vin || 'N/A'}</td>
          <td>{product?.parsedSalePrice || '$0'}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default PurchaseManagementDetailTable;
