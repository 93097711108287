import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import {withFormik, Form, getIn, Field} from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import * as Yup from 'yup';
import { indexVehiclesRequest } from '../../../requests/vehicles';
import ClientTab from './ClientTab';
import VehicleTab from '../../../components/Vehicle/VehicleTab';
import CalendarEventTab from './CalendarEventTab';
import basicCompany from '../../Company/basicCompany';
import basicVehicle from '../../Vehicle/basicVehicle';
import { BasicTextArea, InputRemoteSelect } from "../../../components";
import {debouncePublicIndexVehiclesRequest} from "../../../requests/publicVehicles";
import {sendAlert} from "../../../actions/utils";

const CalendarEventForm = props => {
  const {
    action,
    calendarEvent,
    errors,
    handleClose,
    handleRemove,
    onHide,
    setFieldValue,
    submitVariant,
    touched,
    values
  } = props;
  const btnMessage = action === 'new' ? 'Crear cita' : 'Actualizar';
  const [clientDisabled, setClientDisabled] = useState(false);
  const [currentCompany, setCompany] = useState(basicCompany);
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [newClient, setNewClient] = useState(false);
  const [newVehicle, setNewVehicle] = useState(false);
  const [vehicleDisabled, setVehicleDisabled] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleSelected, setVehicleSelected] = useState('');
  const dispatch = useDispatch();
  const { companyCode } = useParams();
  const stableDispatch = useCallback(dispatch, []);
  const currentValues = getIn(values, 'calendarEvent');
  const currentClientId = currentValues.clientId;

  const resultFetchData = useCallback(response => {
    const result = response.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.label,
        value: element.id
      };
    });
    setVehicles(camelCaseRecursive(tempArray));
    return tempArray;
  }, []);

  const fetchInitialVehicles = useCallback(
    params => {
      indexVehiclesRequest(params.clientId, {
        dispatch: stableDispatch,
        params: {
          ...params,
          sort_column: 'plate',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: resultFetchData
      });
    },
    [stableDispatch, resultFetchData]
  );

  useEffect(() => {
    if (currentValues.vehicleId === calendarEvent.vehicleId) setCurrentVehicle(currentValues.vehicle);
  }, [currentValues, calendarEvent]);

  const handleClientChange = (value) => {
    setFieldValue('calendarEvent[clientId]', value);
    setFieldValue('calendarEvent[vehicleId]', '');
  };

  const resetVehicle = () => {
    setCurrentVehicle({});
    setVehicleSelected('');
    if (currentValues) setFieldValue('calendarEvent[vehicleId]', '');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchVehicles = (value, callback) => {
    debouncePublicIndexVehiclesRequest({
      dispatch,
      companyCode,
      clientId: currentClientId,
      params: {
        filtered: value,
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  }

  const fetchAllVehicles = val => {
    debouncePublicIndexVehiclesRequest({
      dispatch,
      companyCode,
      clientId: currentClientId,
      params: {},
      successCallback: res => {
        resultFetchData(res, val);
      },
      failureCallback: handleFailureRequest
    });
  }

  useEffect(() => {
    if(currentClientId) fetchAllVehicles();
  }, [currentClientId]);

  return (
    <>
      <Form>
        <Row className="change-modal-order mt-3">
          <Col md={3}>
            <CalendarEventTab
              calendarEvent={calendarEvent}
              setFieldValue={setFieldValue}
              currentValues={currentValues}
              edit={action === 'edit'}
              errors={errors}
              touched={touched}
            />
          </Col>
          <Col md={3} className={`${!newClient ? 'mobile-mt-3' : ''}`}>
            <ClientTab
              abbr
              modelKey="calendarEvent"
              currentModel={calendarEvent}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
              newClient={newClient}
              setNewClient={setNewClient}
              clientDisabled={clientDisabled}
              companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
              setVehicleDisabled={setVehicleDisabled}
              handleClientChange={handleClientChange}
              newVehicle={newVehicle}
              resetVehicle={resetVehicle}
            />
          </Col>
          <Col md={3} className={`${!newVehicle ? 'mobile-mt-3' : ''}`}>
            <Row>
              <Col md={12}>
                <Field name="calendarEvent[vehicleId]">
                  {({ field }) => (
                    <InputRemoteSelect
                      {...field}
                      label="Vehículo"
                      placeholder="Seleccionar Vehículo"
                      isDisabled={vehicleDisabled}
                      defaultOptions={vehicles}
                      value={vehicleSelected}
                      onChange={data => {
                        setVehicleSelected(data);
                        setCurrentVehicle(camelCaseRecursive(data));
                        setFieldValue(field.name, data ? data.id : '');
                      }}
                      request={fetchVehicles}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </Col>
          <Col md={3} className="mb-3">
            <Field name="calendarEvent[notes]">
              {({ field, form }) => (
                <BasicTextArea
                  {...field}
                  row="6"
                  label="Motivos de ingreso / Nota"
                  error={getIn(form.errors, field.name)}
                  touched={getIn(form.touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>

        {!(newClient || newVehicle) && (
          <Row className="d-flex justify-content-end mb-4">
            {handleRemove && (
              <Col md={3}>
                <Row>
                  <Col md={6}>
                    <Button className="btn" variant="danger" block onClick={() => handleRemove(calendarEvent.id)}>
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
            <Col md={{ span: 3, offset: 0 }} />
            <Col md={{ span: 3, offset: 3 }}>
              <Row>
                <Col md={6}>
                  <Button className="btn cancel mb-2" variant="primary" block onClick={handleClose}>
                    Cancelar
                  </Button>
                </Col>
                <Col md={6}>
                  <Button type="submit" variant={submitVariant} block onClick={onHide}>
                    {btnMessage}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
      {newClient && (
        <ClientTab
          abbr
          createClient
          modelKey="calendarEvent"
          companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
          currentModel={calendarEvent}
          setFieldValue={setFieldValue}
          values={values}
          formSize={{ span: 3, offset: 3 }}
          formClassName="modal-new-form"
          setCurrentVehicle={setCurrentVehicle}
          setNewClient={setNewClient}
          setVehicleDisabled={setVehicleDisabled}
          resetVehicle={resetVehicle}
          handleClientChange={handleClientChange}
        />
      )}
      {newVehicle && (
        <VehicleTab
          createVehicle
          modelKey="calendarEvent"
          companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
          currentModel={calendarEvent}
          values={values}
          formSize={{ span: 3, offset: 6 }}
          formClassName="modal-new-form"
          fetchInitialVehicles={fetchInitialVehicles}
          setClientDisabled={setClientDisabled}
          currentVehicle={currentVehicle || { ...basicVehicle, vtype: 'vehicle' }}
          setCurrentVehicle={setCurrentVehicle}
          setNewVehicle={setNewVehicle}
          setVehicleDisabled={setVehicleDisabled}
          setVehicleSelected={setVehicleSelected}
          vehicles={vehicles}
          resetVehicle={resetVehicle}
        />
      )}
    </>
  );
};

const setInitialValues = ({ calendarEvent }) => {
  return { calendarEvent: { ...calendarEvent } };
};

const validationSchema = Yup.object().shape({
  calendarEvent: Yup.object().shape({
    clientId: Yup.string()
      .required()
      .when('orderType', (orderType, schema) => {
        return orderType === 'desk_sell' ? schema.required('Debes seleccionar un Cliente') : schema;
      }),
    notes: Yup.string().nullable(),
    vehicleId: Yup.string().nullable()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CalendarEventForm);
