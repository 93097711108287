import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import ComponentDataTable from '../../components/Utils/DataTable';
import { DefaultModal, SimpleCenteredModal } from '../../components';
import {
  debounceCompanyIndexProductInventoriesRequest,
  deleteProductInventoryRequest,
  updateProductInventoryRequest
} from '../../requests/productInventories';
import { sendAlert } from '../../actions/utils';
import ProductInventoryForm from './ProductInventoryForm';
import conditionalRowStyles from './RowStyles';

const ProductInventoryDataTable = ({ columns, moreData, setMoreData, setQueries }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [productInventories, setProductInventories] = useState([]);
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Inventario actualizado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleUpdateRequest = values => {
    const {
      productInventory: { id }
    } = values;
    updateProductInventoryRequest(id, {
      dispatch,
      params: snakecaseKeys(values),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRequest = response => {
    setProductInventories(response.data.data);
    setAmount(response.data.metadata.amount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceCompanyIndexProductInventoriesRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'edit':
        setCenteredModalShow(true);
        setModalBody(
          <ProductInventoryForm
            action={action}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            submitVariant="success"
            productInventory={camelCaseEmptyStringRecursive(item)}
          />
        );
        setModalTitle('Editar Ítem');
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas eliminar este ítem?');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Ítem eliminado con éxito' }));
    setMoreData(!moreData);
  };

  const removeProductInventory = productInventoryId => {
    deleteProductInventoryRequest(productInventoryId, {
      dispatch,
      successCallback: () => handleSuccessRemove(productInventoryId)
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'branch_office_name':
        return { sort_branch_office_name: name };
      case 'category_name':
        return { sort_category_name: name };
      case 'product_category_name':
        return { sort_product_category_name: name };
      case 'product_cost':
        return { sort_product_cost: name };
      case 'product_name':
        return { sort_product_name: name };
      case 'product_sale_price':
        return { sort_product_sale_price: name };
      case 'product_sku':
        return { sort_product_sku: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        conditionalRowStyles={conditionalRowStyles}
        onRequest={onRequest}
        columns={columns(handleButtonClick, true)}
        handleSortCase={sortColumnCase}
        data={productInventories}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries(response);
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
      {modalShow && (
        <DefaultModal
          title="Eliminar Inventario"
          body={modalBody}
          show={modalShow}
          handleClose={handleModalClose}
          handleConfirm={() => removeProductInventory(modalItem.id)}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
    </>
  );
};

export default ProductInventoryDataTable;
