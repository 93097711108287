import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormikSelect } from '../../../components';

const invoiceStates = [
  { label: 'Emitida', value: 'issued' },
  { label: 'Pagada', value: 'paid' },
  { label: 'Cancelada', value: 'canceled' }
];

const EditProjectInvoiceForm = ({ errors, onHide, setFieldValue, submitVariant, touched, projectInvoice }) => {
  const { state } = projectInvoice;

  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="projectInvoice[state]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Estado de Factura"
                placeholder="Seleccionar estado"
                options={invoiceStates}
                defaultValue={state}
                onChange={data => {
                  setFieldValue(field.name, data?.value);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="justify-content-end mt-3">
        <Col md={3} className="pl-0">
          <Button block variant="light-darker" onClick={onHide}>
            Cancelar
          </Button>
        </Col>
        <Col md={3} className="pl-0">
          <Button type="submit" variant={submitVariant} block>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ projectInvoice }) => {
  return { projectInvoice };
};

const validationSchema = Yup.object().shape({
  projectInvoice: Yup.object().shape({
    state: Yup.string().required('Debes seleccionar un estado de factura')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(EditProjectInvoiceForm);
