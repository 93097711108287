import React from 'react';
import { Form } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const RichEditor = ({
  abbr,
  customClass,
  error,
  helpText,
  inputType,
  label,
  placeholder,
  row,
  size,
  touched,
  data,
  setFieldValue,
  setData = null,
  ...props
}) => {
  const editorConfiguration = {
    toolbar: {
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        'blockQuote',
        'highlight',
        'alignment',
        '|',
        'undo',
        'redo'
      ]
    },
    language: 'es',
    image: {
      toolbar: ['imageTextAlternative']
    }
  };

  return (
    <Form.Group className="textarea-custom">
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <CKEditor
        editor={Editor}
        config={editorConfiguration}
        data={data}
        onChange={(event, editor) => {
          const newData = editor.getData();
          setFieldValue(props.name, newData);
          if (setData) setData(newData);
        }}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default RichEditor;
