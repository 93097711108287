module.exports = function(key, width, height, fit, format) {
  if (key && key.substring(0, 4) !== 'http') {
    let imageHandlerCDN = 'https://cdn.gestioncar.cl';
    width = width || 650;
    height = height || 650;
    fit = fit || 'outside';
    let jsontob64 = btoa(
      JSON.stringify({
        bucket: 'gestioncar-prod',
        key: key,
        edits: {
          resize: { width, height, fit }
        }
      })
    );
    //console.log('RUTA: ',imageHandlerCDN + '/' + jsontob64);
    return imageHandlerCDN + '/' + jsontob64;
  } else {
    if (key && key.includes('https://platform-lookaside')) {
      let imageHandlerCDN = 'https://cdn.gestioncar.cl';
      return imageHandlerCDN + '/default/defaultAvatar.png';
    } else {
      return key;
    }
  }
};
