import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { showPurchaseManagementRequest, updatePurchaseManagementRequest } from '../../requests/purchaseManagements';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import PurchaseManagementForm from './Form/PurchaseManagementForm';

const basicPurchaseManagement = {
  id: '',
  notes: '',
  purchaseManagementDetailsAttributes: [],
  user: {},
  userId: ''
};

const PurchaseManagementEdit = ({ match }) => {
  const [purchaseManagement, setPurchaseManagement] = useState(basicPurchaseManagement);
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = match;

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchPurchaseManagement = () => {
    showPurchaseManagementRequest(params.id, {
      dispatch,
      successCallback: response => setPurchaseManagement(camelCaseEmptyStringRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchPurchaseManagement, [params]);

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Listado de compra actualizado con éxito' }));
    history.goBack();
  };

  const handleUpdateRequest = values => {
    updatePurchaseManagementRequest(params.id, {
      dispatch,
      params: snakeCaseKeys(values, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row className="my-4">
        <Col className="mx-5">
          <h2 className="text-uppercase">Editar listado de compra</h2>
        </Col>
      </Row>
      <Row>
        <Col className="mx-5">
          <PurchaseManagementForm
            action="edit"
            purchaseManagement={purchaseManagement}
            submitVariant="primary"
            formRequest={handleUpdateRequest}
          />
        </Col>
      </Row>
    </>
  );
};

export default PurchaseManagementEdit;
