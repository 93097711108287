import React, { useEffect, useState } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { hours, minutes } from './Constants';

import { FormikDatePicker, BasicTextArea, FormikSelect } from '../../components';
import '../../services/yupCustomMethods';
import { ROLE_IDS } from '../../config/ability';

const ProjectWorkHourInput = ({ index, modelName, errors, touched, users, projectWork }) => {
  const { user } = useSelector(state => state.auth);
  const { setFieldValue } = useFormikContext();
  const [sendPromiseDate] = useState(new Date());
  const getInPath = `[projectWorkHoursAttributes][${index}]`;
  const accountableId = getIn(projectWork, `${getInPath}[accountableUserId]`) || false;
  const isApproved = getIn(projectWork, `${getInPath}[approved]`);
  const userIsAdmin = user.roleIds.includes(ROLE_IDS.admin);

  const isEditable = () => {
    if (isApproved) return false;
    if (userIsAdmin) return true;
    return accountableId ? accountableId === user.id : true;
  };

  useEffect(() => {
    if (index >= projectWork.projectWorkHoursAttributes.length) {
      setFieldValue(`${modelName}[${index}][date]`, sendPromiseDate);
    }
    if (!userIsAdmin && !accountableId) {
      setFieldValue(`${modelName}[${index}][accountableUserId]`, user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col className="item-background">
      <Row>
        <Col md={4}>
          <Field name={`${modelName}[${index}][date]`}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                disabled
                label="Fecha"
                dateFormat="yyyy-MM-dd"
                selected={sendPromiseDate}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>

        <Col md={4}>
          <Field name={`${modelName}[${index}][accountableUserId]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Responsable"
                defaultValue={accountableId || user.id}
                options={users}
                onChange={data => setFieldValue(field.name, data.value || 0)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isDisabled={!userIsAdmin || isApproved}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name={`${modelName}[${index}][hours]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Horas"
                placeholder={field.value}
                options={hours}
                onChange={data => setFieldValue(field.name, data.value || 0)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isDisabled={!isEditable()}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name={`${modelName}[${index}][minutes]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Minutos"
                placeholder={field.value}
                options={minutes}
                onChange={data => setFieldValue(field.name, data.value || '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                isDisabled={!isEditable()}
              />
            )}
          </Field>
        </Col>

        <Col md={12}>
          <Field name={`${modelName}[${index}][details]`}>
            {({ field }) => (
              <BasicTextArea {...field} placeholder="Agregar detalle(opcional)" disabled={!isEditable()} />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  );
};

export default ProjectWorkHourInput;
