import React, { useEffect } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { Trash } from 'react-feather';
import { Button } from 'react-bootstrap';

import { FormikInput, FormikSelect } from '../../components';
import { formatToPrice } from '../../services/utils';

const MovementDetailInput = ({ index, modelKey, movementDetail, projectDetails, setProjectDetails, ...props }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const inputName = `${modelKey}[${index}]`;
  const { projectId } = values.productMovement;
  const { createProductModal, movementDetailsAttributes } = props;
  const { productId, productCategoryName, productName, productSku, quantity, salePrice, totalAmount } = movementDetail;

  const handleRemoveBtn = () => {
    const arrayValuesLength = movementDetailsAttributes.length;
    const deleteValue = movementDetail;
    const newValues = [
      ...movementDetailsAttributes.slice(0, index),
      ...movementDetailsAttributes.slice(index + 1, arrayValuesLength)
    ];
    if (deleteValue && deleteValue.id) {
      deleteValue._destroy = 'true';
      newValues.push(deleteValue);
    }
    setFieldValue(modelKey, newValues);
  };

  const calculateTotal = () => {
    if (quantity && salePrice) {
      const productTotal = quantity * salePrice;
      setFieldValue(`${inputName}[totalAmount]`, parseFloat(productTotal.toFixed(2)));
    }
  };

  const handleProjectDetail = data => {
    const currentProjectDetail = movementDetail.projectDetail;
    const hasCurrentDetail = currentProjectDetail && currentProjectDetail.projectId === projectId;
    if (hasCurrentDetail) setProjectDetails([...projectDetails, currentProjectDetail]);
    setFieldValue(`${inputName}[projectDetailId]`, data ? data.value : '');
    setFieldValue(`${inputName}[projectDetail]`, data);
  };

  const handleUnavailableItems = () => {
    const unavailableIds = movementDetailsAttributes.map(md => md.projectDetailId);
    const filterData = projectDetails.filter(pd => !unavailableIds.includes(pd.value));
    setProjectDetails(filterData);
  };

  const handleCleanSelectors = () => {
    setFieldValue(`${inputName}[projectDetailId]`, '');
  };

  useEffect(calculateTotal, [quantity, salePrice]);
  useEffect(handleUnavailableItems, [movementDetailsAttributes]);
  useEffect(handleCleanSelectors, [projectId]);

  return (
    <tr className={`col-vertical-center${productId ? '' : ' col-not-created-product'}`}>
      <td style={{ width: '200px' }}>{productCategoryName}</td>
      <td style={{ width: '200px' }}>
        {productId ? (
          productName
        ) : (
          <Button className="btn btn-success" block onClick={() => createProductModal(movementDetail)}>
            Crear ítem
          </Button>
        )}
      </td>
      <td style={{ width: '120px' }}>{`${productSku}${productId ? '' : ' (No creado)'}`}</td>
      <td style={{ width: '200px' }}>
        <Field name={`${inputName}[projectDetailId]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              isClearable
              placeholder="Seleccionar Ítem"
              options={projectDetails}
              onChange={handleProjectDetail}
              margin="no-margin"
            />
          )}
        </Field>
      </td>
      <td style={{ width: '120px' }}>
        <Field name={`${inputName}[location]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="no-margin"
            />
          )}
        </Field>
      </td>
      <td style={{ width: '120px' }}>
        <Field name={`${inputName}[quantity]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              inputType="number"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="no-margin"
            />
          )}
        </Field>
      </td>
      <td style={{ width: '120px' }}>
        <Field name={`${inputName}[salePrice]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              inputType="number"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="no-margin"
            />
          )}
        </Field>
      </td>
      <td style={{ width: '80px' }}>{formatToPrice(totalAmount)}</td>
      <td className="text-center" style={{ width: '10px' }}>
        <Button className="btn-sm" variant="outline-danger" onClick={handleRemoveBtn}>
          <Trash width="12px" height="12px" />
        </Button>
      </td>
    </tr>
  );
};

export default MovementDetailInput;
