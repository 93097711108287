import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCompanyLogsRequest = ({ dispatch, format, params = {}, successCallback, failureCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/company_logs${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showProjectLogsRequest = (id, { dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/projects/${id}/logs${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexCompanyLogsRequest = AwesomeDebouncePromise(indexCompanyLogsRequest, 300);
export const debounceShowProjectLogRequest = AwesomeDebouncePromise(showProjectLogsRequest, 300);
