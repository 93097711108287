import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { indexUsersRequest, debounceIndexUsersRequest } from '../../requests/user';
import ComponentDataTable from '../../components/Utils/DataTable';
import downloadFile from '../../services/utils';

const UserDataTable = props => {
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const { columns } = props;
  const [queries, setQueries] = useState({});

  const handleSuccessRequest = response => {
    const {
      data: responseUsers,
      metadata: { amount: responseAmount }
    } = response.data;
    setUsers(responseUsers);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexUsersRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest
    });
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const downloadIndex = (format, params) => {
    indexUsersRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload
    });
  };

  return (
    <>
      <Row className="justify-content-end mt-2">
        <Col md={2}>
          <Button variant="secondary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={users}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries(response);
          if (!onRequest) handleIndexRequest(response);
        }}
      />
    </>
  );
};

export default UserDataTable;
