const logTypes = [
  { label: 'PROYECTO CREADO POR USUARIO', value: 'project_created_by_user' },
  { label: 'PROYECTO RECEPCIONADO POR USUARIO', value: 'project_received_by_user' },
  { label: 'PROYECTO REVISADO POR USUARIO', value: 'project_reviewed_by_user' },
  { label: 'PROYECTO ACTUALIZADO', value: 'project_updated' },
  { label: 'PAGO ACTUALIZADO', value: 'payment_updated' },
  { label: 'INCIDENCIA REPORTADA', value: 'incident_created' },
  { label: 'CLIENTE CREADO', value: 'client_created' },
  { label: 'CLIENTE ACTUALIZADO', value: 'client_updated' },
  { label: 'PROVEEDOR CREADO', value: 'provider_created' },
  { label: 'PROVEEDOR ACTUALIZADO', value: 'provider_updated' },
  { label: 'ÍTEM CREADO', value: 'product_created' },
  { label: 'ÍTEM ACTUALIZADO', value: 'product_updated' },
  { label: 'CATEGORÍA CREADA', value: 'product_category_created' },
  { label: 'CATEGORÍA ACTUALIZADA', value: 'product_category_updated' },
  { label: 'SUCURSAL CREADA', value: 'branch_office_created' },
  { label: 'SUCURSAL ACTUALIZADA', value: 'branch_office_updated' },
  { label: 'USUARIO CREADO', value: 'user_created' },
  { label: 'USUARIO ACTUALIZADO', value: 'user_updated' },
  { label: 'VEHÍCULO CREADO', value: 'vehicle_created' },
  { label: 'VEHÍCULO ACTUALIZADO', value: 'vehicle_updated' },
  { label: 'FACTURA EMITIDA', value: 'project_invoice_created' }
].sort((a, b) => (a.label < b.label ? -1 : 1));

export const projectLogTypes = [
  { label: 'PROYECTO CREADO POR USUARIO', value: 'project_created_by_user' },
  { label: 'PROYECTO RECEPCIONADO POR USUARIO', value: 'project_received_by_user' },
  { label: 'PROYECTO REVISADO POR USUARIO', value: 'project_reviewed_by_user' },
  { label: 'PROYECTO ACTUALIZADO', value: 'project_updated' },
  { label: 'PAGO ACTUALIZADO', value: 'payment_updated' },
  { label: 'INCIDENCIA REPORTADA', value: 'incident_created' }
].sort((a, b) => (a.label < b.label ? -1 : 1));

export default logTypes;
