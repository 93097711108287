/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { Link } from 'react-router-dom';

const columns = memoize(() => [
  {
    name: 'FECHA DEL CREACIÓN',
    grow: '1.5',
    selector: 'log_date',
    sortable: false
  },
  {
    name: 'TIPO',
    selector: 'log_type_name',
    sortable: false,
    grow: '4'
  },
  {
    name: 'PATENTE/CÓDIGO VEHÍCULO',
    selector: 'vehicle_plate',
    cell: item => (
      <Link to={`/clients/${item?.vehicle_info?.client_id}/vehicles/${item.entity_id}`}>
        {item?.vehicle_info?.plate}
      </Link>
    ),
    sortable: false,
    grow: '1.5'
  },
  {
    name: 'TRANSFERIDO DE',
    selector: 'transfered_from',
    sortable: false,
    grow: '2.5'
  },
  {
    name: 'TRANSFERIDO A',
    selector: 'transfered_to',
    sortable: false,
    grow: '2.5'
  },
  {
    name: 'REALIZADO POR',
    selector: 'responsable_name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'CONTACTO DEL RESPONSABLE',
    selector: 'responsable_email',
    sortable: true,
    grow: '2'
  }
]);

export default columns;
