import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import ComponentDataTable from '../../../components/Utils/DataTable';
import { DefaultModal, SimpleCenteredModal } from '../../../components';
import {
  debounceIndexIncidentTypesRequest,
  deleteIncidentTypeRequest,
  updateIncidentTypeRequest
} from '../../../requests/incidentTypes';
import { sendAlert } from '../../../actions/utils';
import IncidentTypeForm from './IncidentTypeForm';

const IncidentTypeDataTable = props => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const { columns, moreData, setMoreData } = props;

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
    handleModalClose();
  };

  const handleSuccessRequest = response => {
    const responseIncidentTypes = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setIncidentTypes(responseIncidentTypes);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexIncidentTypesRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Tipo de Incidencia actualizado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleUpdateRequest = values => {
    const {
      incidentType: { id }
    } = values;
    updateIncidentTypeRequest(id, {
      dispatch,
      params: snakeCaseKeys(values, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'associated_incidents':
        return { sort_incidents_number: name };
      default:
        return { sort_column: name };
    }
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'edit':
        setCenteredModalShow(true);
        setModalBody(
          <IncidentTypeForm
            action={action}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            submitVariant="success"
            incidentType={camelCaseEmptyStringRecursive(item)}
          />
        );
        setModalTitle('Editar Tipo de Incidencia');
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalTitle('Eliminar Tipo de Incidencia');
        setModalBody('¿Estás seguro que deseas eliminar este tipo de incidencia?');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Tipo de Incidencia eliminado con éxito' }));
    setMoreData(!moreData);
  };

  const handleModalConfirm = () => {
    const { id: incidentTypeId } = modalItem;

    deleteIncidentTypeRequest(incidentTypeId, {
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={incidentTypes}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton={false}
      />
      {modalShow && (
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          handleClose={handleModalClose}
          handleConfirm={handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
    </>
  );
};

export default IncidentTypeDataTable;
