import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';

const InputPrepend = ({ size, text, placeholder, ariaLabel, ariaDescribedby }) => (
  <InputGroup size={size} className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text id="basic-addon1">{text}</InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl placeholder={placeholder} aria-label={ariaLabel} aria-describedby={ariaDescribedby} />
  </InputGroup>
);

InputPrepend.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaDescribedby: PropTypes.string
};

InputPrepend.defaultProps = {
  size: 'sm',
  placeholder: null,
  ariaLabel: null,
  ariaDescribedby: null
};
export default InputPrepend;
