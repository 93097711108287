import camelCaseRecursive from 'camelcase-keys-recursive';
import api from '../services/api';
import useQuery, { useInvalidateQuery } from './useQuery';

const findCompany = id => api.get(`/public/companies/${id}.json`).then(res => res.json());
const getSchedulesCompany = (id, date) => {
  const urlSearchParams = (new URLSearchParams({date})).toString();
  return api.get(`/public/companies/${id}/schedules.json?${urlSearchParams}`).then(res => res.json());
}

export const useFindCompany = id => {
  const { data = {} } = useQuery(['public_company', id], findCompany);
  const invalidateQueries = useInvalidateQuery();
  const reFetch = async () => {
    await invalidateQueries(['public_company', id]);
  };
  return { company: camelCaseRecursive(data), reFetch };
};

export const useGetSchedulesCompany = (companyId, date) => {
  const { data = [] } = useQuery(['public_company_schedules', companyId, date], getSchedulesCompany);
  return { schedules: camelCaseRecursive(data) };
};
