import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import * as Yup from 'yup';

import '../../../services/yupCustomMethods';
import { BasicTextArea, FormikSelect, Dropzone } from '../../../components';
import { indexIncidentTypesRequest } from '../../../requests/incidentTypes';
import { deleteIncidentImageRequest } from '../../../requests/incidents';
import { createDropZoneFileRequest, deleteDropZoneFileRequest } from '../../../requests/dropzone';

const IncidentForm = ({
  action,
  errors,
  handleModalClose,
  onHide,
  incident: initialIncident,
  setFieldValue,
  setFieldTouched,
  submitVariant,
  touched
}) => {
  const dispatch = useDispatch();
  const { incidentTypeId, imagesInfo } = initialIncident;
  const [incidentTypes, setIncidentTypes] = useState([]);
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';

  const maxAvatarSize = 20971520;
  const [documentCode, setDocumentCode] = useState(Date.now());
  const [documentKeys, setDocumentKeys] = useState([]);
  const [imageCounter, setImageCounter] = useState(imagesInfo.length);
  const [incident, setIncident] = useState({
    ...initialIncident,
    currentImages: [],
    persistedImages: imagesInfo
  });

  const handleOnDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'incident_image',
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => {
        const { data } = response;
        const newDocumentKeys = documentKeys;
        data.map(element => newDocumentKeys.push(element.document_key));
        setDocumentKeys(newDocumentKeys);
        return response;
      }
    });
  };

  const handleUpdateImages = images => {
    const { persistedImages } = incident;
    const updatedState = {
      ...incident,
      currentImages: [...images],
      persistedImages: [...persistedImages]
    };

    setFieldValue('incident[documentKeys]', documentKeys);
    setImageCounter(persistedImages.length + images.length);
    setIncident(updatedState);
    setDocumentCode(Date.now());
  };

  const handleSuccessDeleteImage = response => {
    const documentInfo = JSON.parse(response.config.data);
    const { persistedImages } = incident;
    const updatedPersistedImages = persistedImages.filter(image => image.id !== documentInfo.document_id);
    const updatedState = {
      ...incident,
      persistedImages: updatedPersistedImages
    };
    setIncident(updatedState);
  };

  const handleOnDelete = image => {
    deleteDropZoneFileRequest(image.id, {
      dispatch,
      params: { dropZoneFile: { documentKey: image.code } },
      successCallback: () => {
        const newDocumentKeys = documentKeys.filter(element => element !== image.code);
        setDocumentKeys(newDocumentKeys);
        setImageCounter(imageCounter - 1);
      }
    });
  };

  const handleDeleteProjectReviewImageRequest = params => {
    deleteIncidentImageRequest(incident.id, {
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessDeleteImage
    });
  };

  const handleOnDeletePersistedImages = document => {
    setImageCounter(imageCounter - 1);
    const destroyImage = {
      document_type: 'incident_image',
      document_id: document.id
    };
    return handleDeleteProjectReviewImageRequest(destroyImage);
  };

  const fetchIncidentTypes = () => {
    indexIncidentTypesRequest({
      dispatch,
      params: {
        for_selector: true
      },
      successCallback: response => setIncidentTypes(response.data.data)
    });
  };

  useEffect(fetchIncidentTypes, []);

  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="incident[incidentTypeId]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Incidencia"
                placeholder="Seleccionar Tipo de Incidencia"
                options={incidentTypes}
                defaultValue={incidentTypeId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                setFieldTouched={() => setFieldTouched('incident[incidentTypeId]')}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="incident[description]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                abbr
                label="Descripción"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between mt-3">
        <Col md={6}>
          <Dropzone
            modalPreview
            id={documentCode}
            fileAccept="image/*"
            documentCounter={imageCounter}
            maxSize={maxAvatarSize} // 20MB
            btnText="Seleccionar imágenes"
            persistedFiles={incident.persistedImages}
            files={incident.currentImages}
            onDrop={handleOnDrop}
            onDropUploaded={images => handleUpdateImages(images)}
            onDelete={image => handleOnDelete(image)}
            onDeletePersistedFiles={handleOnDeletePersistedImages}
            name="incident[documentKeys]"
            error={getIn(errors, 'incident[documentKeys]')}
            touched={getIn(touched, 'incident[documentKeys]')}
            disabled={false}
          />
        </Col>
        <Col md={4}>
          <Row>
            <Col md={6}>
              <Button className="btn mb-3" variant="light-darker" block onClick={handleModalClose}>
                Cancelar
              </Button>
            </Col>
            <Col md={6}>
              <Button type="submit" variant={submitVariant} block onClick={onHide}>
                {btnMessage}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { incident } = props;
  return { incident };
};

const validationSchema = Yup.object().shape({
  incident: Yup.object().shape({
    incidentTypeId: Yup.string().required('Debes seleccionar un Tipo de Incidencia'),
    description: Yup.string().required('Debes ingresar una descripción para Incidencia')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(IncidentForm);
