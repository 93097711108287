/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Col, Row, Table} from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import snakeCaseKeys from 'snakecase-keys';
import {SimpleCenteredModal} from '../../components';
import {updateProjectRequest} from '../../requests/projects';
import {createAutopartOrder} from '../../requests/autoparts';
import {sendAlert} from '../../actions/utils';
import ProjectAutopartsListQuote from './ProjectAutopartsListQuote';
import ProjectAutopartListEdit from './ProjectAutopartsListEdit';
import ProjectAutopartsCheckout from './ProjectAutopartsCheckout';

const ProjectAutopartsList = ({
  project,
  id,
  setMoreData,
  toggleAutopartsExpanded
}) => {
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const { projectAutopartsAttributes } = project;

  const buy = (productsToBuy, amount) => {
    const description = JSON.stringify(productsToBuy, null, 2);
    const params = { project_id: id, autopart_order: {amount, description} };
    createAutopartOrder({
      dispatch, 
      params, 
      successCallback: (response) => window.open(`https://pay.piriod.com/payments/${response.data.id}`, "_blank"), 
      failureCallback: () => dispatch(sendAlert({ kind: 'error', message: `Error al crear el pago` }))
    });
  };

  const checkout = (values) => {
    const productsToBuy = values.quotation.reduce((result, ap) => {
      return [...result,...ap.quotationAutoparts.filter(qap => qap.buy)];
    }, []);
    const productsPrice = productsToBuy.reduce((total, n) => {return total + n.__price * n.quantity}, 0);
    const shipmentPrice = 5000;
    setModalTitle('Resumen compra');
    setCenterModalShow(true);
    setModalBody(
      <ProjectAutopartsCheckout
        productsToBuy={productsToBuy}
        productsPrice={productsPrice}
        shipmentPrice={shipmentPrice}
        buyRequest={() => buy(productsToBuy, productsPrice+shipmentPrice)}
      />
    );
  };
  
  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalBody('');
    setModalTitle('');
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: `Lista de repuestos actualizada con éxito` }));
    setMoreData(m => !m);
    handleModalClose();
  };

  const handleFailureRequest = () => {
    dispatch(sendAlert({ kind: 'error', message: `Error al actualizar Lista de repuestos` }));
  };

  const handleUpdateRequest = (values) => {
    const { projectAutopartsAttributes } = values.project;
    const params = { project: {} }
    params.project.projectAutopartsAttributes = { ...projectAutopartsAttributes };
    updateProjectRequest(id, {
      dispatch,
      params: snakeCaseKeys(params, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleQuoteModalCreate = () => {
    setModalTitle('Cotizar repuestos');
    setCenterModalShow(true);
    setModalBody(
      <ProjectAutopartsListQuote
        project={project}
        onHide={handleModalClose}
        submitVariant="success"
        formRequest={(values) => checkout(values)}
      />
    );
  };

  const handleEditModalCreate = () => {
    setModalTitle('Editar lista de repuestos');
    setCenterModalShow(true);
    setModalBody(
      <ProjectAutopartListEdit
        project={project}
        setMoreData={setMoreData}
        onHide={handleModalClose}
        action="edit"
        submitVariant="success"
        formRequest={(values) => handleUpdateRequest(values)}
      />
    );
  };

  const autopartsTable = () => {
    if (projectAutopartsAttributes.length === 0) {
      return;
    }
    return (
      <Table responsive size="sm" className="mt-2">
        <thead>
          <tr>
            <th>Repuesto</th>
            <th>Cantidad</th>
            <th>Cantidad comprada</th>
            <th>Cantidad recibida</th>
          </tr>
        </thead>
        <tbody>
          {projectAutopartsAttributes.map(autopart => {
            if (autopart._destroy) {
              return undefined;
            }
            return (<tr>
              <td>{autopart.name}</td>
              <td>{autopart.quantity}</td>
              <td>{autopart.purchasedQuantity}</td>
              <td>{autopart.receivedQuantity}</td>
            </tr>);
          })}
        </tbody>
      </Table>
    );
  };

  const toggle = () => {
    setExpanded(!expanded);
    toggleAutopartsExpanded();
  }

  return (
    <>
      <Row className="px-3 d-block">
        <strong>Lista de repuestos</strong>
        { expanded &&
          <>
            {autopartsTable()}
            <Col md={12} className="text-center mb-3">
              <Button className="px-5 mr-2" variant="secondary" onClick={() => handleEditModalCreate()}>
                Editar
              </Button>
              <Button className="px-5 ml-2" variant="primary" onClick={() => handleQuoteModalCreate()}>
                Cotizar
              </Button>
            </Col>
          </>
        }
      </Row>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
        size="xl"
        closeButton
      />
      <Row>
        <button className='w-100 btn btn-light' onClick={toggle}>
          { expanded ? <ChevronUp size={15}/> : <ChevronDown size={15}/>}
        </button>
      </Row>
    </>
  );
};

export default ProjectAutopartsList;
