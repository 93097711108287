import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import * as icons from 'react-feather';

import './styles.scss';

const IconTooltip = ({ id, delayHide, delayShow, direction, iconClass, iconName, text }) => {
  const IconComponent = icons[iconName];
  return (
    <>
      <IconComponent data-tip data-for={id} className={`icon-component ${iconClass}`} width="15px" height="15px" />
      <ReactTooltip id={id} delay={{ show: delayShow, hide: delayHide }} place={direction}>
        {text}
      </ReactTooltip>
    </>
  );
};

IconTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  iconClass: PropTypes.string,
  text: PropTypes.string.isRequired
};

IconTooltip.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'top',
  iconClass: ''
};

export default IconTooltip;
