import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { FormikInput, FormikCheckBox } from '../../../components';

const ReviewInput = ({ errors, touched, reviewGroupIndex, index, fromType }) => {
  return (
    <Col>
      <Row className="checklist-point-container">
        <Col md={4}>
          <Field name={`company[reviewGroupsAttributes][${reviewGroupIndex}][reviewsAttributes][${index}][name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre de punto"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name={`company[reviewGroupsAttributes][${reviewGroupIndex}][reviewsAttributes][${index}][position]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Posición"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field
            name={`company[reviewGroupsAttributes][${reviewGroupIndex}][reviewsAttributes][${index}][imageRequired]`}
          >
            {({ field }) => (
              <FormikCheckBox {...field} className="m-t-30" field={field} label="Requiere imagen" custom />
            )}
          </Field>
        </Col>
        {fromType === 'mechanic' && (
          <Col md={3}>
            <Field
              name={`company[reviewGroupsAttributes][${reviewGroupIndex}][reviewsAttributes][${index}][workRequired]`}
            >
              {({ field }) => (
                <FormikCheckBox {...field} className="m-t-30" field={field} label="Requiere trabajo" custom />
              )}
            </Field>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default ReviewInput;
