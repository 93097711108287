import React from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { withFormik, Form, Field, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikInput, RichEditor } from '../../components';

const CollectionPaymentEmailForm = ({ errors, touched, handleClose, onHide, values, setFieldValue }) => {
  return (
    <Form>
      <Col md={12}>
        <Field name="email[subject]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              label="Asunto"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <Field name="email[body]">
          {({ field }) => (
            <RichEditor
              {...field}
              label="Cuerpo email cobranza"
              data={getIn(values, field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              setFieldValue={setFieldValue}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <HelpText />
      </Col>
      <Col md={12} className="mt-5">
        <Row className="d-flex justify-content-end">
          <Col md={3}>
            <Button className="btn cancel" variant="info" block onClick={handleClose}>
              Cancelar
            </Button>
          </Col>
          <Col md={3}>
            <Button type="submit" variant="success" block onClick={onHide}>
              Enviar Mail Cobranza
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

const HelpText = () => {
  return (
    <Alert variant="secondary">
      <p className="py-1 font-italic mb-0">
        Junto con este email, se enviará una copia del PDF de Presupuesto correspondiente a este proyecto.
      </p>
    </Alert>
  );
};

const setInitialValues = ({ collectionEmailTitle, collectionEmailBody, collectionEmailSecondaryBody, project }) => {
  const { parsedProjectRemainingUnpaid } = project;
  const unpaidAmountText = `<p>Le recordamos que aún mantiene un balance impago con nosotros por <strong>${parsedProjectRemainingUnpaid}</strong></p>`;
  const body = collectionEmailBody.concat(unpaidAmountText, collectionEmailSecondaryBody);
  return {
    email: {
      subject: collectionEmailTitle,
      body
    }
  };
};

const validationSchema = Yup.object().shape({
  email: Yup.object().shape({
    subject: Yup.string().required('Debes ingresar un Asunto'),
    body: Yup.string().required('Debes ingresar un cuerpo para el mail')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CollectionPaymentEmailForm);
