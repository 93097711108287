import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showCompanyRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/companies`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const destroyDocumentCompanyRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/companies/destroy_document`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateCompanyRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', '/companies', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const status2Chat = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/status_2chat`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const qr2Chat = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/qr_2chat`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceShowCompanyRequest = AwesomeDebouncePromise(showCompanyRequest, 300);
