import snakeCaseKeys from "snakecase-keys";
import api from '../services/api';

const createCalendarEvents = (companyCode, params) =>
  api.postAxios(`/public/companies/${companyCode}/calendar_events.json`, snakeCaseKeys(params)).then(res => res.data);

export const useCreateCalendarEvents = companyCode => {
  const create = async (values, options = {
    onSuccess: () => {}, onError: () => {}
  }) => {
    try {
      const data = await createCalendarEvents(companyCode, values);
      options.onSuccess(data);
    } catch (error) {
      options.onError(error);
    }
  };
  return { create };
};
