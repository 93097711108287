import React from 'react';
import {Nav, NavDropdown} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Can } from "../../../config/abilityContext";
import { scrollTop } from "../../../services/utils";
import columnsInvoiceMenu from "./ColumnsInvoiceMenu";

const InvoiceMenu = () => {
  return (
    <Nav className="nav-tab">
      <NavDropdown title="Facturación" id="invoice-nav-dropdown">
        {columnsInvoiceMenu.map((column, index) => (
          <Can I="read" a={column.can} key={index}>
            <NavDropdown.Item as={Link} onClick={scrollTop} to={column.path}>
              {column.name}
            </NavDropdown.Item>
          </Can>
        ))}
      </NavDropdown>
    </Nav>
  );
}
export default InvoiceMenu;