import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexProductInventoriesRequest = ({ dispatch, format, params = {}, successCallback, failureCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/product_inventories${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const companyIndexProductInventoriesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/inventories${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createProductInventoryRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/product_inventories', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateProductInventoryRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/product_inventories/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProductInventoryRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/product_inventories/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexProductInventoriesRequest = AwesomeDebouncePromise(indexProductInventoriesRequest, 300);

export const debounceCompanyIndexProductInventoriesRequest = AwesomeDebouncePromise(
  companyIndexProductInventoriesRequest,
  300
);
