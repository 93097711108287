import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTemplateNotificationsRequest = ({ dispatch, failureCallback, successCallback }) => {
  ApiService.requestV2('get', `/template_notifications`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const debounceIndexTemplateNotificationsRequest = AwesomeDebouncePromise(indexTemplateNotificationsRequest, 300);
