import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Can } from '../../config/abilityContext';

const DashboardIndex = () => {
  return (
    <Can I="read" a="Dashboard">
      <Row>
        <Col xs={12} lg={11} style={{ height: '90vh', margin: 'auto' }}>
          <iframe
            width="100%"
            height="100%"
            title="Dashboard de Proyectos"
            src="https://lookerstudio.google.com/embed/reporting/b7aa3c04-86fe-4ae0-b155-4356546d54ea/page/inslD"
            frameBorder="0"
            allowFullScreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          />
        </Col>
      </Row>
    </Can>
  );
};

export default DashboardIndex;
