export const defaultStates = [
  { label: 'En Proceso', value: '0' },
  { label: 'Ppto. Enviado', value: '1' },
  { label: 'Ppto. Aprobado', value: '2' },
  { label: 'Ppto. Parcial', value: '7' },
  { label: 'OT Finalizada', value: '4' },
  { label: 'Entregado', value: '5' },
  { label: 'Garantía', value: '6' },
  { label: 'Garantía y Entregado', value: '8' },
  { label: 'Rechazado', value: '3' }
];

export const defaultTypes = [
  { label: 'Venta Mesón', value: true },
  { label: 'Órdenes de Trabajo', value: false }
];

export const invoiceStates = [
  { label: 'No Facturado', value: 'unbilled' },
  { label: 'Por cobrar', value: 'billed' },
  { label: 'Pagada', value: 'paid' }
];
