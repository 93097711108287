import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';

function CardPricing() {
  return (
    <>
      <div className="p-0 col-pricing order-sm-2">
        <Card className="card-margin" style={{ width: '95%', margin: 'auto' }}>
          <Card.Body>
            <h2 className="text-pricing mb-0">2 UF/mes</h2>
            <p className="text-center font-italic">Mensual (no inc. IVA)</p>
            <p className="text-center font-weight-bold">
              Para talleres pequeños que realizan de 1 - 50 órdenes de trabajo mensuales
            </p>
            <div>
              <ul className="w-90 w-85-mobile">
                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">3 usuarios + 0,4 UF/mes usuario adicional</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Agenda</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Recepción de autos con foto</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Checklist de diagnóstico con foto</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Presupuestos</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Gestión de órdenes de servicio</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Inventario</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Gestión de compra de repuestos</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Reportes</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Informe horas mecánicos</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Mail automático al cliente</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Notificación vía whatsapp al cliente</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Integración con software de facturación (Bsale, Piriod)</p>
                  </div>
                </Row>
              </ul>
            </div>
            <Link className="btn btn-primary-blue-outline__landing button-font mt-2 w-70 button-center" to="/contact">
              Contratar
            </Link>
          </Card.Body>
        </Card>
      </div>

      <div className="p-0 col-pricing-principal order-sm-1">
        <Card className="card-margin" style={{ width: '95%', margin: 'auto' }}>
          <h4 className="recomended">Recomendado</h4>
          <Card.Body>
            <h2 className="text-pricing mb-0">3 UF/mes</h2>
            <p className="text-center font-italic">Mensual (no inc. IVA)</p>
            <p className="text-center font-weight-bold">
              Para talleres medianos que realizan de 51 - 100 órdenes de trabajo mensuales
            </p>
            <div>
              <ul className="w-80 w-85-mobile">
                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">3 usuarios + 0,4 UF/mes usuario adicional</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Agenda</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Recepción de autos con foto</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Checklist de diagnóstico con foto</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Presupuestos</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Gestión de órdenes de servicio</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Inventario</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Gestión de compra de repuestos</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Reportes</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Informe horas mecánicos</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Mail automático al cliente</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Notificación vía whatsapp al cliente</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Integración con software de facturación (Bsale, Piriod)</p>
                  </div>
                </Row>
              </ul>
            </div>
            <Link className="btn-primary-yellow__landing btn button-font mt-2" to="/contact">
              Contratar
            </Link>
          </Card.Body>
        </Card>
      </div>

      <div className="p-0 col-pricing order-sm-3">
        <Card className="card-margin" style={{ width: '95%', margin: 'auto' }}>
          <Card.Body>
            <h2 className="text-pricing mb-0">4UF/mes</h2>
            <p className="text-center font-italic">Mensual (no inc. IVA)</p>
            <p className="text-center font-weight-bold">
              Para talleres grandes que realizan de 100 y más órdenes de trabajo mensuales
            </p>
            <div>
              <ul className="w-90 w-85-mobile">
                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">3 usuarios + 0,4 UF/mes usuario adicional</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Agenda</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Recepción de autos con foto</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Checklist de diagnóstico con foto</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Presupuestos</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Gestión de órdenes de servicio</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Inventario</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Gestión de compra de repuestos</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Reportes</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Informe horas mecánicos</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Mail automático al cliente</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Notificación vía whatsapp al cliente</p>
                  </div>
                </Row>

                <Row>
                  <div className="col-1 sm-margin-bottom-15">
                    <svg className="card-pricing-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </div>
                  <div className="col">
                    <p className="ml-2">Integración con software de facturación (Bsale, Piriod)</p>
                  </div>
                </Row>
              </ul>
            </div>
            <Link className="btn btn-primary-blue-outline__landing button-font mt-2 w-70 button-center" to="/contact">
              Contratar
            </Link>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default CardPricing;
