import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Field, FieldArray, Form, Formik, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import { BasicTextArea, FormikInput, FormikSelect } from '../../components';
import { indexNpsFormsRequest } from '../../requests/npsForms';
import { updateCompanyRequest } from '../../requests/companies';
import { sendAlert } from '../../actions/utils';

const NpsQuestionForm = ({ handleModalClose }) => {
  const [initialValues, setInitialValues] = useState({ npsFormDescription: '', npsQuestionsAttributes: [] });
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.user);

  const fetchQuestions = () => {
    indexNpsFormsRequest({
      dispatch,
      successCallback: ({ data }) => {
        const npsQuestionsAttributes = data.nps_questions_attributes;
        if (npsQuestionsAttributes.length === 0)
          npsQuestionsAttributes.push({
            label: `¿Recomendarías ${currentUser.companyName} a un familiar o amigo?`,
            kind: 'ranking'
          });
        setInitialValues({
          npsFormDescription: data.nps_form_description,
          npsQuestionsAttributes
        });
      }
    });
  };

  useEffect(fetchQuestions, []);

  const validationSchema = Yup.object().shape({
    npsFormDescription: Yup.string().required('El campo es requerido'),
    npsQuestionsAttributes: Yup.array().of(
      Yup.object().shape({
        kind: Yup.string().required('El campo es requerido'),
        label: Yup.string().required('El campo es requerido')
      })
    )
  });

  const handleSubmit = values => {
    updateCompanyRequest({
      dispatch,
      params: { company: snakeCaseKeys(values) },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Preguntas guardadas correctamente' }));
        handleModalClose();
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <Field name="npsFormDescription">
            {({ field, form }) => (
              <BasicTextArea
                {...field}
                label="Descripción"
                abbr
                error={getIn(form.errors, field.name)}
                touched={getIn(form.touched, field.name)}
              />
            )}
          </Field>
          <FieldArray name="npsQuestionsAttributes">
            {({ push }) => (
              <>
                {values.npsQuestionsAttributes.map((question, index) => (
                  <fieldset key={index} className="mb-3">
                    <legend>Pregunta {index + 1}</legend>
                    <Row>
                      <Col md={12}>
                        <Field name={`npsQuestionsAttributes[${index}].kind`}>
                          {({ field, form }) => (
                            <FormikSelect
                              {...field}
                              abbr
                              label="Tipo de pregunta"
                              isDisabled={index === 0}
                              error={getIn(form.errors, field.name)}
                              touched={getIn(form.touched, field.name)}
                              options={[
                                { value: 'ranking', label: 'Ranking' },
                                { value: 'text', label: 'Texto' }
                              ]}
                              defaultValue={question.kind}
                              onChange={data => {
                                form.setFieldValue(field.name, data?.value);
                              }}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Field name={`npsQuestionsAttributes[${index}].label`}>
                          {({ field, form }) => (
                            <FormikInput
                              {...field}
                              abbr
                              label="Etiqueta"
                              disabled={index === 0}
                              error={getIn(form.errors, field.name)}
                              touched={getIn(form.touched, field.name)}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </fieldset>
                ))}
                <Button
                  onClick={() => push({ label: '', kind: '' })}
                  disabled={values.npsQuestionsAttributes.length > 4}
                >
                  Agregar +
                </Button>
              </>
            )}
          </FieldArray>
          <Row className="d-flex justify-content-end mt-3">
            <Col md={2}>
              <Button className="btn mb-3" variant="primary" block onClick={handleModalClose}>
                Cancelar
              </Button>
            </Col>
            <Col md={2}>
              <Button type="submit" block variant="success">
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default NpsQuestionForm;
