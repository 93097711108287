import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { SimpleCenteredModal } from '../../components';
import WorkDataTable from './WorkDataTable';
import columns from './Columns';
import { sendAlert } from '../../actions/utils';
import { createWorkRequest } from '../../requests/works';
import WorkForm from './WorkForm';
import { uuid } from "../../utils/utils";

const basicWork = {
  copyPrice: false,
  manHour: '',
  name: '',
  vehicleBrandId: '',
  vehicleModelId: '',
  workDetailsAttributes: [
    {
      id: '',
      categoryId: '',
      price: '',
      productCategoryId: '',
      productId: '',
      productNameCustom: '',
      quantity: '',
      unitPrice: '',
      key: uuid(),
    }
  ]
};

const WorkIndex = () => {
  const dispatch = useDispatch();
  const [modalBody, setModalBody] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Trabajo creado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createWorkRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  const handleModalCreate = () => {
    setModalBody(
      <WorkForm
        action="new"
        dispatch={dispatch}
        formRequest={handleCreateRequest}
        handleModalClose={handleModalClose}
        submitVariant="success"
        work={basicWork}
      />
    );
    setModalShow(true);
    setModalTitle('Crear Trabajo');
  };

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">Trabajos</h2>
            </Col>
            <Col md={2}>
              <Button variant="primary" block onClick={handleModalCreate}>
                Crear
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <WorkDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />
            </Col>
          </Row>
          <SimpleCenteredModal
            title={modalTitle}
            body={modalBody}
            show={modalShow}
            onHide={handleModalClose}
            centered={false}
            closeButton
          />
        </Col>
      </Row>
    </>
  );
};

export default WorkIndex;
