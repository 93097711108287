import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../components';
import { sendAlert } from '../../actions/utils';
import { showUserRequest, updateUserRequest } from '../../requests/user';
import ProfileForm from './ProfileForm';

const ProfileEdit = () => {
  const [user, setUser] = useState({
    avatarInfo: {},
    companyAttributes: {
      address: '',
      businessActivity: '',
      businessLogo: {},
      email: '',
      legalName: '',
      name: '',
      nationalIdentification: '',
      phone: ''
    },
    email: '',
    name: '',
    lastName: '',
    roles: []
  });
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.auth);
  const history = useHistory();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchUser = () => {
    const userId = currentUser.id;
    if (userId !== 0) {
      showUserRequest(userId, {
        dispatch,
        successCallback: response => setUser(camelCaseRecursive(response.data)),
        failureCallback: handleFailureRequest
      });
    }
  };

  useEffect(fetchUser, [currentUser]);

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Perfil actualizado con éxito' }));
    history.push('/profile');
  };

  const handleUpdateRequest = params => {
    const { user: paramsUser } = params;
    const sendParams = {
      user: {
        ...paramsUser,
        last_name: paramsUser.lastName,
        whatsapp_phone_number: paramsUser.whatsappPhoneNumber
      }
    };

    updateUserRequest(currentUser.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col md={{ span: 8, offset: 1 }}>
          <h2 className="text-uppercase">Editar Perfil</h2>
        </Col>
        <Col md={2}>
          <LinkBtn className="cancel" variant="primary" block to="/profile">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <ProfileForm user={user} action="edit" submitVariant="success" formRequest={handleUpdateRequest} fromEdit />
        </Col>
      </Row>
    </>
  );
};

export default ProfileEdit;
