import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ProjectClientDetails from './ProjectClientDetails';

const ProjectClient2 = ({ client }) => {
  return (
    <>
      <Row>
        <Col md="11" className="justify-content-end">
          <Row>
            <Col md={6}>
              <strong>Cliente 2</strong>
            </Col>
            <Col md={6}>
              <strong>Datos de Contacto Cliente 2</strong>
            </Col>
          </Row>
        </Col>
      </Row>
      <ProjectClientDetails client={client} />
    </>
  );
};

export default ProjectClient2;
