import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { LinkBtn } from '../Button';

const handleDelete = (arrayValues, setFieldValue, valuePath, index, setWithMethod) => {
  const arrayValuesLength = arrayValues.length;
  const deleteValue = arrayValues[index];
  if (deleteValue.id && deleteValue !== '') {
    deleteValue._destroy = 'true';
    if (setWithMethod) {
      return setWithMethod(deleteValue);
    }
    return setFieldValue(`${valuePath}[${index}]`, deleteValue);
  }
  const newValues = [...arrayValues.slice(0, index), ...arrayValues.slice(index + 1, arrayValuesLength)];
  if (setWithMethod) {
    return setWithMethod(newValues);
  }
  return setFieldValue(`${valuePath}`, newValues);
};

const addRow = (arrayValues, setFieldValue, valuePath, newAttributes, setWithMethod) => {
  const newValues = [...arrayValues, newAttributes];
  if (setWithMethod) {
    return setWithMethod(newValues);
  }
  return setFieldValue(`${valuePath}`, newValues);
};

// Esta pensado para funcionar con los values de formik, para efecto de funcionar en el ejemplo de componentes
// Lo voy a cambiar un poco
const NestedBody = ({
  arrayValues,
  body,
  deleteLastOne,
  index,
  inputLength,
  setFieldValue,
  shownLength,
  valuePath,
  setWithMethod
}) => (
  <>
    {body}
    {body && (
      <Col md={2}>
        {deleteLastOne
          ? index === inputLength &&
            inputLength !== 0 &&
            shownLength !== 0 && (
              <Button
                variant="dark"
                className="mr-2 btn-circle"
                size="sm"
                onClick={() => handleDelete(arrayValues, setFieldValue, valuePath, index, setWithMethod)}
              >
                X
              </Button>
            )
          : inputLength !== 0 &&
            shownLength !== 0 && (
              <Button
                variant="dark"
                className="mr-2 btn-circle"
                size="sm"
                onClick={() => handleDelete(arrayValues, setFieldValue, valuePath, index, setWithMethod)}
              >
                X
              </Button>
            )}
      </Col>
    )}
  </>
);
const NestedForm = ({
  setFieldValue,
  newAttributes,
  mapInputs,
  arrayValues,
  valuePath,
  addTitle,
  deleteLastOne,
  setWithMethod
}) => {
  const inputLength = mapInputs.length - 1;
  const shownLength =
    mapInputs.filter(e => {
      return e !== undefined;
    }).length - 1;
  const nestedBody = mapInputs.map((body, index) => (
    <NestedBody
      key={`nested-${index.toString()}`}
      arrayValues={arrayValues}
      body={body}
      deleteLastOne={deleteLastOne}
      index={index}
      inputLength={inputLength}
      setFieldValue={setFieldValue}
      shownLength={shownLength}
      valuePath={valuePath}
      setWithMethod={setWithMethod}
    />
  ));
  return (
    <>
      {nestedBody}
      <Col md={12}>
        <LinkBtn
          variant="primary"
          to="#"
          onClick={() => addRow(arrayValues, setFieldValue, valuePath, newAttributes, setWithMethod)}
        >
          {addTitle}
        </LinkBtn>
      </Col>
    </>
  );
};

export default NestedForm;
