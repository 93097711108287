import React from 'react';
import NestedAttributes from '../../components/Utils/NestedAttributes/NestedAttributes';
import WorkDetailInput from './WorkDetailInput';
import { uuid } from "../../utils/utils";

const WorkDetailAdd = ({ touched, errors, setFieldValue, values }) => {
  const { workDetailsAttributes } = values.work;

  const mapResults = workDetailsAttributes.map((workDetail, index) => {
    if (workDetail._destroy) {
      return undefined;
    }

    return (
      <WorkDetailInput
        index={index}
        touched={touched}
        errors={errors}
        setFieldValue={setFieldValue}
        workDetail={workDetail}
        values={values}
      />
    );
  });

  return (
    <NestedAttributes
      removeFirstItem
      mapInputs={mapResults}
      arrayValues={workDetailsAttributes}
      setFieldValue={setFieldValue}
      valuePath="work[workDetailsAttributes]"
      isDraggable
      newAttributes={{
        categoryId: '',
        copyPrice: false,
        price: '',
        productCategoryId: '',
        productId: '',
        productNameCustom: '',
        quantity: '',
        unitPrice: '',
        key: uuid()
      }}
      btnMessage="Agregar nuevo detalle trabajo"
    />
  );
};

export default WorkDetailAdd;
