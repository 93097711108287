import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Table } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { Edit, Trash } from 'react-feather';

import { sendAlert } from '../../actions/utils';
import { DefaultModal, SimpleCenteredModal } from '../../components';
import { deleteProjectPaymentRequest, updateProjectPaymentRequest } from '../../requests/projectPayments';
import AddProjectPayment from './AddProjectPayment';
import ProjectPaymentForm from './ProjectPaymentForm';

const ProjectPaymentsShow = ({ project, publicVersion, setMoreData, version }) => {
  const { id, parsedTotalPayments, projectPaymentsAttributes, projectInvoicesAttributes: projectInvoices } = project;
  const dispatch = useDispatch();
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setDefaultModalShow(false);
    setModalBody('');
    setModalItem(null);
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessAction = (response, message) => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(m => !m);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleDeleteRequest = () => {
    deleteProjectPaymentRequest(id, modalItem.id, {
      dispatch,
      successCallback: response => handleSuccessAction(response, 'Abono eliminado con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateRequest = values => {
    const {
      projectPayment: { id: projectPaymentId }
    } = values;
    updateProjectPaymentRequest(id, projectPaymentId, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: response => handleSuccessAction(response, 'Abono editado con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'edit':
        setModalBody(<ProjectPaymentForm projectPayment={item} action="edit" projectInvoices={projectInvoices}
                                         formRequest={handleUpdateRequest} />);
        setModalItem(item);
        setModalShow(true);
        setModalTitle('Editar Abono');
        break;
      case 'destroy':
        setDefaultModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar este abono?');
        setModalItem(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const mapProjectPayments = projectPaymentsAttributes.map((projectPayment, index) => {
    return (
      <tr key={`project-payment-${index.toString()}`}>
        <td>{projectPayment.translatedPaymentType}</td>
        <td>{projectPayment.date}</td>
        <td>{projectPayment.parsedAmount}</td>
        <td>{projectPayment.documentNames}</td>
        <td>{projectPayment.parsedBalance}</td>
        {!publicVersion && (
          <td className="text-center">
            <Edit
              width="18px"
              height="18px"
              className="clickable delete-item text-warning mr-3"
              onClick={() => handleButtonClick(projectPayment, 'edit')}
            />
            <Trash
              width="18px"
              height="18px"
              className="clickable delete-item text-danger"
              onClick={() => handleButtonClick(projectPayment, 'destroy')}
            />
          </td>
        )}
      </tr>
    );
  });
  mapProjectPayments.push(
      <tr key="project-payment-total">
        <th colSpan={2}>Total</th>
        <th>{parsedTotalPayments}</th>
        <th colSpan={2}></th>
        {!publicVersion && <th/>}
      </tr>
  );

  return (
    <>
      <Row className="mb-3">
        <Col className="section-header">
          <strong>ABONOS</strong>
          <AddProjectPayment project={project} version={version} setMoreData={setMoreData} />
        </Col>
      </Row>
      <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr className="text-uppercase fit-content">
            <th>Tipo</th>
            <th>Fecha</th>
            <th>Monto</th>
            <th>Docs.</th>
            <th>Saldo</th>
            {!publicVersion && <th style={{ width: '80px' }} />}
          </tr>
        </thead>
        <tbody>{mapProjectPayments}</tbody>
      </Table>
      {defaultModalShow && (
        <DefaultModal
          title="Eliminar abono"
          body={modalBody}
          show={defaultModalShow}
          handleClose={handleModalClose}
          handleConfirm={handleDeleteRequest}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={handleModalClose} closeButton />
    </>
  );
};

export default ProjectPaymentsShow;
