import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';

const MechanicCard = ({ project, title }) => {
  const {
    id,
    belatedProject,
    client,
    countCompletedWorks,
    createdBy,
    formatedPromiseDate,
    otState,
    projectWorksCount,
    translatedOtState,
    vehicle = {},
    checklistsAttributes
  } = project;
  const { plate, vehicleBrandName, vehicleModelName } = vehicle || {};
  const { fullName, id: clientId } = client || {};
  const { email } = createdBy;
  const isInProcess = title === 'inProcess';
  const isWorking = title === 'working';
  const [backgroundColor, setBackgroundColor] = useState('');
  const history = useHistory();
  const {
    user: { roleIds }
  } = useSelector(state => state.auth);
  const checklists = checklistsAttributes?.filter(checklist => checklist.checklistType !== 'output');

  const logicColor = () => {
    const hasCompletedAllWorks = countCompletedWorks === projectWorksCount;
    return {
      hasCompletedAllWorks
    };
  };

  const handleColors = () => {
    let bgColor = 'light-darker';
    const { hasCompletedAllWorks } = logicColor();
    if (isWorking) {
      if (otState === 'partial_budget') bgColor = 'primary';
      if (hasCompletedAllWorks) bgColor = 'completed';
      if (otState === 'rejected') bgColor = 'danger';
      if (otState === 'warranty' || otState === 'delivered_warranty') bgColor = 'info';
    }
    if (isInProcess) {
      let count = checklists.reduce(
        (acc, value) => {
          if (value.progressPercentage === '100%') {
            acc['complete']++;
          } else {
            acc['incomplete']++;
          }
          return acc;
        },
        { complete: 0, incomplete: 0 }
      );
      bgColor = 'secondary';
      if (count.incomplete === checklists.length) {
        bgColor = 'light-darker';
      }
      if (count.complete === checklists.length) {
        bgColor = 'success';
      }
    }
    setBackgroundColor(bgColor);
  };

  useEffect(handleColors, [project]);

  const handleRedirect = () => {
    if (roleIds.includes(1) || roleIds.includes(7)) {
      history.push(`/projects/${id}`);
    } else {
      history.push(`/project_mechanic_reviews/${id}`);
    }
  };

  return (
    <Col md={12} className="custom-card-style mb-4">
      <Card className={`card-predefined-size border-${backgroundColor}`}>
        <Card.Body className="pr-2 pb-0 small">
          <Row className="body-row">
            <Col xs={11}>
              <Row className="body-info">
                <Col xs={6} className="clickable mb-2" onClick={handleRedirect}>
                  <h3 className="font-weight-bold">OT #{id}</h3>
                </Col>
                <Col xs={6} className="mb-3">
                  <span>{email}</span>
                </Col>
                <Col xs={6} className="pr-0">
                  <span>
                    Vehículo:
                    {plate ? (
                      <Link className="ml-1" to={`/projects?plate=${plate}`}>
                        {vehicleBrandName} {vehicleModelName} {plate}
                      </Link>
                    ) : (
                      <span className="ml-1">SIN VEHÍCULO ASOCIADO</span>
                    )}
                  </span>
                </Col>
                <Col xs={6}>
                  <span>
                    Cliente: <Link to={`/projects?client_id=${clientId}`}>{fullName}</Link>
                  </span>
                </Col>
                {isInProcess && (
                  <Col xs={12} className="pr-0">
                    <span>Total Trabajos:</span> {projectWorksCount}
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={1} className={`p-0 tag-style bg-${backgroundColor}`} />
          </Row>
        </Card.Body>
        <Card.Footer className="pt-0 border-top-0 bg-white">
          <Row>
            <Col xs={7} className={`align-self-sm-center small pr-2 ${belatedProject ? 'text-danger' : ''}`}>
              <span className="font-weight-bold">Entrega:</span> {formatedPromiseDate} {belatedProject && '(atrasada)'}
            </Col>
            <Col xs={5} className={`text-capitalize font-weight-bold pl-0 text-${backgroundColor}`}>
              {isInProcess ? (
                checklists.map((checklistType, index) => (
                  <p
                    key={index}
                    className={`d-flex justify-content-between mb-0 text-${
                      checklistType.progressPercentage === '100%' ? 'success' : 'light-darker'
                    }`}
                  >
                    <span>{checklistType.name}</span> {checklistType.progressPercentage}
                  </p>
                ))
              ) : (
                <>
                  <p className="text-right mb-0">{translatedOtState}</p>
                  <p className="text-right mb-0">
                    Trabajos {countCompletedWorks}/{projectWorksCount}
                  </p>
                </>
              )}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default MechanicCard;
