import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { AlertCircle, CheckCircle, MinusCircle, XCircle } from 'react-feather';

import { BasicTextArea, FormikInput, IconTooltip, SimpleCenteredModal } from '../../../components';
import PurchaseManagementDetailTable from '../../../components/PurchaseManagement';

const basicData = {
  id: '',
  notes: '',
  parsedSalePrice: '',
  parsedTotalPrice: '',
  parsedUnitPrice: '',
  productNameSelected: '',
  projectDetail: {},
  projectDetailNotes: '',
  quantityPurchased: '',
  salePrice: '',
  state: '',
  totalPrice: '',
  totalQuantity: '',
  unitPrice: ''
};

const PurchaseManagementDetailCard = ({ index, purchaseManagementDetail, ...props }) => {
  const [data, setData] = useState(basicData);
  const [itemState, setItemState] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [stateIcon, setStateIcon] = useState('');
  const [stateMsg, setStateMsg] = useState('');
  const [stateVariant, setStateVariant] = useState('');

  const incompletePurchase = itemState === 'incomplete';
  const { isDisabled, sendParams, setSendParams } = props;
  const {
    id,
    notes,
    parsedSalePrice,
    parsedTotalPrice,
    parsedUnitPrice,
    productNameSelected,
    projectDetail,
    projectDetailNotes,
    quantity,
    quantityPurchased
  } = data;

  const handleData = () => {
    setData(purchaseManagementDetail);
    setItemState(purchaseManagementDetail.state);
  };

  const handleSendParams = () => {
    const details = sendParams.purchaseManagementDetailsAttributes;
    details[index] = { id, notes, quantityPurchased, state: itemState };
    const params = { purchaseManagementDetailsAttributes: details };
    setSendParams(params);
  };

  const handleChangeState = () => {
    let icon;
    let translation;
    let variant;
    switch (itemState) {
      case 'pending':
        icon = <MinusCircle />;
        translation = '';
        variant = 'warning';
        break;
      case 'purchased':
        icon = <CheckCircle />;
        translation = 'Compra completa';
        variant = 'success';
        break;
      case 'canceled':
        icon = <XCircle />;
        translation = 'No se pudo realizar la compra';
        variant = 'danger';
        break;
      case 'incomplete':
        icon = <AlertCircle />;
        translation = 'Cantidad requerida incompleta';
        variant = 'incomplete';
        break;
      default:
        break;
    }

    setStateIcon(icon);
    setStateMsg(translation);
    setStateVariant(variant);
  };

  const handleClickState = () => {
    const btnStates = { pending: 'purchased', purchased: 'canceled', canceled: 'incomplete', incomplete: 'pending' };
    setItemState(btnStates[itemState]);
  };

  const purchaseInfo = () => {
    setModalShow(true);
    setModalBody(<PurchaseManagementDetailTable purchaseManagementDetails={[data]} />);
  };

  useEffect(handleData, [purchaseManagementDetail]);
  useEffect(handleSendParams, [data, itemState]);
  useEffect(handleChangeState, [itemState]);

  return (
    <Col md={6} lg={4}>
      <Card className="card-detail">
        <Card.Body>
          <Row>
            <Col xs={4} className="mb-2">
              OT: {projectDetail.projectId}
            </Col>
            <Col xs={8} className={`card-detail__state-msg text-${stateVariant}`}>
              {stateMsg}
            </Col>
            <Col xs={9} className="card-detail__title">
              <h3>
                {productNameSelected}
                {projectDetailNotes && (
                  <IconTooltip
                    id={`notes-${index.toString()}`}
                    text={projectDetailNotes}
                    iconName="Info"
                    iconClass="ml-2"
                  />
                )}
              </h3>
            </Col>
            <Col xs={3} className="card-detail__state-btn">
              <Button disabled={isDisabled} variant={stateVariant} className="btn-circle" onClick={handleClickState}>
                {stateIcon}
              </Button>
            </Col>
            <Col xs={12} className="my-2">
              <Row className="box-info">
                <Col xs={6}>
                  <p>
                    <span className="box-title">C/C Referencial:</span> {parsedUnitPrice}
                  </p>
                </Col>
                <Col xs={6}>
                  <p>
                    <span className="box-title">Costo Total:</span> {parsedTotalPrice}
                  </p>
                </Col>
                <Col xs={6}>
                  <p>
                    <span className="box-title">Precio Venta Total:</span> {parsedSalePrice}
                  </p>
                </Col>
                <Col xs={6} className="card-detail__detail-btn">
                  <Button variant="light" onClick={purchaseInfo}>
                    Ver detalle
                  </Button>
                  <SimpleCenteredModal
                    size="xl"
                    title={productNameSelected}
                    body={modalBody}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </Col>
                <Col xs={6}>
                  <p className="box-non-grid text-uppercase font-weight-bold text-dark">
                    <span className="box-title">Cantidad:</span> {quantity}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {incompletePurchase && (
              <Col xs={12}>
                <FormikInput
                  inputType="number"
                  label="Cantidad comprada"
                  labelColor="text-incomplete"
                  placeholder="Ingresar cantidad"
                  value={quantityPurchased}
                  onChange={({ target }) => setData({ ...data, quantityPurchased: target.value })}
                  disabled={isDisabled}
                />
              </Col>
            )}
            <Col xs={12}>
              <BasicTextArea
                placeholder="Agregar nota"
                value={notes}
                onChange={({ target }) => setData({ ...data, notes: target.value })}
                disabled={isDisabled}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default PurchaseManagementDetailCard;
