import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { SimpleCenteredModal, FormikSelect } from '../../../components';
import { sendEmailRequest } from '../../../requests/projects';
import { sendAlert } from '../../../actions/utils';

const ProjectProgressEmailButton = ({ variant = 'info', project }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const { id } = project;

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    handleModalClose();
  };

  const handleSuccessSendCollectionEmail = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Mail de recordatorio ha sido enviado exitosamente' }));
    handleModalClose();
  };

  const handleSendEmail = type => {
    const sendParams = {
      otState: type
    };
    sendEmailRequest({
      id,
      type: 'progress_ot_state_email',
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: handleSuccessSendCollectionEmail,
      failureCallback: handleFailureRequest
    });
  };

  const handleOpenModal = () => {
    setModalShow(true);
    setModalBody(<ProgressEmailForm project={project} formRequest={handleSendEmail} handleClose={handleModalClose} />);
  };

  return (
    <>
      <Button variant={variant} className="w-100 d-block" onClick={handleOpenModal}>
        Enviar Email Progreso Estado
      </Button>
      {modalShow && (
        <SimpleCenteredModal
          title="Enviar email progreso de estado"
          body={modalBody}
          show={modalShow}
          onHide={handleModalClose}
          closeButton
        />
      )}
    </>
  );
};

export default ProjectProgressEmailButton;

const otStates = [
  { label: 'Recepción', value: 'reception' },
  { label: 'Revisado por Mecánico', value: 'mechanic' },
  { label: 'Ppto. Enviado', value: 'budget_sent' },
  { label: 'Ppto. Aprobado', value: 'budget_approved' },
  { label: 'Entregado', value: 'delivered' }
];

const ProgressEmailForm = ({ handleClose, formRequest }) => {
  const [selectedOtState, setSelectedOtState] = useState('');

  return (
    <>
      <Alert variant="secondary">
        <p className="py-1 font-italic mb-0">Seleccione un estado sobre el cual quiere informar avance.</p>
      </Alert>
      <Col md={12}>
        <FormikSelect
          abbr
          label="Estado a recordar"
          placeholder="Seleccionar Estado OT de notificación..."
          options={otStates}
          onChange={data => setSelectedOtState(data.value)}
        />
      </Col>
      <Col md={12} className="mt-5">
        <Row className="d-flex justify-content-end">
          <Col md={3}>
            <Button className="btn cancel" variant="light-darker" block onClick={handleClose}>
              Cancelar
            </Button>
          </Col>
          <Col md={3}>
            <Button
              type="submit"
              disabled={!selectedOtState}
              variant="success"
              block
              onClick={() => formRequest(selectedOtState)}
            >
              Enviar Mail Recordatorio
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};
