import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexProjectsRequest = ({ dispatch, format, params = {}, successCallback, failureCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/projects${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showProjectRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/projects/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showProjectVersionRequest = (
  projectId,
  version,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/projects/${projectId}/project_versions/${version}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showProjectLogsRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/projects/${id}/logs`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createProjectRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/projects', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const createProjectVersionRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', `/projects/${id}/project_versions`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateProjectRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/projects/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const updateSpecificAttributesRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('patch', `/projects/${id}/update_specific_attributes`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const updateAdvancedConfigurationRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('patch', `/projects/${id}/advanced_configuration`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProjectRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/projects/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const deleteProjectReviewImageRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/projects/${id}/destroy_project_review_image`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const deleteProjectWorkImageRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/projects/${id}/destroy_project_work_image`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const getChecklistPdfRequest = ({
  id,
  type,
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('getBlob', `/projects/${id}/export_checklist${formatt}?type=${type}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const getPdfRequest = ({ id, type, dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('getBlob', `/projects/${id}/${type}${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const getOldVersionPdfRequest = ({
  id,
  type,
  versionNum,
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('getBlob', `/projects/${id}/project_versions/${versionNum}/${type}${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const sendEmailRequest = ({ id, type, dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('post', `/projects/${id}/${type}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createTemplateFromProjectRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('post', `/projects/${id}/templates`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const publicShowProjectRequest = (token, version, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/public/projects/${token}/${version}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const publicShowPrivateProjectRequest = (token, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/public/show_projects/${token}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const publicUpdateProjectRequest = (token, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/public/projects/${token}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const publicUpdateProjectWorksApprovalStatusRequest = (token, version, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/public/projects/${token}/${version}/works_approval_status`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProjectAutopartImageRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/projects/${id}/destroy_project_autopart_image`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  }
);

export const updateQuoteRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/projects/${id}/update_quote`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const debounceIndexProjectsRequest = AwesomeDebouncePromise(indexProjectsRequest, 300);
