import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CardPricing from './CardPricing';
import Tool1 from '../../../assets/images/tools1.webp';

const PlansSection = () => {
  return (
    <>
      <Row id="pricing">
        <div>
          <img
            className="img-tool1"
            src={Tool1}
            alt="Tornillos decorativos"
            title="Tornillos decorativos"
            width="100%"
            height="auto"
          />
        </div>

        <div>
          <Row className="mb-4">
            <Col className="text-center pl-3 pr-3">
              <h3 className="text-center">Planes</h3>
              <p className="white-text  w-85-mobile">
                Nuestros planes se diferencian según el tamaño del taller. Contarás con tres usuarios y la posibilidad
                de crear adicionales por 0.4UF mensual.
              </p>
              <p className="white-text w-85-mobile">* Consulta por promociones y beneficios</p>
            </Col>
          </Row>
          <Row style={{ width: '95%', margin: 'auto', alignItems: 'center' }}>
            <CardPricing />
          </Row>
        </div>
      </Row>
    </>
  );
};

export default PlansSection;
