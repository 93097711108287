const mechanicAbility = can => {
  can('create', 'Client');
  can('create', 'Incident');
  can('create', 'DropZoneFile');
  can('create', 'Log');
  can('create', 'Project');
  can('create', 'ProjectReview');
  can('create', 'ProjectWork');
  can('create', 'Vehicle');
  can('destroy', 'CalendarEvent');
  can('destroy', 'DropZoneFile');
  can('destroy', 'ProjectWork');
  can('destroy_document', 'User');
  can('read', 'CalendarEvent');
  can('read', 'Client');
  can('read', 'Company');
  can('read', 'Commune');
  can('read', 'IncidentType');
  can('read', 'Product');
  can('read', 'ProductCategory');
  can('read', 'Project');
  can('read', 'ProjectReview');
  can('read', 'ProjectWork');
  can('read', 'Province');
  can('read', 'Region');
  can('read', 'Setting');
  can('read', 'Slide');
  can('read', 'User');
  can('read', 'Vehicle');
  can('read', 'VehicleBrand');
  can('read', 'VehicleModel');
  can('read', 'Work');
  can('reception', 'Project');
  can('update_password', 'User');
  can('update', 'Project');
  can('update', 'ProjectReview');
  can('update', 'ProjectWork');
  can('update', 'User');
  can('update_specific_attributes', 'Project');
};

export default mechanicAbility;
