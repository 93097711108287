import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal, DefaultModal } from '../../../components';
import { debounceIndexClientsRequest } from '../../../requests/clients';
import { createProjectInvoiceRequest } from '../../../requests/projectInvoices';
import ProjectInvoiceForm from './ProjectInvoiceForm';
import moment from 'moment/moment';

const AddProjectInvoice = ({ project, setMoreData, version }) => {
  const { id: projectId, clientId, net, projectPaymentsAttributes: projectPayments, total, companyTaxRate } = project;
  const [clients, setClients] = useState([]);
  const [currentClient, setCurrentClient] = useState({});
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [originalValues, setOriginalValues] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const basicProjectInvoice = {
    invoiceNumber: '',
    id: '',
    amount: parseInt(net),
    clientId,
    issueDate: new Date(),
    expirationDate: moment()
      .add(1, 'months')
      .toDate(),
    description: '',
    isAssociateInvoice: true,
    total: total,
    companyTaxRate: parseFloat(companyTaxRate)
  };

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setCurrentClient({});
    setOriginalValues({});
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessCreate = () => {
    setMoreData(m => !m);
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Factura asociada con éxito' }));
    setOnRequest(false);
  };

  const handleCreateRequest = values => {
    const sendParams = snakecaseKeys(values);
    createProjectInvoiceRequest(projectId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
    setOnRequest(true);
  };

  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.is_company ? element.legal_name : element.full_name,
        value: element.id
      };
    });
    setClients(camelCaseRecursive(tempArray));
    return tempArray;
  };

  const fetchClients = (inputValue, callback) => {
    debounceIndexClientsRequest({
      dispatch,
      params: {
        query: inputValue || clientId,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const handleOnClickDefaultModal = () => {
    setModalShow(false);
    setDefaultModalShow(true);
    setDefaultModalBody(
      <>
        <p>Al seleccionar Editar Cliente, abandonarás esta vista y todos los datos que no han sido guardados.</p>
        <br />
        <p>Confirmar para continuar.</p>
      </>
    );
  };

  const handleDefaultModalConfirm = () => {
    if (currentClient && Object.keys(currentClient).length > 0) {
      history.push(`/clients/${currentClient.id}/edit`);
    }
  };

  const handleDefaultModalClose = () => {
    setDefaultModalShow(false);
    setModalShow(true);
  };

  const handleModalBody = () => {
    setModalBody(
      <>
        <ProjectInvoiceForm
          action="new"
          clients={clients}
          projectInvoice={basicProjectInvoice}
          fetchClients={fetchClients}
          formRequest={handleCreateRequest}
          handleModalClose={handleModalClose}
          onClickEditClient={handleOnClickDefaultModal}
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
          originalValues={originalValues}
          setOriginalValues={setOriginalValues}
          initialClientId={clientId}
          projectPayments={projectPayments}
          associateInvoice
        />
      </>
    );
  };

  useEffect(handleModalBody, [originalValues, clients, currentClient, onRequest]);

  const handleClick = () => {
    handleModalBody();
    setModalShow(true);
  };

  return (
    <>
      <Button hidden={version} variant="light-darker" className="add-payment-btn mt-2 mr-2" block onClick={handleClick}>
        Asociar Documento
      </Button>
      <SimpleCenteredModal title="Asociar nuevo Documento" body={modalBody} show={modalShow} onHide={handleModalClose} />
      <DefaultModal
        title="Confirmar"
        body={defaultModalBody}
        show={defaultModalShow}
        handleClose={handleDefaultModalClose}
        handleConfirm={handleDefaultModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default AddProjectInvoice;
