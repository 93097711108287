import React, {useEffect, useState} from "react";
import camelCaseRecursive from "camelcase-keys-recursive";
import { Button } from 'react-bootstrap';
import {useDispatch} from "react-redux";
import {debounceIndexClientsRequest} from "../../requests/clients";
import {sendAlert} from "../../actions/utils";
import {InputRemoteSelect} from "../../components";

const ClientSelect = ({clientDefault, formRequest}) => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({label: '', value: ''});
  const dispatch = useDispatch();

  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.full_name,
        value: element.id
      };
    });
    setClients(camelCaseRecursive(tempArray));
    setClient({label: clientDefault?.fullName, value: clientDefault?.id});
    return tempArray;
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
  };

  const fetchClients = (inputValue, callback) => {
    debounceIndexClientsRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const handleChange = (value) => {
    setClient(value);
  }

  const saveClient = () => {
    formRequest({client_id: client.value});
  }

  useEffect(() => {
    if (clientDefault) {
      setClient({label: clientDefault.fullName, value: clientDefault.id});
    }
  }, [clientDefault]);

  return (
    <div>
      <InputRemoteSelect
        label="Cliente"
        placeholder="Seleccionar Cliente"
        options={clients}
        value={clients.find(data => data.id === client?.value)}
        request={fetchClients}
        onChange={data => handleChange(data)}
      />
      <Button variant="success" className="mt-3" onClick={saveClient}>Guardar</Button>
    </div>
  );
}

export default ClientSelect;
