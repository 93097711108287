import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showProductMovementRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/product_movements/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexProductMovementsRequest = ({ dispatch, format, params = {}, successCallback, failureCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/product_movements${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexWithDetailsProductMovementsRequest = ({
  dispatch,
  format,
  params = {},
  successCallback,
  failureCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/detailed_product_movements${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const companyIndexProductMovementsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/movements${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const companyIndexWithDetailsProductMovementsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/detailed_movements${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createProductMovementRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/product_movements', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateProductMovementRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/product_movements/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProductMovementRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/product_movements/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexProductMovementsRequest = AwesomeDebouncePromise(indexProductMovementsRequest, 300);

export const debounceCompanyIndexProductMovementsRequest = AwesomeDebouncePromise(
  companyIndexProductMovementsRequest,
  300
);
