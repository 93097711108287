import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexProjectPaymentsRequest = (
  projectId,
  { dispatch, format, params = {}, failureCallback, successCallback }
) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/projects/${projectId}/project_payments${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexProjectsPaymentsRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/project_payments${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createProjectPaymentRequest = (
  projectId,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('post', `/projects/${projectId}/project_payments`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateProjectPaymentRequest = (
  projectId,
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/projects/${projectId}/project_payments/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProjectPaymentRequest = (projectId, id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/projects/${projectId}/project_payments/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexProjectPaymentsRequest = AwesomeDebouncePromise(indexProjectPaymentsRequest, 300);
export const debounceIndexProjectsPaymentsRequest = AwesomeDebouncePromise(indexProjectsPaymentsRequest, 300);
