import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

const WhatsappConnection = props => {
  const {
    submitVariant,
    handleModalClose,
    connectRequest,
    content
  } = props;

  return (
    <Col>
      <Row className="d-flex mb-4">
        {content}
      </Row>
      <Row className="d-flex mb-4">
        {handleModalClose && (
          <Col md={{ span: 3, offset: 3 }}>
            <Button className="btn" variant="primary" block onClick={handleModalClose}>
              Cancelar
            </Button>
          </Col>
        )}
        <Col md={3}>
          <Button type="submit" variant={submitVariant} block onClick={connectRequest}>
            Conectar
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default WhatsappConnection;