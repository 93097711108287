import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import {
  Calendar,
  Folder,
  List,
  Tool,
  Repeat,
  ShoppingCart,
  Users,
  UserCheck,
  AlertTriangle
} from 'react-feather';
import { updateUserRequest } from '../../requests/user';
import { FormikSelect } from '../../components';
import { Can } from '../../config/abilityContext';
import { sendAlert } from '../../actions/utils';
import { validateToken } from '../../actions/auth';

const HomeIndex = () => {
  const { user } = useSelector(state => state.auth);
  const { id } = user;
  const { branchOffices, currentBranchOffice, name } = user;
  const [selectedBranchOfficeId, setSelectedBranchOfficeId] = useState('');
  const dispatch = useDispatch();

  const noBranchOfficeMessage = () => {
    if (!branchOffices.length) {
      return 'Actualmente no tienes asignada ninguna sucursal. Por favor contacta al Administrador.';
    }
    return 'Actualmente no tienes seleccionada ninguna sucursal. Por favor selecciona una Sucursal para continuar';
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(validateToken());
    dispatch(sendAlert({ kind: 'success', message: 'Sucursal asignada con éxito' }));
  };

  const handleUpdateUserBranchOffice = () => {
    const params = { user: { id, currentBranchOfficeId: selectedBranchOfficeId } };
    const sendParams = snakecaseKeys(params);
    updateUserRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <Row className="mt-5">
            <Col>
              <h1 className="text-primary text-center mb-5">Bienvenido, {name}!</h1>
            </Col>
          </Row>
          {!currentBranchOffice && (
            <Col md={8} className="mx-auto">
              <Alert variant="warning">
                <p className="lead text-center">{noBranchOfficeMessage()}</p>
                {branchOffices.length > 0 && (
                  <>
                    <Col md={6} className="mx-auto">
                      <FormikSelect
                        abbr
                        label="Sucursal"
                        placeholder="Seleccionar Sucursal"
                        options={branchOffices}
                        onChange={data => setSelectedBranchOfficeId(data ? data.value : '')}
                      />
                    </Col>
                    <Button className="d-block mx-auto mt-4 w-25" onClick={handleUpdateUserBranchOffice}>
                      Confirmar
                    </Button>
                  </>
                )}
              </Alert>
            </Col>
          )}
          {currentBranchOffice?.value && (
            <>
              <Row className="mb-5 mx-5">
                <Can I="create" a="CalendarEvent">
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/calendar" className="btn btn-primary">
                      <div className="report-big-btn">
                        <Calendar className="mb-4 report-icon" />
                        <p>Calendario</p>
                      </div>
                    </Button>
                  </Col>
                </Can>
                <Can I="update" a="Client">
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/clients" className="btn btn-primary">
                      <div className="report-big-btn">
                        <Users className="mb-4 report-icon" />
                        <p>Clientes</p>
                      </div>
                    </Button>
                  </Col>
                </Can>
                <Can I="create" a="ProjectReview">
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/project_vehicle_reviews" className="btn btn-primary">
                      <div className="report-big-btn">
                        <Tool className="mb-4 report-icon" />
                        <p>Revisión</p>
                      </div>
                    </Button>
                  </Col>
                </Can>

                <Can I="create" a="ProjectReview">
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/project_mechanic_reviews" className="btn btn-primary">
                      <div className="report-big-btn">
                        <Folder className="mb-4 report-icon" />
                        <p>En Carpeta</p>
                      </div>
                    </Button>
                  </Col>
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/project_mechanic_list" className="btn btn-primary">
                      <div className="report-big-btn">
                        <List className="mb-4 report-icon" />
                        <p>Listado de OTs</p>
                      </div>
                    </Button>
                  </Col>
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/assigned_tasks" className="btn btn-primary">
                      <div className="report-big-btn">
                        <UserCheck className="mb-4 report-icon"/>
                        <p>Tareas Asignadas</p>
                      </div>
                    </Button>
                  </Col>
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/project_work_hour_reports" className="btn btn-primary">
                      <div className="report-big-btn">
                        <AlertTriangle className="mb-4 report-icon"/>
                        <p>Reporte por Mecánico</p>
                      </div>
                    </Button>
                  </Col>
                </Can>

                <Can I="create" a="ProductInventory">
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/inventory" className="btn btn-primary">
                      <div className="report-big-btn">
                        <List className="mb-4 report-icon" />
                        <p>Inventario</p>
                      </div>
                    </Button>
                  </Col>
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/movement" className="btn btn-primary">
                      <div className="report-big-btn">
                        <Repeat className="mb-4 report-icon" />
                        <p>Movimientos</p>
                      </div>
                    </Button>
                  </Col>
                </Can>
                <Can I="create" a="PurchaseManagement">
                  <Col md={4} className="d-flex justify-content-center mb-4">
                    <Button as={Link} to="/purchase_management" className="btn btn-primary">
                      <div className="report-big-btn">
                        <ShoppingCart className="mb-4 report-icon" />
                        <p>Gestión de Compra</p>
                      </div>
                    </Button>
                  </Col>
                </Can>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default HomeIndex;
