import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '../../components';
import UserDataTable from './UserDataTable';
import columns from './Columns';
import { Can } from '../../config/abilityContext';

const UserIndex = () => (
  <>
    <Row>
      <Col md={{ span: 10, offset: 1 }} xs={12}>
        <Row className="mt-4">
          <Col md={10}>
            <h2 className="text-uppercase mb-4">Usuarios</h2>
          </Col>
          <Can I="create" a="User">
            <Col md={2}>
              <LinkBtn variant="primary" block to="/users/new">
                Crear
              </LinkBtn>
            </Col>
          </Can>
        </Row>
        <UserDataTable columns={columns} />
      </Col>
    </Row>
  </>
);

export default UserIndex;
