import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavDropdown, Accordion, Card } from 'react-bootstrap';
import { ChevronDown } from 'react-feather';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import { DefaultModal } from '../Utils/Modal';
import { updateUserRequest } from '../../requests/user';
import { sendAlert } from '../../actions/utils';
import { validateToken } from '../../actions/auth';

const BranchOfficesDropdown = ({ isMobile = false, closeNavbar }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const { id, branchOffices, currentBranchOffice, roleIds } = user;
  const noCurrentBranchOffices = currentBranchOffice
    ? branchOffices.filter(branchOffice => branchOffice.value !== currentBranchOffice.value)
    : branchOffices;
  const selectedBranchOfficeLabel = currentBranchOffice?.label || 'Seleccionar Sucursal';
  const [modalBody, setModalBody] = useState('');
  const [modalItemId, setModalItemId] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
    setModalItemId(null);
  };

  const handleClick = branchOffice => {
    setModalShow(true);
    setModalBody(`¿Estás seguro que deseas cambiar la sucursal a ${branchOffice.label}?`);
    setModalItemId(branchOffice.value);
  };

  const handleSuccessUpdate = () => {
    dispatch(validateToken());
    dispatch(sendAlert({ kind: 'success', message: 'Sucursal actualizada con éxito' }));
    if (roleIds.includes(1) || roleIds.includes(7)) {
      history.push('/dashboard');
    } else {
      history.push('/home');
    }
  };

  const handleFailureUpdate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleModalConfirm = () => {
    setModalShow(false);
    const params = { user: { id, currentBranchOfficeId: modalItemId } };
    const sendParams = snakecaseKeys(params);
    updateUserRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
    closeNavbar();
  };

  return (
    <>
      {isMobile ? (
        <>
          {noCurrentBranchOffices.length ? (
            <Accordion className="responsive-navbar-accordion" defaultActiveKey="1">
              <Card className="accordion-card">
                <Accordion.Toggle className="nav-tab accordion-toggle responsive" as={Card.Header} eventKey="0">
                  <strong className="mr-2">Sucursal Actual:</strong> {selectedBranchOfficeLabel}
                  <ChevronDown className="ml-2" size={18} />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="accordion-options-bg">
                    {noCurrentBranchOffices.map(branchOffice => (
                      <Nav.Link key={`branch-office-${branchOffice.value}`} onClick={() => handleClick(branchOffice)}>
                        {branchOffice.label}
                      </Nav.Link>
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ) : (
            <Nav.Link className="text-center">{selectedBranchOfficeLabel}</Nav.Link>
          )}
        </>
      ) : (
        <Nav className="nav-link">
          {noCurrentBranchOffices.length ? (
            <NavDropdown className="branch-office-btn btn" title={selectedBranchOfficeLabel} id="branch-office-user">
              {noCurrentBranchOffices.map(branchOffice => (
                <NavDropdown.Item key={`branch-office-${branchOffice.value}`} onClick={() => handleClick(branchOffice)}>
                  {branchOffice.label}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          ) : (
            <Nav.Link>{selectedBranchOfficeLabel}</Nav.Link>
          )}
        </Nav>
      )}

      <DefaultModal
        title="Cambiar Sucursal"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default BranchOfficesDropdown;
