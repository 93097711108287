import React from 'react';
import AsyncSelect from 'react-select/async';

const customStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 100
  }),
  control: styles => ({ ...styles, borderRadius: '1.5rem' }),
  option: (provided, { data }) => {
    if (data.custom) {
      const opacity = 0.5;
      const fontStyle = 'italic';
      return { ...provided, opacity, fontStyle };
    }
    if (data.productCurrentStock <= 0 && data.inventoryable && data.isProjectDetail) {
      return { ...provided, color: 'red' };
    }
    return { ...provided };
  },
  singleValue: (provided, { data }) => {
    if (data.productCurrentStock <= 0 && data.inventoryable && data.isProjectDetail) {
      return { ...provided, color: 'red' };
    }
    return { ...provided };
  }
};
const RemoteSelect = ({ disabled, value, request, onChange, ...moreProps }) => (
  <AsyncSelect
    styles={customStyles}
    placeholder="Buscar..."
    loadingMessage={() => 'Cargando...'}
    noOptionsMessage={() => 'No hay opciones'}
    value={value}
    defaultOptions
    loadOptions={request}
    onChange={onChange}
    isDisabled={disabled}
    {...moreProps}
  />
);

export default RemoteSelect;
