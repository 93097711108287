import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { defaultStates, defaultTypes, invoiceStates } from './projectFiltersData';
import { sendAlert } from '../../actions/utils';
import ComponentDataTable from '../../components/Utils/DataTable';
import ProjectDeskSell from './ProjectDeskSell';
import { DefaultModal, FormikSelect, InputRemoteSelect, SimpleCenteredModal } from '../../components';
import { AbilityContext, Can } from '../../config/abilityContext';
import { debounceIndexProjectsRequest, deleteProjectRequest, indexProjectsRequest } from '../../requests/projects';
import { debounceIndexClientsRequest, indexClientsRequest } from '../../requests/clients';
import { debounceIndexUsersRequest, indexUsersRequest } from '../../requests/user';
import downloadFile from '../../services/utils';
import ProjectQuote from './ProjectQuote';
import ProjectPasswordToDelete from './ProjectPasswordToDelete';
import useSavedFilter from "../../hooks/useSavedFilter";
import InputDate from "../../components/Utils/Input/InputDate";

const projectPassword = {
  id: '',
  password: ''
};

const avoidStates = ['Entregado', 'Garantía', 'Garantía y Entregado', 'Rechazado'];

const ProjectDataTable = ({
    columns,
    fromMechanicList,
    currentBranchOfficeId,
    urlQueries,
    isQuote = false,
    externalView = false,
    adminView = false,
    rowsPerPage = 10,
    filterId,
  }) => {
  const {setFilter, getFilter} = useSavedFilter(filterId);
  const {client_id: urlClientId, plate: urlPlateQuery} = urlQueries;
  const [amount, setAmount] = useState(0);
  const [modalBody, setModalBody] = useState('');
  const [modalPasswordBody, setModalPasswordBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalPasswordShow, setModalPasswordShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [projects, setProjects] = useState([]);
  const [stateId, setStateId] = useState(getFilter('state_id') || '');
  const [isDeskSale, setIsDeskSale] = useState(getFilter('desk_sale_filtered') || '');
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState(urlClientId || getFilter('client_id') || '');
  const [sellers, setSellers] = useState([]);
  const [sellerId, setSellerId] = useState(getFilter('seller_id') || '');
  const [deliveredDateFrom, setDeliveredDateFrom] = useState(getFilter('delivered_date_from') || '');
  const [deliveredDateTo, setDeliveredDateTo] = useState(getFilter('delivered_date_to') || '');
  const [invoiceState, setInvoiceState] = useState(getFilter('invoice_state') || '');
  const [plate, setPlate] = useState(urlPlateQuery || '');
  const [queries, setQueries] = useState({});
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();
  let isFirstFetch = useRef(true);

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseProjects = response.data.data;
    const {amount: responseAmount} = response.data.metadata;
    setProjects(responseProjects);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalPasswordShow(false);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({kind: 'success', message: 'Proyecto eliminado con éxito'}));
    setMoreData(!moreData);
    handleModalClose();
  };

  const removeProject = (params = {}) => {
    const idToDelete = params.id || modalItem.id;
    deleteProjectRequest(idToDelete, {
      dispatch,
      params: {password: params.password},
      successCallback: handleSuccessRemove,
      failureCallback: handleFailureRequest
    });
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const {sort_column: sortColumn, client_id: prevClientId} = params;
    const sendParams = {
      ...params,
      client_id: clientId || prevClientId || '',
      seller_id: sellerId || '',
      desk_sale_filtered: isDeskSale,
      state_id: stateId,
      sort_column: sortColumn || 'code',
      invoice_state: invoiceState,
      delivered_date_from: deliveredDateFrom,
      delivered_date_to: deliveredDateTo,
      is_quote: isQuote,
      ...(adminView ? {} : {active: true})
    };
    if (fromMechanicList) {
      sendParams.branch_office_id = currentBranchOfficeId;
      sendParams.without_rejected = true;
    }
    debounceIndexProjectsRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/projects/${item.id}`);
        break;
      case 'edit':
        history.push(`/projects/${item.id}/edit`);
        break;
      case 'mechanicReview':
        history.push(`/project_mechanic_reviews/${item.id}`);
        break;
      case 'destroy':
        let message = `¿Estás seguro que deseas eliminar este proyecto?`;
        if (item.product_movements_count > 0) {
          message += ` Se eliminará ${item.product_movements_count} movimiento(s).`;
        }
        setModalBody(message);
        if (item['need_password?']) {
          setModalPasswordShow(true);
          setModalPasswordBody(
            <ProjectPasswordToDelete
              projectPassword={projectPassword}
              project={item}
              formRequest={removeProject}
              handleModalClose={handleModalClose}
              dispatch={dispatch}
            />
          );
        } else {
          setModalShow(true);
          setModalItem(item);
        }
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleStateIdChange = (data) => {
    const newStateId = data ? data.value : '';
    setStateId(newStateId);
    setMoreData(!moreData);
    setFilter('state_id', newStateId);
  };

  const handleProjectType = (data) => {
    const newIsDeskSale = data ? data.value : '';
    setIsDeskSale(newIsDeskSale);
    setMoreData(!moreData);
    setFilter('desk_sale_filtered', String(newIsDeskSale) );
  }

  const handleInvoiceState = (data) => {
    const newInvoiceState = data ? data.value : '';
    setInvoiceState(newInvoiceState);
    setMoreData(!moreData);
    setFilter('invoice_state', newInvoiceState);
  }

  const handleSellerId = (data) => {
    const newSellerId = data ? data.value : '';
    setSellerId(newSellerId);
    setMoreData(!moreData);
    setFilter('seller_id', newSellerId);
  }

  const handleClientId = (data) => {
    const newClientId = data ? data.value : '';
    setClientId(newClientId);
    setMoreData(!moreData);
    setFilter('client_id', newClientId);
  }

  const sortColumnCase = name => {
    switch (name) {
      case 'branch_office':
        return {sort_branch_office: name};
      case 'client':
        return {sort_client: name};
      case 'formated_promise_date_no_time':
        return {sort_column: 'promise_date'};
      case 'formated_delivered_date_no_time':
        return {sort_column: 'delivered_date'};
      case 'project_date':
        return {sort_column: 'project_date'};
      case 'id':
        return {sort_code: name};
      case 'translated_invoice_state':
        return {sort_column: 'invoice_state'};
      case 'translated_ot_state':
        return {sort_column: 'ot_state'};
      case 'brand':
        return {sort_vehicle_brand: name};
      case 'model':
        return {sort_vehicle_model: name};
      case 'parsed_mileage':
        return {sort_mileage: name};
      case 'plate':
        return {sort_plate: name};
      default:
        return {sort_column: name};
    }
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    const {sort_column: sortColumn} = params;
    const sendParams = {
      ...params,
      display_length: 100000,
      sort_column: sortColumn || 'code',
      is_quote: isQuote,
      ...(adminView ? {} : {active: true})
    };
    if (fromMechanicList) {
      sendParams.branch_office_id = currentBranchOfficeId;
      sendParams.without_rejected = true;
    }
    indexProjectsRequest({
      dispatch,
      params: sendParams,
      format,
      successCallback: handleSuccessDownload
    });
  };

  // Remote Select for Clients
  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.full_name,
        value: element.id
      };
    });
    setClients(camelCaseRecursive(tempArray));
    return tempArray;
  };

  const fetchClients = (inputValue, callback) => {
    debounceIndexClientsRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const fetchInitialClients = useCallback(
    params => {
      indexClientsRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          sort_direction: 'asc',
          display_length: 50
        },
        successCallback: response => {
          setClients(resultFetchData(response));
        }
      });
    },
    [stableDispatch]
  );

  // Remote Select for Sellers
  const resultFetchSellers = response => {
    const result = response.data.data;
    setSellers(result);
    return result;
  };

  const fetchSellers = (inputValue, callback) => {
    debounceIndexUsersRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_user_by_fullname: 'ASC',
        display_length: 50,
        for_selector: true
      },
      successCallback: response => callback(resultFetchSellers(response)),
      failureCallback: handleFailureRequest
    });
  };

  const fetchInitialSellers = useCallback(
    params => {
      indexUsersRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          sort_user_by_fullname: 'ASC',
          display_length: 50,
          for_selector: true
        },
        successCallback: response => {
          setSellers(resultFetchSellers(response));
        }
      });
    },
    [stableDispatch]
  );

  const firstFetchWithPlate = () => {
    if (isFirstFetch && urlPlateQuery) {
      handleIndexRequest({...queries, query: urlPlateQuery});
      isFirstFetch = false;
    }
  };

  const handleRedirect = (clientIdForURL = null, plateForUrl = null) => {
    if (clientIdForURL) {
      setClientId(clientIdForURL);
      handleIndexRequest({...queries, client_id: clientIdForURL});
    }
    if (plateForUrl) {
      setPlate(plateForUrl);
      handleIndexRequest({...queries, query: plateForUrl});
    }
  };

  useEffect(fetchInitialClients, [fetchInitialClients]);
  useEffect(fetchInitialSellers, [fetchInitialSellers]);
  useEffect(firstFetchWithPlate, [urlPlateQuery]);

  useEffect(() => {
    if(getFilter('client_id') !== '') {
      fetchClients(getFilter('client_id'))
    }
    if(getFilter('seller_id') !== '') {
      fetchSellers(getFilter('seller_id'))
    }
  }, []);

  const listOtStates = defaultStates.filter(state => !avoidStates.includes(state.label));

  return (
    <>
      {!externalView && (
        <Row className="justify-content-end">
          <Can I="manage" a="ProjectQuote">
            <ProjectQuote/>
          </Can>
          {!isQuote && <ProjectDeskSell/>}
          <Col md={2}>
            <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
              Exportar
            </Button>
          </Col>
        </Row>
      )}

      <ComponentDataTable
        filterId={filterId}
        onRequest={onRequest}
        columns={columns(handleButtonClick, fromMechanicList, ability, handleRedirect, setMoreData, isQuote)}
        handleSortCase={sortColumnCase}
        data={projects}
        totalRows={amount}
        moreData={moreData}
        withDate={!externalView}
        initialQuery={urlPlateQuery}
        RowsPage={rowsPerPage}
        resourceRequest={response => {
          const {query: prevQuery} = response;
          setQueries({
            ...response,
            query: prevQuery || plate,
            client_id: clientId,
            seller_id: sellerId || '',
            desk_sale_filtered: isDeskSale,
            state_id: stateId,
            invoice_state: invoiceState,
            delivered_date_from: deliveredDateFrom,
            delivered_date_to: deliveredDateTo,
            is_quote: isQuote,
          });
          if (!onRequest) handleIndexRequest(response);
        }}
        firstCustomMediumSearch={
          !isQuote &&
          !externalView && (
            <FormikSelect
              isClearable
              options={adminView ? defaultStates : listOtStates}
              placeholder="Estado de OT"
              defaultValue={adminView ? defaultStates.find(state => state.value === stateId) : listOtStates.find(state => state.value === stateId)}
              onChange={handleStateIdChange}
            />
          )
        }
        secondCustomMediumSearch={
          !externalView && (
            <InputRemoteSelect
              isClearable
              placeholder="Cliente"
              defaultOptions={clients}
              value={clients.find(client => client.id === parseInt(clientId, 10))}
              onChange={handleClientId}
              request={fetchClients}
            />
          )
        }
        thirdCustomMediumSearch={
          !isQuote &&
          !externalView && (
            <FormikSelect
              isClearable
              options={defaultTypes}
              placeholder="Tipo de Proyecto"
              defaultValue={defaultTypes.find(type => String(type.value) === String(isDeskSale))}
              onChange={handleProjectType}
            />
          )
        }
        fourthCustomMediumSearch={
          !isQuote && !externalView && (
            <FormikSelect
              isClearable
              options={invoiceStates}
              placeholder="Estado de Cobro"
              defaultValue={invoiceStates.find(state => state.value === invoiceState)}
              onChange={handleInvoiceState}
            />
          )
        }
        fifthCustomMediumSearch={
          !externalView && (
            <InputRemoteSelect
              isClearable
              placeholder="Vendedor"
              defaultOptions={sellers}
              value={sellers.find(seller => seller.value === parseInt(sellerId, 10))}
              onChange={handleSellerId}
              request={fetchSellers}
            />
          )
        }
        filters={
          !externalView &&
          !isQuote && (
            <>
              <Col xs={6} md={2}>
                <Form.Group>
                  <InputDate
                    placeholderText="Entregado desde"
                    selected={deliveredDateFrom}
                    onChange={date => {
                      setDeliveredDateFrom(date)
                      setMoreData(!moreData);
                      setFilter('delivered_date_from', date);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={2}>
                <Form.Group>
                  <InputDate
                    placeholderText="Entregado hasta"
                    selected={deliveredDateTo}
                    onChange={date => {
                      setDeliveredDateTo(date)
                      setMoreData(!moreData);
                      setFilter('delivered_date_to', date);
                    }}
                  />
                </Form.Group>
              </Col>
            </>
          )
        }
      />
      <DefaultModal
        title="Eliminar Proyecto"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => removeProject()}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        title={modalBody}
        body={modalPasswordBody}
        show={modalPasswordShow}
        onHide={handleModalClose}
        closeButton
      />
    </>
  );
};

export default ProjectDataTable;
