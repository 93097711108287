import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { SimpleCenteredModal } from '../../components';
import { createProjectPaymentRequest } from '../../requests/projectPayments';
import ProjectPaymentForm from './ProjectPaymentForm';

const AddProjectPayment = ({ project, setMoreData, version }) => {
  const { id: projectId, projectInvoicesAttributes: projectInvoices } = project;
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const basicProjectPayment = {
    amount: '',
    date: '',
    observations: '',
    payment_type: '',
    projectAmortizationsAttributes: []
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Abono creado con éxito' }));
    setMoreData(m => !m);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
  };

  const handleCreateRequest = values => {
    const sendParams = snakecaseKeys(values);
    createProjectPaymentRequest(projectId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
    handleModalClose();
  };

  const handleClick = () => {
    setModalTitle('Agregar Abono');
    setModalBody(
      <ProjectPaymentForm projectPayment={basicProjectPayment} action="new" formRequest={handleCreateRequest}
                          projectInvoices={projectInvoices} />
    );
    setModalShow(true);
  };

  return (
    <>
      <Button block hidden={version} variant="secondary" className="add-payment-btn" onClick={handleClick}>
        Abonar pago
      </Button>
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={handleModalClose} closeButton />
    </>
  );
};

export default AddProjectPayment;
