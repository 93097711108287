import React from 'react';
import { useSelector } from 'react-redux';
import { Navbar,   Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Whatsapp, Envelope, Telephone } from 'react-bootstrap-icons';
import MainLogo from '../MainLogo';
import getImage from '../../../utils/getImage';
import { useCompany } from '../../../stores/company';
import {useFindCompany} from "../../../hooks/usePublicCompanies";

const PrincipalNavbar = ({ children, expanded: navExpanded, setNavExpanded, isPublicView, companyCode }) => {
  const {
    user,
    user: { businessLogo }
  } = useSelector(state => state.auth);
  const { roleIds } = user;
  const defaultCompany = useCompany();
  const companyData = useFindCompany(companyCode);
  const { company } = companyData;
  const {
    whatsappPhoneNumber = defaultCompany.whatsappPhoneNumber,
    website = defaultCompany.website,
    email = defaultCompany.email,
    phoneNumber = defaultCompany.phoneNumber
  } = company && company ? company : defaultCompany;

  const logoUrl = company?.businessLogo?.fileUrl || businessLogo?.key;
  const logoImage = getImage(logoUrl, 50, 50, 'outside');
  const logoLink = isPublicView
    ? website
    : businessLogo?.key
    ? roleIds.includes(1) || roleIds.includes(7)
      ? '/dashboard'
      : '/home'
    : '/';
  const whatsappLink = `https://wa.me/${whatsappPhoneNumber}`;

  return (
    <Navbar expanded={navExpanded} bg="light" expand="lg" fixed="top" className="d-flex bg-white">
      <Navbar.Brand as={Link} to={logoLink}>
        <MainLogo src={logoImage} />
      </Navbar.Brand>
      {!isPublicView && (
        <Navbar.Toggle
          onClick={() => setNavExpanded(navExpanded ? false : 'navExpanded')}
          aria-controls="basic-navbar-nav"
        />
      )}
      {children}
      {isPublicView && Boolean(whatsappPhoneNumber) && (
        <Nav className="align-items-center flex-row justify-content-center mx-auto">
          <strong className="d-none d-md-inline">{company ? 'CONTACTO:' : 'APRUEBA AQUI:'}</strong>
          <Nav.Link href={whatsappLink} target="_blank" rel="noopener noreferrer" className="mx-2">
            <Whatsapp size={20} color="#25d366" />
          </Nav.Link>
          <Nav.Link href={`mailto:${email}`} target="_blank" rel="noopener noreferrer" className="mx-2">
            <Envelope size={20} />
          </Nav.Link>
          <Nav.Link href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer" className="mx-2">
            <Telephone size={20} />
          </Nav.Link>
        </Nav>
      )}
    </Navbar>
  );
};

export default PrincipalNavbar;
