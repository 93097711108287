import React from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { HelpCircle } from 'react-feather';

const FormikCheckBox = ({
  customClass,
  delayHide,
  delayShow,
  direction,
  field,
  label,
  margin,
  toolbarVariant,
  tooltipText,
  ...props
}) => (
  <Form.Group className={`week-day-input ${margin}`}>
    <Row>
      <Form.Check
        {...field}
        custom
        type="checkbox"
        checked={field.value}
        label={label}
        className={`weekday-selector ${customClass}`}
        id={field.name}
        {...props}
      />
      {tooltipText && (
        <ButtonToolbar className="ml-3">
          <OverlayTrigger
            key={direction}
            placement={direction}
            delay={{ show: delayShow, hide: delayHide }}
            overlay={<Tooltip>{tooltipText}</Tooltip>}
          >
            <HelpCircle />
          </OverlayTrigger>
        </ButtonToolbar>
      )}
    </Row>
  </Form.Group>
);
FormikCheckBox.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

FormikCheckBox.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};
export default FormikCheckBox;
