import React from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';

import { FormikInput, NestedAttributes, FormikSelect } from '../../../components';
import reviewTypeValues from '../Constants';
import ReviewInput from './ReviewInput';
import FormikError from '../../../components/Utils/Input/FormikError';

const ReviewGroupInput = ({ errors, touched, index, setFieldValue, reviewGroup }) => {
  const { reviewsAttributes } = reviewGroup;
  const { values } = useFormikContext();
  const fromType = values.checklist.checklistType;

  const addReviewInputs = () => {
    const mapResults = reviewsAttributes.map((review, currentIndex) => {
      if (review._destroy) {
        return undefined;
      }
      return (
        <ReviewInput
          key={`review-input-${index.toString()}-${currentIndex.toString()}`}
          reviewGroupIndex={index}
          index={currentIndex}
          errors={errors}
          touched={touched}
          review={review}
          setFieldValue={setFieldValue}
          fromType={fromType}
        />
      );
    });

    return (
      <NestedAttributes
        removeFirstItem
        additionalNested
        mapInputs={mapResults}
        arrayValues={reviewsAttributes}
        setFieldValue={setFieldValue}
        valuePath={`checklist[reviewGroupsAttributes][${index}][reviewsAttributes]`}
        newAttributes={{ name: '', position: 0, imageRequired: false }}
        btnMessage="Agregar nuevo punto"
      />
    );
  };

  return (
    <Col>
      <Row>
        <Col md={12}>
          <Row className="checklist-section-header">
            <Col md={8}>
              <Field name={`checklist[reviewGroupsAttributes][${index}][name]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Nombre de sección"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    reviews={reviewsAttributes}
                  />
                )}
              </Field>
              <Field name={`checklist[reviewGroupsAttributes][${index}][reviewType]`}>
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    className="hidden"
                    placeholder="Seleccionar Tipo"
                    options={reviewTypeValues}
                    defaultValue={reviewGroup.reviewType}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name={`checklist[reviewGroupsAttributes][${index}][position]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Posición"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    reviews={reviewsAttributes}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <hr className="w100" />
          <Row>
            <Col md={12}>
              <h4 className="text-uppercase mt-4">Listado de Puntos</h4>
              <FormikError name={`checklist.reviewGroupsAttributes[${index}].reviewsAttributes`} />
              {addReviewInputs()}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ReviewGroupInput;
