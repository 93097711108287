import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Copy, Eye, Edit, Trash, FileText } from 'react-feather';
import { ButtonTooltip } from '../Utils/Tooltips';

const BasicActionBtns = ({
  clickHandler,
  cloneClick,
  destroyText,
  editClick,
  item,
  mechanicReview,
  path,
  showClick,
  withDestroy,
  withEdit,
  withShow
}) => (
  <>
    {mechanicReview && (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'mechanicReview')}
        variant="outline-primary"
        className="btn-circle table-actions-btn"
        size="sm"
        text="Revisar OT"
      >
        <FileText width="15px" height="15px" />
      </ButtonTooltip>
    )}
    {showClick ? (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'show')}
        variant="outline-info"
        className="btn-circle table-actions-btn"
        size="sm"
        text="Ver"
      >
        <Eye width="15px" height="15px" />
      </ButtonTooltip>
    ) : (
      withShow && (
        <ButtonTooltip
          to={`/${path}/${item.id}`}
          variant="outline-info"
          className="btn-circle table-actions-btn"
          size="sm"
          text="Ver"
          as={Link}
        >
          <Eye width="15px" height="15px" />
        </ButtonTooltip>
      )
    )}
    {cloneClick && (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'clone')}
        variant="outline-info"
        className="btn-circle table-actions-btn"
        size="sm"
        text="Crear OT"
      >
        <Copy width="15px" height="15px" />
      </ButtonTooltip>
    )}
    {editClick ? (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'edit')}
        variant="outline-warning"
        className="btn-circle table-actions-btn"
        size="sm"
        text="Editar"
      >
        <Edit width="15px" height="15px" />
      </ButtonTooltip>
    ) : (
      withEdit && (
        <ButtonTooltip
          to={`/${path}/${item.id}/edit`}
          variant="outline-warning"
          className="btn-circle table-actions-btn"
          size="sm"
          text="Editar"
          as={Link}
        >
          <Edit width="15px" height="15px" />
        </ButtonTooltip>
      )
    )}
    {withDestroy && (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'destroy')}
        variant="outline-danger"
        className="btn-circle table-actions-btn"
        size="sm"
        text={destroyText}
      >
        <Trash width="15px" height="15px" />
      </ButtonTooltip>
    )}
  </>
);

BasicActionBtns.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  clickHandler: PropTypes.func.isRequired,
  destroyText: PropTypes.string,
  editClick: PropTypes.bool,
  mechanicReview: PropTypes.bool,
  path: PropTypes.string,
  showClick: PropTypes.bool,
  withDestroy: PropTypes.bool,
  withEdit: PropTypes.bool,
  withShow: PropTypes.bool
};

BasicActionBtns.defaultProps = {
  destroyText: 'Eliminar',
  editClick: false,
  mechanicReview: false,
  path: '',
  showClick: false,
  withDestroy: true,
  withEdit: true,
  withShow: true
};

export default BasicActionBtns;
