import api from '../services/api';
import useQuery, { useInvalidateQuery } from './useQuery';
import camelCaseRecursive from 'camelcase-keys-recursive';

const getCommentsByProjects = projectCode => api.get(`/projects/${projectCode}/comments.json`).then(res => res.json());

const createCommentsByProjects = (projectCode, params) =>
  api.post(`/projects/${projectCode}/comments.json`, params).then(res => res.json());

export const useListCommentsByProjects = projectCode => {
  const { data = [] } = useQuery(['comments', projectCode], getCommentsByProjects);
  const invalidateQueries = useInvalidateQuery();
  const reFetch = () => {
    invalidateQueries(['comments', projectCode]);
  };
  let comments = camelCaseRecursive(data);
  return { comments, reFetch };
};

export const useCreateCommentsByProjects = projectCode => {
  const create = async (values, options = {}) => {
    try {
      const data = await createCommentsByProjects(projectCode, values);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
  return { create };
};
