import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Row, Col } from 'react-bootstrap';
import VehicleForm from './VehicleForm';
import { createVehicleRequest } from '../../requests/vehicles';
import { showCompanyRequest } from '../../requests/companies';
import { LinkBtn } from '../../components';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicVehicle from './basicVehicle';
import basicCompany from '../Company/basicCompany';

const newBasicVehicle = {
  ...basicVehicle,
  vtype: 'vehicle'
};

const VehicleNew = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { client_id: clientId } = match.params;
  const [currentCompany, setCompany] = useState(basicCompany);

  const handleSuccessCreate = response => {
    const { id } = response.data;
    dispatch(sendAlert({ kind: 'success', message: 'Vehículo creado con éxito' }));
    history.push(`/clients/${clientId}/vehicles/${id}`);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    sendParams.vehicle.client_id = clientId;
    createVehicleRequest(clientId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  const fetchCurrentCompany = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(fetchCurrentCompany, []);

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-3">
            <Col className="mb-4">
              <h2>Crear Vehículo</h2>
            </Col>
            <Col md={2}>
              <LinkBtn className="cancel" variant="light-darker" block to={`/clients/${clientId}/vehicles/`}>
                Volver
              </LinkBtn>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <VehicleForm
                companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
                vehicle={newBasicVehicle}
                action="new"
                formRequest={handleCreateRequest}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default VehicleNew;
