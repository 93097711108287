import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import { FormikInput, FormikCheckBox, FormikNumberFormat, RegionCommuneSelect, BasicTextArea } from '../../components';
import ContactAdd from '../Contact/ContactAdd';
import useCountryFactory from "../../hooks/useCountryFactory";
import PhoneInputField from "../../components/Utils/Input/PhoneInputField";

const ProviderForm = props => {
  const countryFactory = useCountryFactory();
  const { action, dispatch, errors, onHide, provider, setFieldValue, submitVariant, touched, values } = props;
  const { contactsAttributes } = values.provider;
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';

  return (
    <Form>
      <Row>
        <Col>
          <Field name="provider[legalName]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Razón Social"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="provider[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field name="provider[nationalIdentification]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label={countryFactory.getLabelForCompanyIdentification()}
                onChange={e => countryFactory.handleNationalIdentificationFormat(e, setFieldValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="provider[businessActivity]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Actividad Económica"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <RegionCommuneSelect
          regionAbbr
          communeAbbr
          modelKey="provider"
          dispatch={dispatch}
          currentModel={provider}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          countryFactory={countryFactory}
        />
        <Col>
          <Field name="provider[address]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Dirección"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field name="provider[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Email"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="provider[website]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Sitio web"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <PhoneInputField name="provider[cellphone]" label="Celular" />
        </Col>
        <Col>
          <Field name="provider[phone]">
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                label="Teléfono Fijo"
                format={countryFactory.formatPhone}
                onValueChange={number => setFieldValue(field.name, number.formattedValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field name="provider[active]">
            {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" custom />}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="provider[notes]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Notas"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <h4 className="text-uppercase mt-3 mb-4">Contactos</h4>
          <ContactAdd
            contactsAttributes={contactsAttributes}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            parent="provider"
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mb-3 mt-4">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { provider } = props;
  return {
    provider
  };
};

const validationSchema = ({ country }) => {
  return Yup.object().shape({
    provider: Yup.object().shape({
      active: Yup.boolean(),
      cellphone: Yup.string()
        .nullable()
        .phone('El formato no es válido'),
      phone: Yup.string()
        .nullable()
        .phone('El formato no es válido'),
      address: Yup.string().nullable(),
      businessActivity: Yup.string().nullable(),
      communeId: Yup.string().required('Debes seleccionar una comuna'),
      email: Yup.string()
        .required('Debes ingresar un email')
        .email('Debes ingresar un email válido'),
      name: Yup.string().required('Debes ingresar un nombre'),
      notes: Yup.string().nullable(),
      regionId: Yup.string().required('Debes seleccionar una región'),
      website: Yup.string().nullable(),
      legalName: Yup.string().required('Debes ingresar la razón social'),
      nationalIdentification: Yup.string()
        .required('Debes ingresar un RUT')
        .rut('El RUT es inválido', country),
      contactsAttributes: Yup.array().of(
        Yup.object().shape({
          cellphone: Yup.string()
            .required('Debes ingresar un número de celular')
            .phone('El formato no es válido'),
          charge: Yup.string().nullable(),
          email: Yup.string()
            .required('Debes ingresar un email')
            .email('Debes ingresar un email válido'),
          name: Yup.string().required('Debes ingresar un nombre'),
          phone: Yup.string()
            .nullable()
            .phone('El formato no es válido')
        })
      )
    })
  });
}

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProviderForm);
