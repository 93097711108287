const purchaseManagementAbility = can => {
  can('create', 'PurchaseManagement');
  can('destroy', 'PurchaseManagement');
  can('read', 'Product');
  can('read', 'ProductCategory');
  can('read', 'ProductInventory');
  can('read', 'Project');
  can('read', 'PurchaseManagement');
  can('read', 'Setting');
  can('read', 'Slide');
  can('read', 'User');
  can('update_password', 'User');
  can('update', 'PurchaseManagement');
  can('update', 'User');
};

export default purchaseManagementAbility;
