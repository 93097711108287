import React, { useEffect, useRef } from 'react';
import { Formik, Field, Form } from 'formik';
import { Col, Row, Button, Card, InputGroup } from 'react-bootstrap';
import { useListCommentsByProjects, useCreateCommentsByProjects } from '../../hooks/useComments';
import { useSelector } from 'react-redux';

const ProjectComments = ({ projectCode }) => {
  const currentUser = useSelector(state => state.auth.user);
  const { comments, reFetch: reFetchComments } = useListCommentsByProjects(projectCode);
  const { create: createComment } = useCreateCommentsByProjects(projectCode);
  const commentsContainerRef = useRef(null);
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    createComment(values, {
      onSuccess: () => {
        reFetchComments();
        resetForm();
        setSubmitting(false);
      },
      onError: () => {
        setSubmitting(false);
      }
    });
  };

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [comments]);

  return (
    <Row>
      <Col md={12} className="my-4">
        <h4 className="mb-3 d-flex">
          <div className="font-weight-bold mr-1">Comentarios</div> <i>(uso interno)</i>
        </h4>
        <Card>
          <Row className="g-0">
            <Col>
              <div
                className="chat-messages d-flex flex-column p-4"
                ref={commentsContainerRef}
                style={{ maxHeight: '300px', overflowY: 'auto' }}
              >
                {comments.map(comment =>
                  currentUser.id === comment.user.id ? (
                    <div
                      className="chat-message-right d-flex flex-shrink-0 flex-row-reverse margin-left-auto pb-4"
                      key={comment.id}
                    >
                      <div>
                        <img
                          src={
                            comment.user.avatarInfo.fileUrl
                              ? comment.user.avatarInfo.fileUrl
                              : '/static/media/default.7191549e.png'
                          }
                          className="rounded-circle"
                          alt={comment.user.fullName}
                          width="40"
                          height="40"
                        />
                        <div className="text-muted small text-nowrap mt-2">{comment.createdAt}</div>
                      </div>
                      <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                        <div className="font-weight-bold mb-1">Tú</div>
                        {comment.comment}
                      </div>
                    </div>
                  ) : (
                    <div className="chat-message-left d-flex flex-shrink-0 margin-right-auto pb-4" key={comment.id}>
                      <div>
                        <img
                          src={
                            comment.user.avatarInfo.fileUrl
                              ? comment.user.avatarInfo.fileUrl
                              : '/static/media/default.7191549e.png'
                          }
                          className="rounded-circle"
                          alt={comment.user.fullName}
                          width="40"
                          height="40"
                        />
                        <div className="text-muted small text-nowrap mt-2">{comment.createdAt}</div>
                      </div>
                      <div className="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                        <div className="font-weight-bold mb-1">{comment.user.fullName}</div>
                        {comment.comment}
                      </div>
                    </div>
                  )
                )}
              </div>
              <Formik initialValues={{ comment: { comment: '' } }} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                  <Form className="flex-grow-0 py-3 px-4 border-top">
                    <InputGroup>
                      <Field name="comment[comment]" className="form-control" placeholder="Escribe un comentario" />
                      <Button variant="primary" type="submit" disabled={isSubmitting}>
                        Enviar
                      </Button>
                    </InputGroup>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col></Col>
    </Row>
  );
};

export default ProjectComments;
