import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexPlansRequest = ({ dispatch, params = {}, successCallback, failureCallback }) => {
  ApiService.request('get', '/plans', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexPlansRequest = AwesomeDebouncePromise(indexPlansRequest, 300);
