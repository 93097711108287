import React, { useEffect } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { Trash } from 'react-feather';
import { Button } from 'react-bootstrap';
import { FormikInput } from '../../../components';

const StockTakeDetailInput = ({
  createProductModal,
  index,
  movementDetail,
  movementDetail: { productId, productCategoryName, productName, productSku, quantity: updatedQuantity },
  movementDetailsAttributes,
  modelKey
}) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const inputName = `${modelKey}[${index}]`;
  const quantityToBeUpdated = getIn(values, `${inputName}[quantityToBeUpdated]`);
  const productCurrentStock = getIn(values, `${inputName}[productCurrentStock]`);

  const handleRemoveBtn = () => {
    const arrayValuesLength = movementDetailsAttributes.length;
    const deleteValue = movementDetail;
    const newValues = [
      ...movementDetailsAttributes.slice(0, index),
      ...movementDetailsAttributes.slice(index + 1, arrayValuesLength)
    ];
    if (deleteValue && deleteValue.id) {
      deleteValue._destroy = 'true';
      newValues.push(deleteValue);
    }
    setFieldValue(modelKey, newValues);
  };

  const calculateMovement = () => {
    const movementQuantity = quantityToBeUpdated - productCurrentStock;
    setFieldValue(`${inputName}[quantity]`, movementQuantity);
  };

  useEffect(calculateMovement, [quantityToBeUpdated]);

  return (
    <tr className={`col-vertical-center${productId ? '' : ' col-not-created-product'}`}>
      <td>{productCategoryName}</td>
      <td>
        {productId ? (
          productName
        ) : (
          <Button className="btn btn-success" block onClick={() => createProductModal(movementDetail)}>
            Crear ítem
          </Button>
        )}
      </td>
      <td>{`${productSku}${productId ? '' : ' (No creado)'}`}</td>
      <td>
        <Field name={`${inputName}[productCurrentStock]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              disabled
              error={getIn(errors, field.name)}
              margin="no-margin"
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </td>
      <td>
        <Field name={`${inputName}[quantityToBeUpdated]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              inputType="number"
              error={getIn(errors, field.name)}
              margin="no-margin"
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </td>
      <td>{updatedQuantity}</td>
      <td>
        <Button className="btn-sm ml-1" variant="outline-danger" onClick={handleRemoveBtn}>
          <Trash width="12px" height="12px" />
        </Button>
      </td>
    </tr>
  );
};

export default StockTakeDetailInput;
