import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { LinkBtn } from '../../components';
import VehicleForm from './VehicleForm';
import { showCompanyRequest } from '../../requests/companies';
import { showVehicleRequest, updateVehicleRequest } from '../../requests/vehicles';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicVehicle from './basicVehicle';
import basicCompany from '../Company/basicCompany';

const VehicleEdit = props => {
  const [vehicle, setVehicle] = useState(basicVehicle);
  const [currentCompany, setCompany] = useState(basicCompany);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    match: { params }
  } = props;
  const { id, client_id: clientId } = params;

  const handleSuccessShow = response => {
    setVehicle(camelCaseRecursive(response.data));
  };

  const handleFailureUpdate = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response.data.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Vehículo actualizado con éxito' }));
    history.push(`/clients/${clientId}/vehicles/${id}`);
  };

  const fetchVehicle = () => {
    showVehicleRequest(clientId, id, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  const fetchCurrentCompany = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(fetchCurrentCompany, []);

  useEffect(fetchVehicle, [params]);

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);

    updateVehicleRequest(clientId, id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <h1>Editar Vehículo</h1>
        </Col>
        <Col md={2}>
          <LinkBtn className="cancel" variant="light-darker" block to={`/clients/${clientId}/vehicles`}>
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <VehicleForm
            companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
            vehicle={vehicle}
            action="edit"
            formRequest={handleUpdateRequest}
          />
        </Col>
      </Row>
    </>
  );
};

export default VehicleEdit;
