import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import snakeCaseKeys from 'snakecase-keys';
import { Briefcase, Image, MessageSquare, Tool } from 'react-feather';
import * as Yup from 'yup';
import { createDropZoneFileRequest, deleteDropZoneFileRequest } from '../../app/requests/dropzone';
import { BasicTextArea, DropzoneChecklist, SimpleCenteredModal } from '../../app/components';
import { deleteProjectReviewImageRequest } from '../../app/requests/projects';
import ProjectWorkTab from '../../app/screens/Project/Form/ProjectWorkTab';
import ProjectAutopartTab from '../../app/screens/Project/Form/ProjectAutopartTab';
import Accordion from './components/accordion';
import NotApplyButton from '../../app/screens/Checklist/buttons/NotApplyButton';
import BadGoodButton from '../../app/screens/Checklist/buttons/BadGoodButton';
import PercentageButton from '../../app/screens/Checklist/buttons/PercentageButton';
import NormalReviewButtons from '../../app/screens/Checklist/buttons/NormalReviewButtons';
import PercentageReviewButtons from '../../app/screens/Checklist/buttons/PercentageReviewButtons';

const maxAvatarSize = 20971520;

const ProjectReviewCard = ({ projectReview: initialProjectReview, fromReviewType, index, project, isDisabled, setUploadImage, isOpenReviewCard }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const dispatch = useDispatch();

  const { imageRequired, imagesInfo, name, workRequired } = initialProjectReview;

  const modelKeyProjectReview = `project[projectReviewsAttributes][${index}]`;

  const modelKeyProjectWorks = `${modelKeyProjectReview}[projectWorksAttributes]`;
  const projectWorksAttributesValues = getIn(values, modelKeyProjectWorks);
  const errorProjectWork = getIn(errors, modelKeyProjectWorks);

  const modelKeyProjectAutoparts = `${modelKeyProjectReview}[projectAutopartsAttributes]`;
  if (!getIn(values, modelKeyProjectAutoparts)) {
    setFieldValue(modelKeyProjectAutoparts, []);
  }
  const projectAutopartsAttributesValues = getIn(values, modelKeyProjectAutoparts);

  const modelKeyProjectImages = `${modelKeyProjectReview}[documentKeys]`;
  const errorProjectImages = getIn(errors, modelKeyProjectImages);

  const customStateValues = getIn(values, `${modelKeyProjectReview}[customState]`);
  const notes = getIn(values, `${modelKeyProjectReview}[notes]`);

  const errorSomeProjectWork = getIn(errors, `${modelKeyProjectReview}[someProjectWork]`);

  const touchedProjectWorks = getIn(touched, modelKeyProjectWorks);

  const [projectReviewState, setProjectReviewState] = useState(initialProjectReview.state);
  const [documentCode, setDocumentCode] = useState(Date.now());
  const [documentKeys, setDocumentKeys] = useState([]);
  const [imageCounter, setImageCounter] = useState(imagesInfo.length);
  const [workCounter, setWorkCounter] = useState(0);
  const [autopartCounter, setAutopartCounter] = useState(0);
  const [projectReview, setProjectReview] = useState({
    ...initialProjectReview,
    currentImages: [],
    persistedImages: imagesInfo
  });
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [autopartsModalBody, setAutopartsModalBody] = useState('');
  const [autopartsModalShow, setAutopartsModalShow] = useState(false);

  const assignProjectWork = () => {
    const data = (
      <ProjectWorkTab
        fromProjectReview
        projectWorksAttributes={projectWorksAttributesValues}
        projectReview={projectReview}
        modelKey={modelKeyProjectWorks}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        values={values}
        indexProjectReview={index}
        onHide={() => setModalShow(false)}
      />
    );
    if (projectWorksAttributesValues) {
      setModalBody(data);
      setWorkCounter(projectWorksAttributesValues.length);
    }
  };

  useEffect(assignProjectWork, [projectWorksAttributesValues, errors, touched]);

  const assignProjectAutopart = () => {
    const data = (
      <ProjectAutopartTab
        projectAutopartsAttributes={projectAutopartsAttributesValues}
        projectReview={projectReview}
        modelKey={modelKeyProjectAutoparts}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        values={values}
        indexProjectReview={index}
        onHide={() => setAutopartsModalShow(false)}
      />
    );
    if (projectAutopartsAttributesValues) {
      setAutopartsModalBody(data);
      setAutopartCounter(projectAutopartsAttributesValues.length);
    }
  };

  useEffect(assignProjectAutopart, [projectAutopartsAttributesValues, errors, touched]);

  const handleUpdateImages = images => {
    setUploadImage(false);
    const { persistedImages } = projectReview;
    const updatedState = {
      ...projectReview,
      currentImages: [...images],
      persistedImages: [...persistedImages]
    };

    setFieldValue(`${modelKeyProjectReview}[documentKeys]`, documentKeys);
    setImageCounter(persistedImages.length + images.length);
    setProjectReview(updatedState);
    setDocumentCode(Date.now());
  };

  const handleSuccessDeleteImage = response => {
    const documentInfo = JSON.parse(response.config.data);
    const { persistedImages } = projectReview;
    const updatedPersistedImages = persistedImages.filter(image => image.id !== documentInfo.document_id);
    const updatedState = {
      ...projectReview,
      persistedImages: updatedPersistedImages
    };
    setProjectReview(updatedState);
  };

  const handleDeleteProjectReviewImageRequest = params => {
    deleteProjectReviewImageRequest(project.id, {
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessDeleteImage
    });
  };

  const handleOnDrop = (code, documents) => {
    setUploadImage(true);
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'project_review_image',
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => {
        const { data } = response;
        const newDocumentKeys = documentKeys;
        data.map(element => newDocumentKeys.push(element.document_key));
        setDocumentKeys(newDocumentKeys);
        return response;
      }
    });
  };

  const handleOnDelete = image => {
    deleteDropZoneFileRequest(image.id, {
      dispatch,
      params: { dropZoneFile: { documentKey: image.code } },
      successCallback: () => {
        const newDocumentKeys = documentKeys.filter(element => element !== image.code);
        setDocumentKeys(newDocumentKeys);
        setImageCounter(imageCounter - 1);
      }
    });
  };

  const handleOnDeletePersistedImages = document => {
    setImageCounter(imageCounter - 1);
    const destroyImage = {
      project_review_id: projectReview.id,
      document_type: 'project_review_image',
      document_id: document.id
    };
    return handleDeleteProjectReviewImageRequest(destroyImage);
  };

  const handleButtonsAction = value => {
    setFieldValue(`${modelKeyProjectReview}[customState]`, value);
    if (value === 'not_apply') {
      setFieldValue(`${modelKeyProjectReview}[notApply]`, true);
      setFieldValue(`${modelKeyProjectReview}[state]`, 'pending');
      setProjectReviewState('pending');
      setProjectReview({ ...projectReview, state: 'pending', notApply: true });
    } else {
      setFieldValue(`${modelKeyProjectReview}[notApply]`, false);
      setFieldValue(`${modelKeyProjectReview}[state]`, value);
      setProjectReviewState(value);
      setProjectReview({ ...projectReview, state: value, notApply: false });
    }
  };

  const showTitleButton =
    customStateValues === 'not_apply' ||
    [
      'rejected',
      'approved',
      'percentage_0',
      'percentage_25',
      'percentage_50',
      'percentage_75',
      'percentage_100'
    ].includes(projectReviewState);

  const Title = ({ isOpen }) => (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div>
        <span style={{ textTransform: 'capitalize', fontSize: '0.85rem', fontWeight: '700', color: 'gray' }}>
          {name?.toLowerCase()}
        </span>
        {(imageRequired || workRequired) && (
          <span className={`ml-2 text-danger ${(errorProjectWork || errorProjectImages) && 'is-invalid'}`}>
            * {(errorProjectWork || errorProjectImages) && 'Tienes errores'}
          </span>
        )}
        {notes && (
          <OverlayTrigger key="top" placement="top" overlay={<Tooltip id="tooltip-top">{notes}</Tooltip>}>
            <MessageSquare size={16} className="ml-2" color="rgb(0, 81, 123)" />
          </OverlayTrigger>
        )}
        {Boolean(imageCounter) && <Image size={16} className="ml-2" color="rgb(0, 81, 123)" />}
      </div>
      {!isOpen &&
        showTitleButton &&
        (customStateValues === 'not_apply' ? (
          <NotApplyButton />
        ) : initialProjectReview.type === 'NormalReview' ? (
          <BadGoodButton customStateValues={customStateValues} />
        ) : (
          <PercentageButton customStateValues={customStateValues} />
        ))}
    </div>
  );

  return (
    <Accordion
      title={Title}
      key={index}
      content={handleIsOpen => (
        <div>
          {/** Imagenes - documentKeys */}
          <Row>
            <Col md={fromReviewType === 'mechanic' ? 6 : 12}>
              <DropzoneChecklist
                modalPreview={false}
                id={documentCode}
                fileAccept="image/*"
                documentCounter={imageCounter}
                maxSize={maxAvatarSize} // 20MB
                persistedFiles={projectReview.persistedImages}
                files={projectReview.currentImages}
                onDrop={handleOnDrop}
                onDropUploaded={images => handleUpdateImages(images)}
                onDelete={image => handleOnDelete(image)}
                onDeletePersistedFiles={handleOnDeletePersistedImages}
                name={`${modelKeyProjectReview}[documentKeys]`}
                error={getIn(errors, `${modelKeyProjectReview}[documentKeys]`)}
                touched={getIn(touched, `${modelKeyProjectReview}[documentKeys]`)}
                disabled={isDisabled}
                imageRequired={imageRequired}
              />
            </Col>
            {/** Work required */}
            {fromReviewType === 'mechanic' && (
              <>
                <input name={`${modelKeyProjectReview}[someProjectWork]`} style={{ display: 'none' }} />
                {/* <Row> */}
                  <Col md={3}>
                    <p className="fix-d">
                      <span
                        onClick={() => setAutopartsModalShow(true)}
                        disabled={isDisabled}
                        style={{ color: '#00517B', cursor: 'pointer' }}
                        className="ont-weight-bold"
                      >
                        <Tool className="icon-file-text" strokeWidth="1.5" size={16} />

                        <span>Agregar repuestos</span>
                      </span>
                    </p>

                    <SimpleCenteredModal
                      size="lg"
                      title="Repuestos"
                      body={autopartsModalBody}
                      show={autopartsModalShow}
                      onHide={() => setAutopartsModalShow(false)}
                    />
                    <p>{`${autopartCounter} ${autopartCounter === 1 ? 'repuesto agregado' : 'repuestos agregados'}`}</p>
                  </Col>
                  <Col md={3}>
                    <p className="fix-d">
                      <span
                        onClick={() => setModalShow(true)}
                        disabled={isDisabled}
                        style={{ color: '#00517B', cursor: 'pointer' }}
                        className="ont-weight-bold"
                      >
                        <Briefcase className="icon-file-text" strokeWidth="1.5" size={16} />

                        <span className="ml-1">Agregar trabajos {workRequired ? '*' : ''}</span>
                      </span>
                    </p>

                    <SimpleCenteredModal
                      size="lg"
                      title="Trabajos"
                      body={modalBody}
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                    {errorSomeProjectWork && touchedProjectWorks && (
                      <small className="text-danger">{errorSomeProjectWork}</small>
                    )}
                    {errorProjectWork && touchedProjectWorks && (
                      <small className="text-danger">Faltan datos en los trabajos ingresados</small>
                    )}
                    <p>{`${workCounter} ${workCounter === 1 ? 'trabajo agregado' : 'trabajos agregados'}`}</p>
                  </Col>
                {/* </Row> */}
              </>
            )}
          </Row>
          {/** Notes field */}
          <Row>
            <Col md={8}>
              <Field name={`${modelKeyProjectReview}[notes]`}>
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="1"
                    label={<span style={{ fontSize: '12px', fontWeight: 400, color: '#202020' }}>Notas</span>}
                    placeholder="Agregar nota"
                    disabled={isDisabled}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          {/* </Row> */}
          {/** Actions buttons */}
          {/* <Row> */}
            <Col md={4} className="d-flex justify-content-end mt-auto" style={{ gap: '1rem', marginBottom: '1rem' }}>
              <Field name={`${modelKeyProjectReview}[customState]`}>
                {({ field }) =>
                  initialProjectReview.type === 'NormalReview' ? (
                    <NormalReviewButtons
                      errors={errors}
                      touched={touched}
                      handleIsOpen={handleIsOpen}
                      handleButtonsAction={handleButtonsAction}
                      field={field}
                      isDisabled={isDisabled}
                    />
                  ) : (
                    <PercentageReviewButtons
                      errors={errors}
                      touched={touched}
                      handleIsOpen={handleIsOpen}
                      handleButtonsAction={handleButtonsAction}
                      field={field}
                      isDisabled={isDisabled}
                    />
                  )
                }
              </Field>
            </Col>
          </Row>
        </div>
      )}
      defaultOpen={isOpenReviewCard}
    />
  );
};

export const yupProjectReview = Yup.object().shape({
  notApply: Yup.boolean(),
  documentKeys: Yup.array().when('imageRequired', (imageRequired, schema, { parent, value }) => {
    const { fromReviewType, imagesInfo, notApply, reviewType, state } = parent;
    if (imageRequired && fromReviewType === reviewType) {
      const { documentKeys } = value;
      const validateState = state !== 'pending';
      const validateImagesInfo = imagesInfo === undefined || imagesInfo.length < 1;
      const validateDocumentKeys = documentKeys === undefined || documentKeys.length < 1;
      const validation = validateState && validateImagesInfo && validateDocumentKeys;
      return (!notApply && validation && schema.required('Debes ingresar al menos una imagen')) || schema;
    }
    return schema;
  }),
  notes: Yup.string().nullable(),
  projectWorksAttributes: Yup.array().of(
    Yup.object().shape({
      done: Yup.boolean(),
      name: Yup.string().required('Debes ingresar un nombre'),
      workHours: Yup.number().nullable(),
      projectDetailsAttributes: Yup.array().of(
        Yup.object().shape({
          categoryId: Yup.string().required('Debes seleccionar una categoría'),
          hidden: Yup.boolean().nullable(),
          productCategoryId: Yup.string().nullable(),
          productId: Yup.string().nullable(),
          quantity: Yup.number().nullable()
        })
      )
    })
  ),
  someProjectWork: Yup.string().when('workRequired', (workRequired, schema, { parent }) => {
    const { fromReviewType, notApply, projectWorksAttributes, reviewType, state } = parent;
    if (workRequired && fromReviewType === reviewType) {
      const validateState = state !== 'pending';
      const validateProjectWorks = projectWorksAttributes === undefined || projectWorksAttributes.length < 1;
      const validation = validateState && validateProjectWorks;
      return (!notApply && validation && schema.required('Debes ingresar al menos un trabajo')) || schema;
    }
    return schema;
  })
});

export default ProjectReviewCard;
