import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import ProjectLogsDataTable from '../Log/ProjectLogsDataTable';
import columns from '../Log/Columns';

const ProjectLogs = ({ match }) => {
  const [moreData, setMoreData] = useState(false);
  const { id } = match.params;

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">HISTORIAL DE CAMBIOS</h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ProjectLogsDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} id={id} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProjectLogs;
