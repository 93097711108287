import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';

import ComponentDataTable from '../../components/Utils/DataTable';
import logTypes from './logTypes';
import { FormikSelect } from '../../components/Utils/Select';
import { debounceIndexCompanyLogsRequest, indexCompanyLogsRequest } from '../../requests/logs';
import { sendAlert } from '../../actions/utils';
import downloadFile from '../../services/utils';

const CompanyLogsDataTable = ({
  columns,
  moreData,
  setMoreData,
  fromClientShow,
  clientId,
  fromVehicleShow,
  vehicleId
}) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [logs, setLogs] = useState([]);
  const [logType, setLogType] = useState('');
  const [queries, setQueries] = useState({});
  const [onRequest, setOnRequest] = useState(false);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseLogs = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setLogs(responseLogs);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const { sort_column: sortColumn } = params;
    const sendParams = {
      ...params,
      log_type: logType,
      client_id: clientId || '',
      vehicle_id: vehicleId || '',
      sort_column: sortColumn || 'created_at'
    };
    debounceIndexCompanyLogsRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'log_date':
        return { sort_column: 'created_at' };
      case 'responsable_name':
        return { sort_by_responsable_name: name };
      case 'responsable_email':
        return { sort_by_responsable_email: name };
      default:
        return { sort_column: name };
    }
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    indexCompanyLogsRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      {!fromClientShow && !fromVehicleShow && (
        <Row className="justify-content-end">
          <Col md={2}>
            <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
              Exportar
            </Button>
          </Col>
        </Row>
      )}
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        withDate
        handleSortCase={sortColumnCase}
        data={logs}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries({ ...response, log_type: logType });
          if (!onRequest) handleIndexRequest(response);
        }}
        customSearchLong={
          !fromClientShow &&
          !fromVehicleShow && (
            <FormikSelect
              isClearable
              options={logTypes}
              placeholder="Filtro Tipo"
              onChange={data => {
                setLogType(data ? data.value : '');
                setMoreData(!moreData);
              }}
            />
          )
        }
      />
    </>
  );
};

export default CompanyLogsDataTable;
