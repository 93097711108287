import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Player } from 'video-react';

const VideoSection = () => {
  return (
    <Fade up>
      <div id="video-publicity">
        <Row className="video-margin-mobile max-width-2000">
          <Col className="text-center">
            <h3 className="text-center">Conoce más acerca de nuestro software</h3>
            <Player playsInline src="/assets/videos/video-marketing-gestioncar.mp4" type="video/mp4" />
          </Col>
        </Row>
      </div>
    </Fade>
  );
};

export default VideoSection;
