import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import ClientInfo from './ClientInfo';
import ClientVehicles from './ClientVehicles';
import { LinkBtn } from '../../components';
import { showClientRequest } from '../../requests/clients';
import columns from './LogColumns';
import projectColumns from '../Project/Columns';
import CompanyLogsDataTable from '../Log/CompanyLogsDataTable';
import ProjectDataTable from '../Project/ProjectDataTable';
import useCountryFactory from "../../hooks/useCountryFactory";
import GoBackButton from "../../components/GoBackButton";

const basicClient = {
  id: '',
  active: '',
  address: '',
  businessActivity: '',
  cellphone: '',
  commune: {},
  contactsAttributes: [],
  createdAt: '',
  email: '',
  firstLastName: '',
  legalName: '',
  name: '',
  nationalIdentification: '',
  notes: '',
  phone: '',
  region: {},
  secondLastName: '',
  seller: {},
  isCompany: ''
};

const ClientShow = ({ match }) => {
  const { id: clientId } = match.params;
  const [client, setClient] = useState(basicClient);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    setClient(camelCaseRecursive(response.data));
  };

  const handleIndexClient = () => {
    showClientRequest(clientId, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };
  const countryFactory = useCountryFactory();

  useEffect(handleIndexClient, [moreData]);

  return (
    <>
      <Row className="mt-3">
        <Col md={{ span: 6, offset: 1 }}>
          <h2>{client.fullName}</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="primary" block to={`/clients/${client.id}/edit`}>
            Editar
          </LinkBtn>
        </Col>
        <Col md={2}>
          <GoBackButton />
        </Col>
      </Row>
      <Row className="mt-4 mb-5">
        <Col md={{ span: 5, offset: 1 }}>
          <ClientInfo client={client} countryFactory={countryFactory} />
        </Col>
        <Col md={5}>
          <ClientVehicles clientId={clientId} />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <h2>PROYECTOS ASOCIADOS A CLIENTE</h2>
          <ProjectDataTable
            columns={projectColumns}
            urlQueries={{ client_id: clientId }}
            externalView
            adminView
          />
        </Col>

        <Col className="mt-5" md={{ span: 6, offset: 1 }}>
          <h2>HISTORIAL DE CAMBIOS</h2>
        </Col>
        <Col md={{ span: 10, offset: 1 }}>
          <CompanyLogsDataTable
            columns={columns}
            setMoreData={setMoreData}
            moreData={moreData}
            clientId={clientId}
            fromClientShow
          />
        </Col>
      </Row>
    </>
  );
};

export default ClientShow;
