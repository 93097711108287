import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';

import { debounceIndexRegionsRequest } from '../../requests/regions';
import { debounceIndexCommunesRequest, indexCommunesRequest } from '../../requests/communes';
import { InputRemoteSelect } from '../Utils/Select';

class RegionCommuneSelect extends Component {
  constructor(props) {
    super(props);
    const { currentModel } = this.props;

    this.state = {
      communeDisabled: !currentModel?.communeId,
      communes: []
    };
  }

  componentDidUpdate(prevProps) {
    const { values, modelKey } = this.props;
    const prevModel = getIn(prevProps.values, modelKey);
    const currentValue = getIn(values, modelKey);
    if (currentValue.regionId && prevModel.regionId !== currentValue.regionId) {
      this.fetchInitialCommunes({ region: currentValue.regionId });
    }
  }

  handleSuccessCommunesIndex = response => {
    const { data } = response.data;
    this.setState({ communes: data });
  };

  handleRegionChange = value => {
    const { setFieldValue, modelKey } = this.props;
    setFieldValue(`${modelKey}[regionId]`, value);
    setFieldValue(`${modelKey}[communeId]`, '');
    setFieldValue(`${modelKey}[communeName]`, '');
    if (value !== '') {
      this.fetchInitialCommunes({ region: value });
    } else {
      this.setState({ communeDisabled: true });
    }
  };

  initialRegionValue = () => {
    const { values, modelKey, currentModel } = this.props;
    const currentValues = getIn(values, modelKey);
    if (currentValues.regionId && currentModel.regionId && currentValues.regionId === currentModel.regionId) {
      return { label: currentModel.regionName, value: currentModel.regionId };
    }
    return undefined;
  };

  initialCommuneValue = () => {
    const { values, modelKey } = this.props;
    const currentModel = getIn(values, modelKey);
    if (currentModel.communeId !== '') {
      return { label: currentModel.communeName, value: currentModel.communeId };
    }
    return false;
  };

  fetchRegions = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexRegionsRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: data => callback(this.resultFetchData(data))
    });
  };

  fetchCommunes = (inputValue, callback) => {
    const { dispatch, values, modelKey } = this.props;
    const { regionId } = getIn(values, modelKey);
    debounceIndexCommunesRequest({
      dispatch,
      params: {
        name: inputValue,
        region: regionId,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 50
      },
      successCallback: data => callback(this.resultFetchData(data))
    });
  };

  resultFetchData = response => {
    const { data } = response.data;
    return data;
  };

  fetchInitialCommunes(params = {}) {
    const { dispatch } = this.props;
    indexCommunesRequest({
      dispatch,
      params: {
        ...params,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 1000
      },
      successCallback: this.handleSuccessCommunesIndex
    });
    this.setState({ communeDisabled: false });
  }

  render() {
    const { communeDisabled, communes } = this.state;
    const { errors, touched, setFieldValue, modelKey, regionAbbr, communeAbbr, countryFactory } = this.props;
    const initRegion = this.initialRegionValue();
    const initCommune = this.initialCommuneValue();

    return (
      <>
        <Col md={6}>
          <Field name={`${modelKey}[regionId]`}>
            {({ field }) => (
              <InputRemoteSelect
                {...field}
                abbr={regionAbbr}
                isClearable={!regionAbbr}
                label={countryFactory.getLabelForRegion()}
                placeholder={`Seleccionar ${countryFactory.getLabelForRegion()}`}
                value={initRegion}
                onChange={data => this.handleRegionChange(data ? data.value : '')}
                request={this.fetchRegions}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name={`${modelKey}[communeId]`}>
            {({ field }) => (
              <InputRemoteSelect
                {...field}
                abbr={communeAbbr}
                isClearable={!communeAbbr}
                label={countryFactory.getLabelForCommune()}
                placeholder={`Seleccionar ${countryFactory.getLabelForCommune()}`}
                isDisabled={communeDisabled}
                value={initCommune}
                onChange={data => {
                  setFieldValue(field.name, data ? data.value : '');
                  setFieldValue(`${modelKey}[communeName]`, data ? data.label : '');
                }}
                defaultOptions={communes}
                request={this.fetchCommunes}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </>
    );
  }
}

export default RegionCommuneSelect;
