import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexMovementDetailsRequest = ({ dispatch, format, params = {}, successCallback, failureCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/movement_details${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexMovementDetailsRequest = AwesomeDebouncePromise(indexMovementDetailsRequest, 300);
