import React, {useState, useEffect} from 'react';
import {withFormik, Form, Field, getIn, FieldArray} from 'formik';
import {Button, Col, Row, Nav, Tab} from 'react-bootstrap';
import * as Yup from 'yup';
import {Check, X} from 'react-feather';
import moment from "moment";
import {
  InputRemoteSelect,
  FormikNumberFormat,
  FormikDatePicker,
  FormikInput,
  FormikCheckBox, FormikSelect
} from '../../../components';
import documentsType from "./Constants";
import useCountryFactory from "../../../hooks/useCountryFactory";

const codeSiiOptions = [
  {label: 'Factura Electrónica (33)', value: 33},
  {label: 'Boleta Electrónica (39)', value: 39},
  {label: 'AWB (Air Will Bill) (809)', value: 809},
  {label: 'B/L (Conocimiento de embarque) (808)', value: 808},
  {label: 'Boleta (35)', value: 35},
  {label: 'Boleta Exenta (38)', value: 38},
  {label: 'Boleta Exenta Electrónica (41)', value: 41},
  {label: 'Carta de Porte (811)', value: 811},
  {label: 'Certificado de Depósito Bolsa Prod. Chile (814)', value: 814},
  {label: 'CIRAPPE (820)', value: 820},
  {label: 'Contrato (803)', value: 803},
  {label: 'Declaración de Ingreso (DIN) (914)', value: 914},
  {label: 'DUS (807)', value: 807},
  {label: 'Factura (30)', value: 30},
  {label: 'Factura de Compra (45)', value: 45},
  {label: 'Factura de Compra Electrónica (46)', value: 46},
  {label: 'Factura de exportación (101)', value: 101},
  {label: 'Factura de Exportación Electrónica (110)', value: 110},
  {label: 'Factura Exenta (32)', value: 32},
  {label: 'Factura No Afecta o Exenta Electrónica (34)', value: 34},
  {label: 'Ficha ChileCompra (806)', value: 806},
  {label: 'Guía de Despacho (50)', value: 50},
  {label: 'Guía de Despacho Electrónica (52)', value: 52},
  {label: 'HES', value: 'HES'},
  {label: 'Liquidación (103)', value: 103},
  {label: 'Liquidación Factura (40)', value: 40},
  {label: 'Liquidación-Factura Electrónica (43)', value: 43},
  {label: 'MIC/DTA (810)', value: 810},
  {label: 'Nota de Crédito (60)', value: 60},
  {label: 'Nota de Crédito de exportación (106)', value: 106},
  {label: 'Nota de Crédito de Exportación Electrónica (112)', value: 112},
  {label: 'Nota de Crédito Electrónica (61)', value: 61},
  {label: 'Nota de Débito (55)', value: 55},
  {label: 'Nota de débito de exportación (104)', value: 104},
  {label: 'Nota de Débito de Exportación Electrónica (111)', value: 111},
  {label: 'Nota de Débito Electrónica (56)', value: 56},
  {label: 'Nota de pedido (802)', value: 802},
  {label: 'Orden de Compra (801)', value: 801},
  {label: 'Pasaporte (813)', value: 813},
  {label: 'Proceso ChileCompra (805)', value: 805},
  {label: 'Resolución (804)', value: 804},
  {label: 'Resolución del SNA donde califica Servicios de Exportación (812)', value: 812},
  {label: 'Vale de Prenda Bolsa Prod. Chile (815)', value: 815},
  {label: 'Vales comprobantes de Pago Medios Electrónicos (Transbank) (48)', value: 48}
];

const ProjectInvoiceForm = ({errors, onHide, setFieldValue, submitVariant, touched, values, ...props}) => {
  const {
    action,
    clients,
    fetchClients,
    handleModalClose,
    onClickEditClient,
    associateInvoice = false,
    showIsDetailed = false,
    projectPayments
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const [currentClient, setCurrentClient] = useState({});
  const allBillingData = associateInvoice || values.projectInvoice.type === 'ReceiptDocument' || currentClient?.allBillingData
  const [date, setDate] = useState(new Date());
  const [generateDetailed, setGenerateDetailed] = useState(false);
  const [expirationDate, setExpirationDate] = useState(new Date());
  const countryFactory = useCountryFactory();
  let filteredDocumentsType = documentsType.filter(document => document.value !== 'CreditNoteDocument');
  if (!associateInvoice) {
    filteredDocumentsType = filteredDocumentsType.filter(document => document.value !== 'SaleNoteDocument');
  }

  const listProjectPayments = projectPayments?.filter(payment => parseFloat(payment.balance) > 0).map(payment => ({
    label: `${payment.translatedPaymentType} (${payment.date}) - Monto pendiente: ${payment.balance}`,
    value: payment.id,
    balance: parseFloat(payment.balance)
  }));

  const handleRestoreOriginalValues = () => {
    setCurrentClient(clients.find(client => client.id === values.projectInvoice.clientId));
  };
  useEffect(handleRestoreOriginalValues, [clients, values.projectInvoice.clientId]);

  const handleFormatting = e => {
    const formattedValue = e.target.value.replace(/\./g, '');
    setFieldValue(e.target.name, formattedValue);
  };

  const onChangeDetailedCheck = (e) => {
    setGenerateDetailed(e.target.checked);
    setFieldValue(e.target.name, e.target.checked);
  }

  const selectedAmortization = async (index, data) => {
    const totalBalance = await calculateTotalBalance(index, data.balance);
    const totalDocument = parseFloat(values.projectInvoice.amount) * ((values.projectInvoice.companyTaxRate / 100) + 1)
    if (totalDocument > totalBalance) {
      setFieldValue(`projectInvoice[projectAmortizationsAttributes][${index}][amount]`, data.balance);
    } else {
      const finalBalance = totalDocument - (totalBalance - data.balance);
      setFieldValue(`projectInvoice[projectAmortizationsAttributes][${index}][amount]`, finalBalance);
    }
  };

  const calculateTotalBalance = async (editedIndex, balance) => {
    return new Promise((resolve, reject) => {
      let totalAmount = 0;
      values.projectInvoice.projectAmortizationsAttributes.forEach((payment, index) => {
        if(index !== editedIndex && payment.amount !== '') {
          totalAmount += parseFloat(payment.amount);
        }
      });
      resolve(totalAmount + balance);
    });
  };

  return (
    <Form>
      <Row className="my-3">
        <Col md={12}>
          <Field name="projectInvoice[type]">
            {({field}) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Documento"
                placeholder="Seleccione un tipo de Documento"
                options={filteredDocumentsType}
                defaultValue="InvoiceDocument"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {associateInvoice && (
          <Col md={12}>
            <Field name="projectInvoice[invoiceNumber]">
              {({field}) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Número de Documento / Folio"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        <Col md={12}>
          <Field name="projectInvoice[clientId]">
            {({field}) => (
              <InputRemoteSelect
                {...field}
                abbr
                isClearable
                label="Cliente"
                placeholder="Seleccionar Cliente"
                value={clients.find(client => client.id === currentClient?.id)}
                onChange={data => {
                  const newClientId = data ? data.id : '';
                  setFieldValue(field.name, newClientId);
                }}
                request={fetchClients}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="projectInvoice[issueDate]">
            {({field}) => (
              <FormikDatePicker
                {...field}
                abbr
                label="Fecha de Emisión"
                dateFormat="dd/MM/yyyy"
                selected={date}
                onChange={newDate => {
                  setDate(newDate);
                  setFieldValue(field.name, newDate);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="projectInvoice[expirationDate]">
            {({field}) => (
              <FormikDatePicker
                {...field}
                abbr
                label="Fecha de Vencimiento"
                dateFormat="dd/MM/yyyy"
                selected={expirationDate}
                onChange={newDate => {
                  setExpirationDate(newDate);
                  setFieldValue(field.name, newDate);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {showIsDetailed && <Col md={12} className="mt-3 ml-3">
          <Field name="projectInvoice[isDetailed]">
            {({field}) => (
              <FormikCheckBox {...field} custom className="font-weight-bolder"
                              label="Emitir documento con detalle de la OT"
                              style={{zIndex: 0}} type="switch" field={field} onChange={onChangeDetailedCheck}/>
            )}
          </Field>
        </Col>}
        {!generateDetailed && <Col md={12}>
          <Field name="projectInvoice[amount]">
            {({field}) => (
              <FormikNumberFormat
                {...field}
                abbr
                leftAddon={countryFactory.getSymbol()}
                label="Valor Neto"
                decimalScale={2}
                onChange={handleFormatting}
                error={getIn(errors, 'projectInvoice[amount]')}
                touched={getIn(touched, 'projectInvoice[amount]')}
              />
            )}
          </Field>
        </Col>}
        {!generateDetailed && <Col md={12}>
          <Field name="projectInvoice[description]">
            {({field}) => (
              <FormikInput
                {...field}
                label="Descripción (opcional)"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>}
        <Col md={12}>
          <Tab.Container defaultActiveKey="first">
            <Nav variant="tabs" className="mb-3 equal-width-tabs">
              <Nav.Item>
                <Nav.Link eventKey="first">Abonos</Nav.Link>
              </Nav.Item>
              {!associateInvoice && <Nav.Item>
                <Nav.Link eventKey="second">Referencias</Nav.Link>
              </Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <FieldArray name="projectInvoice[projectAmortizationsAttributes]">
                  {({push, remove}) => (
                    <>
                      {values.projectInvoice.projectAmortizationsAttributes.map((payment, index) => (
                        <Col md={12} key={`payment${index}`} className="mb-3">
                          <Row>
                            <Col md={6}>
                              <Field
                                name={`projectInvoice[projectAmortizationsAttributes][${index}][projectPaymentId]`}>
                                {({field}) => (
                                  <FormikSelect
                                    {...field}
                                    abbr
                                    label="Abono"
                                    placeholder="Seleccione Abono"
                                    options={listProjectPayments}
                                    onChange={data => {
                                      selectedAmortization(index, data);
                                      setFieldValue(field.name, data ? data.value : '');
                                    }}
                                    error={getIn(errors, field.name)}
                                    touched={getIn(touched, field.name)}
                                  />
                                )}
                              </Field>
                            </Col>
                            <Col md={4}>
                              <Field name={`projectInvoice[projectAmortizationsAttributes][${index}][amount]`}>
                                {({field}) => (
                                  <FormikNumberFormat
                                    {...field}
                                    abbr
                                    leftAddon={countryFactory.getSymbol()}
                                    decimalScale={2}
                                    label="Monto"
                                    disabled
                                    onChange={handleFormatting}
                                    error={getIn(errors, field.name)}
                                    touched={getIn(touched, field.name)}
                                  />
                                )}
                              </Field>
                            </Col>
                            <Col md={2} className="my-auto">
                              <Button variant="danger" onClick={() => remove(index)}>
                                Eliminar
                              </Button>
                            </Col>
                          </Row>
                          <hr className="mt-1"/>
                        </Col>
                      ))}
                      <Button onClick={() => push({projectPaymentId: '', amount: ''})}
                              className="ml-3">
                        Agregar Abono
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Tab.Pane>
              {!associateInvoice && <Tab.Pane eventKey="second">
                <FieldArray name="projectInvoice[jsonReferences]">
                  {({push, remove}) => (
                    <>
                      {values.projectInvoice.jsonReferences.map((reference, index) => (
                        <Col md={12} key={`reference${index}`} className="mb-3">
                          <Field name={`projectInvoice[jsonReferences][${index}].codeSii`}>
                            {({field}) => (
                              <FormikSelect
                                {...field}
                                abbr
                                label="Documento de Referencia"
                                placeholder="Seleccione tipo de documento"
                                options={codeSiiOptions}
                                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                              />
                            )}
                          </Field>
                          <Field name={`projectInvoice[jsonReferences][${index}].number`}>
                            {({field}) => (
                              <FormikInput
                                {...field}
                                abbr
                                label="Número de documento"
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                              />
                            )}
                          </Field>
                          <Field name={`projectInvoice[jsonReferences][${index}].referenceDate`}>
                            {({field}) => (
                              <FormikDatePicker
                                {...field}
                                abbr
                                label="Fecha de Referencia"
                                dateFormat="dd/MM/yyyy"
                                selected={field.value ? new Date(field.value) : null}
                                onChange={newDate => {
                                  setFieldValue(field.name, newDate);
                                }}
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                              />
                            )}
                          </Field>
                          <Field name={`projectInvoice[jsonReferences][${index}].reason`}>
                            {({field}) => (
                              <FormikInput
                                {...field}
                                abbr
                                label="Motivo"
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                              />
                            )}
                          </Field>
                          <Button variant="danger" onClick={() => remove(index)}>
                            Eliminar
                          </Button>
                          <hr className="mt-1"/>
                        </Col>
                      ))}
                      <Button onClick={() => push({codeSii: '', number: '', referenceDate: new Date(), reason: ''})}
                              className="ml-3">
                        Agregar Referencia
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Tab.Pane>}
            </Tab.Content>
          </Tab.Container>
        </Col>
        {currentClient && Object.keys(currentClient).length > 0 && (
          <Col md={12} className="my-4">
            <table className="table table-striped">
              <tbody>
              <tr>
                <th>NOMBRE *</th>
                <td>{currentClient.fullName}</td>
              </tr>
              <tr>
                <th>RUT *</th>
                <td>{currentClient.nationalIdentification}</td>
              </tr>
              <tr>
                <th>DIRECCIÓN *</th>
                <td>{currentClient.address}</td>
              </tr>
              <tr>
                <th>{countryFactory.getLabelForCommune()} *</th>
                <td>{currentClient.communeName}</td>
              </tr>
              <tr>
                <th>CELULAR</th>
                <td>{currentClient.cellphone}</td>
              </tr>
              <tr>
                <th>EMAIL</th>
                <td>{currentClient.email}</td>
              </tr>
              </tbody>
            </table>
          </Col>
        )}
      </Row>
      <Row className="mb-4">
        <Col md={6}>
          {currentClient && Object.keys(currentClient).length > 0 && allBillingData ? (
            <strong className="text-success">
              Datos de facturación correctos <Check/>
            </strong>
          ) : (
            currentClient &&
            Object.keys(currentClient).length > 0 && (
              <strong className="text-danger">
                Datos de facturación incorrectos <X/>{' '}
              </strong>
            )
          )}
        </Col>
        {currentClient && Object.keys(currentClient).length > 0 && (
          <>
            <Col md={2} className="pl-0">
              <Button block variant="outline-dark" onClick={handleModalClose}>
                Cancelar
              </Button>
            </Col>
            <Col md={2} className="pl-0">
              <Button block variant="secondary" onClick={onClickEditClient}>
                Editar Cliente
              </Button>
            </Col>
            {allBillingData && (
              <Col md={2} className="pl-0">
                <Button type="submit" variant={submitVariant} block onClick={onHide}>
                  {btnMessage}
                </Button>
              </Col>
            )}
          </>
        )}
      </Row>
    </Form>
  );
};

const setInitialValues = ({projectInvoice, showIsDetailed}) => {
  return {
    projectInvoice: {
      ...projectInvoice,
      isDetailed: showIsDetailed,
      type: 'InvoiceDocument',
      jsonReferences: [],
      projectAmortizationsAttributes: []
    }
  };
};

const validationSchema = Yup.object().shape({
  projectInvoice: Yup.object().shape({
    invoiceNumber: Yup.string().when('isAssociateInvoice', {
      is: true,
      then: Yup.string().required('Debes ingresar un Folio')
    }),
    clientId: Yup.string().required('Debes seleccionar un cliente'),
    amount: Yup.number()
      .required('Debes ingresar un valor total')
      .positive('Debe ser mayor a 0'),
    description: Yup.string().nullable(),
    isDetailed: Yup.boolean(),
    type: Yup.string().required('Debes seleccionar un tipo de documento'),
    projectAmortizationsAttributes: Yup.array().of(
      Yup.object().shape({
        projectPaymentId: Yup.string().required('Debes seleccionar un abono'),
        amount: Yup.number().required('Debes ingresar un monto').positive('Debe ser mayor a 0')
      })
    ),
  })
});

const handleSubmit = (values, {props}) => {
  const {formRequest} = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: false,
  validateOnMount: props => props.action !== 'new'
})(ProjectInvoiceForm);
