import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Row, Col } from 'react-bootstrap';
import StockTakeForm from './StockTakeForm';
import { createProductMovementRequest } from '../../../requests/productMovements';
import { sendAlert } from '../../../actions/utils';

const basicProductMovement = {
  invoiceNumber: '',
  movementType: '',
  movementDetailsAttributes: [],
  providerId: '',
  totalAmount: ''
};

const StockTakeNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Toma de Inventario creada con éxito' }));
    history.push(`/inventory`);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createProductMovementRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <Row>
      <Col md={{ span: 10, offset: 1 }} xs={12}>
        <Row className="mt-3">
          <Col className="mb-4">
            <h2 className="text-uppercase">Toma de Inventario</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StockTakeForm productMovement={basicProductMovement} formRequest={handleCreateRequest} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StockTakeNew;
