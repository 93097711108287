import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from "snakecase-keys";
import api from '../services/api';
import useQuery, { useInvalidateQuery } from './useQuery';

const getClients = (companyCode, params) => {
  const urlSearchParams = (new URLSearchParams(params)).toString();
  return api.get(`/public/companies/${companyCode}/clients.json?${urlSearchParams}`).then(res => res.json());
}

export const useListClients = (companyCode, params) => {
  const { data = [] } = useQuery(['public_clients', companyCode, params], getClients);
  const invalidateQueries = useInvalidateQuery();
  const reFetch = async () => {
    await invalidateQueries(['public_clients', companyCode]);
  };
  return { clients: camelCaseRecursive(data), reFetch };
};

const createClient = (companyCode, params) =>
  api.postAxios(`/public/companies/${companyCode}/clients.json`, snakeCaseKeys(params)).then(res => res.data);

export const useCreateClient = companyCode => {
  const create = async (values, options = {
    onSuccess: () => {}, onError: () => {}
  }) => {
    try {
      const data = await createClient(companyCode, values);
      options.onSuccess(data);
    } catch (error) {
      options.onError(error);
    }
  };
  return { create };
};
