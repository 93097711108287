import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import { FormikInput, RegionCommuneSelect } from '../../components';
import useCountryFactory from "../../hooks/useCountryFactory";

const BranchOfficeForm = props => {
  const {
    action,
    branchOffice,
    dispatch,
    errors,
    handleModalClose,
    onHide,
    setFieldValue,
    submitVariant,
    touched,
    values
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const countryFactory = useCountryFactory();

  return (
    <Form>
      <Row className="mt-3 mb-4">
        <Col md={4}>
          <Field name="branchOffice[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="branchOffice[address]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Dirección"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="branchOffice[addressNumber]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="number"
                label="Número"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <RegionCommuneSelect
          regionAbbr
          communeAbbr
          modelKey="branchOffice"
          dispatch={dispatch}
          currentModel={branchOffice}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          countryFactory={countryFactory}
        />
      </Row>

      <Row className="d-flex justify-content-end mb-4">
        {handleModalClose && (
          <Col md={{ span: 3, offset: 6 }}>
            <Button className="btn" variant="primary" block onClick={handleModalClose}>
              Cancelar
            </Button>
          </Col>
        )}
        <Col md={3}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { branchOffice } = props;
  return { branchOffice };
};

const validationSchema = Yup.object().shape({
  branchOffice: Yup.object().shape({
    address: Yup.string().required('Debes ingresar una dirección'),
    addressNumber: Yup.number().required('Debes ingresar un número'),
    communeId: Yup.string().required('Debes seleccionar una comuna'),
    name: Yup.string().required('Debes ingresar un nombre'),
    regionId: Yup.string().required('Debes seleccionar una región')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(BranchOfficeForm);
