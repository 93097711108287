const otStateTypes = [
  { label: 'En Proceso', value: 'in_process' },
  { label: 'Ppto. Enviado', value: 'budget_sent' },
  { label: 'Ppto. Aprobado', value: 'budget_approved' },
  { label: 'Ppto. Parcial', value: 'partial_budget' },
  { label: 'Garantía', value: 'warranty' },
  { label: 'OT Finalizada', value: 'ot_completed' },
  { label: 'Entregado', value: 'delivered' },
  { label: 'Garantía y Entregado', value: 'delivered_warranty' },
  { label: 'Rechazado', value: 'rejected' }
];

const otQuoteStateTypes = [
  { label: 'En Proceso', value: 'in_process' },
  { label: 'Ppto. Enviado', value: 'budget_sent' },
  { label: 'Ppto. Aprobado', value: 'budget_approved' },
  { label: 'Rechazado', value: 'rejected' }
];

const invoiceStateTypes = [
  { label: 'No Facturado', value: 'unbilled' },
  { label: 'Por cobrar', value: 'billed' },
  { label: 'Pagada', value: 'paid' }
];

const discountTypes = [
  { label: 'Precio', value: 'price' },
  { label: 'Porcentaje', value: 'percentage' }
];

const kanbanOt = [
  { label: 'Ppto. Aprobado', value: 'budget_approved' },
  { label: 'Ppto. Parcial', value: 'partial_budget' },
  { label: 'OT Finalizada', value: 'ot_completed' },
  { label: 'Garantía', value: 'warranty' }
];
const kanbanOtEnviado = [{ label: 'Ppto. Enviado', value: 'budget_sent' }];

export { discountTypes, otStateTypes, invoiceStateTypes, kanbanOt, kanbanOtEnviado, otQuoteStateTypes };
