import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import { BasicDropdown, LinkBtn } from '../../components';
import {
  companyIndexProductMovementsRequest,
  companyIndexWithDetailsProductMovementsRequest
} from '../../requests/productMovements';
import columns from './Columns';
import ProductMovementCompanyDataTable from './ProductMovementCompanyDataTable';
import { sendAlert } from '../../actions/utils';
import downloadFile from '../../services/utils';

const ProductMovementCompanyIndex = () => {
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const [queries, setQueries] = useState({});

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const downloadIndex = (format, params) => {
    companyIndexProductMovementsRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: response => downloadFile(response),
      failureCallback: handleFailureRequest
    });
  };

  const downloadIndexWithDetails = (format, params) => {
    companyIndexWithDetailsProductMovementsRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: response => downloadFile(response),
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row>
        <Col md={11} className="mx-auto" xs={12}>
          <Row className="mt-4">
            <Col md={9}>
              <h2 className="text-uppercase">Movimientos Empresa</h2>
            </Col>
            <Col md={2}>
              <LinkBtn variant="primary" block to="/movements/new">
                Realizar Movimiento
              </LinkBtn>
            </Col>
            <Col md={1} style={{ paddingRight: 0 }}>
              <BasicDropdown
                small
                noArrow
                variant="link"
                classColor="text-dark"
                titleDrop={<MoreVertical />}
                items={[
                  { key: '1', title: 'Exportar', onClick: () => downloadIndex('xlsx', queries) },
                  { key: '2', title: 'Exportar detalles', onClick: () => downloadIndexWithDetails('xlsx', queries) }
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ProductMovementCompanyDataTable
                columns={columns}
                moreData={moreData}
                setMoreData={setMoreData}
                setQueries={setQueries}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProductMovementCompanyIndex;
