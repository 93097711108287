import React, { useState }  from 'react';
import { useDispatch } from 'react-redux';
import { Field, getIn } from 'formik';
import { Card, Col, Row, Button, Spinner } from 'react-bootstrap';
import QRCode from "react-qr-code";

import { FormikInput, UploadImage, SimpleCenteredModal } from '../../../components';
import defaultImg from '../../../assets/images/default.png';
import { sendAlert } from '../../../actions/utils';
import WhatsappConnection from '../../Company/WhatsappConnection';
import { status2Chat, qr2Chat } from '../../../requests/user';
import useCountryFactory from "../../../hooks/useCountryFactory";
import PhoneInputField from "../../../components/Utils/Input/PhoneInputField";

const UserForm = props => {
  const { errors, touched, setFieldValue, fromShow, user } = props;
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const dispatch = useDispatch();
  const countryFactory = useCountryFactory();
  let qrCode;

  const renderQr = qr => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
        content={
          <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
            <b>Escanea el código de abajo usando WhatsApp</b>
            <ol>
              <li>Abre Configuración (iPhone) o Menú (Android)</li>
              <li>Selecciona Dispositivos Vinculados</li>
              <li>Toca en 'Vincular un dispositivo'</li>
            </ol>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={qr}
              viewBox="0 0 256 256"
            />
          </div>
        }
      />
    );
  };

  const notSuscribedMessage = () => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
        content={
          <div>
            <b>No tienes contratado el servicio de mensajería directo. Para contratarlo, ponte en contacto con nosotros.</b>
          </div>
        }
      />
    );
  };

  const handleSuccessConnect = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Whatsapp conectado con éxito' }));
    handleModalClose();
  };

  const handleMessage = status => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
      />
    );
    const msg = {
      'channel-creation-failed': {kind: 'error', message: `Error al crear el canal para el número ${  user.whatsappPhoneNumber}`},
      'failure-state': {kind: 'error', message: 'Error'},
      'already-connected': {kind: 'success', message: 'Ya estás conectado.'},
      'connection-failed': {kind: 'error', message: 'Conexión fallida.'}
    };
    dispatch(sendAlert({ kind: msg[status].kind, message: msg[status].message }));    
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const getQr = () => {
    qr2Chat({
      dispatch,
      successCallback: response => {
        if (response.data.status === 'disconnected') {
          if (response.data.qr) {
            if (response.data.qr !== qrCode) {
              qrCode = response.data.qr;
              renderQr(response.data.qr);
            }
          }
          setTimeout(getQr, 1000);
        } else if (response.data.status === 'connected') {
          handleSuccessConnect();
        } else {
          handleMessage(response.data.status);
        }
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleConnectRequest = () => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
        content={
          <Col>
            <Row className='justify-content-center'>
              <b>Obteniendo código, puede tardar unos segundos.</b>
            </Row>
            <Row>
              <div className="containerSpinnerLoad">
                <Spinner animation="border" variant="primary" />
              </div>
            </Row>
          </Col>
        }
      />
    );
    status2Chat({
      dispatch,
      successCallback: response => {
        if (response.data.status === 'disconnected') {
          renderQr(response.data.qr);
        } else if (response.data.status === 'not-suscribed') {
          notSuscribedMessage();
        } else if (response.data.status === 'waiting-qr') {
          setTimeout(getQr, 10000);
        } else {
          handleMessage(response.data.status);
        }
      },
      failureCallback: handleFailureRequest
    });
  };
  
  const handleModalCreate = () => {
    setCenterModalShow(true);
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
      />
    );
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
  };

  return (
    <>
      <Col md={6} className="mt-3">
        <Row>
          <Col md={12}>
            <Field name="user[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Nombre"
                  disabled={fromShow}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="user[lastName]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Apellido"
                  disabled={fromShow}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field name="user[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  disabled
                  label="Email"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <PhoneInputField name="user[whatsappPhoneNumber]" label="Número de Whatsapp" disabled={fromShow}/>
          </Col>
          {user.companyAttributes && user.companyAttributes.messengerService && user.whatsappPhoneNumber &&
            <Col>
              <Button
                style={{ fontWeight: '700', backgroundColor: '#25d366' }}
                className="connect-wsp-btn mt-4"
                onClick={() => handleModalCreate()}
              >
                Conectar Whatsapp
              </Button>
            </Col>
          }
        </Row>
      </Col>
      <Col md={fromShow ? { span: 2, offset: 1 } : 6} className={`mb-3 ${fromShow && 'img-align-center'}`}>
        {fromShow ? (
          <Card.Img src={user.avatarInfo?.fileUrl || defaultImg} />
        ) : (
          <Field name="user[avatar]">
            {({ field }) => (
              <UploadImage
                {...field}
                label="Foto de Perfil"
                name={user.avatarInfo?.filename}
                imageUrl={getIn(user.avatarInfo, 'fileUrl')}
                onChange={_avatar => setFieldValue(field.name, _avatar)}
                helpText="Formato sugerido 620x400px de máximo 10mb."
              />
            )}
          </Field>
        )}
      </Col>
      <SimpleCenteredModal
        title="Conectar Whatsapp"
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
        closeButton
      />
    </>
  );
};

export default UserForm;
