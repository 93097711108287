import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

const Search = ({ onChange }) => {
  return (
    <div className="search-kanban">
      <InputGroup className="mb-3 kanban-search">
        <FormControl
          style={{ marginBottom: '5px', height: '36px', padding: '5px' }}
          placeholder="Buscar proyecto"
          aria-label="search"
          aria-describedby="basic-addon1"
          onChange={onChange}
        />
        <InputGroup.Prepend style={{ marginBottom: '5px' }}>
          <InputGroup.Text id="basic-addon2">
            <i className="bi bi-search" />
          </InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
    </div>
  );
};

export default Search;
