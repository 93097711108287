import { useHistory, useLocation } from "react-router-dom";

const useSavedFilter = (table) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const getFilter = (key, defaultValue = '') => {
    if (!table) return defaultValue;
    return searchParams.get(key) || defaultValue;
  };

  const setFilter = (key, value) => {
    if (table) {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
      history.push({search: searchParams.toString()});
    }
  };

  return {getFilter, setFilter};
}
export default useSavedFilter;