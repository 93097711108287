import React from 'react';
import {withFormik, Field, Form, useFormikContext} from 'formik';
import {Button, Row, Col} from 'react-bootstrap';
import {hours, minutes} from './Constants';
import '../../services/yupCustomMethods';
import {FormikCheckBox, FormikSelect} from '../../components';

const ProjectWorkHourAdminForm = ({action, handleModalClose, onHide, projectWorkHour, submitVariant}) => {
  const {accountableUser, date, details, project} = projectWorkHour;
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';
  const {setFieldValue} = useFormikContext();
  const isEditable = () => {
    if (projectWorkHour.approved) return false;
    return true;
  };

  return (
    <Form>
      <Row>
        <Col md={4}>
          <p>
            <strong>Fecha</strong> {date}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Field name="projectWorkHour[approvedHours]">
            {({field}) => (
              <FormikSelect
                {...field}
                label="Horas"
                placeholder={field.value}
                options={hours}
                onChange={data => setFieldValue(field.name, data.value || 0)}
                isDisabled={!isEditable()}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="projectWorkHour[approvedMinutes]">
            {({field}) => (
              <FormikSelect
                {...field}
                label="Minutos"
                placeholder={field.value}
                options={minutes}
                onChange={data => setFieldValue(field.name, data.value || '')}
                isDisabled={!isEditable()}
              />
            )}
          </Field>
        </Col>

        <Col md={4}>
          <strong>Usuario</strong>
          <p>{accountableUser.fullName}</p>
        </Col>

        <Col md={4}>
          <strong>Código Proyecto</strong>
          <p>{project.code}</p>
        </Col>
      </Row>
      <Row className="margin-bottom-md">
        <Col md={8}>
          <strong>Detalles</strong>
          <p>{details}</p>
        </Col>
        <Col md={1}>
          <Field name="projectWorkHour[approved]">
            {({field}) => (
              <FormikCheckBox className="padding-left-sm" {...field} field={field} label="Aprobado" custom/>
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3">
        <Col md={2}>
          <Button className="btn mb-3" variant="primary" block onClick={handleModalClose}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {projectWorkHour} = props;
  return {
    projectWorkHour: {
      ...projectWorkHour,
      approvedHours: projectWorkHour.approvedHours !== '' ? projectWorkHour.approvedHours : projectWorkHour.hours,
      approvedMinutes: projectWorkHour.approvedMinutes !== '' ? projectWorkHour.approvedMinutes : projectWorkHour.minutes,
    }
  };
};

const handleSubmit = (values, {props}) => {
  const {formRequest} = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProjectWorkHourAdminForm);
