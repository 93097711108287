/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';

const FilterByPromise = ({ onFilterChange }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  const handleSwitchChange = e => {
    const checked = e.target.checked;
    setIsChecked(checked);
    onFilterChange({ promiseDateOverdue: checked });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="custom-control custom-switch ml-3 pt-2 switch-promise">
      <input
        type="checkbox"
        className="custom-control-input"
        id="customSwitch1"
        checked={isChecked}
        onChange={handleSwitchChange}
      />
      <label className="custom-control-label" htmlFor="customSwitch1">
        {isMobile ? 'Promesa atrasada' : 'Fecha estimada de entrega atrasada'}
      </label>
    </div>
  );
};

export default FilterByPromise;
