/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { Eye } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import { LinkBtn } from '../../components';
import { shortText } from '../../services/utils';

const columns = memoize(() => [
  { name: 'ID', selector: 'id', sortable: true, grow: '1' },
  { name: 'FECHA DE PAGO', selector: 'date', sortable: true, grow: '2' },
  {
    name: 'CÓDIGO PROYECTO',
    selector: 'project_code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FORMA DE PAGO',
    selector: 'payment_type',
    cell: item => item.translated_payment_type,
    sortable: true,
    grow: '2'
  },
  {
    name: 'MONTO',
    selector: 'amount',
    cell: item => item.parsed_amount,
    sortable: true,
    grow: '2'
  },
  {
    name: 'TOTAL ABONADO',
    selector: 'parsed_project_payments_total',
    sortable: false,
    grow: '2'
  },
  {
    name: 'TOTAL MONTO INSOLUTO',
    selector: 'parsed_project_remaining_unpaid',
    sortable: false,
    grow: '2'
  },
  {
    name: 'TOTAL FACTURADO',
    selector: 'parsed_project_invoices_total',
    sortable: false,
    grow: '2'
  },
  {
    name: 'OBSERVACIONES',
    selector: 'observations',
    cell: item => shortText(item.observations, 30) || 'Sin observaciones',
    sortable: false,
    grow: '3'
  },
  {
    name: '',
    selector: 'project_link',
    cell: item => (
      <>
        <LinkBtn
          className="btn-circle mx-auto"
          variant="outline-info"
          data-tip
          data-for="project-show"
          to={`/projects/${item.project_code}`}
        >
          <Eye size={16} />
        </LinkBtn>
        <ReactTooltip id="project-show" place="top">
          Ir a Proyecto
        </ReactTooltip>
      </>
    ),
    sortable: false,
    grow: '1'
  }
]);

export default columns;
