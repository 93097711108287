import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../components';
import ProviderInfo from './ProviderInfo';
import { showProviderRequest } from '../../requests/providers';
import GoBackButton from "../../components/GoBackButton";

class ProviderShow extends Component {
  state = {
    provider: {
      id: '',
      active: true,
      address: '',
      businessActivity: '',
      cellphone: '',
      contactsAttributes: [],
      email: '',
      nationalIdentification: '',
      legalName: '',
      name: '',
      notes: '',
      phone: '',
      website: ''
    }
  };

  componentDidMount() {
    const { match, dispatch } = this.props;

    const providerId = match.params.id;

    showProviderRequest(providerId, {
      dispatch,
      successCallback: this.handleSuccessShow
    });
  }

  handleSuccessShow = response => {
    const provider = camelCaseRecursive(response.data);
    this.setState({ provider });
  };

  render() {
    const { provider } = this.state;

    return (
      <>
        <Row className="mt-3 mb-5 d-flex align-items-center">
          <Col md={{ span: 1, offset: 1 }}>
            <h2>Proveedor</h2>
          </Col>
          <Col md={7} className="d-flex align-items-center">
            {provider.legalName}
          </Col>
          <Col md={1}>
            <LinkBtn variant="primary" block to={`/providers/${provider.id}/edit`}>
              Editar
            </LinkBtn>
          </Col>
          <Col md={1}>
            <GoBackButton />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={{ span: 7, offset: 1 }}>
            <ProviderInfo provider={provider} />
          </Col>
        </Row>
      </>
    );
  }
}

export default connect()(ProviderShow);
