import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import ReactTooltip from 'react-tooltip';

import ProjectShowForm from './Form/ProjectShowForm';
import { SimpleCenteredModal } from '../../components';
import { updateProjectRequest } from '../../requests/projects';
import { sendAlert } from '../../actions/utils';

const ProjectPromiseDateEdit = ({ children, project, setMoreData, moreData, ...props }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const { id } = project;
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Fecha estimada de entrega actualizada con éxito' }));
    setModalShow(false);
    setModalBody('');
    setMoreData(m => !m);
  };

  const handleUpdateRequest = values => {
    updateProjectRequest(id, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleEditPromiseDate = () => {
    setModalBody(
      <ProjectShowForm
        from="ProjectPromiseDateEdit"
        project={camelCaseRecursive(project)}
        action="edit"
        submitVariant="success"
        formRequest={handleUpdateRequest}
      />
    );
    setModalShow(true);
  };

  return (
    <>
      <Button
        data-tip
        data-for="stock-alert"
        onClick={handleEditPromiseDate}
        className="btn btn-link-no-hover-effects"
        {...props}
        variant="secondary"
      >
        {children}
      </Button>
      <ReactTooltip id="stock-alert" place="top">
        Editar Fecha Entrega
      </ReactTooltip>
      <SimpleCenteredModal
        closeButton
        size="sm"
        title="Editar Fecha de Entrega"
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ProjectPromiseDateEdit;
