import React from 'react';
import {useField} from "formik";
import { Form } from 'react-bootstrap';
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";

const PhoneInputField = ({ label, abbr, ...props }) => {
  const [field, meta] = useField(props);
  const { user } = useSelector(state => state.auth);
  const country = user.companyCountry || "cl";
  const { error, touched } = meta;

  const changeValue = value => {
    field.onChange({
      target: {
        name: props.name,
        value
      }
    });
  };

  return (
    <div className="mb-3">
      {label && (
        <Form.Label className="font-weight-bolder">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <PhoneInput
        {...field}
        {...props}
        country={country}
        enableSearch
        masks={{cl: '. .... ....', ar: '. .. ....-....', ec: '... ... ...', cr: '....-....', pe: '... ... ...'}}
        onChange={changeValue}
        value={field.value}
        isValid={!(error && touched)}
        inputProps={{
          class: `form-control ${error && touched ? 'is-invalid' : ''}`,
        }}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    </div>
  );
}

export default PhoneInputField;
