import ApiService from '../services/apiService';

export const getGoogleAuthRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/google_auth.json`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createGoogleAuthRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', `/google_auth.json`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });
