/* eslint-disable camelcase */
import React from 'react';
import {useSelector} from 'react-redux';
import memoize from 'memoize-one';

import {BasicActionBtns} from '../../components';

const columns = memoize((clickHandler, company) => {
  const {
    user: {roleIds = []}
  } = useSelector(state => state.auth);

  const isAdminUser = roleIds.includes(1);
  const isStocktake = type => type === 'Inventario';

  const array = [
    {
      name: 'FACTURA / BOLETA',
      grow: '2',
      selector: 'invoice_number',
      cell: item => (isStocktake(item?.translated_movement_type) ? 'No aplica' : item?.invoice_number),
      sortable: true
    },
    {
      name: 'FECHA DEL MOVIMIENTO',
      grow: '2',
      selector: 'created_at',
      sortable: true
    },
    {
      name: 'PRODUCTO',
      grow: '2',
      selector: 'product.code',
      cell: item => item.product.code,
      sortable: false
    },
    {
      name: 'REFERENCIA (PROYECTO)',
      grow: '2',
      selector: 'project_reference',
      sortable: false
    },
    {
      name: 'ESTADO OT',
      grow: '1',
      selector: 'project_ot_status',
      sortable: false,
      cell: ({translated_project_ot_status: projectOtState}) => projectOtState || 'No Aplica'
    },
    {
      name: 'VEHÍCULO',
      grow: '2',
      selector: 'vehicle_description',
      sortable: false,
      cell: ({vehicle_info: vehicleInfo}) => vehicleInfo?.description || 'No Aplica'
    },
    {
      name: 'CÓDIGO',
      grow: '1',
      selector: 'vehicle_plate',
      sortable: false,
      cell: ({vehicle_info: vehicleInfo}) => vehicleInfo?.plate || 'No Aplica'
    },
    {
      name: 'CANTIDAD',
      grow: '2',
      selector: 'quantity',
      sortable: false
    },
    {
      name: 'VALOR UNITARIO',
      grow: '2',
      selector: 'parsed_sale_price',
      sortable: false
    },
    {
      name: 'VALOR NETO',
      grow: '2',
      selector: 'parsed_total_amount',
      sortable: false
    },
    {
      name: 'PROVEEDOR',
      grow: '2',
      selector: 'provider_name',
      cell: item => (isStocktake(item?.translated_movement_type) ? 'No aplica' : item.provider_name),
      sortable: true
    },
    {name: 'TIPO', selector: 'translated_movement_type', sortable: false, grow: '1'},
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={clickHandler}
          withDestroy={company && isAdminUser}
          showClick
          withEdit={false}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      minWidth: '123px'
    }
  ];
  if (company) {
    array.splice(4, 0, {
      name: 'SUCURSAL',
      grow: '2',
      selector: 'branch_office_name',
      cell: item => item.branch_office?.label,
      sortable: true
    });
  }

  return array;
});

export default columns;
