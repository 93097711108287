import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

const InputBtn = ({ variant, title, ariaDescribedby, placeholder }) => (
  <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <Button variant={variant}>{title}</Button>
    </InputGroup.Prepend>
    <FormControl aria-describedby={ariaDescribedby} placeholder={placeholder} />
  </InputGroup>
);

InputBtn.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  ariaDescribedby: PropTypes.string,
  placeholder: PropTypes.string
};

InputBtn.defaultProps = {
  variant: 'info',
  ariaDescribedby: null,
  placeholder: null
};

export default InputBtn;
