import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { SimpleCenteredModal } from '../../components';
import { sendAlert } from '../../actions/utils';
import { createBranchOfficeRequest } from '../../requests/branchOffices';
import BranchOfficeDataTable from './BranchOfficeDataTable';
import BranchOfficeForm from './BranchOfficeForm';
import columns from './Columns';
import { Can } from '../../config/abilityContext';

const branchOffice = {
  address: '',
  addressNumber: '',
  communeId: '',
  name: '',
  regionId: ''
};

const BranchOfficeIndex = () => {
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setCenterModalShow(false);
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Sucursal creada con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = params => {
    createBranchOfficeRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  const handleModalCreate = () => {
    setCenterModalShow(true);
    setModalBody(
      <BranchOfficeForm
        branchOffice={branchOffice}
        action="new"
        submitVariant="success"
        formRequest={handleCreateRequest}
        handleModalClose={handleModalClose}
        dispatch={dispatch}
      />
    );
  };

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">Sucursales</h2>
            </Col>
            <Can I="create" a="BranchOffice">
              <Col md={2}>
                <Button variant="primary" block onClick={handleModalCreate}>
                  Crear
                </Button>
              </Col>
            </Can>
          </Row>

          <SimpleCenteredModal
            title="Crear Sucursal"
            body={modalBody}
            show={centerModalShow}
            onHide={handleModalClose}
            closeButton
          />
          <BranchOfficeDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />
        </Col>
      </Row>
    </>
  );
};

export default BranchOfficeIndex;
