import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch } from 'react-redux';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { debounceIndexFAQsRequest } from '../../../requests/faqs';

const AccordionFaqs = () => {
  const [faqs, setFaqs] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessFetchFaqs = response => {
    const responseFaqs = camelCaseEmptyStringRecursive(response.data);
    setFaqs(responseFaqs);
  };

  const fetchFaqs = () => {
    debounceIndexFAQsRequest({
      dispatch,
      successCallback: handleSuccessFetchFaqs
    });
  };

  useEffect(fetchFaqs, []);

  return (
    <Accordion defaultActiveKey="0" className="col-lg-11 col-md-12 p-0">
      {faqs.map((faq, index) => (
        <Card key={`faq-key-${index.toString()}`}>
          <Accordion.Toggle as={Card.Header} eventKey={`faq-list-${index.toString()}`}>
            {faq.question}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={`faq-list-${index.toString()}`}>
            <Card.Body style={{ whiteSpace: 'pre-line' }}>{faq.answer}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
};

export default AccordionFaqs;
