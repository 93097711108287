const basicVehicle = {
  id: '',
  client: {},
  createdAt: '',
  customBrand: '',
  customModel: '',
  engineCapacity: '',
  fuelType: '',
  lastMaintenance: '',
  motorNumber: '',
  notes: '',
  plate: '',
  technicalReviewExpiration: '',
  vehicleType: '',
  vehicleBrandId: '',
  vehicleModelId: '',
  vin: '',
  vtype: '',
  year: '',
  vehicleBrand: {},
  vehicleModel: {}
};

export default basicVehicle;
