import React from 'react';
import { withFormik, Form, getIn, Field } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';

import { FormikCheckBox, FormikInput, FormikSelect, NestedAttributes } from '../../components';
import ReviewGroupInput from './inputs/ReviewGroupInput';
import checklistTypeValues from './Constants';
import FormikError from '../../components/Utils/Input/FormikError';
import GoBackButton from "../../components/GoBackButton";

const ReviewGroupForm = props => {
  const { action, errors, onHide, setFieldValue, submitVariant, touched, values } = props;
  const { reviewGroupsAttributes } = values.checklist;
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar Checklist';

  const newData = {
    name: '',
    position: 0,
    reviewType: '',
    reviewsAttributes: [
      {
        imageRequired: false,
        name: '',
        position: 0,
        workRequired: false
      }
    ]
  };

  const addReviewGroupInputs = () => {
    const mapResults = reviewGroupsAttributes.map((reviewGroup, index) => {
      if (reviewGroup._destroy) {
        return undefined;
      }
      return (
        <ReviewGroupInput
          key={`review-group-input-${index.toString()}`}
          index={index}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          reviewGroup={reviewGroup}
        />
      );
    });

    return (
      <NestedAttributes
        removeFirstItem
        mapInputs={mapResults}
        arrayValues={reviewGroupsAttributes}
        setFieldValue={setFieldValue}
        valuePath="checklist[reviewGroupsAttributes]"
        newAttributes={newData}
        btnMessage="Agregar nueva sección"
      />
    );
  };

  return (
    <Form>
      <Row className="d-flex justify-content-end mb-5">
        <Col>
          <h1>Editar Checklist</h1>
        </Col>
        <Col md={3}>
          <Button block type="submit" variant={submitVariant} onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
        <Col md={3}>
          <GoBackButton />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <Field name={`checklist[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre de checklist"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4} xs={12}>
          <Field name={`checklist[checklistType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo"
                placeholder="Seleccionar..."
                options={checklistTypeValues}
                isDisabled={action === 'edit'}
                defaultValue={values.checklist.checklistType}
                onChange={data => {
                  setFieldValue(field.name, data?.value);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2} xs={12} className="d-flex align-items-center font-weight-bold text-big">
          <Field name={`checklist[signatureEnabled]`}>
            {({ field }) => <FormikCheckBox {...field} field={field} label="Habilitar firma" custom />}
          </Field>
        </Col>
      </Row>
      <FormikError name="checklist.reviewGroupsAttributes" />
      {addReviewGroupInputs()}
    </Form>
  );
};

const setInitialValues = props => {
  const { checklist } = props;
  return {
    checklist
  };
};

const validationSchema = Yup.object().shape({
  checklist: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre'),
    checklistType: Yup.string().required('Debes seleccionar un tipo de checklist'),
    reviewGroupsAttributes: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Debes ingresar un nombre'),
          reviewsAttributes: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required('Debes ingresar un nombre')
              })
            )
            .min(1, 'Debe haber al menos un punto de revisión')
        })
      )
      .min(1, 'Debe haber al menos una sección de revisión')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ReviewGroupForm);
