/* eslint-disable camelcase */
import memoize from 'memoize-one';

import { Can } from '../../config/abilityContext';
import { ButtonTooltip } from '../../components';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import React from 'react';

const columns = memoize(() => [
  { name: 'ID', selector: 'id', sortable: true },
  { name: 'Nombre', selector: 'name', sortable: true, grow: '1' },
  {
    name: 'Tipo',
    selector: 'type.name',
    sortable: true,
    grow: '1'
  },
  {
    cell: item => (
      <Can I="create" a="Checklist">
        <ButtonTooltip
          to={`/checklists/${item.id}/edit`}
          variant="outline-warning"
          className="btn-circle"
          size="sm"
          text="Editar"
          as={Link}
        >
          <Edit width="15px" height="15px" />
        </ButtonTooltip>
      </Can>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '50px'
  }
]);

export default columns;
