import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DefaultModal } from '../Utils/Modal';

const ModalConfirmationButton = ({ handleModalConfirm, modalTitle, modalBody, buttonText, variant, ...props }) => {
  const [modalShow, setModalShow] = useState(false);

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleModalOpen = () => {
    setModalShow(true);
  };

  const handleConfirm = () => {
    handleModalConfirm();
    handleModalClose();
  };

  return (
    <>
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <Button onClick={handleModalOpen} {...props} variant={variant}>
        {buttonText}
      </Button>
    </>
  );
};

ModalConfirmationButton.propTypes = {
  handleModalConfirm: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  variant: PropTypes.string
};

ModalConfirmationButton.defaultProps = {
  variant: 'primary'
};

export default ModalConfirmationButton;
