/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import ReactTooltip from 'react-tooltip';
import {Download, Eye} from 'react-feather';
import {LinkBtn} from '../../../components';
import {Button, Dropdown} from "react-bootstrap";
import moment from "moment";

const columns = memoize((handleDownloadDocument, handleCreditNote) => [
  {
    name: 'TIPO',
    selector: 'document_type_name',
    cell: item => (
      <div className={item.sum_factor === -1 ? "text-danger" : ''}>
        {item.document_type_name}
      </div>
    ),
    sortable: true,
    grow: '1'
  },
  { name: 'N° DE DOCUMENTO', selector: 'invoice_number', sortable: true, grow: '2' },
  {
    name: 'FECHA DE EMISIÓN',
    selector: 'invoice_date',
    cell: item => moment(item.invoice_date, 'DD/MM/YYYY HH:mm').format('DD/MM/YY'),
    sortable: true,
    grow: '2'
  },
  {
    name: 'VENCIMIENTO',
    selector: 'expiration_date',
    cell: item => moment(item.expiration_date, 'DD/MM/YYYY').format('DD/MM/YY'),
    sortable: true,
    grow: '2'
  },
  {
    name: 'OT',
    selector: 'project_code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'CLIENTE',
    selector: 'client_full_name',
    cell: item => (
      <>
        <div data-tip data-for={`full-name-${item.id}`}>
          {item.client_full_name}
        </div>
        <ReactTooltip id={`full-name-${item.id}`} place="top">
          {item.client_full_name}
        </ReactTooltip>
      </>
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'NETO',
    selector: 'parsed_net',
    cell: item => (
      <div className={item.sum_factor === -1 ? "text-danger" : ''}>
        {item.parsed_net}
      </div>
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'IVA',
    selector: 'parsed_tax',
    cell: item => (
      <div className={item.sum_factor === -1 ? "text-danger" : ''}>
        {item.parsed_tax}
      </div>
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'TOTAL',
    selector: 'parsed_total',
    cell: item => (
      <div className={item.sum_factor === -1 ? "text-danger" : ''}>
        {item.parsed_total}
      </div>
    ),
    sortable: false,
    grow: '2'
  },
  {
    name: 'ABONADO',
    selector: 'parsed_payment_amount',
    cell: item => (
      <div className={item.sum_factor === -1 ? "text-danger" : ''}>
        {item.parsed_payment_amount}
      </div>
    ),
    sortable: false,
    grow: '2'
  },
  {
    name: 'DEUDA TOTAL',
    selector: 'parsed_balance',
    cell: item => (
      <div className={item.sum_factor === -1 ? "text-danger" : ''}>
        {item.parsed_balance}
      </div>
    ),
    sortable: false,
    grow: '2'
  },
  {
    name: 'TIPOS DE ABONO',
    selector: 'payments_types',
    sortable: false,
    grow: '2'
  },
  {
    name: 'ESTADO',
    selector: 'translated_state',
    sortable: false,
    grow: '2'
  },
  {
    name: '',
    selector: 'project_link',
    cell: item => (
      <>
        <LinkBtn
          className="btn-circle mx-auto"
          variant="outline-info"
          data-tip
          data-for="project-show"
          to={`/projects/${item.project_code}`}
        >
          <Eye size={16}/>
        </LinkBtn>
        <ReactTooltip id="project-show" place="top">
          Ir a Proyecto
        </ReactTooltip>
        {!item.is_associate_invoice && item.state !== 'deleted' && (
          <Dropdown>
            <Dropdown.Toggle className={"btn-circle mx-auto"} variant="outline-success">
              <Download size={16}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleDownloadDocument(item.project_code, item.id, 'pdf')}>
                Descargar PDF
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDownloadDocument(item.project_code, item.id, 'xml')}>
                Descargar XML
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {item['can_generate_credit_note?'] && (
          <Button className={"btn-circle mx-auto"} variant="outline-danger"
                  onClick={() => handleCreditNote(item)}>
            <div style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              NC
            </div>
          </Button>
        )}
      </>
    ),
    sortable: false,
    grow: '3'
  }
]);

export default columns;
