import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';
import './styles.scss';

const FormikDatePicker = ({
  abbr,
  selected,
  disabled,
  error,
  helpText,
  label,
  margin,
  onChange,
  placeholder,
  touched,
  ...props
}) => (
  <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
    {label && (
      <Form.Label>
        {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
      </Form.Label>
    )}
    <InputGroup>
      <DatePicker
        disabled={disabled}
        className="form-control"
        dateFormat="dd/MM/yyyy"
        autoComplete="off"
        selected={selected}
        onChange={selectedDate => onChange(selectedDate)}
        placeholderText={placeholder}
        locale={es}
        {...props}
      />
    </InputGroup>
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);
export default FormikDatePicker;
