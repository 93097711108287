import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '../../components';
import ProductDataTable from './ProductDataTable';
import columns from './Columns';

const ProductIndex = () => (
  <>
    <Row>
      <Col md={{ span: 10, offset: 1 }} xs={12}>
        <Row className="mt-4">
          <Col md={10}>
            <h2 className="text-uppercase mb-4">Ítems</h2>
          </Col>
          <Col md={2}>
            <LinkBtn variant="primary" block to="/products/new">
              Crear
            </LinkBtn>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ProductDataTable columns={columns} />
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export default ProductIndex;
