/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    grow: '1'
  },
  {
    name: 'RUT',
    selector: 'national_identification',
    sortable: true,
    grow: '2'
  },
  {
    name: 'RAZON SOCIAL',
    selector: 'legal_name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'EMAIL',
    selector: 'email',
    sortable: true,
    grow: '1'
  },
  {
    name: 'CELULAR',
    selector: 'cellphone',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACTIVO',
    grow: '1',
    sortable: true,
    selector: 'active',
    cell: item => (item.active ? 'Si' : 'No')
  },
  {
    cell: item => <BasicActionBtns item={item} clickHandler={clickHandler} showClick editClick />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
