import CountryFactory from "./CountryFactory";
import { insertString } from "../services/utils";

export default class ClCountryFactory extends CountryFactory {
  get formatPhone() {
    return "+### ########";
  }

  handleNationalIdentificationFormat(e, setFieldValue, isForeign) {
    if (isForeign) {
      setFieldValue(e.target.name, e.target.value);
    } else if (this.validRutInput(e)) {
        const formattedValue = this.rutFormat(e.target.value);
        setFieldValue(e.target.name, formattedValue);
    }
  }

  validRutInput(e) {
    const re = /^[0-9kK\b]+$/;
    const rawRut = e.target.value
      .replace(/\s/g, '')
      .replace(/\./g, '')
      .replace(/-/g, '');
    return e.target.value === '' || re.test(rawRut);
  }

  rutFormat(value) {
    let rut = value
      .replace(/\s/g, '')
      .replace(/\./g, '')
      .replace(/-/g, '');
    if (rut.length > 1) {
      rut = insertString(rut, -1, '-');
    }
    if (rut.length > 5) {
      rut = insertString(rut, -5, '.');
    }
    if (rut.length > 9) {
      rut = insertString(rut, -9, '.');
    }
    return rut;
  }

  isValidRut(rut) {
    return (/^\d{1,2}\.?\d{3}\.?\d{3}[-]?[0-9kK]{1} *$/i.test(rut) || /^\d{1,2}\.\d{3}\.\d{3}[-]?[0-9kK]{1} *$/i.test(rut)) &&
      this.rutValidation(rut);
  }

  rutValidation(rutInput) {
    // FORMAT
    const rut = rutInput
      .replace(/\s/g, '')
      .replace(/\./g, '')
      .replace(/-/g, '');
    const body = rut.slice(0, -1);
    let dv = rut.slice(-1).toUpperCase();

    // Special case
    if (body === '70966800' && dv === 'K') {
      return true;
    }

    // Compute
    let sum = 0;
    let multiple = 2;
    // For every digit
    for (let i = 1; i <= body.length; i += 1) {
      // Get product
      const index = multiple * rut.charAt(body.length - i);
      // add to count
      sum += index;
      // In range [2,7]
      if (multiple < 7) {
        multiple += 1;
      } else {
        multiple = 2;
      }
    }
    // Division Remainder
    const dvComputed = 11 - (sum % 11);
    // (0 & K)
    dv = dv === 'K' ? 10 : dv;
    dv = dv === '0' ? 11 : dv;
    // Is valid?
    if (`${dvComputed}` !== `${dv}`) {
      return false;
    }
    return true;
  }

  getLabelForPersonalIdentification() {
    return "RUT";
  }

  getLabelForCompanyIdentification() {
    return "RUT";
  }

  getLabelForRegion() {
    return "Región";
  }

  getLabelForCommune() {
    return "Comuna";
  }

  getFormatNationalIdentification() {
    return "##.###.###-#";
  }

  getFormatNationalIdentificationCompany() {
    return "##.###.###-#";
  }
}