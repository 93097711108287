import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ComponentDataTable from '../../components/Utils/DataTable';
import { SimpleCenteredModal } from '../../components';
import { debounceCompanyIndexProductMovementsRequest } from '../../requests/productMovements';
import { sendAlert } from '../../actions/utils';

const ProductMovementCompanyDataTable = ({ columns, moreData, setMoreData, setQueries }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [amount, setAmount] = useState(0);
  const [productMovements, setProductMovements] = useState([]);
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalTitle('');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    setProductMovements(response.data.data);
    setAmount(response.data.metadata.amount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);

    debounceCompanyIndexProductMovementsRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/movements/${item.id}`);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'branch_office_name':
        return { sort_branch_office_name: name };
      case 'provider_name':
        return { sort_provider_name: name };
      case 'translated_movement_type':
        return { sort_column: 'movement_type' };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, true)}
        handleSortCase={sortColumnCase}
        data={productMovements}
        withDate
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries(response);
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
    </>
  );
};

export default ProductMovementCompanyDataTable;
