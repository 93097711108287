import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { DefaultModal, SimpleCenteredModal } from '../../components';
import {
  debounceIndexBranchOfficesRequest,
  deleteBranchOfficeRequest,
  updateBranchOfficeRequest
} from '../../requests/branchOffices';
import ComponentDataTable from '../../components/Utils/DataTable';
import BranchOfficeForm from './BranchOfficeForm';

const BranchOfficeDataTable = props => {
  const [amount, setAmount] = useState(0);
  const [branchOffices, setBranchOffices] = useState([]);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const { columns, moreData, setMoreData } = props;

  const handleSuccessRequest = response => {
    const {
      data: responseBranchOffice,
      metadata: { amount: responseAmount }
    } = response.data;
    setBranchOffices(responseBranchOffice);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        ...params,
        sort_column: params.sort_column || 'id'
      },
      successCallback: handleSuccessRequest
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setCenterModalShow(false);
    setModalTitle('');
    setModalBody('');
    setModalItem(null);
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = params => {
    const {
      branchOffice: { id: branchOfficeId }
    } = params;
    updateBranchOfficeRequest(branchOfficeId, {
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: () => handleSuccessAction('Sucursal actualizada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const handleDeleteRequest = () => {
    deleteBranchOfficeRequest(modalItem.id, {
      dispatch,
      successCallback: () => handleSuccessAction('Sucursal eliminada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const setModal = (title, body, item = null) => {
    setModalTitle(title);
    setModalBody(body);
    setModalItem(item);
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'edit':
        setCenterModalShow(true);
        setModal(
          'Editar Sucursal',
          <BranchOfficeForm
            branchOffice={camelCaseRecursive(item)}
            action="edit"
            submitVariant="success"
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            dispatch={dispatch}
          />
        );
        break;
      case 'destroy':
        setModalShow(true);
        setModal('Eliminar Sucursal', '¿Estás seguro que deseas eliminar esta sucursal?', item);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        data={branchOffices}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
        closeButton
      />
      {modalShow && (
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          handleClose={handleModalClose}
          handleConfirm={handleDeleteRequest}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
    </>
  );
};

export default BranchOfficeDataTable;
