import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexVehiclesRequest = ({ dispatch, companyCode, clientId, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/public/companies/${companyCode}/clients/${clientId}/vehicles.json`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debouncePublicIndexVehiclesRequest = AwesomeDebouncePromise(indexVehiclesRequest, 300);
