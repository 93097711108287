/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { Link } from 'react-router-dom';

const columns = memoize(() => [
  {
    name: 'FECHA DEL CREACIÓN',
    grow: '1',
    selector: 'log_date',
    sortable: false
  },
  {
    name: 'TIPO',
    selector: 'log_type_name',
    sortable: false,
    grow: '3'
  },
  {
    name: 'TRANSFERIDO DE',
    selector: 'transfered_from',
    cell: item => <Link to={`/clients/${item.client_id}`}>{item.transfered_from}</Link>,
    sortable: false,
    grow: '2'
  },
  {
    name: 'TRANSFERIDO A',
    selector: 'transfered_to',
    cell: item => <Link to={`/clients/${item.client_id}`}>{item.transfered_to}</Link>,
    sortable: false,
    grow: '2'
  },
  {
    name: 'REALIZADO POR',
    selector: 'responsable_name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'CONTACTO DEL RESPONSABLE',
    selector: 'responsable_email',
    sortable: true,
    grow: '2'
  }
]);

export default columns;
