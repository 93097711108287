import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import ComponentDataTable from '../../components/Utils/DataTable';
import { SimpleCenteredModal, FormikSelect } from '../../components';
import { updateProductMovementRequest } from '../../requests/productMovements';
import { sendAlert } from '../../actions/utils';
import ProductMovementForm from './ProductMovementForm';
import movementTypes from './movementTypes';
import { debounceIndexMovementDetailsRequest } from '../../requests/movementDetails';

const ProductMovementDataTable = ({ columns, moreData, setMoreData, setQueries }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [amount, setAmount] = useState(0);
  const [movementType, setMovementType] = useState('');

  const [productMovements, setProductMovements] = useState([]);
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalTitle('');
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Movimiento actualizado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleUpdateRequest = values => {
    const {
      productMovement: { id }
    } = values;
    updateProductMovementRequest(id, {
      dispatch,
      params: snakecaseKeys(values),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRequest = response => {
    setProductMovements(response.data.data);
    setAmount(response.data.metadata.amount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const { sort_column: sortColumn } = params;
    const sendParams = { ...params, sort_column: sortColumn || 'created_at', movement_type: movementType };
    debounceIndexMovementDetailsRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/movements/${item.product_movement_id}`);
        break;
      case 'edit':
        setCenteredModalShow(true);
        setModalBody(
          <ProductMovementForm
            action={action}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            submitVariant="success"
            productMovement={camelCaseEmptyStringRecursive(item)}
          />
        );
        setModalTitle('Editar Movimiento');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'provider_name':
        return { sort_provider_name: name };
      case 'translated_movement_type':
        return { sort_column: 'movement_type' };
      case 'parsed_total_amount':
        return { sort_column: 'total_amount' };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, false)}
        handleSortCase={sortColumnCase}
        data={productMovements}
        withDate
        totalRows={parseInt(amount, 10)}
        moreData={moreData}
        resourceRequest={response => {
          setQueries({
            ...response,
            movement_type: movementType
          });
          if (!onRequest) handleIndexRequest(response);
        }}
        customSearchLong={
          <FormikSelect
            isClearable
            options={movementTypes}
            placeholder="Tipo de Movimiento"
            onChange={data => {
              setMovementType(data ? data.value : '');
              setMoreData(!moreData);
            }}
          />
        }
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
    </>
  );
};

export default ProductMovementDataTable;
