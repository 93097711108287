import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCommunesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/public/communes', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexCommunesRequest = AwesomeDebouncePromise(indexCommunesRequest, 300);
