import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { SimpleCenteredModal } from '../../components';

const ProjectWorkHoursAlertExceededModal = ({ handleClose, modalShow }) => {
  return (
    <SimpleCenteredModal
      centered
      title="Horas de trabajo Excedidas"
      dialogClassName="w-100"
      show={modalShow}
      size="sz"
      handleClose={handleClose}
      body={
        <Col>
          <Row className="justify-content-around">
            <Col md={10} className="mb-3">
              <p className="text-center">
                <span> Se han excedido las horas máximas de la empresa</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Button className="btn mb-3" variant="secondary" block onClick={handleClose}>
              Entendido
            </Button>
          </Row>
        </Col>
      }
    />
  );
};

export default ProjectWorkHoursAlertExceededModal;
