import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';

const InputRadio = ({ size, ariaLabelInp, ariaLabelForm }) => (
  <InputGroup size={size}>
    <InputGroup.Prepend>
      <InputGroup.Radio aria-label={ariaLabelInp} />
    </InputGroup.Prepend>
    <FormControl aria-label={ariaLabelForm} />
  </InputGroup>
);

InputRadio.propTypes = {
  size: PropTypes.string,
  ariaLabelInp: PropTypes.string,
  ariaLabelForm: PropTypes.string
};

InputRadio.defaultProps = {
  size: 'sm',
  ariaLabelInp: null,
  ariaLabelForm: null
};

export default InputRadio;
