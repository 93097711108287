/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import EditOwnerButton from '../Vehicle/EditOwnerButton';
import { BasicActionBtns } from '../../components';

const columns = memoize((clickHandler, setMoreData) => [
  { name: 'MARCA', selector: 'vehicle_brand_name', sortable: true, grow: '1' },
  { name: 'MODELO', selector: 'vehicle_model_name', sortable: true, grow: '1' },
  { name: 'PATENTE/CÓDIGO', selector: 'plate', sortable: true, grow: '1' },
  {
    cell: item => (
      <>
        <BasicActionBtns
          item={item}
          withShow
          clickHandler={clickHandler}
          withEdit={false}
          withDestroy={false}
          path={`clients/${item.client_id}/vehicles`}
        />
        <EditOwnerButton setMoreData={setMoreData} item={item} />
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '2',
    minWidth: '240px'
  }
]);

export default columns;
