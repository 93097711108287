import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ComponentDataTable from '../../components/Utils/DataTable';
import { debounceIndexChecklistRequest } from '../../requests/checklists';
import { sendAlert } from '../../actions/utils';

const ChecklistDataTable = props => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [checklists, setChecklists] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const { columns } = props;

  const handleSuccessRequest = response => {
    const responseChecklists = response.data;
    setChecklists(responseChecklists);
    setAmount(responseChecklists.length);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const paramsData = { ...params };
    debounceIndexChecklistRequest({
      dispatch,
      params: paramsData,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={checklists}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (!onRequest) handleIndexRequest(response);
        }}
      />
    </>
  );
};

export default ChecklistDataTable;
