import React, {useState, useEffect} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Field, getIn} from 'formik';
import moment from 'moment';
import {FormikDatePicker} from '../../../components';

const CalendarEventTab = props => {
  const {calendarEvent, errors, setFieldValue, touched, disabledDate = true} = props;
  const {startDate, dateSelected} = calendarEvent;
  const baseDate = moment(startDate).format('YYYY-MM-DD');

  return (
    <Row>
      <Col md={12}>
        <Field name="calendarEvent[dateSelected]">
          {({field}) => (
            <FormikDatePicker
              {...field}
              abbr
              label="Fecha"
              disabled={disabledDate}
              dateFormat="dd/MM/yyyy"
              selected={dateSelected}
              onChange={date => {
                setFieldValue(field.name, moment(date).format('DD/MM/YYYY'));
              }}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <Field name="calendarEvent[startDate]">
          {({field}) => (
            <FormikDatePicker
              abbr
              dateFormat="H:mm"
              showTimeSelect
              showTimeSelectOnly
              label="Hora Cita"
              timeIntervals={30}
              selected={startDate}
              disabled
              onChange={date => {
                setFieldValue(field.name, `${baseDate}T${moment(date).format('HH:mm')}`);
                setFieldValue(
                  'calendarEvent[endDate]',
                  `${baseDate}T${moment(date)
                    .add(100, 'minutes')
                    .format('HH:mm')}`
                );
              }}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

export default CalendarEventTab;
