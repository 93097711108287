import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import styles from './styles.module.scss';

import { FormikSelect } from '../../../components';

const stateTypes = [
  { label: 'Todos', value: '' },
  { label: 'Pendientes', value: ['pending', 'created'] },
  { label: 'Recepcionados', value: 'received' },
  { label: 'Revisados', value: 'reviewed' }
];

const indexTypes = [
  { label: 'Fecha de Ingreso', value: '' },
  { label: 'Fecha estimada de entrega', value: 'promise_date' }
];

const CalendarFilters = ({ setFilterState, setFilterIndex, onOpenCreateModal, onOpenCreateModalDeskSell }) => {
  return (
    <Row>
      <Col md={{ span: 2, offset: 1 }} className="calendar-filters">
        <FormikSelect
          placeholder="Filtrar por estado..."
          className="ml-2"
          options={stateTypes}
          onChange={newState => setFilterState(newState.value)}
        />
      </Col>
      <Col md={{ span: 2, offset: 3 }} className="calendar-filters">
        <FormikSelect 
          placeholder="Filtrar por fecha..."
          className="ml-2"
          options={indexTypes} 
          onChange={e => setFilterIndex(e.value)} 
        />
      </Col>
      <Col md={{ span: 2, offset: 7 }} className="calendar-filters">
        <Button
          variant="success"
          className={`${styles.createButton} p-2`}
          onClick={() => onOpenCreateModal && onOpenCreateModal()}
        >
          Crear Cita
        </Button>
      </Col>
      <Col md={{ span: 2, offset: 9 }} className="calendar-filters">
        <Button
          variant="secondary"
          className={`${styles.createButton} p-2`}
          onClick={() => onOpenCreateModalDeskSell && onOpenCreateModalDeskSell()}
        >
          Venta Mesón
        </Button>
      </Col>
    </Row>
  );
};

export default CalendarFilters;
