import ApiService from '../services/apiService';

export const publicShowNpsFormsRequest = (token, { dispatch, format, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/public/nps_forms/${token}${formatt}`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const indexNpsFormsRequest = ({ dispatch, format, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/nps_forms${formatt}`, {
    dispatch,
    failureCallback,
    successCallback
  });
};
