import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

function Steps() {
  return (
    <>
      <Row id="steps" className="m-auto p-3">
        <Col md={4} className="p-0 card-margin">
          <img
            src="/assets/images/icon-contact.webp"
            alt="Icono contacto"
            title="Icono contacto"
            width="100%"
            height="auto"
          />
          <Card style={{ width: '95%', margin: 'auto' }}>
            <Card.Body>
              <h2 className="text-pricing mb-0 pb-2">Contáctanos</h2>
              <p>Escríbenos por WSP o a través del formulario de contacto.</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="p-0 card-margin">
          <img
            src="/assets/images/icon-phone.webp"
            alt="Icono teléfono"
            title="Icono teléfono"
            width="100%"
            height="auto"
          />
          <Card style={{ width: '95%', margin: 'auto' }}>
            <Card.Body>
              <h2 className="text-pricing mb-0 pb-2">Atenderemos tus dudas</h2>
              <p>
                Te llamaremos o escribiremos según tu preferencia para conversar sobre el plan que te interesa y
                resolver todas tus inquietudes.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="p-0 card-margin">
          <img
            src="/assets/images/icon-user.webp"
            alt="Icono Agregar Usuario"
            title="Icono aAgregar Usuario"
            width="100%"
            height="auto"
          />
          <Card style={{ width: '95%', margin: 'auto' }}>
            <Card.Body>
              <h2 className="text-pricing mb-0 pb-2">Creamos tu cuenta</h2>
              <p>Te crearemos un usuario y podrás comenzar tu prueba gratis de 15 días.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Steps;
