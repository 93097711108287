import React, {useState} from 'react';
import {withFormik, Form, Field, getIn} from 'formik';
import {Button, Col, Row} from "react-bootstrap";
import {BasicTextArea, FormikDatePicker, FormikInput} from "../../../components";
import * as Yup from "yup";

const CreditNoteToDelete = props => {
    const { errors, onHide, touched, handleModalClose, setFieldValue } = props;
    const [date, setDate] = useState(new Date());
    return (
        <Form>
            <Row>
                <Col md={12}>
                    <Field name="id">
                        {({field}) => (
                            <FormikInput
                                {...field}
                                inputType="hidden"
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                            />
                        )}
                    </Field>
                    <Field name="projectCode">
                        {({field}) => (
                          <FormikInput
                            {...field}
                            inputType="hidden"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                    </Field>
                    <Field name="issueDate">
                        {({field}) => (
                          <FormikDatePicker
                            {...field}
                            abbr
                            label="Fecha de Emisión"
                            dateFormat="dd/MM/yyyy"
                            selected={date}
                            onChange={newDate => {
                                setDate(newDate);
                                setFieldValue(field.name, newDate);
                            }}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                    </Field>
                    <Field name="motive">
                        {({field}) => (
                            <BasicTextArea
                                {...field}
                                abbr
                                label="Motivo de la anulación"
                                placeholder="Motivo de la anulación"
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                            />
                        )}
                    </Field>
                </Col>
            </Row>
            <Row className="d-flex justify-content-end mb-2">
                <Col md={{span: 3, offset: 6}}>
                    <Button className="btn" variant="primary" block onClick={handleModalClose}>
                        Cancelar
                    </Button>
                </Col>
                <Col md={3}>
                    <Button type="submit" variant="success" className={"float-right mb-2"} block onClick={onHide}>
                        Confirmar
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

const setInitialValues = props => {
    const { projectInvoiceToDelete } = props;
    return projectInvoiceToDelete;
};

const validationSchema = Yup.object().shape({
    motive: Yup.string().required('Debes ingresar un motivo')
});

const handleSubmit = (values, {props}) => {
    const {formRequest} = props;
    formRequest(values);
};

export default withFormik({
    mapPropsToValues: props => setInitialValues(props),
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
    validateOnMount: false
})(CreditNoteToDelete);