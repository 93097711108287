import React from 'react';

import { Glossary } from '../../../components';

const ColorGlossary = () => (
  <>
    <Glossary
      color="secondary"
      title="Pendiente"
      description="Indica que esta OT tiene acciones pendientes por realizar en alguno de sus procesos."
    />
    <Glossary
      color="danger"
      title="Atrasado"
      description="Indica que esta OT no se ha finalizado antes de su fecha estimada, o que el presupuesto enviado al cliente fue rechazado."
    />
    <Glossary
      color="completed"
      title="Completado"
      description="Indica que esta OT tiene todos sus trabajos realizados correctamente."
    />
    <Glossary
      color="success"
      title="OT recibida y revisada"
      description="Indica que esta OT tiene sus checklists de recepción y revisión completos."
    />
    <Glossary
      color="info"
      title="Garantía"
      description="Indica que esta OT ha vuelto a activarse en el taller por concepto de garantía."
    />
    <Glossary
      color="primary"
      title="Presupuesto Parcial"
      description="Indica que esta OT tiene su presupuesto finalizado parcialmente."
    />
  </>
);

export default ColorGlossary;
