import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import CardSuccess from './CardSuccess';

const StoriesSection = () => {
  return (
    <Fade up>
      <div id="success-stories" className="max-width-2000">
        <Row className="mb-5">
          <Col md={7} className="text-center m-auto blue-bg">
            <h3 className="text-center">
              Ellos han aumentado sus ventas y disminuido sus garantías desde que utilizan Gestioncar
            </h3>
            <h4 className="col-md-6 col-sm-10"> Retención de un 100% </h4>
          </Col>
        </Row>

        <Row className="card-success">
          <CardSuccess />
        </Row>
      </div>
    </Fade>
  );
};

export default StoriesSection;
