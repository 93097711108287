import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import ProductCategoryForm from './ProductCategoryForm';
import ProductCategoryShow from './ProductCategoryShow';
import {
  indexProductCategoriesRequest,
  deleteProductCategoryRequest,
  updateProductCategoryRequest
} from '../../requests/productCategories';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../../components';

import { sendAlert } from '../../actions/utils';

class ProductCategoryDataTable extends Component {
  state = {
    onRequest: false,
    productCategories: [],
    amount: 0,
    modalBody: '',
    modalEdit: false,
    modalDestroy: false,
    modalItem: null,
    modalShow: false,
    productCategory: {}
  };

  handleRequest = params => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    indexProductCategoriesRequest({
      dispatch,
      params: {
        ...params,
        filter_parents: true,
        sort_column: params.sort_column || 'id'
      },
      successCallback: this.handleSuccessIndex,
      failureCallback: this.handleFailureRequest
    });
  };

  handleSuccessIndex = response => {
    const productCategories = response.data.data;
    const { amount } = response.data.metadata;
    this.setState({
      productCategories,
      amount,
      onRequest: false
    });
  };

  removeProductCategory = providerId => {
    const { dispatch } = this.props;
    deleteProductCategoryRequest(providerId, {
      dispatch,
      successCallback: this.handleSuccessRemove,
      failureCallback: this.handleFailureRequest
    });
  };

  showEditModal = category => {
    this.setState({
      productCategory: camelCaseRecursive(category),
      modalEdit: true
    });
  };

  showModal = category => {
    this.setState({
      productCategory: camelCaseRecursive(category),
      modalShow: true
    });
  };

  handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        this.showModal(item);
        break;
      case 'edit':
        this.showEditModal(item);
        break;
      case 'destroy':
        this.setState({
          modalDestroy: true,
          modalItem: item,
          modalBody: `¿Estás seguro que deseas eliminar esta categoría?`
        });
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  handleModalDestroyClose = () => {
    this.setState({
      modalDestroy: false
    });
  };

  handleModalConfirm = () => {
    const { modalItem } = this.state;
    this.removeProductCategory(modalItem.id);
  };

  handleSuccessRemove = () => {
    const { dispatch, setMoreData, moreData } = this.props;

    dispatch(sendAlert({ kind: 'success', message: 'Categoría eliminada correctamente' }));
    this.handleModalDestroyClose();
    setMoreData(!moreData);
  };

  sortColumnCase = name => {
    switch (name) {
      case 'caption':
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  handleModalEditClose = () => {
    this.setState({ modalEdit: false });
  };

  handleModalShowClose = () => {
    this.setState({ modalShow: false });
  };

  handleSuccessAction = message => {
    const { dispatch, moreData, setMoreData } = this.props;

    dispatch(sendAlert({ kind: 'success', message }));
    this.handleModalEditClose();
    setMoreData(!moreData);
  };

  handleFailureRequest = error => {
    const { dispatch } = this.props;
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  handleUpdateRequest = productCategories => {
    const { dispatch } = this.props;
    const productCategory = productCategories.productCategories;

    updateProductCategoryRequest(productCategory.id, {
      dispatch,
      params: snakeCaseKeys(productCategories, { exclude: ['_destroy'] }),
      successCallback: () => this.handleSuccessAction('Categoría actualizada con éxito'),
      failureCallback: this.handleFailureRequest
    });
  };

  renderModalBodyShow = () => {
    const { productCategory } = this.state;
    return <ProductCategoryShow productCategory={productCategory} handleModalClose={this.handleModalShowClose} />;
  };

  renderModalBodyEdit = () => {
    const { dispatch } = this.props;
    const { productCategory } = this.state;

    return (
      <ProductCategoryForm
        productCategories={productCategory}
        action="edit"
        submitVariant="success"
        handleModalClose={this.handleModalEditClose}
        formRequest={this.handleUpdateRequest}
        dispatch={dispatch}
      />
    );
  };

  render() {
    const { productCategories, amount, onRequest, modalShow, modalDestroy, modalEdit, modalBody } = this.state;
    const { columns, moreData } = this.props;
    return (
      <>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(this.handleButtonClick)}
          handleSortCase={this.sortColumnCase}
          data={productCategories}
          totalRows={amount}
          moreData={moreData}
          resourceRequest={this.handleRequest}
        />
        <DefaultModal
          title="Eliminar Categoría"
          body={modalBody}
          show={modalDestroy}
          handleClose={this.handleModalDestroyClose}
          handleConfirm={this.handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />

        <SimpleCenteredModal
          title="Categoría"
          body={this.renderModalBodyShow}
          show={modalShow}
          onHide={this.handleModalShowClose}
          centered={false}
          closeButton
        />

        <SimpleCenteredModal
          title="Editar Categoría"
          body={this.renderModalBodyEdit}
          show={modalEdit}
          onHide={this.handleModalEditClose}
          centered={false}
          closeButton
        />
      </>
    );
  }
}

export default withRouter(connect()(ProductCategoryDataTable));
