import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Form, getIn, Field } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { parse } from 'date-fns';
import * as Yup from 'yup';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import { useMediaQuery } from 'react-responsive';
import 'moment/locale/es';

import { FormikInput } from '../../../components';
import { indexVehiclesRequest } from '../../../requests/vehicles';
import ClientTab from '../../../components/Client/ClientTab';
import VehicleTab from '../../../components/Vehicle/VehicleTab';
import ProjectTab from './ProjectTab';
import { yupProjectReview } from '../../../../lib/project-review-card/ProjectReviewCard';
import basicCompany from '../../Company/basicCompany';
import { showCompanyRequest } from '../../../requests/companies';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import basicVehicle from '../../Vehicle/basicVehicle';
import GoBackButton from "../../../components/GoBackButton";


const ProjectReceptionForm = ({
  action,
  fromProjectProgressBar,
  onHide,
  setFieldValue,
  submitVariant,
  values,
  isModal= false,
  errors,
  touched,
  version= false,
  isSubmitting,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const formRef = useRef(null);

  const { calendarEvent, fromCalendar, fromReviewType, fromTemplate, project, workOrderModal } = props;

  const currentValues = getIn(values, 'project');
  const projectReviewsAttributes = values.project?.projectReviewsAttributes;
  const hasCheckAll = projectReviewsAttributes?.every(project => project?.customState);

  const finishOrSave = hasCheckAll ? 'Finalizar' : 'Guardar';

  const btnReviewType =
    fromReviewType === 'reception' ? 'Recepción' : fromReviewType === 'pre_inspection' ? 'Preinspección' : 'Checklist';

  const btnMessage = action === 'new' ? 'Continuar' : `${finishOrSave} ${btnReviewType}`;

  const { clientId, vehicleId } = currentValues;

  const [clientDisabled, setClientDisabled] = useState(false);
  const [clientSelected, setClientSelected] = useState('');
  const [currentClient, setCurrentClient] = useState({});
  const [currentCompany, setCompany] = useState(basicCompany);
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpenReviewCard, setIsOpenReviewCard] = useState(true);
  const [newClient, setNewClient] = useState(false);
  const [newVehicle, setNewVehicle] = useState(false);
  const [vehicleDisabled, setVehicleDisabled] = useState(true);
  const [vehicleSelected, setVehicleSelected] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [projectChecklist, setProjectChecklist] = useState({});
  const [projectChecklistBaseName, setProjectChecklistBaseName] = useState('');

  const signatureRef = useRef({});

  const saveSignature = () => {
    const signature = signatureRef.current.getTrimmedCanvas().toDataURL('image/jpg');
    setFieldValue(`${projectChecklistBaseName}[signature]`, signature);
  };

  const clearSignature = () => {
    setFieldValue(`${projectChecklistBaseName}[signature]`, '');
    signatureRef.current.clear();
  };

  const resultFetchData = useCallback(
    response => {
      const result = response.data.data;
      const tempArray = result.map(element => {
        return { ...element, label: element.plate, value: element.id };
      });
      setVehicles(camelCaseRecursive(tempArray));
      return tempArray;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicles]
  );

  const fetchInitialVehicles = useCallback(
    params => {
      indexVehiclesRequest(params.clientId, {
        dispatch: stableDispatch,
        params: {
          ...params,
          sort_column: 'plate',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: resultFetchData
      });
    },
    [stableDispatch, resultFetchData]
  );

  const fetchCurrentCompany = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(() => {
    if (!version) fetchCurrentCompany();
  }, []);

  const handleClientChange = value => {
    setFieldValue('project[clientId]', value);
    setFieldValue('project[vehicleId]', '');
    if (value !== '') fetchInitialVehicles({ clientId: value });
  };

  const resetVehicle = () => {
    setCurrentVehicle({});
    setVehicleSelected('');
    if (currentValues) setFieldValue('project[vehicleId]', '');
  };

  const handleStatusChecklists = () => {
    const checklist = project?.checklistsAttributes?.find(checklistAttribute => checklistAttribute.checklistType === fromReviewType);
    setIsOpenReviewCard(project.state !== 'finalized' && !checklist?.completedAt && !version);
    setIsDisabled((!!checklist?.completedAt && fromReviewType !== 'mechanic') || version);
  };

  const handleCurrentVehicle = () => {
    if (!fromReviewType && vehicleId === project.vehicleId) {
      setCurrentVehicle(currentValues.vehicle);
    }
    if (vehicleId === undefined && currentVehicle.id) {
      setFieldValue('project[vehicleId]', currentVehicle.id);
    }
  };

  const findVehicles = () => {
    if (!fromReviewType && clientId) fetchInitialVehicles({ clientId });
  };

  const handleSetProjectChecklist = () => {
    const index = project?.checklistsAttributes?.findIndex(checklist => checklist.checklistType === fromReviewType);
    if (index === -1 || index === undefined) return;
    setProjectChecklist(project?.checklistsAttributes[index]);
    setProjectChecklistBaseName(`project[checklistsAttributes][${index}]`);
    if (signatureRef.current && signatureRef.current.fromDataURL) {
      signatureRef.current.fromDataURL(project?.checklistsAttributes[index]?.signature);
    }
  };

  useEffect(findVehicles, [clientId]);
  useEffect(handleCurrentVehicle, [vehicleId]);
  useEffect(handleStatusChecklists, [project]);
  useEffect(handleSetProjectChecklist, [project]);

  const scrollToError = async () => {
    setTimeout(() => {
      if (formRef.current) {
        const errorElements = formRef.current.querySelectorAll('.is-invalid');

        if (errorElements?.length > 0) {
          errorElements[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 500);
  };

  return (
    <>
      <Form ref={formRef}>
        {!(newClient || newVehicle) && !version && (
          <Row className="d-flex justify-content-end mt-2 back-confirm-project-order">
            {!isDisabled && (
              <Col md={action === 'new' ? 3 : 2} className="confirm-project-btn">
                <Button block type="submit" disabled={uploadImage} style={{ backgroundColor: 'steelblue' }} onClick={onHide}>
                  {btnMessage}
                </Button>
              </Col>
            )}
            {action !== 'new' && !workOrderModal && (
              <Col md={2}>
                <GoBackButton className="back-project-btn mb-2" />
              </Col>
            )}
          </Row>
        )}
        <Row className="mt-3 mb-5">
          <Col md={12}>
            <ProjectTab
              setUploadImage={setUploadImage}
              isDisabled={isDisabled}
              fromReviewType={fromReviewType}
              project={project}
              calendarEvent={calendarEvent || ''}
              projectReviewsAttributes={projectReviewsAttributes}
              isOpenReviewCard={isOpenReviewCard}
            />
          </Col>
          {projectChecklist.signatureEnabled && (
            <>
              <Col md={12} className="text-center">
                <h3
                  className="font-weight-bold text-left mt-3"
                  style={{
                    color: '#1387c4',
                    fontSize: '1rem',
                    fontWeight: 500
                  }}
                >
                  Firma
                </h3>
                {projectChecklist.signature ? (
                  <img src={projectChecklist.signature} alt="Firma" className="p-2" />
                ) : (
                  !isDisabled && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <SignatureCanvas
                        canvasProps={{
                          width: isMobile ? 'auto' : 500,
                          height: 200,
                          style: { border: '1px solid #000000' }
                        }}
                        minWidth={2}
                        maxWidth={3}
                        ref={signatureRef}
                        onEnd={saveSignature}
                      />
                      {!isDisabled && (
                        <Button onClick={clearSignature} className="mt-3">
                          Limpiar
                        </Button>
                      )}
                    </div>
                  )
                )}
              </Col>
              <Col md={4} className="offset-md-4 mt-2">
                <Field name={`${projectChecklistBaseName}[signatory]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="Nombre y rut de quien firma"
                      disabled={isDisabled}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
          {(fromCalendar || fromTemplate) && (
            <>
              {!newClient && (
                <Col md={12} className="mt-3">
                  <ClientTab
                    abbr
                    modelKey="project"
                    currentModel={project}
                    clientSelected={clientSelected}
                    setClientSelected={setClientSelected}
                    companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
                    currentClient={currentClient}
                    setCurrentClient={setCurrentClient}
                    newClient={newClient}
                    setNewClient={setNewClient}
                    clientDisabled={clientDisabled}
                    setVehicleDisabled={setVehicleDisabled}
                    handleClientChange={handleClientChange}
                    newVehicle={newVehicle}
                    resetVehicle={resetVehicle}
                  />
                </Col>
              )}
              {!newVehicle && (
                <Col md={12} className="mt-3">
                  <VehicleTab
                    abbr
                    modelKey="project"
                    currentModel={project}
                    companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
                    currentVehicle={currentVehicle}
                    setCurrentVehicle={setCurrentVehicle}
                    newVehicle={newVehicle}
                    setNewVehicle={setNewVehicle}
                    vehicleDisabled={vehicleDisabled}
                    setVehicleDisabled={setVehicleDisabled}
                    vehicleSelected={vehicleSelected}
                    setVehicleSelected={setVehicleSelected}
                    setClientDisabled={setClientDisabled}
                    vehicles={vehicles}
                    newClient={newClient}
                    resetVehicle={resetVehicle}
                  />
                </Col>
              )}
            </>
          )}
        </Row>

        {!(newClient || newVehicle) && action !== 'new' && (
          <Row
            className={`justify-content-center mt-1 fixed-bottom position-sticky ${isMobile || isModal ? 'mb-5' : ''}`}
          >
            {!isDisabled && (
              <Col md={12}>
                <Button
                  disabled={uploadImage || isSubmitting}
                  block
                  type="submit"
                  style={{ backgroundColor: 'steelblue' }}
                  onClick={() => {
                    scrollToError();
                    onHide && onHide();
                  }}
                >
                  {btnMessage}
                </Button>
              </Col>
            )}
          </Row>
        )}
      </Form>

      {newClient && (
        <ClientTab
          createClient
          modelKey="project"
          companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
          currentModel={project}
          formSize="12"
          setClientSelected={setClientSelected}
          setCurrentClient={setCurrentClient}
          setCurrentVehicle={setCurrentVehicle}
          setNewClient={setNewClient}
          setVehicleDisabled={setVehicleDisabled}
          resetVehicle={resetVehicle}
        />
      )}
      {newVehicle && (
        <VehicleTab
          createVehicle
          modelKey="project"
          companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
          currentModel={project}
          formSize="12"
          fetchInitialVehicles={fetchInitialVehicles}
          setClientDisabled={setClientDisabled}
          currentVehicle={currentVehicle || { ...basicVehicle, vtype: 'vehicle' }}
          setCurrentVehicle={setCurrentVehicle}
          setNewVehicle={setNewVehicle}
          setVehicleDisabled={setVehicleDisabled}
          setVehicleSelected={setVehicleSelected}
          vehicles={vehicles}
          resetVehicle={resetVehicle}
        />
      )}
    </>
  );
};

const setInitialValues = ({ calendarEvent, fromReviewType, project, template, fromTemplate }) => {
  const {
    calendarEventId,
    clientId,
    formatedPromiseDate,
    notes,
    projectDate,
    projectReviewsAttributes,
    templateId,
    vehicleId
  } = project;

  let setProjectReviews = projectReviewsAttributes;
  const projectReviews = [];
  if (projectReviewsAttributes.length > 0) {
    projectReviewsAttributes
      .filter(projectReview => projectReview.reviewType === fromReviewType)
      .map(projectReview => {
        const documentKeys = projectReview.documentKeys || [];
        return projectReviews.push({
          ...projectReview,
          documentKeys,
          fromReviewType,
          customState: projectReview?.notApply
            ? 'not_apply'
            : projectReview?.state !== 'pending'
              ? projectReview?.state
              : undefined
        });
      });
    setProjectReviews = projectReviews;
  }

  let initialCalendarEventId = calendarEventId;
  let initialClientId = clientId;
  let initialNotes = notes;
  let initialProjectDate = projectDate;
  let initialPromiseDate = formatedPromiseDate;
  let initialTemplateId = templateId;
  let initialVehicleId = vehicleId;

  if (calendarEvent) {
    const {
      id: calendarId,
      clientId: calendarClientId,
      notes: calendarNotes,
      promiseDate: calendarPromiseDate,
      vehicleId: calendarVehicleId
    } = calendarEvent;

    if (calendarClientId) initialClientId = calendarClientId;
    if (calendarVehicleId) initialVehicleId = calendarVehicleId;

    initialCalendarEventId = calendarId;
    initialNotes = calendarNotes;
    initialProjectDate = moment(Date.now()).format('DD/MM/yyyy HH:mm');
    initialPromiseDate = calendarPromiseDate;
  }

  if (fromTemplate) {
    initialProjectDate = moment().format('DD/MM/yyyy HH:mm');
    initialTemplateId = template.id;
  }

  initialProjectDate = initialProjectDate && parse(initialProjectDate, 'dd/MM/yyyy HH:mm', new Date());
  initialPromiseDate = initialPromiseDate && parse(initialPromiseDate, 'dd/MM/yyyy HH:mm', new Date());
  let promiseParseDate = '';
  let promiseTime = '';
  if (initialPromiseDate) {
    promiseParseDate = moment(initialPromiseDate, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');
    promiseTime = moment(initialPromiseDate, 'DD/MM/YYYY HH:mm').format('HH:mm');
  } else {
    promiseTime = '18:00';
  }

  return {
    project: {
      ...project,
      calendarEventId: initialCalendarEventId,
      clientId: initialClientId,
      notes: initialNotes,
      projectDate: initialProjectDate,
      projectReviewsAttributes: setProjectReviews,
      promiseParseDate,
      promiseTime,
      templateId: initialTemplateId,
      vehicleId: initialVehicleId,
      fromReviewType
    }
  };
};

const validationSchema = Yup.object().shape({
  project: Yup.object().shape({
    clientId: Yup.string().required('Debes seleccionar un cliente'),
    mileage: Yup.string().when('fromReviewType', (fromReviewType, schema) => {
      if (fromReviewType === 'reception') {
        return schema.required('Debes ingresar un kilometraje');
      }
      return schema.notRequired();
    }),
    notes: Yup.string().nullable(),
    projectDate: Yup.date().when('$projectDateExists', {
      is: true,
      then: Yup.date().required('Debes ingresar una fecha de ingreso'),
      otherwise: Yup.date().notRequired()
    }),
    projectReviewsAttributes: Yup.array().of(yupProjectReview),
    promiseParseDate: Yup.string().when('fromReviewType', (fromReviewType, schema) => {
      if (fromReviewType === 'reception') {
        return schema.required('Debes ingresar una fecha de entrega');
      }
      return schema.notRequired();
    }),
    promiseTime: Yup.string().when('fromReviewType', (fromReviewType, schema) => {
      if (fromReviewType === 'reception') {
        return schema.required('Debes ingresar una hora de entrega');
      }
      return schema.notRequired();
    }),
    vehicleId: Yup.string().required('Debes seleccionar un vehículo')
  })
});

const handleSubmit = async (values, { props, setSubmitting, isSubmitting }) => {
  if (!isSubmitting) {
    setSubmitting(true);
    const { formRequest } = props;
    if (values.project.promiseParseDate && values.project.promiseTime) {
      values.project.promiseDate = moment(
        `${values.project.promiseParseDate} ${values.project.promiseTime}`,
        'DD/MM/YYYY HH:mm'
      ).toDate();
    }
    await formRequest(values);
    setSubmitting(false);
  }
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new',
  validateOnChange: false
})(ProjectReceptionForm);
