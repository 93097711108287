import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../components';
import { sendAlert } from '../../actions/utils';
import { showUserRequest } from '../../requests/user';
import { showCompanyRequest } from '../../requests/companies';

import ProfileForm from './ProfileForm';

const basicUser = {
  avatarInfo: {},
  name: '',
  lastName: '',
  email: '',
  roles: []
};

const basicCompany = {
  address: '',
  businessActivity: '',
  businessLogo: {},
  email: '',
  legalName: '',
  name: '',
  nationalIdentification: '',
  phone: ''
};

const ProfileIndex = () => {
  const [user, setUser] = useState(basicUser);
  const [company, setCompany] = useState(basicCompany);
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.auth);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchUser = () => {
    const userId = currentUser.id;
    if (userId !== 0) {
      showUserRequest(userId, {
        dispatch,
        successCallback: response => setUser(camelCaseRecursive(response.data)),
        failureCallback: handleFailureRequest
      });
    }
  };

  const fetchCompany = () => {
    if (!currentUser.id) return;
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchUser, [currentUser]);
  useEffect(fetchCompany, [currentUser]);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col md={{ span: 8, offset: 1 }}>
          <h2 className="text-uppercase">Mi Perfil</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="primary" block to="/profile/edit">
            Editar
          </LinkBtn>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={{ span: 10, offset: 1 }}>
          <ProfileForm user={user} company={company} fromShow />
        </Col>
      </Row>
    </>
  );
};

export default ProfileIndex;
