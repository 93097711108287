import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexRolesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/roles', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexRolesRequest = AwesomeDebouncePromise(indexRolesRequest, 300);
