import React from 'react';
import { FormikSelect } from '../../../../../app/components';

const FilterByUser = ({ users, onFilterChange }) => {
  const handleUserChange = data => {
    const userId = data ? data.value : '';
    onFilterChange({ userId });
  };

  return (
    <div className="ml-3 filter-seller">
      <FormikSelect isClearable placeholder="Responsable" options={users} onChange={handleUserChange} />
    </div>
  );
};

export default FilterByUser;
