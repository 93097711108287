import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Glossary = ({ color, title, description }) => (
  <Row className="mt-3 mb-4 d-flex align-items-center">
    <Col md={1} className={`bg-${color} rounded`} style={{ height: '30px' }} />
    <Col md={11}>
      <span className="font-weight-bold">{title}:</span> {description}
    </Col>
  </Row>
);

export default Glossary;
