import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexInsuranceCompaniesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/insurance_companies', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexInsuranceCompaniesRequest = AwesomeDebouncePromise(indexInsuranceCompaniesRequest, 300);
