import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Edit } from 'react-feather';

const ProjectNavbarBudget = ({
  project,
  version,
  onClick,
  handleChangeDiscount,
}) => {
  const {
    companyTaxRate,
    invoiceDate,
    parsedTotalDiscount,
    parsedTotalPayments,
    parsedTotalPaymentsPercentage,
    parsedTotalPrice,
    parsedTotalPriceTax,
    parsedTotalTax,
    seller,
    translatedInvoiceState,
    parsedProjectRemainingUnpaid
  } = project;

  return (
    <Row className="project-section mb-3 pt-3">
      <Col md={12} className="pl-0">
        <strong>Presupuesto</strong>
      </Col>
      <Col md={12} className="mt-2 pt-2 pl-0 small text-uppercase">
        <Row>
          <Col md={2}>
            <strong>Vendedor / Asesor Técnico</strong>
            <p>{seller?.fullName}</p>
          </Col>
          <Col md={10}>
            <Row>
              <Col md={6}>
                <Row>
                  <Col>
                    <strong>Valor Neto</strong>
                    <p>{parsedTotalPrice}</p>
                  </Col>
                  <Col>
                    <strong>Descuento</strong>
                    <Button variant="link" className="btn-sm text-dark float-right p-0" onClick={handleChangeDiscount}>
                      <Edit width="15px" height="15px" />
                    </Button>
                    <p>{parsedTotalDiscount} </p>
                  </Col>
                  <Col>
                    <strong>IVA ({companyTaxRate})</strong>
                    <p>{parsedTotalTax}</p>
                  </Col>
                  <Col className="project-info-division">
                    <strong>Total</strong>
                    <p>{parsedTotalPriceTax}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col>
                    <strong>Fecha Facturación</strong>
                    <p>{invoiceDate}</p>
                  </Col>
                  <Col className={`p-0 ${version ? 'project-info-division' : ''}`}>
                    <strong>Estado Cobro</strong>
                    <p>{translatedInvoiceState}</p>
                  </Col>
                  {!version && (
                    <Col xs={1} className="pl-0 project-info-division">
                      <Button variant="link" className="btn-sm text-dark float-right p-0" onClick={onClick}>
                        <Edit width="15px" height="15px" />
                      </Button>
                    </Col>
                  )}
                  <Col>
                    <strong>Valor Abonado</strong>
                    <p>
                      {parsedTotalPayments} ({parsedTotalPaymentsPercentage})
                    </p>
                  </Col>
                  <Col>
                    <strong>Monto por pagar</strong>
                    <p className="text-danger">{parsedProjectRemainingUnpaid}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
);
};

export default ProjectNavbarBudget;
