import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { Link } from 'react-router-dom';
import getImage from '../../../utils/getImage';

const IncidentShow = ({ fromMechanicCard, incident }) => {
  const { id, createdAt, description, projectCode, reportedBy, incidentType, imagesInfo } = incident;
  const { label: incidentTypeName } = incidentType;

  const images = imagesInfo.map(image => {
    return { original: getImage(image.key, 800, 800, 'inside'), thumbnail: getImage(image.key, 800, 800, 'inside') };
  });

  const noImages = imagesInfo.length === 0;

  return (
    <>
      <div className="d-flex justify-content-between mt-3 mb-4">
        <h3>Incidencia #{id}</h3>
        <p>
          <strong>Creada:</strong> {createdAt}
        </p>
      </div>
      <Row>
        <Col md={4}>
          <p>
            <strong>Tipo de incidencia:</strong>
          </p>
          <p>{incidentTypeName}</p>
        </Col>
        <Col md={4}>
          <p>
            <strong>Reportada por:</strong>
          </p>
          <p>{reportedBy.fullName}</p>
        </Col>
        {!fromMechanicCard && (
          <Col md={4}>
            <p>
              <strong>Proyecto: </strong>
            </p>
            <p>
              <Link to={`/projects/${projectCode}`}>Ir a Proyecto</Link>
            </p>
          </Col>
        )}
        <Col className="my-2" md={12}>
          <p>
            <strong>Descripción </strong>
          </p>
          <p>{description}</p>
        </Col>
        <Col md={12}>
          <p>
            <strong>Fotos: </strong>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {noImages ? (
            <p className="font-italic text-center my-4">No hay imágenes asociadas a esta incidencia</p>
          ) : (
            <ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} lazyLoad fullscreen />
          )}
        </Col>
      </Row>
    </>
  );
};

export default IncidentShow;
