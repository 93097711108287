import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { format, startOfMonth } from "date-fns";
import ComponentDataTable from '../../components/Utils/DataTable';
import { sendAlert } from '../../actions/utils';
import { debounceIndexNpsAnswersRequest } from '../../requests/npsAnswers';

const NpsAnswersDataTable = props => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [bulkUploads, setNpsAnswers] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const { columns, moreData, setMoreData, setQuestions, setNps } = props;

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseNpsAnswers = response.data.data;
    const { amount: responseAmount, questions_with_average_score, nps_score } = response.data.meta;
    setQuestions(questions_with_average_score);
    setNps(nps_score);
    setNpsAnswers(responseNpsAnswers);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexNpsAnswersRequest({
      dispatch,
      params: {
        ...params,
        q: {
          project_delivery_date_from: params.date_from,
          project_delivery_date_to: params.date_to
        }
      },
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const currentDate = new Date();

  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={columns()}
      data={bulkUploads}
      totalRows={amount}
      moreData={moreData}
      startDate={format(startOfMonth(currentDate),'yyyy-MM-dd')}
      endDate={format(currentDate, 'yyyy-MM-dd')}
      withDate
      resourceRequest={response => {
        if (!onRequest) handleIndexRequest(response);
      }}
    />
  );
};

export default NpsAnswersDataTable;
