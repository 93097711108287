import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Check, Minus } from 'react-feather';
import { getIn } from 'formik';

const NormalReviewButtons = ({ isDisabled, field, handleIsOpen, handleButtonsAction, errors, touched }) => {
    return (
        <ToggleButtonGroup
            {...field}
            onChange={value => {
                handleIsOpen(false);
                handleButtonsAction(value);
            }}
            disabled={isDisabled}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
        >
            <ToggleButton
                className="btn"
                value="not_apply"
                variant="outline-dark"
                style={{ marginLeft: '0.5rem', borderRadius: '3px', ...(isDisabled  ? field?.value === 'not_apply' ? notApplyStyleActive : notApplyStyles : {})}}
                disabled={isDisabled}
            >
                <Minus/> No aplica
            </ToggleButton>
            <ToggleButton
                className="btn"
                variant="outline-danger"
                value="rejected"
                style={{ marginLeft: '0.5rem', borderRadius: '3px', ...(isDisabled ? field?.value === 'rejected' ? dangerStyleActive : dangerStyles : {})}}
                disabled={isDisabled}
            >
                <Minus/> Malo
            </ToggleButton>
            <ToggleButton
                className="btn"
                variant="outline-success"
                value="approved"
                style={{ marginLeft: '0.5rem', borderRadius: '3px', ...(isDisabled ? field?.value === 'approved' ? successStyleActive : successStyles: {})}}
                disabled={isDisabled}
            >
                <Check/> Bueno
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

export default NormalReviewButtons;


const dangerStyleActive = {
    color: "#fff",
    backgroundColor: "#ee5252",
    borderColor: "#ee5252"
}
const dangerStyles = {
    color: "#ee5252",
    borderColor: "#ee5252",
    backgroundColor: "transparent"
}
const successStyleActive = {
    color: "#fff",
    backgroundColor: "#5cc689",
    borderColor: "#5cc689"
}

const successStyles = {
    color: "#5cc689",
    borderColor: "#5cc689",
    backgroundColor: "transparent"
}

const notApplyStyleActive = {
    color: "#fff",
    backgroundColor: "#494949",
        borderColor: "#494949"   
}

const notApplyStyles = {
    color: "#494949",
    borderColor: "#494949",
    backgroundColor: "transparent"
}
