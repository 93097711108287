const inventoryAbility = can => {
  can('create', 'ProductInventory');
  can('create', 'Provider');
  can('create', 'Log');
  can('create', 'ProductMovement');
  can('company_index', 'ProductInventory');
  can('company_index', 'ProductMovement');
  can('destroy_document', 'User');
  can('index', 'Dashboard');
  can('read', 'Company');
  can('read', 'Commune');
  can('read', 'Product');
  can('read', 'ProductCategory');
  can('read', 'ProductInventory');
  can('read', 'ProductMovement');
  can('read', 'Provider');
  can('read', 'Province');
  can('read', 'Region');
  can('read', 'Setting');
  can('read', 'Slide');
  can('read', 'User');
  can('read', 'VehicleBrand');
  can('read', 'VehicleModel');
  can('scan', 'Product');
  can('update_password', 'User');
  can('update', 'Product');
  can('update', 'ProductInventory');
  can('update', 'ProductMovement');
  can('update', 'Provider');
  can('update', 'User');
};

export default inventoryAbility;
