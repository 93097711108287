import axios from 'axios';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import jsonToFormData from 'json-form-data';
import { EXTERNAL_API_CONFIG } from '../config/configurations';

import { sendAlert, setHeaders } from '../actions/utils';

const url = process.env.MARKETPLACE_API_URL;
const { urlAm } = EXTERNAL_API_CONFIG;
const headers = {

};

// Function to create formData
const bodyToFetch = (params, formData, showLeafArrayIndexes) => {
  if (formData) {
    return jsonToFormData(params, { showLeafArrayIndexes });
  }
  return JSON.stringify(params);
};

const paramsGetRequest = params => {
  params = snakeCaseKeys(params);
  let searchParams = new URLSearchParams();
  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      if (typeof params[key] === 'object') {
        for (let subKey in params[key]) {
          if (params[key].hasOwnProperty(subKey)) {
            searchParams.append(`${key}[${subKey}]`, params[key][subKey]);
          }
        }
      } else {
        searchParams.append(key, params[key]);
      }
    }
  }
  return searchParams.toString();
};

const genericErrorMessage = (error, dispatch) => {
  const { response } = error;
  if (!response) {
    return dispatch(sendAlert({ kind: 'error', message: error.message }));
  }
  let errorMessage = response.statusText;
  if (response.data && response.data.error) {
    errorMessage = response.data.error;
  }

  if (response.data.message && response.data.message.length) {
    errorMessage += `: ${response.data.message.split(',')}`;
  }

  dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  switch (response.status) {
    case 400: // Bad request
      return { error: errorMessage, ...response };
    case 401: // Unauthorized
      return { error: errorMessage, ...response };
    case 403: // Forbidden
      return { error: errorMessage, ...response };
    case 404: // Not found
      return { error: errorMessage, ...response };
    case 408: // Request timeout
      return { error: errorMessage, ...response };
    case 422: // Unprocessable entity
      return { error: errorMessage, ...response };
    case 500: // Internal Server Error
      return { error: errorMessage, ...response };
    default:
      return { error: errorMessage, ...response };
  }
};

const genericSuccessCallback = response => {
  return camelCaseRecursive(response.data);
};

export default class MarketplaceService {
  static request(
    method,
    route,
    {
      params = {},
      dispatch,
      formData = false,
      successCallback = genericSuccessCallback,
      failureCallback = genericErrorMessage,
      callback = () => null,
      showLeafArrayIndexes = false
    }
  ) {
    let request;
    switch (method) {
      case 'get':
        request = axios({
          method: 'get',
          url: url + route + '?' + paramsGetRequest(params),
          headers: headers
        });
        break;
      case 'post':
        request = axios({
          method: 'post',
          url: url + route,
          cache: 'no-cache',
          data: bodyToFetch(params, formData, showLeafArrayIndexes),
          headers: headers
        });
        break;
      case 'put':
        request = axios({
          method: 'put',
          url: url + route,
          cache: 'no-cache',
          data: bodyToFetch(params, formData, showLeafArrayIndexes),
          headers: headers
        });
        break;
      case 'patch':
        request = axios({
          method: 'patch',
          url: url + route,
          cache: 'no-cache',
          data: bodyToFetch(params, formData),
          headers: headers
        });
        break;
      case 'delete':
        request = axios({
          method: 'delete',
          url: url + route,
          cache: 'no-cache',
          data: JSON.stringify(snakeCaseKeys(params)),
          headers: headers
        });
        break;
      default:
        request = axios({
          method: 'get',
          url: url + route,
          headers: headers
        });
    }

    return request
      .then(response => {
        dispatch(setHeaders(response.headers));
        return response;
      })
      .then(response => successCallback(response))
      .catch(error => failureCallback(error, dispatch))
      .finally(response => callback(response));
  }

  static requestAutomarco(
    method,
    route,
    {
      params = {},
      dispatch,
      successCallback = genericSuccessCallback,
      failureCallback = genericErrorMessage,
      callback = () => null
    }
  ) {
    console.log(urlAm);
    let request;
    switch (method) {
      case 'get':      
        request = axios({
          method: 'get',
          url: urlAm + route + '?' + params,
          headers: headers
        });
        break;
      case 'post':
        request = axios({
          method: 'post',
          url: urlAm + route,
          data: params,
          headers: headers
        });
        break;
      default:
        request = axios({
          method: 'get',
          url: urlAm + route,
          headers: headers
        });
    }

    return request
      .then(response => {
        dispatch(setHeaders(response.headers));
        return response;
      })
      .then(response => successCallback(response))
      .catch(error => failureCallback(error, dispatch))
      .finally(response => callback(response));
  }
}
