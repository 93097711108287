import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import ComponentDataTable from '../../components/Utils/DataTable';
import ProjectReceptionForm from '../Project/Form/ProjectReceptionForm';
import { DefaultModal, SimpleCenteredModal } from '../../components';
import { createProjectRequest } from '../../requests/projects';
import { debounceIndexTemplatesRequest, deleteTemplateRequest, updateTemplateRequest } from '../../requests/templates';
import { sendAlert } from '../../actions/utils';
import TemplateForm from './TemplateForm';

const initialProject = {
  calendarEventId: '',
  clientId: '',
  mileage: '',
  notes: '',
  projectDate: '',
  projectReviewsAttributes: [],
  promiseDate: '',
  vehicleId: ''
};

const TemplateDataTable = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [amount, setAmount] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const { columns, moreData, setMoreData } = props;

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Template actualizado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleUpdateRequest = values => {
    const {
      template: { id }
    } = values;
    updateTemplateRequest(id, {
      dispatch,
      params: snakeCaseKeys(values, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRequest = response => {
    const responseTemplates = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setTemplates(responseTemplates);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexTemplatesRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleCreateProject = async params => {
    await createProjectRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: response => {
        const projectId = response.data.id;
        history.push(`/projects/${projectId}/reception`);
        dispatch(sendAlert({ kind: 'success', message: 'OT ha sido creada desde template con éxito' }));
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'clone':
        setCenteredModalShow(true);
        setModalTitle('Crear OT desde Template');
        setModalBody(
          <ProjectReceptionForm
            fromTemplate
            template={item}
            action="new"
            project={initialProject}
            submitVariant="success"
            formRequest={handleCreateProject}
            isModal
          />
        );
        break;
      case 'edit':
        setCenteredModalShow(true);
        setModalBody(
          <TemplateForm
            action={action}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            submitVariant="success"
            template={camelCaseEmptyStringRecursive(item)}
          />
        );
        setModalTitle('Editar Template');
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalTitle('Eliminar Template');
        setModalBody('¿Estás seguro que deseas eliminar este template?');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Template eliminado con éxito' }));
    setMoreData(!moreData);
  };

  const removeTemplate = templateId => {
    deleteTemplateRequest(templateId, {
      dispatch,
      successCallback: () => handleSuccessRemove(templateId)
    });
  };

  const handleModalConfirm = () => {
    removeTemplate(modalItem.id);
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={templates}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
      {modalShow && (
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          handleClose={handleModalClose}
          handleConfirm={handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
    </>
  );
};

export default TemplateDataTable;
