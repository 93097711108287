import React from 'react';

const productInfo = ({ product }) => {
  const {
    categoryName,
    code,
    createdAt,
    description,
    inventoryable,
    parsedCost,
    parsedCostQuotation,
    parsedSalePrice,
    productCategoryName,
    providerCode,
    providerName,
    sku,
    translatedUnit,
    vehicleBrandName,
    vehicleModelName,
    workforce,
    parsedMaxSalePrice
  } = product;

  return (
    <div className="show-info-container">
      <div className="show-info-small-container">
        <div className="show-info-column">
          <p>
            <strong>Nombre / Código :</strong> {code}
          </p>
          <p>
            <strong>Categoría:</strong> {categoryName}
          </p>
          <p>
            <strong>Subcategoría:</strong> {productCategoryName}
          </p>
          <p>
            <strong>Mano de Obra:</strong> {workforce ? 'Sí' : 'No'}
          </p>
          <p>
            <strong>Creado:</strong> {createdAt}
          </p>
        </div>
        <div className="show-info-column">
          <p>
            <strong>Inventariable:</strong> {inventoryable ? 'Sí' : 'No'}
          </p>
          {inventoryable && (
            <p>
              <strong>SKU:</strong> {sku}
            </p>
          )}
          <p>
            <strong>Proveedor:</strong> {providerName}
          </p>
          <p>
            <strong>Código Proveedor:</strong> {providerCode}
          </p>
          <p>
            <strong>Marca:</strong> {vehicleBrandName}
          </p>
          <p>
            <strong>Modelo:</strong> {vehicleModelName}
          </p>
        </div>
      </div>
      <div className="show-info-small-container mt-5">
        <div className="show-info-column">
          <p>
            <strong>Descripción:</strong> {description || 'No hay descripción para éste ítem'}
          </p>
        </div>
        <div className="show-info-column">
          <p>
            <strong>Unidad:</strong> {translatedUnit}
          </p>
          <p>
            <strong>Costo: Unitario</strong> {parsedCost}
          </p>
          <p>
            <strong>Costo Cotización:</strong> {parsedCostQuotation}
          </p>
          <p>
            <strong>Precio Venta:</strong> {parsedSalePrice}
          </p>
          { parsedMaxSalePrice && 
            <p>
              <strong>Precio Máximo de Venta:</strong> {parsedMaxSalePrice}
            </p>
          }
        </div>
      </div>
    </div>
  );
};

export default productInfo;
