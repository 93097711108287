import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import { FormikInput, FormikSelect } from '../../components';

const bulk_upload_models = [
  { value: 'product', label: 'Items' },
  { value: 'product_category', label: 'Categorías' },
  { value: 'product_inventory', label: 'Inventario' },
  { value: 'work', label: 'Trabajos' }
];
const BulkUploadForm = ({
  action,
  errors,
  handleModalClose,
  onHide,
  submitVariant,
  touched,
  bulkUpload,
  setFieldValue
}) => {
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';
  const [excelTemplate, setExcelTemplate] = useState(`plantilla_carga_masiva_${bulkUpload.model}.xlsx`);

  return (
    <Form>
      <Row>
        <Col md={8}>
          <Field name="bulk_upload[model]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo"
                placeholder="Seleccionar..."
                options={bulk_upload_models}
                defaultValue={bulkUpload.model}
                onChange={data => {
                  if (data?.value) setExcelTemplate(`plantilla_carga_masiva_${data?.value}.xlsx`);
                  setFieldValue(field.name, data?.value);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4} className={'my-auto'}>
          <a href={`/excel_templates/${excelTemplate}`} download>
            <Button variant="secondary">Descargar Plantilla</Button>
          </a>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field name="bulk_upload[file]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Archivo"
                type="file"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                value={bulkUpload.file}
                onChange={event => {
                  setFieldValue(field.name, event.currentTarget.files[0]);
                }}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3">
        <Col md={2}>
          <Button className="btn mb-3" variant="primary" block onClick={handleModalClose}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { bulkUpload } = props;
  return { bulk_upload: bulkUpload };
};

const validationSchema = Yup.object().shape({
  bulk_upload: Yup.object().shape({
    model: Yup.string().required('Debe seleccionar un tipo'),
    file: Yup.mixed().required('Debe seleccionar un archivo')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(BulkUploadForm);
