const productUnits = [
  { label: '', value: '' },
  { label: 'Barras', value: 'bars' },
  { label: 'Caja x 10', value: 'box10' },
  { label: 'Caja x 50', value: 'box50' },
  { label: 'Caja x 100', value: 'box100' },
  { label: 'Envase', value: 'container' },
  { label: 'Horas', value: 'hours' },
  { label: 'Kilo', value: 'kilo' },
  { label: 'Litro', value: 'liter' },
  { label: 'Metro', value: 'meter' },
  { label: 'Pieza de pintura', value: 'piece' },
  { label: 'Unidad', value: 'unit' }
];

export default productUnits;
