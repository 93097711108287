const basicCompany = {
  active: false,
  address: '',
  businessActivity: '',
  businessLogo: {},
  companyRequiredFieldAttributes: {
    clientAddress: true,
    clientCellphone: false,
    clientEmail: true,
    regionAndCommune: true,
    vehicleVin: true,
    clientNationalIdentification: true
  },
  settings: {
    notifications: {
      email: {
        collection: {
          client: true,
          secondClient: false
        },
        invoiceIssuance: {
          client: true,
          secondClient: false
        },
        mechanicProgress: {
          client: true,
          secondClient: false
        },
        deliveredProgress: {
          client: true,
          secondClient: false
        },
        receptionProgress: {
          client: true,
          secondClient: false
        },
        budgetApprovedProgress: {
          client: true,
          secondClient: false
        },
        budgetSentProgress: {
          client: true,
          secondClient: false
        },
        preInspectionProgress: {
          client: true,
          secondClient: false
        }
      },
      whatsapp: {
        collection: {
          client: true,
          secondClient: false
        },
        newAppointment: {
          client: true,
          secondClient: false
        },
        invoiceIssuance: {
          client: true,
          secondClient: false
        },
        mechanicProgress: {
          client: true,
          secondClient: false
        },
        deliveredProgress: {
          client: true,
          secondClient: false
        },
        receptionProgress: {
          client: true,
          secondClient: false
        },
        appointmentReminder: {
          client: true,
          secondClient: false
        },
        budgetApprovedProgress: {
          client: true,
          secondClient: false
        },
        budgetSentProgress: {
          client: true,
          secondClient: false
        },
        sendNpsSurvey: {
          client: true,
          secondClient: false
        }
      }
    }
  },
  email: '',
  endTime: '',
  hourPrice: 0,
  legalName: '',
  maxWorkHours: 0,
  name: '',
  nationalIdentification: '',
  phone: '',
  startTime: '',
  taxRate: 0,
  worksFriday: true,
  worksMonday: true,
  worksSaturday: true,
  worksSunday: true,
  worksThursday: true,
  worksTuesday: true,
  worksWednesday: true,
  whatsappPhoneNumber: ''
};

export default basicCompany;
