/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import EditOwnerButton from './EditOwnerButton';
import { BasicActionBtns } from '../../components';

const columns = memoize((clickHandler, setMoreData) => [
  { name: 'ID', selector: 'id', sortable: true, grow: '0.5' },
  { name: 'CÓDIGO', selector: 'plate', sortable: true, grow: '0.5' },
  {
    name: 'TIPO',
    selector: 'translated_vtype',
    sortable: true,
    grow: '0.9'
  },
  {
    name: 'MARCA',
    selector: 'vehicle_brand_name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'MODELO',
    selector: 'vehicle_model_name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'AÑO',
    selector: 'year',
    cell: item => item.year || 'No Especificado',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO DE COMBUSTIBLE',
    selector: 'translated_fuel_type',
    sortable: false,
    grow: '1'
  },
  {
    name: 'FECHA DE INGRESO',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ÚLTIMA MANTENCIÓN',
    selector: 'last_maintenance',
    sortable: true,
    grow: '1'
  },
  {
    name: 'CLIENTE',
    selector: 'client_full_name',
    sortable: true,
    grow: '2'
  },
  {
    cell: item => (
      <>
        <BasicActionBtns
          item={item}
          clickHandler={clickHandler}
          withShow
          path={`clients/${item.client?.value}/vehicles`}
          editClick
        />
        <EditOwnerButton setMoreData={setMoreData} item={item} />
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '3',
    minWidth: '240px'
  }
]);

export default columns;
