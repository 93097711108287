/* eslint-disable camelcase */
import memoize from 'memoize-one';
import moment from "moment/moment";
import {ButtonTooltip} from "../../../components";
import {Eye} from "react-feather";
import React from "react";

const columns = memoize(() => [
  {name: 'TIPO', selector: 'document_type_name', sortable: true, grow: '2'},
  {name: 'N° DE DOCUMENTO', selector: 'number', sortable: true, grow: '1'},
  {
    name: 'FECHA DE EMISIÓN',
    selector: 'emission_date',
    cell: item => moment(item.emission_date, 'YYYY-MM-DD').format('DD/MM/YY'),
    sortable: true,
    grow: '1'
  },
  {
    name: 'RUT PROVEEDOR',
    selector: 'provider.national_identification',
    sortable: true,
    grow: '1'
  },
  {
    name: 'PROVEEDOR',
    selector: 'provider.legal_name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'NETO',
    selector: 'parsed_net',
    sortable: true,
    grow: '1'
  },
  {
    name: 'IVA',
    selector: 'parsed_tax',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOTAL',
    selector: 'parsed_total',
    sortable: false,
    grow: '1'
  },
  {
    name: '',
    selector: 'id',
    cell: item => (
      <ButtonTooltip
        href={`${item.url_pdf}`}
        variant="outline-info"
        className="btn-circle table-actions-btn"
        size="sm"
        text="Ver Documento"
        target="_blank"
        as={"a"}
      >
        <Eye width="15px" height="15px"/>
      </ButtonTooltip>
    ),
  }
]);

export default columns;
