import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';

const InputMultiAddon = ({ addon1, addon2, placeholder, ariaLabel }) => (
  <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text>{addon1}</InputGroup.Text>
      <InputGroup.Text>{addon2}</InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl placeholder={placeholder} aria-label={ariaLabel} />
  </InputGroup>
);

InputMultiAddon.propTypes = {
  addon1: PropTypes.string.isRequired,
  addon2: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string
};

InputMultiAddon.defaultProps = {
  placeholder: null,
  ariaLabel: null
};

export default InputMultiAddon;
