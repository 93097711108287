import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import ChecklistForm from './ChecklistForm';
import {} from '../../requests/companies';
import { sendAlert } from '../../actions/utils';
import { showChecklistRequest, updateChecklistRequest } from '../../requests/checklists';

const checklistData = {
  id: '',
  name: '',
  checklistType: '',
  reviewGroupsAttributes: [
    {
      id: '',
      name: '',
      reviewType: '',
      position: 0,
      reviewsAttributes: [
        {
          id: '',
          name: '',
          position: 0
        }
      ]
    }
  ]
};

const ChecklistEdit = ({ match }) => {
  const [checklist, setChecklist] = useState(checklistData);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = match.params;

  const getChecklistData = () => {
    showChecklistRequest(id, {
      dispatch,
      successCallback: response => setChecklist(camelCaseRecursive(response.data))
    });
  };

  useEffect(getChecklistData, []);

  const handleSuccessUpdate = response => {
    setChecklist(camelCaseRecursive(response.data));
    dispatch(sendAlert({ kind: 'success', message: 'Checklist actualizado correctamente' }));
    history.push(`/checklists`);
  };

  const handleUpdateRequest = checklistValues => {
    updateChecklistRequest(id, {
      dispatch,
      params: snakeCaseKeys(checklistValues, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate
    });
  };

  return (
    <Row className="mb-3">
      <Col md={{ span: 10, offset: 1 }}>
        {checklist.id !== '' ? (
          <ChecklistForm
            type={checklist.checklistType}
            checklist={checklist}
            action="edit"
            formRequest={handleUpdateRequest}
          />
        ) : (
          <Spinner animation="border" variant="primary" />
        )}
      </Col>
    </Row>
  );
};

export default ChecklistEdit;
