import React from 'react';
import { Link } from 'react-router-dom';

const vehicleInfo = props => {
  const {
    vehicle: {
      clientId,
      clientFullName,
      customBrand,
      customModel,
      engineCapacity,
      createdAt,
      lastMaintenance,
      motorNumber,
      notes,
      plate,
      technicalReviewExpiration,
      translatedFuelType,
      translatedVtype,
      vehicleBrandName,
      vehicleModelName,
      vehicleType,
      vin,
      year
    }
  } = props;

  return (
    <>
      <div className="show-info-container">
        <div className="show-info-small-container">
          <div className="show-info-column">
            <p>
              <strong>Cliente: </strong>
              <Link className="ml-1" to={`/clients/${clientId}`}>
                {clientFullName}
              </Link>
            </p>
            <p>
              <strong>Revisión Expira: </strong>{' '}
              {technicalReviewExpiration || <span className="font-italic ml-1">No especificado</span>}
            </p>
            <p>
              <strong>Patente/Código: </strong> {plate}
            </p>
            <p>
              <strong>Última Mantención: </strong>{' '}
              {lastMaintenance || <span className="font-italic ml-1">No especificado</span>}
            </p>
            <p>
              <strong>Fecha de Ingreso: </strong> {createdAt}
            </p>
          </div>
          <div className="show-info-column">
            <p>
              <strong>Marca: </strong> {vehicleBrandName}
            </p>
            <p>
              <strong>Modelo: </strong> {vehicleModelName}
            </p>
            <p>
              <strong>Año: </strong> {year || <span className="font-italic ml-1">No especificado</span>}
            </p>
            <p>
              <strong>Tipo de Combustible: </strong>{' '}
              {translatedFuelType || <span className="font-italic ml-1">No especificado</span>}
            </p>
            <p>
              <strong>Capacidad de Motor [CC]: </strong>{' '}
              {engineCapacity || <span className="font-italic ml-1">No especificado</span>}
            </p>
          </div>
        </div>
        <div className="show-info-small-container mt-5">
          <div className="show-info-column">
            <p>
              <strong>Marca Libre: </strong> {customBrand}
            </p>
            <p>
              <strong>Tipo Vehículo: </strong> {vehicleType}
            </p>
            <p>
              <strong>Serial Chasis (VIN): </strong> {vin}
            </p>
            <p>
              <strong>Serial Motor: </strong> {motorNumber}
            </p>
          </div>
          <div className="show-info-column">
            <p>
              <strong>Modelo Libre: </strong> {customModel}
            </p>
            <p>
              <strong>Tipo: </strong> {translatedVtype}
            </p>
            <p>
              <strong>Notas: </strong> {notes || <span className="font-italic ml-4">Sin notas</span>}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default vehicleInfo;
