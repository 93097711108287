import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import HeroeSection from './Landing/HeroeSection';
import FuntionSection from './Landing/FuntionSection';
import BenefitsSection from './Landing/BenefitsSection';
import SchedulingSection from './Landing/SchedulingSection';
import GetdemoSection from './Landing/GetdemoSection';
import VideoSection from './Landing/VideoSection';
import StoriesSection from './Landing/StoriesSection';
import FaqsSection from './Landing/FaqsSection';
import PlansSection from './Landing/PlansSection';
import CloseSection from './Landing/CloseSection';

const Home = () => {
  const { settings } = useSelector(state => state.utils);

  const { whatsappNumberLink } = settings;

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-7881MHCN09';
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-7881MHCN09');
    `;
    document.head.appendChild(script2);
  }, []);

  return (
    <>
      <div id="landing-container">
        {whatsappNumberLink && (
          <div id="whatsapp">
            <Row>
              <div className="float-button">
                <a
                  href={`https://api.whatsapp.com/send?phone=${whatsappNumberLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5461 2.66602C23.9088 2.66602 29.8774 8.63464 29.8774 15.9973C29.8774 23.3599 23.9088 29.3286 16.5461 29.3286C14.195 29.3286 11.983 28.7189 10.063 27.6485L9.65614 27.4121L5.61414 28.601C4.64044 28.8874 3.73239 28.0394 3.9079 27.0747L3.94248 26.9292L5.1313 22.8873C3.9148 20.8758 3.21484 18.5164 3.21484 15.9973C3.21484 8.63464 9.18347 2.66602 16.5461 2.66602ZM16.5461 5.33228C10.656 5.33228 5.8811 10.1072 5.8811 15.9973C5.8811 18.0995 6.48794 20.0559 7.53549 21.7055C7.79995 22.1221 7.91244 22.6337 7.82283 23.1414L7.77191 23.3585L7.18313 25.3603L9.18496 24.7716C9.76151 24.602 10.3619 24.7057 10.8379 25.0079C12.4876 26.0555 14.4439 26.6624 16.5461 26.6624C22.4363 26.6624 27.2112 21.8875 27.2112 15.9973C27.2112 10.1072 22.4363 5.33228 16.5461 5.33228ZM12.6822 9.57707C12.97 9.45375 13.3185 9.47631 13.594 9.67729C14.2665 10.1678 14.8004 10.8258 15.2589 11.4693L15.6946 12.1012C15.7638 12.2035 15.8315 12.3036 15.898 12.4009C16.1413 12.7568 16.1133 13.2296 15.8376 13.5523L15.7365 13.6539L14.5055 14.5682C14.3835 14.6589 14.3429 14.8235 14.4161 14.9568C14.6947 15.4638 15.1909 16.2182 15.7587 16.786C16.3271 17.3544 17.1173 17.8828 17.659 18.1926C17.7761 18.2596 17.9184 18.2384 18.014 18.1512L18.0651 18.0911L18.8661 16.8712C19.1731 16.4626 19.7466 16.3733 20.1635 16.6617L20.8872 17.1662C21.6063 17.6795 22.2986 18.2313 22.8458 18.9321C23.0614 19.2082 23.0934 19.569 22.9649 19.869C22.437 21.1009 21.1008 22.1501 19.711 22.0989L19.4996 22.0867L19.2438 22.0614C19.1976 22.0559 19.1497 22.0496 19.1001 22.0425L18.7832 21.99C17.5518 21.7575 15.5764 21.0592 13.531 19.0138C11.4855 16.9684 10.7872 14.9929 10.5547 13.7615L10.5022 13.4446L10.4688 13.1676L10.4505 12.9337C10.4485 12.8984 10.4469 12.8651 10.4458 12.8337C10.3946 11.4419 11.4494 10.1054 12.6822 9.57707Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </div>
            </Row>
          </div>
        )}
        <HeroeSection />
        <FuntionSection />
        <BenefitsSection />
        <SchedulingSection />
        <GetdemoSection />
        <VideoSection />
        <StoriesSection />
        <FaqsSection />
        <PlansSection />
        <CloseSection />
      </div>
    </>
  );
};

export default Home;
