import React from 'react';

import PurchaseManagementDetailForm from './PurchaseManagementDetailForm';

const AddPurchaseManagementDetail = ({ purchaseManagement }) => {
  const { purchaseManagementDetailsAttributes } = purchaseManagement;

  const mapResults = purchaseManagementDetailsAttributes.map((purchaseManagementDetail, index) => {
    if (purchaseManagementDetail._destroy) {
      return undefined;
    }
    return (
      <PurchaseManagementDetailForm
        key={`purchase-management-detail-${index.toString()}`}
        purchaseManagementDetail={purchaseManagementDetail}
        index={index}
      />
    );
  });

  return mapResults;
};

export default AddPurchaseManagementDetail;
