import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import columns from './Columns';
import CreditNoteDataTable from "./CreditNoteDataTable";
import {HelpCircle} from "react-feather";
import ReactTooltip from "react-tooltip";

const CreditNotesIndex = () => {
  const [moreData, setMoreData] = useState(false);

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase">
                Notas de Crédito
                <HelpCircle
                  role="button"
                  width="17px"
                  height="17px"
                  className="ml-1 mb-1"
                  data-tip
                  data-for="tooltip"
                />
                <ReactTooltip id="tooltip" place="top">
                  Suma en base a los <br/> registros filtrados
                </ReactTooltip>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <CreditNoteDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreditNotesIndex;
