import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { sendAlert } from '../../actions/utils';
import { showProductMovementRequest } from '../../requests/productMovements';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicProductMovement from './basicProductMovement';
import ProductMovementInfo from './ProductMovementInfo';
import GoBackButton from "../../components/GoBackButton";

const ProductMovementShow = ({ match }) => {
  const [movement, setMovement] = useState(basicProductMovement);
  const { id: movementId } = match.params;
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const movementResponse = camelCaseEmptyStringRecursive(response.data);
    setMovement(movementResponse);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchMovement = () => {
    showProductMovementRequest(movementId, {
      dispatch,
      successCallback: handleSuccessShow,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchMovement, []);

  return (
    <>
      <Row className="mt-3">
        <Col md={{ span: 6, offset: 1 }}>
          <h2>Movimiento N°{movementId}</h2>
        </Col>
        <Col md={{ span: 2, offset: 2 }}>
          <GoBackButton />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={{ span: 10, offset: 1 }}>
          <ProductMovementInfo movement={movement} />
        </Col>
      </Row>
    </>
  );
};

export default ProductMovementShow;
