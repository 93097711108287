import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { SimpleCenteredModal } from '../../components';
import NpsAnswerIndexDataTable from './NpsAnswerDataTable';
import columns from './Columns';
import NpsQuestionForm from '../NpsQuestion/NpsQuestionForm';

const NpsAnswerIndex = () => {
  const [modalBody, setModalBody] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const [nps, setNps] = useState();

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleModalCreate = () => {
    setModalBody(<NpsQuestionForm handleModalClose={handleModalClose} />);
    setModalShow(true);
    setModalTitle('Configurar encuesta');
  };

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">Nps Respuestas</h2>
              {questions.map(
                (question, index) =>
                  question.kind === 'ranking' && (
                    <div className="d-flex" key={index}>
                      <div className="font-weight-bold mr-1">{question.label}:</div>
                      <div className="bg-dark px-1 font-weight-bold mr-1 text-white">{question.average_score}</div>
                      promedio
                    </div>
                  )
              )}
              <div className="d-flex mt-4 mb-1">
                <div className="font-weight-bold mr-1">NPS:</div>
                <div>{nps} %</div>
              </div>
              <div className="text-small">Promotores (P): Clientes que dan una calificación de 9 o 10.</div>
              <div className="text-small">Pasivos (P): Clientes que dan una calificación de 7 u 8.</div>
              <div className="text-small">Detractores (D): Clientes que dan una calificación de 0 a 6.</div>
            </Col>
            <Col md={2}>
              <Button variant="primary" block onClick={handleModalCreate}>
                Configurar encuesta
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <NpsAnswerIndexDataTable
                columns={columns}
                moreData={moreData}
                setMoreData={setMoreData}
                setQuestions={setQuestions}
                setNps={setNps}
              />
            </Col>
          </Row>
          <SimpleCenteredModal
            title={modalTitle}
            body={modalBody}
            show={modalShow}
            onHide={handleModalClose}
            centered={false}
            closeButton
          />
        </Col>
      </Row>
    </>
  );
};

export default NpsAnswerIndex;
