import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCountryFactory } from "../utils/utils";

const useCountryFactory = () => {
  const { user } = useSelector(state => state.auth);
  const [countryFactory, setCountryFactory] = useState(getCountryFactory(user.companyCountry));
  useEffect(() => {
    setCountryFactory(getCountryFactory(user.companyCountry));
  }, [user.companyCountry]);
  return countryFactory;
}
export default useCountryFactory;