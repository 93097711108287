import React from 'react';
import { Form } from 'react-bootstrap';
import { getIn, useFormikContext } from 'formik';

const FormikError = ({ name }) => {
  const { errors, touched } = useFormikContext();
  const error = getIn(errors, name);
  const isTouched = getIn(touched, name);
  return error && typeof error === 'string' && isTouched && <Form.Text className="text-danger">{error}</Form.Text>;
};
export default FormikError;
