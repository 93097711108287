/* eslint-disable react/button-has-type */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faHome, faClipboard, faUser, faCheckCircle } from '@fortawesome/fontawesome-free-solid';

// const themes = {
//   light: {
//     sidebar: {
//       backgroundColor: '#ffffff',
//       color: '#607489',
//     },
//     menu: {
//       menuContent: '#fbfcfd',
//       icon: '#0098e5',
//       hover: {
//         backgroundColor: '#c5e4ff',
//         color: '#44596e',
//       },
//       disabled: {
//         color: '#9fb6cf',
//       },
//     },
//   }
// };

const GcFooter = () => {
  const { pathname } = useLocation();
  const isActive = path => {
    if (path === pathname.replace('/', '')) {
      return 'active';
    }
    return '';
  };

  return (
    <Navbar className="navbar-footer-area" fixed="bottom" bg="dark">
      <Link className={`nav-item flex-sm-fill text-sm-center nav-link ${isActive('dashboard')}`} to="/dashboard">
        <FontAwesomeIcon icon={faHome} />
      </Link>
      <Link className={`nav-item flex-sm-fill text-sm-center nav-link ${isActive('calendar')}`} to="/calendar">
        <FontAwesomeIcon icon={faCalendar} />
      </Link>
      <Link
        className={`nav-item flex-sm-fill text-sm-center nav-link ${isActive('project_mechanic_reviews')}`}
        to="/project_mechanic_reviews"
      >
        <FontAwesomeIcon icon={faClipboard} />
      </Link>
      <Link
        className={`nav-item flex-sm-fill text-sm-center nav-link ${isActive('project_vehicle_reviews')}`}
        to="/project_vehicle_reviews"
      >
        <FontAwesomeIcon icon={faCheckCircle} />
      </Link>
      <Link className={`nav-item flex-sm-fill text-sm-center nav-link ${isActive('profile')}`} to="/profile">
        <FontAwesomeIcon icon={faUser} />
      </Link>
    </Navbar>
  );
};

export default GcFooter;
