import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { BasicTooltip } from '../Tooltips';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

const FormikPassword = ({
  abbr,
  additionalIconLabel,
  error,
  helpText,
  label,
  labelColor,
  margin,
  placeholder,
  size,
  tooltipText,
  touched,
  value,
  ...props
}) => {
  const { setFieldValue = () => null } = useFormikContext() || {};

  const formatInput = () => {
    setFieldValue(props.name, value);
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(formatInput, [value]);

  return (
    <Form.Group className={margin}>
      {label && (
        <>
          {tooltipText ? (
            <BasicTooltip text={tooltipText}>
              <Form.Label className={labelColor || ''}>
                {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
                {additionalIconLabel && additionalIconLabel}
              </Form.Label>
            </BasicTooltip>
          ) : (
            <Form.Label className={labelColor || ''}>
              {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
              {additionalIconLabel && additionalIconLabel}
            </Form.Label>
          )}
        </>
      )}
      <InputGroup>
        <Form.Control
          size={size}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          className={error && touched ? 'is-invalid' : ''}
          autoComplete="new-password"
          value={value}
          {...props}
        />
        <InputGroup.Append>
          <Button variant="outline-primary" onClick={togglePasswordVisibility}>
            {showPassword ? <EyeSlashFill /> : <EyeFill />}
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default FormikPassword;
