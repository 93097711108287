import React from 'react';
import { useField } from 'formik';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Form } from 'react-bootstrap';

const RangeInput = ({ label, abbr, ...props }) => {
  const [field, meta] = useField(props);
  const { error, touched } = meta;

  const handleButtonClick = value => {
    field.onChange({
      target: {
        name: props.name,
        value: value
      }
    });
  };

  return (
    <Form.Group className="d-flex flex-column">
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <ButtonGroup aria-label="Range Input">
        {[...Array(11).keys()].map(value => (
          <button
            key={value}
            type="button"
            className={`${field.value === value ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => handleButtonClick(value)}
            style={{ width: '40px', height: '40px' }}
          >
            {value}
          </button>
        ))}
      </ButtonGroup>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      <Form.Text className="text-muted">0 es "no lo recomendaría por ningún motivo"</Form.Text>
      <Form.Text className="text-muted">10 es "lo recomendaría de todas maneras"</Form.Text>
    </Form.Group>
  );
};

export default RangeInput;
