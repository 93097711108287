import React from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';

const renderSubCategories = subCategoriesAttributes => (
  <Table striped bordered hover>
    <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
      </tr>
    </thead>
    <tbody>
      {subCategoriesAttributes.map(subCategory => (
        <tr key={subCategory.id}>
          <td>{subCategory.id}</td>
          <td>{subCategory.name}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const ProductCategoryShow = props => {
  const { handleModalClose, productCategory } = props;

  return (
    <>
      <Row className="mt-3 mb-4">
        <Col md={4}>
          <h3>{productCategory.name}</h3>
        </Col>
        <hr />
        <Col md={12}>
          <h4 className="text-uppercase">Subcategorías</h4>
          {renderSubCategories(productCategory.subCategoriesAttributes)}
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mb-4">
        {handleModalClose && (
          <Col md={{ span: 3, offset: 6 }}>
            <Button className="btn" variant="primary" block onClick={handleModalClose}>
              Cerrar
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ProductCategoryShow;
