import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Row, Col } from 'react-bootstrap';
import ProductMovementForm from './ProductMovementForm';
import { createProductMovementRequest } from '../../requests/productMovements';
import { sendAlert } from '../../actions/utils';
import GoBackButton from "../../components/GoBackButton";

const basicProductMovement = {
  invoiceNumber: '',
  movementType: '',
  movementDetailsAttributes: [],
  providerId: '',
  totalAmount: ''
};

const ProductMovementNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectCode = history.location ? history.location.search.split('?project_code=')[1] : '';

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Movimiento creado con éxito' }));
    history.push(`/inventory`);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    createProductMovementRequest({
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <Row>
      <Col md={{ span: 10, offset: 1 }} xs={12}>
        <Row className="mt-3">
          <Col className="mb-4">
            <h2 className="text-uppercase">Crear Movimiento</h2>
          </Col>
          <Col md={2}>
            <GoBackButton />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ProductMovementForm
              productMovement={basicProductMovement}
              formRequest={handleCreateRequest}
              projectCode={projectCode}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProductMovementNew;
