import React, {useState} from "react";
import { Button } from 'react-bootstrap';
import {FormikInput} from "../../components";

const ProjectMileage = ({project, formRequest}) => {
  const [mileage, setMileage] = useState(project?.mileage);

  const saveMileage = () => {
    formRequest({mileage});
  }

  return (
    <div>
      <FormikInput
        defaultValue={mileage}
        label="Kilometraje"
        placeholder="Kilometraje"
        inputType="number"
        onChange={e => setMileage(e.target.value)}
      />
      <Button variant="success" className="mt-3" onClick={saveMileage}>Guardar</Button>
    </div>
  );
}

export default ProjectMileage;
