const basicProductMovement = {
  id: '',
  branchOffice: {},
  branchOfficeId: '',
  invoiceNumber: '',
  movementDetailsAttributes: [],
  movementType: '',
  projectId: '',
  projectReference: '',
  provider: {},
  providerId: '',
  totalAmount: '',
  userId: ''
};

export default basicProductMovement;
