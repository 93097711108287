import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { showPurchaseManagementRequest, updatePurchaseManagementRequest } from '../../requests/purchaseManagements';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import PurchaseManagementDetailCard from './Form/PurchaseManagementDetailCard';

const basicPurchaseManagement = {
  id: '',
  branchOffice: {},
  createdAt: '',
  notes: '',
  parsedTotalPrice: '',
  purchaseDate: '',
  purchaseManagementDetailsAttributes: [],
  state: '',
  totalItems: '',
  translatedState: '',
  user: {},
  userId: ''
};

const basicSendParams = { purchaseManagementDetailsAttributes: [] };

const PurchaseManagementShow = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/purchase_managements';

  const [btnCompletedDisabled, setBtnCompletedDisabled] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [originalState, setOriginalState] = useState('');
  const [purchaseManagement, setPurchaseManagement] = useState(basicPurchaseManagement);
  const [sendParams, setSendParams] = useState(basicSendParams);
  const dispatch = useDispatch();
  const history = useHistory();

  const { notes, purchaseDate, purchaseManagementDetailsAttributes, state, translatedState } = purchaseManagement;
  const btnPurchase = state === 'in_process' ? 'Continuar compra' : 'Comenzar compra';
  const pendingPurchase = state !== 'completed';

  const handleVisualStatus = ({ disabled = false, name = 'in_process', translation = 'En Proceso' }) => {
    setPurchaseManagement({ ...purchaseManagement, state: name, translatedState: translation });
    setIsDisabled(disabled);
  };

  const handleRedirect = () => {
    if (isDisabled) history.push(modelUrl);
    else handleVisualStatus({ disabled: true, name: originalState.name, translation: originalState.translation });
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessRequest = response => {
    const { data } = camelCaseEmptyStringRecursive(response);
    setOriginalState({ name: data.state, translation: data.translatedState });
    setPurchaseManagement(data);
  };

  const fetchPurchaseManagement = () => {
    showPurchaseManagementRequest(id, {
      dispatch,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleSubmitBtn = () => {
    const { purchaseManagementDetailsAttributes: purchaseManagementDetails } = sendParams;
    const pending = purchaseManagementDetails.filter(object => object.state === 'pending');
    if (pending.length > 0) setBtnCompletedDisabled(true);
    else setBtnCompletedDisabled(false);
  };

  const handleSuccessUpdate = response => {
    const { data } = camelCaseEmptyStringRecursive(response);
    const vState = data.state === 'in_process' ? 'actualizado' : 'completado';
    dispatch(sendAlert({ kind: 'success', message: `Listado de compra ${vState} con éxito` }));
    setPurchaseManagement(data);
    setIsDisabled(true);
  };

  const handleUpdateParams = vState => {
    const customParams = { purchaseManagement: { id, ...sendParams } };
    if (vState) customParams.purchaseManagement.state = vState;
    return customParams;
  };

  const handleUpdateRequest = (vState = false) => {
    const customParams = handleUpdateParams(vState);
    updatePurchaseManagementRequest(id, {
      dispatch,
      params: snakeCaseKeys(customParams),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const updatePurchaseButton = () => (
    <>
      <Col md={3} lg={2}>
        <Button block variant="primary" onClick={() => handleUpdateRequest('in_process')}>
          Guardar
        </Button>
      </Col>
      <Col md={3} lg={2}>
        <Button
          block
          variant="success"
          onClick={() => handleUpdateRequest('in_process')}
          disabled={btnCompletedDisabled}
        >
          Finalizar compra
        </Button>
      </Col>
    </>
  );

  useEffect(fetchPurchaseManagement, []);
  useEffect(handleSubmitBtn, [sendParams]);

  return (
    <Row className="purchase-management">
      <Col md={{ span: 10, offset: 1 }}>
        <Row className="purchase-management__buttons">
          {pendingPurchase &&
            (isDisabled ? (
              <Col md={3} lg={2}>
                <Button block variant="primary" onClick={handleVisualStatus}>
                  {btnPurchase}
                </Button>
              </Col>
            ) : (
              updatePurchaseButton()
            ))}
          <Col md={2}>
            <Button block className="cancel" variant="light-darker" onClick={handleRedirect}>
              Volver
            </Button>
          </Col>
        </Row>
        <Row className="box-info mt-2">
          <Col xs={12}>
            <h3>ID {id}</h3>
          </Col>
          <Col xs={12}>
            <p>
              <span className="box-title">Estado:</span> {translatedState}
            </p>
          </Col>
          {purchaseDate && (
            <Col xs={12}>
              <p>
                <span className="box-title">Fecha:</span> {purchaseDate}
              </p>
            </Col>
          )}
          {notes && (
            <Col xs={12}>
              <p>
                <span className="box-title">Notas:</span> {notes}
              </p>
            </Col>
          )}
        </Row>
        <Row className="purchase-management__cards">
          {purchaseManagementDetailsAttributes.map((purchaseManagementDetail, index) => (
            <PurchaseManagementDetailCard
              key={`purchase-management-detail-card-${index.toString()}`}
              index={index}
              purchaseManagementDetail={purchaseManagementDetail}
              sendParams={sendParams}
              setSendParams={setSendParams}
              isDisabled={isDisabled}
            />
          ))}
        </Row>
        {pendingPurchase && !isDisabled && <Row className="purchase-management__buttons">{updatePurchaseButton()}</Row>}
      </Col>
    </Row>
  );
};

export default PurchaseManagementShow;
