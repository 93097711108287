import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Footerlogged = () => {
  const {
    user: { companyAttributes }
  } = useSelector(state => state.auth);
  if (!companyAttributes) return null;
  return (
    <footer className="footer justify-content-between px-5">
      <div className="white-footer">
        <Container>
          <Row className="max-width-2000">
            <Col lg={3} className="footer-item center-container mb-2">
              <a href="https://gestioncar.cl/" rel="noopener noreferrer" target="_blank">
                <div className="logo-container main" />
              </a>
            </Col>

            <Col lg={3}>
              <Link className="btn-link-arrow" to="/contact">
                Contáctanos
              </Link>
            </Col>

            <Col lg={3}>
              <p>{companyAttributes.email}</p>
            </Col>

            <Col lg={3} className="d-flex">
              <a href="https://gestioncar.cl/" rel="noopener noreferrer" target="_blank">
                <div className="logo-container secondary">
                  <p className="copyright" />
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footerlogged;
