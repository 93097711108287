import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';

import { sendAlert } from '../../actions/utils';
import downloadFile from '../../services/utils';
import { indexPurchaseManagementsRequest } from '../../requests/purchaseManagements';
import columns from './Columns';
import PurchaseManagementDataTable from './PurchaseManagementDataTable';

const PurchaseManagementAdminIndex = () => {
  const [queries, setQueries] = useState({});
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    indexPurchaseManagementsRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <Row className="nn-purchase-management">
      <Col md={11} className="mx-auto" xs={12}>
        <Row className="mt-4">
          <Col md={10}>
            <h2 className="text-uppercase mb-4">Gestión de Compra</h2>
          </Col>
          <Col md={2}>
            <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
              Exportar
            </Button>
          </Col>
        </Row>
        <PurchaseManagementDataTable fromAdmin columns={columns} setQueries={setQueries} />
      </Col>
    </Row>
  );
};

export default PurchaseManagementAdminIndex;
