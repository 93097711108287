import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Col, Row, Button } from 'react-bootstrap';

import { Edit } from 'react-feather';
import snakecaseKeys from 'snakecase-keys';
import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal } from '../../../components';
import { showProjectRequest, updateProjectRequest } from '../../../requests/projects';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import basicProject from '../Show/basicProject';
import ProjectImages from '../ProjectImages';
import ProjectNavbarInfo from '../ProjectNavbarInfo';
import ProjectProgressBars from '../ProjectProgressBars';
import ProjectLinkButtons from '../ProjectLinkButtons';
import ProjectVehicle from '../ProjectVehicle';
import ProjectWorkEdit from '../../ProjectWork/ProjectWorkEdit';
import ProjectIncidents from '../ProjectIncidents';
import ProjectShowForm from '../Form/ProjectShowForm';
import getImage from '../../../utils/getImage';
import ProjectComments from '../ProjectComments';
import ProjectTitle from '../ProjectTitle';

const ProjectMechanicReviewEdit = ({ match }) => {
  const [moreData, setMoreData] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [project, setProject] = useState(basicProject);
  const { isQuote, orderType } = project;
  const dispatch = useDispatch();
  const { id } = match.params;

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Orden de trabajo actualizada con éxito' }));
    setMoreData(!moreData);
    setModalShow(false);
    setModalBody('');
    setModalTitle('');
  };

  const handleUpdateRequest = values => {
    updateProjectRequest(id, {
      dispatch,
      params: snakecaseKeys(values),
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessShow = response => {
    setProject(camelCaseEmptyStringRecursive(response.data));
  };

  const fetchProject = () => {
    showProjectRequest(id, {
      dispatch,
      params: { screen: 'ProjectMechanicReviewEdit' },
      successCallback: handleSuccessShow,
      failureCallback: handleFailureRequest
    });
  };

  const editProjectMechanicReviewShow = from => {
    const titles = { ProjectRemarks: 'Editar Observaciones' };
    const title = titles[from] ? titles[from] : 'Editar Orden de Trabajo';
    const body = (
      <ProjectShowForm
        from={from}
        project={project}
        action="edit"
        submitVariant="success"
        formRequest={handleUpdateRequest}
      />
    );
    setModalTitle(title);
    setModalBody(body);
    setModalShow(true);
  };

  useEffect(fetchProject, [moreData]);

  const images = projectReviewType => {
    return project.projectReviewsAttributes
      .filter(projectReview => projectReview.reviewType === projectReviewType)
      .map(projectReview => {
        return projectReview.imagesInfo.map(image => {
          return {
            original: image.fileUrl,
            thumbnail: getImage(image.key, 200, 200, 'inside')
          };
        });
      })
      .flat();
  };

  return (
    <Container>
      <ProjectTitle isQuote={isQuote} orderType={orderType} id={id} />
      <ProjectLinkButtons id={id} fromMechanicReviewEdit />
      <ProjectNavbarInfo fromMechanicCard project={project} />
      <Row className="mb-3">
        <Col className="project-section mr-2 pt-3">
          <ProjectVehicle fromProjectEdit project={project} />
        </Col>
        <Col className="project-section ml-2 pt-3">
          <Row>
            <Col md={12}>
              <strong>Motivo / Notas</strong>
            </Col>
            <Col md={12} className="pt-2">
              <pre>{project.notes}</pre>
            </Col>
            <Col>{id && <ProjectComments projectCode={id} />}</Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="project-section mr-2 pt-3">
          <ProjectProgressBars fromMechanicCard project={project} id={id} setMoreData={setMoreData} />
        </Col>
        <Col className="project-section ml-2 pt-3">
          <Row>
            <Col md={12}>
              <strong>Imágenes</strong>
            </Col>
            {project.quoteId && (
              <Col className="gallery-small-box">
                <ProjectImages images={images('quote')} title="Fotos Presupuesto" />
              </Col>
            )}
            {project.orderType === 'reception_secure' && (
              <Col className="gallery-small-box">
                <ProjectImages images={images('pre_inspection')} title="Checklist de Pre Inspección" />
              </Col>
            )}
            <Col className="gallery-small-box">
              <ProjectImages images={images('reception')} title="Checklist recepción" />
            </Col>
            <Col className="gallery-small-box">
              <ProjectImages images={images('mechanic')} title="Checklist mecánico" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="project-section mr-2 pt-3">
          <ProjectIncidents fromMechanicCard project={project} id={id} setMoreData={setMoreData} />
        </Col>
        <Col className="project-section ml-2 pt-3">
          <Row>
            <Col md={10}>
              <strong>Observaciones</strong>
            </Col>
            <Col md={2}>
              <Button
                variant="link"
                className="btn-sm text-dark float-right"
                onClick={() => editProjectMechanicReviewShow('ProjectRemarks')}
              >
                <Edit width="15px" height="15px" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="pt-2" style={{ whiteSpace: 'pre-line' }}>
              <p>{project.remarks}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-2 bg-light pr-4 pl-4 pt-2">
        <Col>
          <ProjectWorkEdit fromMechanicCard project={project} setMoreData={setMoreData} isDragDisabled />
        </Col>
      </Row>
      <SimpleCenteredModal
        size="lg"
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Container>
  );
};

export default ProjectMechanicReviewEdit;
