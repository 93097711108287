/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { Link } from 'react-router-dom';
import { BasicActionBtns } from '../../components';
import { shortText } from '../../services/utils';

const columns = memoize(clickHandler => [
  {
    name: 'FECHA DE HORA DE TRABAJO',
    grow: '2',
    selector: 'date',
    sortable: true
  },
  {
    name: 'CÓDIGO PROYECTO',
    selector: 'project',
    cell: item => <Link to={`/projects/${item.project.code}`}>{item?.project.code}</Link>,
    sortable: true,
    grow: '1'
  },
  {
    name: 'USUARIO ASOCIADO',
    selector: 'accountable_user',
    cell: item => item.accountable_user?.full_name,
    sortable: true,
    grow: '2'
  },
  {
    name: 'NOMBRE DEL TRABAJO',
    selector: 'project_work_name',
    cell: ({ project_work_name }) => project_work_name,
    sortable: true,
    grow: '2'
  },
  {
    name: 'HORAS REGISTRADAS',
    selector: 'hours',
    cell: item => item.time,
    sortable: true,
    grow: '1'
  },
  {
    name: 'HORAS APROBADA',
    selector: 'approved',
    cell: item => item.approved_time,
    sortable: true,
    grow: '1'
  },
  {
    name: 'DESCRIPCIÓN',
    selector: 'details',
    cell: item => shortText(item.details, 50),
    sortable: false,
    grow: '4'
  },
  {
    cell: item => <BasicActionBtns item={item} clickHandler={clickHandler} withShow={false} editClick />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '140px'
  }
]);

export default columns;
