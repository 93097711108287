import React from 'react';

const ContactsShow = ({ contactsAttributes }) => {
  const mapContacts = contactsAttributes.map((contact, index) => {
    return (
      <li className="mb-4" key={`client-contact-${index.toString()}`}>
        <div className="d-flex flex-direction-column mb-2">
          <strong>Nombre:</strong>
          {contact.name}
        </div>
        <div className="d-flex flex-direction-column mb-2">
          <strong>Email:</strong> {contact.email}
        </div>
        <div className="d-flex flex-direction-column mb-2">
          <strong>Celular:</strong> {contact.cellphone}
        </div>
      </li>
    );
  });

  return (
    <>
      <hr />
      <strong className="text-uppercase mb-3">Contactos</strong>
      <ul>{mapContacts}</ul>
    </>
  );
};

export default ContactsShow;
