import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import { FormikInput } from '../../components';

const UserChangePasswordForm = props => {
  const { errors, onHide, touched } = props;

  return (
    <Form>
      <>
        <Row>
          <Col md={6} className="mx-auto">
            <Field name="user[currentPassword]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Contraseña Actual"
                  inputType="password"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mx-auto">
            <Field name="user[password]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Contraseña Nueva"
                  inputType="password"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mx-auto">
            <Field name="user[passwordConfirmation]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Confirmar Contraseña Nueva"
                  inputType="password"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </>
      <Row className="d-flex justify-content-end mb-4">
        <Col md={2} className="mx-auto mt-4">
          <Button type="submit" variant="success" block onClick={onHide}>
            Cambiar Contraseña
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    currentPassword: Yup.string().required('Debes ingresar tu contraseña actual'),
    password: Yup.string()
      .min(6, 'La contraseña debe tener más de 6 caracteres')
      .required('Debes ingresar contraseña nueva'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no son iguales')
      .required('Debes ingresar la confirmación de tu nueva contraseña')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(UserChangePasswordForm);
