import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';

import { debounceIndexVehicleBrandsRequest } from '../../requests/vehicleBrands';
import { debounceIndexVehicleModelsRequest, indexVehicleModelsRequest } from '../../requests/vehicleModels';
import { InputRemoteSelect } from '../Utils/Select';

const BrandModelSelect = props => {
  const {
    currentModel,
    errors,
    modelKey,
    setFieldValue,
    touched,
    values,
    vehicleBrandAbbr,
    vehicleForm,
    vehicleModelAbbr
  } = props;
  const [vehicleModelDisabled, setVehicleModelDisabled] = useState(!currentModel.vehicleModelId);
  const [vehicleModels, setVehicleModels] = useState([]);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const currentValue = getIn(values, modelKey);
  const currentVehicleBrandId = currentValue.vehicleBrandId;

  const resultFetchData = response => {
    return response.data.data;
  };

  const fetchInitialVehicleBrands = useCallback(
    params => {
      indexVehicleModelsRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          sort_column: 'name',
          sort_direction: 'asc',
          display_length: 1000
        },
        successCallback: data => setVehicleModels(resultFetchData(data))
      });
      setVehicleModelDisabled(false);
    },
    [stableDispatch]
  );

  useEffect(() => {
    if (currentVehicleBrandId) {
      fetchInitialVehicleBrands({ vehicleBrandId: currentVehicleBrandId });
    }
  }, [currentVehicleBrandId, fetchInitialVehicleBrands]);

  const handleVehicleBrandChange = data => {
    if (data !== '') {
      setFieldValue(`${modelKey}[vehicleBrandId]`, data.value);
      setFieldValue(`${modelKey}[vehicleBrandName]`, data.label);
    } else {
      setFieldValue(`${modelKey}[vehicleBrandId]`, data);
    }
    setFieldValue(`${modelKey}[vehicleModelId]`, '');
    setFieldValue(`${modelKey}[vehicleModelName]`, '');
    if (data !== '') {
      fetchInitialVehicleBrands({ vehicleBrandId: data.value });
    } else {
      setVehicleModelDisabled(true);
    }
  };

  const initialVehicleBrandValue = () => {
    const currentValues = getIn(values, modelKey);
    if (vehicleForm && currentValues.vehicleBrandId !== '') {
      return { label: currentValues.vehicleBrandName, value: currentValues.vehicleBrandId };
    }
    if (
      currentValues.vehicleBrandId &&
      currentModel.vehicleBrandId &&
      currentValues.vehicleBrandId === currentModel.vehicleBrandId
    ) {
      return { label: currentModel.vehicleBrandName, value: currentModel.vehicleBrandId };
    }
    return undefined;
  };

  const initialVehicleModelValue = () => {
    const currentValues = getIn(values, modelKey);
    if (currentValues.vehicleModelId !== '') {
      return { label: currentValues.vehicleModelName, value: currentValues.vehicleModelId };
    }
    return false;
  };

  const fetchVehicleBrands = (inputValue, callback) => {
    debounceIndexVehicleBrandsRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 50
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const fetchVehicleModels = (inputValue, callback) => {
    const { vehicleBrandId } = getIn(values, modelKey);
    debounceIndexVehicleModelsRequest({
      dispatch,
      params: {
        name: inputValue,
        vehicle_brand_id: vehicleBrandId,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 50
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  return (
    <>
      <Col md={12}>
        <Field name={`${modelKey}[vehicleBrandId]`}>
          {({ field }) => (
            <InputRemoteSelect
              {...field}
              abbr={vehicleBrandAbbr}
              isClearable={!vehicleBrandAbbr}
              label="Marca"
              placeholder="Seleccionar Marca"
              value={initialVehicleBrandValue()}
              onChange={data => handleVehicleBrandChange(data || '')}
              request={fetchVehicleBrands}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <Field name={`${modelKey}[vehicleModelId]`}>
          {({ field }) => (
            <InputRemoteSelect
              {...field}
              abbr={vehicleModelAbbr}
              isClearable={!vehicleModelAbbr}
              label="Modelo"
              placeholder="Seleccionar Modelo"
              isDisabled={vehicleModelDisabled}
              value={initialVehicleModelValue()}
              onChange={data => {
                setFieldValue(field.name, data ? data.value : '');
                setFieldValue(`${modelKey}[vehicleModelName]`, data ? data.label : '');
              }}
              defaultOptions={vehicleModels}
              request={fetchVehicleModels}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default BrandModelSelect;
