import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import CarFaqsimg from '../../../assets/images/car-faqs.webp';
import AccordionFaqs from './AccordionFaqs';

const FaqsSection = () => {
  return (
    <Fade left>
      <div id="Faqs" className="max-width-2000">
        <Row className="mb-5 m-sm-0 faqs-xl">
          <Col lg={5} className="d-none d-lg-block d-xxl-none pl-0">
            <img
              alt="imagen publicitaria de auto"
              title="imagen publicitaria de auto"
              src={CarFaqsimg}
              width="80%"
              height="auto"
            />
          </Col>

          <Col lg={7} md={12}>
            <p className="mb-0">FAQ’S</p>
            <h3>Preguntas frecuentes</h3>
            <AccordionFaqs />
          </Col>
        </Row>
      </div>
    </Fade>
  );
};

export default FaqsSection;
