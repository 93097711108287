import { defineAbility, AbilityBuilder } from '@casl/ability';
import jwt from 'jwt-decode';
import camelCaseRecursive from 'camelcase-keys-recursive';

import inventoryAbility from './Role/inventoryAbility';
import mechanicAbility from './Role/mechanicAbility';
import purchaseManagementAbility from './Role/purchaseManagementAbility';
import schedulerAbility from './Role/schedulerAbility';
import workshopManagerAbility from './Role/workshopManagerAbility';

export const ROLE_IDS = {
  admin: 1,
  mechanic: 4
};

const adminAbility = can => {
  can('manage', 'all');
};

const nonCompanyAbility = (can, cannot) => {
  can('read', 'something');
  cannot('manage', 'app');
};

const setUpAbility = (can, cannot) => {
  const payload = localStorage.getItem('jwt');
  if (payload) {
    const { roles, companyId } = camelCaseRecursive(jwt(payload));
    if (!companyId) {
      nonCompanyAbility(can, cannot);
    } else {
      if (roles.indexOf('scheduler') !== -1) {
        schedulerAbility(can, cannot);
      }
      if (roles.indexOf('purchase_management') !== -1) {
        purchaseManagementAbility(can, cannot);
      }
      if (roles.indexOf('mechanic') !== -1) {
        mechanicAbility(can, cannot);
      }
      if (roles.indexOf('inventory') !== -1) {
        inventoryAbility(can, cannot);
      }
      if (roles.indexOf('workshop_manager') !== -1) {
        workshopManagerAbility(can, cannot);
      }
      if (roles.indexOf('admin') !== -1) {
        adminAbility(can, cannot);
      }
    }
  }
};

export default defineAbility((can, cannot) => setUpAbility(can, cannot));

export const updateAbility = ability => {
  const { can, cannot, rules } = new AbilityBuilder();
  setUpAbility(can, cannot);
  ability.update(rules);
};
