import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import IncidentDataTable from './IncidentDataTable';
import columns from './Columns';

const IncidentIndex = () => {
  const [moreData, setMoreData] = useState(false);

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">INCIDENCIAS</h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <IncidentDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default IncidentIndex;
