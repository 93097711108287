import React, { Component } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import ProviderForm from './ProviderForm';
import { showProviderRequest, updateProviderRequest } from '../../requests/providers';
import { sendAlert } from '../../actions/utils';
import GoBackButton from "../../components/GoBackButton";

class ProviderEdit extends Component {
  state = {
    provider: {
      id: '',
      active: true,
      address: '',
      businessActivity: '',
      cellphone: '',
      communeId: '',
      contactsAttributes: [],
      email: '',
      nationalIdentification: '',
      legalName: '',
      name: '',
      notes: '',
      phone: '',
      regionId: '',
      website: ''
    }
  };

  componentDidMount() {
    const { match, dispatch } = this.props;
    const providerId = match.params.id;
    showProviderRequest(providerId, {
      dispatch,
      successCallback: response => this.handleSuccessShow(response)
    });
  }

  handleSuccessShow = response => {
    const provider = camelCaseRecursive(response.data);
    this.setState({ provider });
  };

  handleUpdateRequest = provider => {
    const { dispatch } = this.props;
    const providerId = provider.provider.id;

    updateProviderRequest(providerId, {
      dispatch,
      params: snakeCaseKeys(provider, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: response => this.handleSuccessUpdate(response),
      failureCallback: this.handleFailureRequest
    });
  };

  handleSuccessUpdate = response => {
    const { dispatch, history } = this.props;
    const provider = response.data;

    dispatch(sendAlert({ kind: 'success', message: 'Proveedor actualizado correctamente' }));
    history.push(`/providers/${provider.id}`);
  };

  handleFailureRequest = error => {
    const { dispatch } = this.props;
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  render() {
    const { provider } = this.state;
    const { dispatch, user } = this.props;

    return (
      <>
        <Row className="mt-3">
          <Col>
            <h1>Editar Proveedor</h1>
          </Col>
          <Col md={2}>
            <GoBackButton />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            {provider.id !== '' ? (
              <ProviderForm
                dispatch={() => dispatch}
                provider={camelCaseRecursive(provider)}
                action="edit"
                formRequest={this.handleUpdateRequest}
                country={user.companyCountry}
              />
            ) : (
              <Spinner animation="border" variant="primary" />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ProviderEdit);
