import ApiService from '../services/apiService';

export const createDropZoneFileRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/drop_zone_files', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const deleteDropZoneFileRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/drop_zone_files/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
