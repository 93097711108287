import React, { useState } from 'react';
import { Field, getIn } from "formik";
import { BasicTextArea, FormikDatePicker, FormikInput } from "../Utils/Input";

const CalendarEventAdditionalInfoTab = ({currentValues, setFieldValue}) => {
  const [deliveryDate, setDeliveryDate] = useState('');
  return (
    <>
      {currentValues.orderType === 'reception_secure' && (
        <>
          <Field name="calendarEvent[sinisterNumber]">
            {({ field, form }) => (
              <FormikInput
                {...field}
                abbr
                label="Número de Siniestro"
                error={getIn(form.errors, field.name)}
                touched={getIn(form.touched, field.name)}
              />
            )}
          </Field>
          <Field name="calendarEvent[ocNumber]">
            {({ field, form }) => (
              <FormikInput
                {...field}
                label="Número OC"
                error={getIn(form.errors, field.name)}
                touched={getIn(form.touched, field.name)}
              />
            )}
          </Field>
          <Field name="calendarEvent[deliveryDate]">
            {({ field, form }) => (
              <FormikDatePicker
                {...field}
                label="Fecha de Entrega"
                selected={deliveryDate}
                onChange={newDate => {
                  setDeliveryDate(newDate);
                  setFieldValue(field.name, newDate);
                }}
                error={getIn(form.errors, field.name)}
                touched={getIn(form.touched, field.name)}
              />
            )}
          </Field>
          <Field name="calendarEvent[orderNumber]">
            {({ field, form }) => (
              <FormikInput
                {...field}
                label="Número Pedido"
                error={getIn(form.errors, field.name)}
                touched={getIn(form.touched, field.name)}
              />
            )}
          </Field>
        </>
      )}
      <Field name="calendarEvent[notes]">
        {({ field, form }) => (
          <BasicTextArea
            {...field}
            row="6"
            label="Motivo / Notas"
            error={getIn(form.errors, field.name)}
            touched={getIn(form.touched, field.name)}
          />
        )}
      </Field>
    </>
  );
}
export default CalendarEventAdditionalInfoTab;