import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';

import ComponentDataTable from '../../components/Utils/DataTable';
import { indexProjectsPaymentsRequest, debounceIndexProjectsPaymentsRequest } from '../../requests/projectPayments';
import { sendAlert } from '../../actions/utils';
import downloadFile from '../../services/utils';

const ProjectPaymentDataTable = ({ columns, moreData, setMoreData }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [projectPayments, setProjectPayments] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [queries, setQueries] = useState({});

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseProjectPayments = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setProjectPayments(responseProjectPayments);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexProjectsPaymentsRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'project_code':
        return { sort_column: 'project_id' };
      default:
        return { sort_column: name };
    }
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    indexProjectsPaymentsRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        handleSortCase={sortColumnCase}
        data={projectPayments}
        totalRows={amount}
        moreData={moreData}
        withDate
        resourceRequest={response => {
          setQueries(response);
          if (!onRequest) handleIndexRequest(response);
        }}
      />
    </>
  );
};

export default ProjectPaymentDataTable;
