import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import camelCaseRecursive from "camelcase-keys-recursive";
import { format, startOfMonth } from "date-fns";

import ComponentDataTable from '../../../components/Utils/DataTable';
import { indexProjectsInvoicesRequest, debounceindexProjectsInvoicesRequest } from '../../../requests/projectInvoices';
import { sendAlert } from '../../../actions/utils';
import downloadFile from '../../../services/utils';
import {FormikSelect, InputRemoteSelect} from "../../../components";
import { debounceIndexClientsRequest } from "../../../requests/clients";
import documentsType from "../ProjectInvoice/Constants";

const CollectionDataTable = ({ columns, moreData, setMoreData }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [projectInvoices, setProjectInvoices] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [queries, setQueries] = useState({});
  const [clientId, setClientId] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [clients, setClients] = useState([]);
  const [metadata, setMetadata] = useState({});

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseProjectInvoices = response.data.data;
    setMetadata(response.data.metadata);
    const { amount: responseAmount } = response.data.metadata;
    setProjectInvoices(responseProjectInvoices);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      client_id: clientId,
      only_collections: true,
      document_type: documentType,
      sort_project: 'code'
    }
    debounceindexProjectsInvoicesRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'project_code':
        return { sort_column: 'project_id' };
      case 'invoice_date':
        return { sort_column: 'created_at' };
      case 'parsed_amount':
        return { sort_column: 'amount' };
      case 'client_full_name':
        return { sort_by_client: name };
      default:
        return { sort_column: name };
    }
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    indexProjectsInvoicesRequest({
      dispatch,
      params: { ...params, only_collections: true, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const fetchClients = (inputValue, callback) => {
    debounceIndexClientsRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.full_name,
        value: element.id
      };
    });
    setClients(camelCaseRecursive(tempArray));
    return tempArray;
  };

  const currentDate = new Date();

  return (
    <>
      <Row>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Monto Neto:</div>
          <div>{metadata.sum_net_amount}</div>
        </Col>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Monto Total:</div>
          <div>{metadata.sum_total_amount}</div>
        </Col>
      </Row>
      <Row>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Total Abonado:</div>
          <div>{metadata.sum_payments_amount}</div>
        </Col>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Deuda Total:</div>
          <div>{metadata.sum_unpaid_amount}</div>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        handleSortCase={sortColumnCase}
        data={projectInvoices}
        totalRows={amount}
        moreData={moreData}
        startDate={format(startOfMonth(currentDate),'yyyy-MM-dd')}
        endDate={format(currentDate, 'yyyy-MM-dd')}
        withDate
        resourceRequest={response => {
          setQueries({
            ...response,
            client_id: clientId,
            document_type: documentType
          });
          if (!onRequest) handleIndexRequest(response);
        }}
        firstCustomMediumSearch={
          <InputRemoteSelect
            isClearable
            placeholder="Cliente"
            defaultOptions={clients}
            value={clients.find(client => client.id === parseInt(clientId, 10))}
            onChange={data => {
              setClientId(data ? data.value : '');
              setMoreData(!moreData);
            }}
            request={fetchClients}
          />
        }
        secondCustomMediumSearch={
          <FormikSelect
            isClearable
            options={documentsType}
            placeholder="Tipo de Documento"
            onChange={data => {
              setDocumentType(data ? data.value : '');
              setMoreData(!moreData);
            }}
          />
        }
      />
    </>
  );
};

export default CollectionDataTable;
