import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexFAQsRequest = ({ dispatch, params = {}, successCallback, failureCallback }) => {
  ApiService.request('get', '/faqs', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexFAQsRequest = AwesomeDebouncePromise(indexFAQsRequest, 300);
