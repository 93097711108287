import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import { Repeat } from 'react-feather';

import { sendAlert } from '../../actions/utils';
import { DefaultModal, ButtonTooltip, InputRemoteSelect } from '../../components';
import { debounceIndexClientsRequest } from '../../requests/clients';
import { updateVehicleOwnerRequest } from '../../requests/vehicles';

const EditOwnerButton = ({ item, setMoreData }) => {
  const { id: vehicleId, client_id: originalOwnerId } = item;
  const [sameOwnerError, setSameOwnerError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [currentClientId, setCurrentClientId] = useState('');
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.full_name,
        value: element.id
      };
    });
    return tempArray;
  };

  const fetchClients = (inputValue, callback) => {
    debounceIndexClientsRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const handleClick = () => {
    setModalShow(true);
    setModalBody(
      <>
        <InputRemoteSelect
          abbr
          label="Nuevo Dueño"
          placeholder="Seleccionar Nuevo Dueño"
          onChange={data => {
            if (parseInt(data.id, 10) === parseInt(originalOwnerId, 10)) {
              setSameOwnerError(true);
            } else {
              setSameOwnerError(false);
            }
            if (data) setCurrentClientId(data.id);
          }}
          request={fetchClients}
        />
      </>
    );
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Vehículo actualizado con éxito' }));
    handleModalClose();
    setMoreData(m => !m);
  };

  const handleModalConfirm = () => {
    if (sameOwnerError) {
      setModalBody(
        <>
          <Alert className="text-center" variant="warning">
            <p>Nuevo dueño debe ser distinto al original</p>
          </Alert>
          <Button className="d-block mx-auto" onClick={handleClick}>
            Volver
          </Button>
        </>
      );
    } else {
      updateVehicleOwnerRequest(vehicleId, {
        dispatch,
        params: {
          client_id: currentClientId,
          former_owner_id: originalOwnerId
        },
        formData: true,
        successCallback: handleSuccessUpdate,
        failureCallback: handleFailureRequest
      });
    }
  };

  return (
    <>
      <ButtonTooltip
        onClick={handleClick}
        variant="outline-success"
        className="btn-circle table-actions-btn"
        size="sm"
        text="Cambiar dueño"
      >
        <Repeat width="15px" height="15px" />
      </ButtonTooltip>
      <DefaultModal
        title="Cambiar Dueño Vehículo"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default EditOwnerButton;
