import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const getHistoricVehicleRequest = (plate, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/historic_vehicles/${plate}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceGetHistoricVehicleRequest = AwesomeDebouncePromise(getHistoricVehicleRequest, 300);
