import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexSuccessfulCasesRequest = ({ dispatch, params = {}, successCallback, failureCallback }) => {
  ApiService.request('get', '/successful_cases', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexSuccessfulCasesRequest = AwesomeDebouncePromise(indexSuccessfulCasesRequest, 300);
