import React from 'react';
import { Row, Col } from 'react-bootstrap';

import columns from './Columns';
import ChecklistDataTable from './ChecklistDataTable';
import { Can } from '../../config/abilityContext';
import { LinkBtn } from '../../components';

const ChecklistIndex = () => {
  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">Checklists</h2>
            </Col>
            <Can I="create" a="User">
              <Col md={2}>
                <LinkBtn variant="primary" block to="/checklists/new">
                  Crear
                </LinkBtn>
              </Col>
            </Can>
          </Row>
          <ChecklistDataTable columns={columns} />
        </Col>
      </Row>
    </>
  );
};

export default ChecklistIndex;
