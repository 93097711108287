import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { SimpleCenteredModal } from '../../components/Utils/Modal';

const ProjectImages = ({ images, title, edit }) => {
  const img = images[0]?.original;
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalTitle('');
    setModalBody('');
    setModalShow(false);
  };

  const handleImgClick = () => {
    if (images.length && !modalShow) {
      setModalBody(<ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} lazyLoad />);
      setModalShow(true);
      setModalTitle(title.toUpperCase());
    }
  };

  return (
    <Row className={img && 'clickable'} onClick={handleImgClick}>
      <Col md={12} className={edit ? 'mt-3' : ''}>
        <strong className="gallery-small-title" style={{ color: edit ? '#5e99b8' : '' }} >
          {title} ({images.length})
        </strong>
      </Col>
      {!edit && (img ? (
        <Col>
          <img style={{ maxWidth: '65%' }} alt="" src={img} />
        </Col>
      ) : (
        <Col>
          <p>No hay imágenes para mostrar</p>
        </Col>
      ))}
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
    </Row>
  );
};

export default ProjectImages;
