import { animateScroll } from 'react-scroll';

const responseToBlob = response => {
  const format = response.headers['content-type'].split('/')[1];
  let blob = '';
  const { data } = response;
  if (format === 'xlsx') {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i !== byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  } else if (format === 'csv') {
    const readUTF8 = data.toString('utf8');
    blob = new Blob([readUTF8], { type: 'application/vnd.ms-excel' });
  } else {
    blob = new Blob([data], { type: response.headers['content-type'] });
  }
  return blob;
};

const parseFilename = headers => {
  let filename = '';
  const disposition = headers['content-disposition'];
  if (disposition && (disposition.indexOf('attachment') !== -1 || disposition.indexOf('inline') !== -1)) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

const downloadFile = response => {
  const blob = responseToBlob(response);
  const filename = parseFilename(response.headers);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${filename}`;
  link.click();
  window.URL.revokeObjectURL(url);
};

const insertString = (value, position, str) => {
  return value.slice(0, position) + str + value.slice(position);
};

const alphaNumericCapitalFormat = value => {
  const re = /^[0-9a-zA-Z]{0,}$/;
  if (re.test(value)) {
    return value.toUpperCase().trim();
  }
  return value
    .substr(0, value.length - 1)
    .toUpperCase()
    .trim();
};

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(?=.{1,64}@)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const emptyStringRecursive = obj => {
  if (typeof obj !== 'object') {
    return obj;
  }
  const newObj = obj;
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(subObj => emptyStringRecursive(subObj));
    } else if (value === null) {
      newObj[key] = '';
    }
  });
  return newObj;
};

const isObject = v => typeof v === 'object';

const camelCase = str => str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());

const camelCaseEmptyStringRecursive = obj => {
  if (obj === null) return '';
  if (Array.isArray(obj)) {
    return obj.map(value => camelCaseEmptyStringRecursive(value));
  }
  if (isObject(obj)) {
    const newObject = {};
    Object.entries(obj).forEach(([key, value]) => {
      newObject[camelCase(key)] = camelCaseEmptyStringRecursive(value);
    });
    return newObject;
  }
  return obj;
};

const unitSanitazer = obj => {
  const newObj = obj;
  if (typeof newObj.translated_unit === 'object') {
    newObj.translated_unit = '';
  }
  return newObj;
};

const isMobile = () => {
  let check = false;
  (a => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      ) || window.innerWidth <= 768
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const groupBy = (array, attribute, optionalAttribute = null) => {
  return array.reduce((acc, current) => {
    const { [`${attribute}`]: key, [`${optionalAttribute}`]: optionalKey } = current;
    const vKey = key || optionalKey;
    if (acc.includes(acc.find(obj => Object.keys(obj).includes(vKey)))) {
      acc.find(obj => Object.keys(obj).includes(vKey) && obj[`${vKey}`].push(current));
    } else {
      acc.push({ [vKey]: [current] });
    }
    return acc;
  }, []);
};

const formatToPrice = (amount, currency = '$') => {
  if (!amount) return `${currency}0`;
  return `${currency}${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
};

const toInteger = number => parseInt(number, 0);

const toDecimal = (number, digits = 2) => {
  const formatter = new Intl.NumberFormat('nl-SR', {
    style: 'decimal',
    maximumFractionDigits: digits
  });
  return formatter.format(number);
};

const shortText = (string, maxLenght) => {
  return string.length > maxLenght ? `${string.substring(0, maxLenght)}...` : string;
};

const numberRange = (start, end) => new Array(end - start + 1).fill().map((d, i) => i + start);

const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0);

const parseQuery = queryString => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

const scrollTop = () => {
  animateScroll.scrollToTop({ duration: 1000, delay: 50 });
};

const textLowerCase = str => str.toLowerCase();

const textUpperCase = str => str.toUpperCase();

const textCapitalize = str => {
  const lower = textLowerCase(str);
  return textUpperCase(lower.charAt(0)) + lower.slice(1);
};

const copyObject = (object, keys) => {
  const newObject = {};
  keys.forEach(element => {
    let originalKey = element;
    let currentKey = element;
    if (isObject(element)) {
      Object.entries(element).forEach(([key, value]) => {
        originalKey = key;
        currentKey = value;
      });
    }
    if (originalKey === 'self') newObject[currentKey] = object;
    else newObject[currentKey] = object[originalKey];
  });
  return camelCaseEmptyStringRecursive(newObject);
};

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

export {
  alphaNumericCapitalFormat,
  camelCaseEmptyStringRecursive,
  capitalizeFirstLetter,
  copyObject,
  emailRegex,
  emptyStringRecursive,
  formatToPrice,
  groupBy,
  isMobile,
  isObject,
  numberRange,
  parseQuery,
  scrollTop,
  shortText,
  sumValues,
  textCapitalize,
  textLowerCase,
  textUpperCase,
  toDecimal,
  toInteger,
  unitSanitazer,
  insertString
};

export default downloadFile;
