/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../../components';
import { shortText } from '../../../services/utils';

const columns = memoize(clickHandler => [
  { name: 'ID', selector: 'id', sortable: true, grow: '1' },
  {
    name: 'FECHA DEL CREACIÓN',
    grow: '2',
    selector: 'created_at',
    sortable: true
  },
  {
    name: 'CÓDIGO PROYECTO',
    selector: 'project_code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO DE INCIDENCIA',
    selector: 'incident_type',
    cell: item => item.incident_type?.label,
    sortable: true,
    grow: '2'
  },
  {
    name: 'REPORTADA POR',
    selector: 'reported_by',
    cell: item => item.reported_by?.full_name,
    sortable: true,
    grow: '2'
  },
  {
    name: 'DESCRIPCIÓN',
    selector: 'description',
    cell: item => shortText(item.description, 50),
    sortable: false,
    grow: '4'
  },
  {
    cell: item => <BasicActionBtns item={item} clickHandler={clickHandler} showClick editClick />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '140px'
  }
]);

export default columns;
