import memoize from 'memoize-one';
import ReactTooltip from "react-tooltip";
import React from "react";

const columns = memoize(() => [
  { name: 'ID', selector: 'id', sortable: false, grow: '1' },
  { name: 'OT', selector: 'project.id', sortable: true, grow: '1' },
  { name: 'Fecha de entrega', selector: 'project.formated_delivered_date', sortable: true, grow: '1' },
  { name: 'Cliente', selector: 'project.client.full_name', sortable: true, grow: '1' },
  {
    name: 'Pregunta',
    selector: row => (row.nps_question ? row.nps_question.label : ''),
    sortable: true,
    grow: '1'
  },
  {
    name: 'Respuesta', selector: 'answer', sortable: true, grow: '2',
    cell: item => (
        <div data-tip data-for={`noteTip-${item.id}`} className="d-inline-block text-truncate">
          {item.answer}
          <ReactTooltip id={`noteTip-${item.id}`} place="top" effect="solid">
            {item.answer}
          </ReactTooltip>
        </div>
    )
  }
]);

export default columns;
