import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { indexProvidersRequest, deleteProviderRequest } from '../../requests/providers';
import { ComponentDataTable, DefaultModal } from '../../components';
import { sendAlert } from '../../actions/utils';
import downloadFile from '../../services/utils';

class ProviderDataTable extends Component {
  state = {
    onRequest: false,
    providers: [],
    amount: 0,
    moreData: false,
    modalShow: false,
    modalItem: null,
    modalBody: '',
    queries: ''
  };

  handleRequest = params => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true,
      queries: params
    });
    indexProvidersRequest({
      dispatch,
      params,
      successCallback: this.handleSuccessIndex
    });
  };

  handleSuccessIndex = response => {
    const providers = response.data.data;
    const { amount } = response.data.metadata;
    this.setState({
      providers,
      amount,
      onRequest: false
    });
  };

  removeProvider = providerId => {
    const { dispatch } = this.props;
    deleteProviderRequest(providerId, {
      dispatch,
      successCallback: this.handleSuccessRemove
    });
  };

  handleButtonClick = (item, action) => {
    const { history } = this.props;
    switch (action) {
      case 'show':
        history.push(`/providers/${item.id}`);
        break;
      case 'edit':
        history.push(`/providers/${item.id}/edit`);
        break;
      case 'destroy':
        this.setState({
          modalShow: true,
          modalItem: item,
          modalBody: `¿Estás seguro que deseas eliminar este proveedor?`
        });
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  handleModalClose = () => {
    this.setState({
      modalShow: false
    });
  };

  handleModalConfirm = () => {
    const { modalItem } = this.state;
    this.removeProvider(modalItem.id);
  };

  handleSuccessRemove = () => {
    const { dispatch } = this.props;
    const { moreData } = this.state;

    dispatch(sendAlert({ kind: 'success', message: 'Proveedor eliminado correctamente' }));
    this.handleModalClose();
    this.setState({ moreData: !moreData });
  };

  sortColumnCase = name => {
    switch (name) {
      case 'caption':
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  downloadIndex = (format, params) => {
    const { dispatch } = this.props;
    indexProvidersRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: this.handleSuccessDownload
    });
  };

  handleSuccessDownload = response => {
    downloadFile(response);
  };

  render() {
    const { providers, amount, onRequest, moreData, modalShow, modalBody, queries } = this.state;
    const { columns } = this.props;
    return (
      <>
        <Row className="justify-content-end mt-2">
          <Col md={2}>
            <Button variant="secondary" block onClick={() => this.downloadIndex('xlsx', queries)}>
              Exportar
            </Button>
          </Col>
        </Row>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(this.handleButtonClick)}
          handleSortCase={this.sortColumnCase} // how to handle special sorts, if not need it just dont sent it
          data={providers}
          totalRows={amount}
          moreData={moreData}
          resourceRequest={this.handleRequest}
        />
        <DefaultModal
          title="Eliminar Proveedor"
          body={modalBody}
          show={modalShow}
          handleClose={this.handleModalClose}
          handleConfirm={this.handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      </>
    );
  }
}

export default withRouter(connect()(ProviderDataTable));
