export const ProjectMechanisStateColors = {
  created: '#FADBD8',
  received: '#ffad26',
  reviewed: '#5cc689',
  budget_sent: '#FDEBD0',
  budget_approved: '#D4EFDF',
  rejected: '#D7DBDD',
  ot_completed: '#D5E8D4',
  delivered: '#EAEDED',
  warranty: '#F9C5B7',
  partial_budget: '#BCE2F9',
  delivered_warranty: '#F5B7B1'
};
