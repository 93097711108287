import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ComposedChart,
  PieChart,
  Pie,
  Cell
} from 'recharts';

function CustomBarChart() {
  const data = [
    { label: 'Page A', value: 400 },
    { label: 'Page B', value: 180 },
    { label: 'Page C', value: 300 },
    { label: 'Page D', value: 800 },
    { label: 'Page E', value: 1400 }
  ];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  return (
    <>
      <h2>Título del Gráfico</h2>
      <BarChart width={500} height={250} data={data}>
        <XAxis dataKey="label" stroke="#666" />
        <YAxis />
        <Tooltip />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Bar dataKey="value" fill="#29b48f" barSize={20}>
          {data.map((entry, index) => (
            <Cell key={`cell-${entry}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar>
      </BarChart>
    </>
  );
}
function CustomSideBarChart() {
  const data = [
    { label: 'Page A', value: 800 },
    { label: 'Page B', value: 967 },
    { label: 'Page C', value: 1098 },
    { label: 'Page D', value: 1200 },
    { label: 'Page E', value: 1108 },
    { label: 'Page F', value: 680 }
  ];
  return (
    <>
      <h2>Título del Gráfico</h2>
      <ComposedChart
        layout="vertical"
        width={500}
        height={250}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" />
        <YAxis dataKey="label" type="category" />
        <Tooltip />
        <Bar dataKey="value" barSize={10} fill="#413ea0" />
      </ComposedChart>
    </>
  );
}
function CustomPieChart({ data, title }) {
  const total = data.reduce((prev, next) => prev + next.value, 0);
  const COLORS = ['#F3CD05', '#6975A6', '#BED905', '#0ABDA0', '#F46A4E', '#8E5C1D'];
  const cx = 300;
  const cy = 150;
  return (
    <>
      <h2>{title}</h2>
      <PieChart width={600} height={400}>
        <Pie
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={0}
          outerRadius={130}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${entry}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend />
        <Tooltip formatter={label => `${label} (${(label / total).toFixed(2) * 100}%)`} />
      </PieChart>
    </>
  );
}
class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;
    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  }
}
class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill={stroke} transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  }
}
function CustomLineChart() {
  const data = [
    { name: 'Page A', pv: 2400 },
    { name: 'Page B', pv: 1398 },
    { name: 'Page C', pv: 9800 },
    { name: 'Page D', pv: 3908 },
    { name: 'Page E', pv: 4800 },
    { name: 'Page F', pv: 3800 },
    { name: 'Page G', pv: 4300 }
  ];
  return (
    <>
      <h2>Título del Gráfico</h2>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" padding={{ left: 30, right: 30 }} height={60} tick={<CustomizedAxisTick />} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" label={<CustomizedLabel />} />
      </LineChart>
    </>
  );
}

const ProgressBar = props => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 20,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 50
  };

  const fillerStyles = {
    height: '100%',
    width: completed,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out'
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{completed}</span>
      </div>
    </div>
  );
};

export { CustomBarChart, CustomSideBarChart, CustomPieChart, CustomLineChart, ProgressBar };
