export const utilsTypes = {
  // SETTINGS
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',
  // SLIDES
  GET_SLIDES_REQUEST: 'GET_SLIDES_REQUEST',
  GET_SLIDES_SUCCESS: 'GET_SLIDES_SUCCESS',
  GET_SLIDES_FAILURE: 'GET_SLIDES_FAILURE',
  // PAGE
  GET_PAGE_REQUEST: 'GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'GET_PAGE_FAILURE',
  // ALERT
  SET_ALERT: 'SET_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
  SET_ALERT_ERROR: 'SET_ALERT_ERROR',
  // SET_HEADERS
  SET_HEADERS: 'SET_HEADERS',
  // HELPERS
  CLIENT_TYPE: 'CLIENT_TYPE',
  CURRENT_CLIENT: 'CURRENT_CLIENT',
  CURRENT_SECOND_CLIENT: 'CURRENT_SECOND_CLIENT'
};

// SETTINGS
export const getSettings = () => ({ type: utilsTypes.GET_SETTINGS_REQUEST });

// SLIDE
export const getSlides = () => ({ type: utilsTypes.GET_SLIDES_REQUEST });

// PAGE
export const getPage = url => ({
  type: utilsTypes.GET_PAGE_REQUEST,
  url
});

// ALERT
export const closeAlert = params => ({ type: utilsTypes.CLOSE_ALERT, params });
export const sendAlert = params => ({ type: utilsTypes.SET_ALERT, ...params });

export const setHeaders = params => ({ type: utilsTypes.SET_HEADERS, ...params });

// HELPERS
export const getClientType = params => ({ type: utilsTypes.CLIENT_TYPE, params });
export const saveCurrentClient = params => ({ type: utilsTypes.CURRENT_CLIENT, params });
export const saveSecondCurrentClient = params => ({ type: utilsTypes.CURRENT_SECOND_CLIENT, params });
