import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import debounce from "lodash/debounce";
import { BasicTooltip } from '../Tooltips';

const FormikInput = ({
  abbr,
  additionalIconLabel,
  error,
  helpText,
  inputType,
  label,
  labelColor,
  margin,
  placeholder,
  size,
  tooltipText,
  touched,
  value,
  allowNegative = false,
  ...props
}) => {
  const { setFieldValue = () => null } = useFormikContext() || {};
  const correctedValue = inputValue => {
    if (inputType === 'password') return inputValue;
    if (typeof inputValue === 'string') return inputValue.toUpperCase();
    if (inputType === 'number' && !allowNegative) {
      if (inputValue < 0) return Math.abs(inputValue);
    }
    return inputValue;
  };

  const formatInput = () => {
    if (typeof value === 'string' && inputType !== 'password') {
      setFieldValue(props.name, value.toUpperCase());
    } else {
      setFieldValue(props.name, value);
    }
  };

  useEffect(() => {
    const debouncedFormatInput = debounce(formatInput, 300);
    debouncedFormatInput();
    return () => debouncedFormatInput.cancel();
  }, [value]);

  return (
    <Form.Group className={margin}>
      {label && (
        <>
          {tooltipText ? (
            <BasicTooltip text={tooltipText}>
              <Form.Label className={labelColor || ''}>
                {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
                {additionalIconLabel && additionalIconLabel}
              </Form.Label>
            </BasicTooltip>
          ) : (
            <Form.Label className={labelColor || ''}>
              {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
              {additionalIconLabel && additionalIconLabel}
            </Form.Label>
          )}
        </>
      )}
      <Form.Control
        size={size}
        placeholder={placeholder}
        type={inputType}
        className={error && touched ? 'is-invalid' : ''}
        value={correctedValue(value)}
        {...props}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default FormikInput;
