import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Steps from './Steps';

const GetdemoSection = () => {
  return (
    <Fade left>
      <div id="steps" className="text-center bg-blue__steps max-width-2000">
        <Row className="mb-4 mt-2">
          <Col>
            <h3>Consigue tu demo</h3>
          </Col>
        </Row>
        <Row>
          <Steps />
        </Row>
      </div>
    </Fade>
  );
};

export default GetdemoSection;
