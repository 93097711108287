import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Table, Button } from 'react-bootstrap';
import { Eye } from 'react-feather';
import snakeCaseKeys from 'snakecase-keys';

import IncidentShow from '../Incident/Incidents/IncidentShow';
import IncidentForm from '../Incident/Incidents/IncidentForm';
import { sendAlert } from '../../actions/utils';
import { SimpleCenteredModal } from '../../components';
import { createIncidentRequest } from '../../requests/incidents';

const basicIncident = {
  description: '',
  imagesInfo: []
};

const ProjectIncidents = ({ fromMechanicCard, project, id, setMoreData }) => {
  const { incidents } = project;
  const dispatch = useDispatch();
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleShowIncident = item => {
    setModalShow(true);
    setModalTitle('Detalle Incidencia');
    setModalBody(<IncidentShow fromMechanicCard={fromMechanicCard} incident={item} />);
  };

  const mapIncidents = incidents.map((incident, index) => {
    return (
      <tr key={`project-payment-${index.toString()}`}>
        <td>{incident?.createdAt}</td>
        <td>{incident?.incidentType?.label}</td>
        <td>{incident?.reportedBy?.fullName}</td>
        <td className="text-center">
          <Eye
            width="18px"
            height="18px"
            className="clickable delete-item text-warning"
            onClick={() => handleShowIncident(incident)}
          />
        </td>
      </tr>
    );
  });

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessIncidentCreate = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Incidencia creada con éxito' }));
    setMoreData(m => !m);
  };

  const handleCreateIncidentRequest = values => {
    const sendParams = snakeCaseKeys({ ...values, projectId: id });
    createIncidentRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessIncidentCreate,
      failureCallback: handleFailureRequest
    });
  };

  const handleSimpleModalCreate = () => {
    setModalBody(
      <IncidentForm
        action="new"
        dispatch={dispatch}
        formRequest={handleCreateIncidentRequest}
        handleModalClose={handleModalClose}
        submitVariant="success"
        incident={basicIncident}
      />
    );
    setModalShow(true);
    setModalTitle('Crear Incidencia');
  };

  return (
    <>
      <Row className="mb-3 justify-content-between">
        <Col className="section-header">
          <strong>Incidencias</strong>
        </Col>
        <Col md={4}>
          <Button variant="secondary" block onClick={handleSimpleModalCreate}>
            Reportar Incidencia
          </Button>
        </Col>
      </Row>
      <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr className="text-uppercase fit-content">
            <th>Fecha</th>
            <th>Tipo</th>
            <th>Reportada por</th>
            <th style={{ width: '40px' }} />
          </tr>
        </thead>
        <tbody>{mapIncidents}</tbody>
      </Table>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton={false}
      />
    </>
  );
};

export default ProjectIncidents;
