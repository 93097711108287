import React from 'react';
import DatePicker from 'react-datepicker';
import { es } from "date-fns/locale";
import { format, parse } from "date-fns";

const InputTime = ({placeholder, onChange, disabled, value, ...props}) => {
  return (
    <DatePicker
      disabled={disabled}
      placeholderText={placeholder}
      dateFormat="H:mm"
      showTimeSelect
      showTimeSelectOnly
      className="form-control"
      autoComplete="off"
      selected={value ? parse(value, 'H:mm', new Date()) : ''}
      timeIntervals={30}
      onChange={date => {
        const formattedDate = date ? format(date, 'HH:mm') : '';
        onChange(formattedDate);
      }}
      locale={es}
      {...props}
    />
  );
}
export default InputTime;