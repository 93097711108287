/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  { name: 'NOMBRE', selector: 'name', sortable: true, grow: '1' },
  { name: 'TRABAJOS ASOCIADOS', selector: 'template_works_attributes.length', sortable: false, grow: '2' },
  {
    cell: item => <BasicActionBtns item={item} clickHandler={clickHandler} withShow={false} editClick cloneClick />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '140px'
  }
]);

export default columns;
