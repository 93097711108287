import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import { sendAlert } from '../../../actions/utils';
import { BasicTextArea, FormikSelect } from '../../../components';
import { indexUsersRequest } from '../../../requests/user';
import LoadItemsForm from './LoadItemsForm';
import GoBackButton from "../../../components/GoBackButton";

const PurchaseManagementForm = ({ errors, onHide, setFieldValue, touched, values, ...props }) => {
  const { purchaseManagementDetailsAttributes } = values.purchaseManagement;
  const { action, purchaseManagement, submitVariant } = props;
  const btnMessage = action === 'new' ? 'Crear listado' : 'Guardar';
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchUsers = () => {
    indexUsersRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_column: 'email',
        display_length: 100
      },
      successCallback: response => setUsers(response.data.data),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchUsers, []);

  return (
    <Form>
      <Row className="mb-3">
        <Col md={3} className="pr-0">
          <Field name="purchaseManagement[notes]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                customClass="two-lines"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3} className="px-5">
          <Field name="purchaseManagement[userId]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Responsable"
                placeholder="Seleccionar Responsable"
                options={users}
                defaultValue={purchaseManagement.userId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={{ span: 2, offset: 2 }}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
        <Col md={2}>
          <GoBackButton />
        </Col>
      </Row>

      <LoadItemsForm purchaseManagementDetailsAttributes={purchaseManagementDetailsAttributes} />
    </Form>
  );
};

const setInitialValues = ({ purchaseManagement }) => ({ purchaseManagement });

const validationSchema = Yup.object().shape({
  purchaseManagement: Yup.object().shape({
    notes: Yup.string().nullable(),
    purchaseManagementDetailsAttributes: Yup.array().of(
      Yup.object().shape({
        categoryId: Yup.string().required('Debes seleccionar una categoría'),
        notes: Yup.string().nullable(),
        productCategoryId: Yup.string().nullable(),
        productId: Yup.string().nullable(),
        productNameCustom: Yup.string().nullable(),
        quantity: Yup.number().required('Debes ingresar una cantidad'),
        totalPrice: Yup.number().nullable(),
        unitPrice: Yup.number().nullable()
      })
    ),
    userId: Yup.string().required('Debes seleccionar un comprador')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new',
  validateOnChange: false
})(PurchaseManagementForm);
