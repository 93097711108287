import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexPurchaseReceiptsRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.requestV2('get', `/purchase_receipts${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexPurchaseReceiptsRequest = AwesomeDebouncePromise(indexPurchaseReceiptsRequest, 300);
