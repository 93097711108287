import React from 'react';
import PropTypes from 'prop-types';
import snakeCaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import { SimpleCenteredModal } from '../../components';
import ProjectWorkHourForm from './ProjectWorkHourForm';
import { sendAlert } from '../../actions/utils';
import { updateProjectWorkRequest } from '../../requests/projectWorks';

const ProjectWorkHoursModal = ({ alertHoursExceeded, handleClose, modalShow, projectWork, users, setMoreData }) => {
  const dispatch = useDispatch();
  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Hora de trabajo creada con éxito' }));
    setMoreData(m => !m);
    if (alertHoursExceeded) {
      alertHoursExceeded();
    }
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values, { exclude: ['_destroy'] });
    updateProjectWorkRequest(projectWork.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <SimpleCenteredModal
      centered
      title="Horas de trabajo"
      dialogClassName="w-100"
      show={modalShow}
      handleClose={handleClose}
      body={
        <ProjectWorkHourForm
          action="new"
          projectWork={projectWork}
          formRequest={handleCreateRequest}
          onHide={handleClose}
          submitVariant="success"
          users={users}
        />
      }
    />
  );
};

ProjectWorkHoursModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  modalShow: PropTypes.bool.isRequired,
  projectWork: PropTypes.instanceOf(Object).isRequired,
  users: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired
};

export default ProjectWorkHoursModal;
