/* eslint-disable camelcase */

const columns = [
  {
    name: 'Tipo',
    selector: 'product_movement_type',
    sortable: true,
    cell: item => item.translated_movement_type
  },
  {
    name: 'Cantidad',
    selector: 'quantity',
    sortable: true
  },
  {
    name: 'Valor Unitario',
    selector: 'sale_price',
    sortable: true,
    cell: item => item.parsed_sale_price
  },
  {
    name: 'Valor Neto',
    selector: 'total_amount',
    sortable: true,
    cell: item => item.parsed_total_amount
  },
  {
    name: 'N° Documento',
    selector: 'invoice_number',
    sortable: false,
    cell: item => item.invoice_number || '-'
  },
  {
    name: 'Proveedor',
    selector: 'provider_name',
    sortable: true,
    cell: item => item.provider_name || '-'
  }
];

export default columns;
