import React from 'react';
import NestedAttributes from '../../components/Utils/NestedAttributes/NestedAttributes';
import ContactInput from './ContactInput';

const ContactAdd = ({ contactsAttributes, touched, errors, setFieldValue, parent }) => {
  const mapResults = contactsAttributes.map((body, index) => {
    if (body._destroy) {
      return undefined;
    }

    return (
      <ContactInput
        key={`contact-${index.toString()}`}
        index={index}
        touched={touched}
        errors={errors}
        parent={parent}
        setFieldValue={setFieldValue}
      />
    );
  });

  return (
    <NestedAttributes
      removeFirstItem
      mapInputs={mapResults}
      arrayValues={contactsAttributes}
      setFieldValue={setFieldValue}
      valuePath={`${parent}[contactsAttributes]`}
      newAttributes={{ contactId: '', cellphone: '', charge: '', email: '', name: '', phone: '' }}
      btnMessage="Agrega nuevo contacto"
    />
  );
};

export default ContactAdd;
