import React from 'react';
import { Button } from 'react-bootstrap';
import { Check, Minus } from 'react-feather';

const buttonsConfig = {
  rejected: {
    variant: 'danger',
    text: 'Malo',
    icon: <Minus />
  },
  approved: {
    variant: 'success',
    text: 'Bueno',
    icon: <Check />
  }
};
const BadGoodButton = ({ customStateValues }) => {
  const { variant, text, icon } = buttonsConfig[customStateValues];
  return (
    <Button variant={variant} style={{ marginRight: '0.5rem', borderRadius: '3px' }}>
      {icon} {text}
    </Button>
  );
};

export default BadGoodButton;
