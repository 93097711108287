/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns } from '../../components';
import { Can } from '../../config/abilityContext';

const columns = memoize(clickHandler => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'DIRECCIÓN',
    selector: 'full_address',
    sortable: false,
    grow: '3'
  },
  {
    cell: item => (
      <Can I="update" a="BranchOffice">
        <BasicActionBtns item={item} clickHandler={clickHandler} withShow={false} editClick />
      </Can>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
