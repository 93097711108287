import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { SimpleCenteredModal } from '../../components';
import TemplateDataTable from './TemplateDataTable';
import columns from './Columns';
import { sendAlert } from '../../actions/utils';
import { createTemplateRequest } from '../../requests/templates';
import TemplateForm from './TemplateForm';

const basicTemplate = {
  name: '',
  templateWorksAttributes: [{ id: '', workId: '', templateId: '' }]
};

const TemplateIndex = () => {
  const dispatch = useDispatch();

  const [modalBody, setModalBody] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Template creado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createTemplateRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  const handleModalCreate = () => {
    setModalBody(
      <TemplateForm
        action="new"
        dispatch={dispatch}
        formRequest={handleCreateRequest}
        handleModalClose={handleModalClose}
        submitVariant="success"
        template={basicTemplate}
      />
    );
    setModalShow(true);
    setModalTitle('Crear Template');
  };

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">Templates</h2>
            </Col>
            <Col md={2}>
              <Button variant="primary" block onClick={handleModalCreate}>
                Crear
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TemplateDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />
            </Col>
          </Row>
          <SimpleCenteredModal
            title={modalTitle}
            body={modalBody}
            show={modalShow}
            onHide={handleModalClose}
            centered={false}
            closeButton
          />
        </Col>
      </Row>
    </>
  );
};

export default TemplateIndex;
