export default class CountryFactory {

  get formatPhone() {
    throw new Error("This method must be overwritten!");
  }

  get code() {
    throw new Error("This method must be overwritten!");
  }

  handleNationalIdentificationFormat(e, setFieldValue, isForeign) {
    throw new Error("This method must be overwritten!");
  }

  isValidRut(rut) {
    throw new Error("This method must be overwritten!");
  }

  getSymbol() {
    return "$";
  }

  getFormatNationalIdentification() {
    throw new Error("This method must be overwritten!");
  }

  getFormatNationalIdentificationCompany() {
    throw new Error("This method must be overwritten!");
  }
}