import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import PlaceHolder from '../../../assets/images/placeholder-card.webp';
import { LinkBtn } from '../../../components';

const InfoModal = ({ successfulCase }) => {
  const {
    branchOfficeName,
    city,
    clientName,
    clientQuote,
    clientRole,
    country,
    fileInfo,
    formatedSinceDate
  } = successfulCase;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <LinkBtn className="btn button-font btn-link-arrow" onClick={handleShow}>
        Leer más
      </LinkBtn>

      <Modal show={show} size="xl" onHide={handleClose} backdrop="static" keyboard={false} id="modal-success">
        <Modal.Header className="border-0 pb-0" closeButton />

        <Modal.Body>
          <div>
            <Row className="px-5">
              <Col md={4} className="d-flex align-items-center">
                <Row className="justify-content-md-center px-3">
                  <img
                    alt="Caso de Éxito"
                    className="img-square-box"
                    src={Object.keys(fileInfo).length !== 0 ? fileInfo.fileUrl : PlaceHolder}
                  />
                </Row>
              </Col>
              <Col md={8} className="d-flex align-items-center">
                <div>
                  <h4>{branchOfficeName}</h4>
                  {formatedSinceDate && <p>{`Cliente desde ${formatedSinceDate}`}</p>}
                  {city && country && <p>{`${city}, ${country}`}</p>}
                  <hr style={{ height: '1px', backgroundColor: '#d9d9d9' }} />
                  <b>{clientName}</b>
                  {clientRole && (
                    <p>
                      <i>{clientRole}</i>
                    </p>
                  )}
                  <p>{`"${clientQuote}"`}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer style={{ height: '35px' }} />
      </Modal>
    </>
  );
};

export default InfoModal;
