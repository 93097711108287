import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment';

import './styles.scss';

const FormikTimePicker = ({ abbr, error, helpText, label, margin, onChange, time, touched, ...props }) => {
  const [showTime, setShowTime] = useState(false);
  const timeParsed = moment(time).format('HH:mm');

  return (
    <Form.Group className={margin}>
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <span
        className="form-control"
        role="button"
        tabIndex={0}
        onClick={() => setShowTime(!showTime)}
        onKeyDown={() => setShowTime(!showTime)}
        onBlur={() => setShowTime(false)}
      >
        {timeParsed}
      </span>
      <InputGroup>
        {showTime && (
          <TimeKeeper
            time={timeParsed}
            onChange={onChange}
            onDoneClick={() => setShowTime(false)}
            switchToMinuteOnHourSelect
            closeOnMinuteSelect
            {...props}
          />
        )}
        {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
        {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
      </InputGroup>
    </Form.Group>
  );
};

export default FormikTimePicker;
