import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import imageComputer from '../../../assets/images/computer-gestioncar-ui.webp';

const HeroeSection = ({ firstSectionSubtitle }) => {
  return (
    <Row className="home-header-container" id="home">
      <div>
        <Row className="home-header">
          <Col lg={5} md={6} className="order-sm-2">
            <h1> Software web para la gestión de tu taller</h1>
            <p>
              {firstSectionSubtitle ||
                'Optimiza tiempos y recursos, automatiza tu taller y ofrece actualizaciones constantes a tus clientes. Administración fácil e intuitiva desde cualquier lugar.'}
            </p>
            <Link className="btn button-font btn-primary-yellow__landing w-100-mobile" to="/contact">
              ¡Prueba gratis de 15 días!
            </Link>
          </Col>

          <Col lg={7} md={6} className="order-sm-1">
            <img
              alt="Interfaz Software GestionCar"
              title="Interfaz Software GestionCar"
              src={imageComputer}
              width="100%"
              height="auto"
            />
          </Col>
        </Row>
      </div>
    </Row>
  );
};

export default HeroeSection;
