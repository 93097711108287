import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../components';
import ProviderDataTable from './ProviderDataTable';
import columns from './Columns';

const ProviderIndex = () => {
  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-3">
            <Col>
              <h2 className="text-uppercase mb-4">Proveedores</h2>
            </Col>
            <Col md={2}>
              <LinkBtn variant="primary" block to="/providers/new">
                Crear
              </LinkBtn>
            </Col>
          </Row>
          <ProviderDataTable columns={columns} />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(connect()(ProviderIndex));
