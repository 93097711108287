import React, { useState } from 'react';
import { withFormik, Form, Field, getIn, FieldArray } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { BasicTextArea, FormikDatePicker, FormikNumberFormat, FormikSelect } from '../../components';
import projectPaymentTypes from './ProjectPaymentTypes';
import useCountryFactory from "../../hooks/useCountryFactory";

const ProjectPaymentForm = ({ values, ...props }) => {
  const {
    action,
    errors,
    handleModalClose,
    onHide,
    projectPayment,
    projectInvoices,
    setFieldValue,
    setFieldTouched,
    submitVariant,
    touched
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const [date, setDate] = useState('');
  const { paymentType } = projectPayment;
  const countryFactory = useCountryFactory();

  const listProjectInvoices = projectInvoices
    .filter(invoice => invoice.type !== 'CreditNoteDocument' && invoice.state !== 'deleted')
    .map(invoice => ({
      label: `${invoice.shortName} - Saldo: ${invoice.parsedBalance}`,
      value: invoice.id,
      isDisabled: parseFloat(invoice.balance) <= 0,
      balance: parseFloat(invoice.balance)
  }));

  const handleFormatting = e => {
    const formattedValue = e.target.value.replace(/\./g, '');
    const numberValue = parseFloat(formattedValue.replace(',', '.'));
    setFieldValue(e.target.name, numberValue);
  };

  const selectedDocument = async (index, data) => {
    let totalBalance = await calculateTotalBalance(index, data.balance);
    if(parseFloat(values.projectPayment.amount) > totalBalance){
      setFieldValue(`projectPayment[projectAmortizationsAttributes][${index}][amount]`, data.balance);
    } else {
      let finalBalance = parseFloat(values.projectPayment.amount) - (totalBalance - data.balance);
      setFieldValue(`projectPayment[projectAmortizationsAttributes][${index}][amount]`, finalBalance);
    }
  };

  const calculateTotalBalance = async (editedIndex, balance) => {
    return new Promise((resolve, reject) => {
      let totalAmount = 0;
      values.projectPayment.projectAmortizationsAttributes.forEach((payment, index) => {
        if(index !== editedIndex && payment.amount !== '') {
          totalAmount += parseFloat(payment.amount);
        }
      });
      resolve(totalAmount + balance);
    });
  };

  return (
    <Form>
      <Row className="mt-3 mb-4">
        <Col>
          <Field name="projectPayment[amount]">
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                leftAddon={countryFactory.getSymbol()}
                decimalScale={2}
                label="Monto"
                value={parseFloat(field.value)}
                disabled={projectPayment.projectAmortizationsAttributes.length > 0}
                onChange={handleFormatting}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="projectPayment[paymentType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Abono"
                placeholder="Seleccionar"
                defaultValue={paymentType}
                options={projectPaymentTypes}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                setFieldTouched={() => setFieldTouched(field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="projectPayment[date]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                allowPast
                label="Fecha"
                selected={date}
                onChange={newDate => {
                  setDate(newDate);
                  setFieldValue(field.name, newDate);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="projectPayment[observations]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Observaciones"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <FieldArray name="projectPayment[projectAmortizationsAttributes]">
          {({ push, remove }) => (
            <>
              {values.projectPayment.projectAmortizationsAttributes.map((payment, index) => (
                <Col md={12} key={`invoice${index}`} className="mb-3">
                  <hr className={"mt-1"} />
                  <Row>
                    <Col md={6}>
                      <Field name={`projectPayment[projectAmortizationsAttributes][${index}][projectInvoiceId]`}>
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            abbr
                            label="Documento"
                            placeholder="Seleccione documento"
                            options={listProjectInvoices}
                            isDisabled={projectPayment.projectAmortizationsAttributes[index]?.projectInvoice}
                            defaultValue={payment.projectInvoiceId || ''}
                            onChange={data => {
                              selectedDocument(index, data);
                              setFieldValue(field.name, data ? data.value : '');
                            }}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            isOptionDisabled={(option) => option.isDisabled}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={4}>
                      <Field name={`projectPayment[projectAmortizationsAttributes][${index}][amount]`}>
                        {({ field }) => (
                          <FormikNumberFormat
                            {...field}
                            abbr
                            leftAddon={countryFactory.getSymbol()}
                            decimalScale={2}
                            label="Monto"
                            disabled={true}
                            value={parseFloat(field.value)}
                            onChange={handleFormatting}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    {!projectPayment.projectAmortizationsAttributes[index]?.projectInvoice && <Col md={2} className={"my-auto"}>
                      <Button variant={"danger"} onClick={() => remove(index)}>
                        Eliminar
                      </Button>
                    </Col>}
                  </Row>
                </Col>
              ))}
              <Col md={12}>
                <hr className={"mt-1"} />
              </Col>
              <Button onClick={() => push({ projectInvoiceId: '', amount: '' })}
                      className={"ml-3"} disabled={!values.projectPayment.amount}>
                Agregar Documento
              </Button>
            </>
          )}
        </FieldArray>
      </Row>
      <Row className="d-flex justify-content-end mb-4">
        {handleModalClose && (
          <Col md={{ span: 3, offset: 6 }}>
            <Button className="btn" variant="primary" block onClick={handleModalClose}>
              Cancelar
            </Button>
          </Col>
        )}
        <Col md={3}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { projectPayment } = props;
  return {
    projectPayment: {
      ...projectPayment
    }
  };
};

const validationSchema = Yup.object().shape({
  projectPayment: Yup.object().shape({
    amount: Yup.number().required('Debes ingresar un monto'),
    date: Yup.string().required('Debes ingresar una fecha'),
    observations: Yup.string().nullable(),
    paymentType: Yup.string().required('Debes seleccionar un tipo de abono'),
    projectAmortizationsAttributes: Yup.array().of(
      Yup.object().shape({
        amount: Yup.number().required('Debes ingresar un monto').min(1, 'El monto debe ser mayor a 0'),
        projectInvoiceId: Yup.string().required('Debes seleccionar un documento')
      })
    )
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProjectPaymentForm);
