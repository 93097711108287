import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ComponentDataTable from '../../components/Utils/DataTable';
import columns from './ClientVehiclesColumns';
import { indexVehiclesRequest } from '../../requests/vehicles';
import { sendAlert } from '../../actions/utils';

const ClientVehicles = ({ clientId }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const history = useHistory();

  const handleSuccessRequest = response => {
    const { amount: responseAmount } = response.data.metadata;
    setVehicles(response.data.data);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    const {
      data: { message }
    } = error.response;
    dispatch(sendAlert({ kind: 'error', message }));
    setOnRequest(false);
  };

  const handleIndexRequest = prevParams => {
    setOnRequest(true);
    const params = {
      ...prevParams
    };
    indexVehiclesRequest(clientId, {
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'vehicle_brand_name':
        return { sort_vehicle_brand: name };
      case 'vehicle_model_name':
        return { sort_vehicle_model: name };
      default:
        return { sort_column: name };
    }
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`clients/${item.client_id}/vehicles/${item.id}`);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  return (
    <>
      <h3 className="mt-3">VEHÍCULOS ASOCIADOS A CLIENTE</h3>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, setMoreData)}
        handleSortCase={sortColumnCase}
        data={vehicles}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (!onRequest) handleIndexRequest(response);
        }}
        noSearch
      />
    </>
  );
};

export default ClientVehicles;
