/* eslint-disable import/prefer-default-export */
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexProjectWorkHoursRequest = ({
  dispatch,
  format,
  params = {},
  formData,
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/project_work_hours${formatt}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });
};

export const updateProjectWorkHourRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/project_work_hours/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProjectWorkHourRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/project_work_hours/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexProjectWorksRequest = AwesomeDebouncePromise(indexProjectWorkHoursRequest, 300);
