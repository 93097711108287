/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'SUB-CATEGORÍAS',
    selector: 'product_categories_count',
    sortable: false
  },
  {
    cell: item => <BasicActionBtns item={item} clickHandler={clickHandler} showClick editClick />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
