/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import ReactTooltip from 'react-tooltip';
import { Download, Eye } from 'react-feather';
import { LinkBtn } from '../../../../components';
import { Button } from "react-bootstrap";
import moment from "moment/moment";

const columns = memoize(handleDownloadInvoicePdf => [
  { name: 'NÚMERO', selector: 'invoice_number', sortable: true, grow: '2' },
  {
    name: 'FECHA DE EMISIÓN',
    selector: 'invoice_date',
    cell: item => moment(item.invoice_date, 'DD/MM/YYYY HH:mm').format('DD/MM/YY'),
    sortable: true,
    grow: '2'
  },
  { name: 'VENCIMIENTO',
    selector: 'expiration_date',
    cell: item => moment(item.expiration_date, 'DD/MM/YYYY').format('DD/MM/YY'),
    sortable: true,
    grow: '2'
  },
  {
    name: 'OT',
    selector: 'project_code',
    sortable: true,
    grow: '1'
  },
  {
    name: 'CLIENTE',
    selector: 'client_full_name',
    cell: item => (
      <>
        <div data-tip data-for={`full-name-${item.id}`}>
          {item.client_full_name}
        </div>
        <ReactTooltip id={`full-name-${item.id}`} place="top">
          {item.client_full_name}
        </ReactTooltip>
      </>
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'NETO',
    selector: 'parsed_net',
    sortable: true,
    grow: '2'
  },
  {
    name: 'TOTAL',
    selector: 'parsed_total',
    sortable: false,
    grow: '2'
  },
  {
    name: 'ESTADO',
    selector: 'translated_state',
    sortable: false,
    grow: '2'
  },
  {
    name: '',
    selector: 'project_link',
    cell: item => (
      <>
        <LinkBtn
          className="btn-circle mx-auto"
          variant="outline-info"
          data-tip
          data-for="project-show"
          to={`/projects/${item.project_code}`}
        >
          <Eye size={16} />
        </LinkBtn>
        <ReactTooltip id="project-show" place="top">
          Ir a Proyecto
        </ReactTooltip>
        {!item.is_associate_invoice && (
          <Button className={"btn-circle"} variant="outline-success"
                  onClick={() => handleDownloadInvoicePdf(item.project_code, item.id)}>
            <Download size={16} />
          </Button>
        )}
      </>
    ),
    sortable: false,
    grow: '1'
  }
]);

export default columns;
