/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Trash } from 'react-feather';
import { useSelector } from 'react-redux';
import { getIn } from 'formik';
import './style.scss';
import { ROLE_IDS } from '../../../config/ability';

const handleDelete = (arrayValues, setFieldValue, valuePath, index) => {
  const arrayValuesLength = arrayValues.length;
  const deleteValue = arrayValues[index];
  const newValues = [...arrayValues.slice(0, index), ...arrayValues.slice(index + 1, arrayValuesLength)];

  if (deleteValue.id && deleteValue !== '') {
    deleteValue._destroy = 'true';
    newValues.push(deleteValue);
  }

  setFieldValue(`${valuePath}`, newValues);
};

const addRow = (arrayValues, setFieldValue, valuePath, newAttributes) => {
  const newValues = [...arrayValues, newAttributes];
  setFieldValue(`${valuePath}`, newValues);
};

const buttonRemoveItem = (arrayValues, setFieldValue, valuePath, index, currentUser) => {
  const isUserAdmin = currentUser.roleIds.includes(ROLE_IDS.admin);
  const accountableId = getIn(arrayValues, `[${index}][accountableUserId]`);
  const isApproved = getIn(arrayValues, `[${index}][approved]`);
  const shouldRenderButton = () => {
    if (isApproved) return false;
    if (isUserAdmin) return true;

    return accountableId ? accountableId === currentUser.id : false;
  };

  if (shouldRenderButton())
    return (
      <Button
        variant="outline-danger"
        className="btn-circle"
        size="sm"
        text="Eliminar"
        onClick={() => handleDelete(arrayValues, setFieldValue, valuePath, index)}
      >
        <Trash width="15px" height="15px" />
      </Button>
    );
  return null;
};

const NestedBody = ({
  additionalNested,
  arrayValues,
  body,
  deleteLastOne,
  index,
  inputLength,
  removeFirstItem,
  setFieldValue,
  shownLength,
  valuePath,
  currentUser
}) => (
  <>
    {body && (
      <>
        <Row className="checklist-section-container">
          {body}
          <Col md={1} xs={2} className="pt-4">
            {deleteLastOne
              ? index === inputLength - 1 &&
                inputLength !== 0 &&
                shownLength !== 0 &&
                buttonRemoveItem(arrayValues, setFieldValue, valuePath, index, currentUser)
              : (removeFirstItem || (inputLength !== 0 && shownLength > 1)) &&
                buttonRemoveItem(arrayValues, setFieldValue, valuePath, index, currentUser)}
          </Col>
        </Row>
        {!additionalNested && index !== inputLength && <hr />}
      </>
    )}
  </>
);

const NestedAttributes = ({
  additionalNested,
  arrayValues,
  deleteLastOne,
  mapInputs,
  btnMessage,
  newAttributes,
  removeFirstItem,
  setFieldValue,
  valuePath
}) => {
  const { user: currentUser } = useSelector(state => state.auth);
  const inputLength = mapInputs.length - 1;
  const shownLength =
    mapInputs.filter(e => {
      return e !== undefined;
    }).length - 1;
  const nestedBody = mapInputs.map((body, index) => (
    <NestedBody
      key={`nested-${index.toString()}`}
      arrayValues={arrayValues}
      body={body}
      deleteLastOne={deleteLastOne}
      removeFirstItem={removeFirstItem}
      index={index}
      inputLength={inputLength}
      setFieldValue={setFieldValue}
      shownLength={shownLength}
      valuePath={valuePath}
      newAttributes={newAttributes}
      additionalNested={additionalNested}
      currentUser={currentUser}
    />
  ));

  nestedBody.push(
    <Row key={`btn-add-row-${shownLength.toString()}`} className={!additionalNested ? 'my-3' : ''}>
      <Col md={4} className="mx-3">
        <Button
          className="mb-4 mt-2"
          block
          variant="secondary"
          onClick={() => addRow(arrayValues, setFieldValue, valuePath, newAttributes)}
        >
          {btnMessage}
        </Button>
      </Col>
    </Row>
  );

  return nestedBody;
};

export default NestedAttributes;
