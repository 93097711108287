import React from 'react';
import MovementShow from './MovementShow';

const ProductMovementInfo = props => {
  const { movement } = props;
  const {
    id,
    createdAt,
    invoiceNumber,
    parsedTotalAmount,
    projectReference,
    provider,
    translatedMovementType
  } = movement;

  const isStocktake = translatedMovementType === 'Inventario';

  return (
    <div className="show-info-container">
      <div className="show-info-small-container">
        <div className="show-info-column">
          <p>
            <strong>N° de Movimiento:</strong> {id}
          </p>
          <p>
            <strong>Fecha:</strong> {createdAt}
          </p>
          {invoiceNumber ? (
            <p>
              <strong>N° de Invoice:</strong> {invoiceNumber}
            </p>
          ) : (
            <p>
              <strong>Referencia (Proyecto):</strong> {projectReference}
            </p>
          )}
        </div>
        <div className="show-info-column">
          {!isStocktake && (
            <>
              <p>
                <strong>Proveedor:</strong>{' '}
                {provider ? provider.label : 'No existe proveedor asociado a este movimiento'}
              </p>
              <p>
                <strong>Monto Total:</strong> {parsedTotalAmount}
              </p>
            </>
          )}
          <p>
            <strong>Tipo de Transacción:</strong> {translatedMovementType}
          </p>
        </div>
      </div>
      <hr />
      <MovementShow movement={movement} isStocktake={isStocktake} />
    </div>
  );
};

export default ProductMovementInfo;
