import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { history } from './app/config/store';
import routes from './app/config/routes';
import { Alert } from './app/components';
import GlobalErrorBoundary from './app/screens/Error/GlobalErrorBoundary';
import { getSettings } from './app/actions/utils';
import './index.scss';

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: false } } });

class App extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(getSettings());
  };

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <GlobalErrorBoundary>
          <ConnectedRouter history={history}>{routes}</ConnectedRouter>
          <Alert />
        </GlobalErrorBoundary>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    );
  }
}

const mapStateToProps = state => {
  const { utils } = state.utils;
  return {
    utils
  };
};

export default connect(mapStateToProps)(App);
