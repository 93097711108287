import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { showClientRequest, updateClientRequest } from '../../requests/clients';
import { showCompanyRequest } from '../../requests/companies';
import ClientForm from './ClientForm';
import basicCompany from '../Company/basicCompany';
import GoBackButton from "../../components/GoBackButton";

const basicClient = {
  id: '',
  active: true,
  address: '',
  addressNumber: '',
  businessActivity: '',
  cellphone: '',
  communeId: '',
  contactsAttributes: [],
  email: '',
  firstLastName: '',
  isCompany: false,
  legalName: '',
  name: '',
  nationalIdentification: '',
  notes: '',
  phone: '',
  regionId: '',
  secondLastName: '',
  sellerId: '',
  country: 'cl'
};

const ClientEdit = props => {
  const [client, setClient] = useState(basicClient);
  const [currentCompany, setCompany] = useState(basicCompany);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    match: { params }
  } = props;
  const { id } = params;

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Cliente actualizado con éxito' }));
    history.push(`/clients/${id}`);
  };

  const handleFailureUpdate = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response.data.message }));
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values, { exclude: ['_destroy'] });

    updateClientRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
  };

  const fetchClient = () => {
    showClientRequest(id, {
      dispatch,
      successCallback: response => setClient(camelCaseEmptyStringRecursive(response.data))
    });
  };

  const fetchCurrentCompany = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(fetchCurrentCompany, []);

  useEffect(fetchClient, [params]);

  return (
    <>
      <Row className="mt-3">
        <Col>
          <h1>Editar Cliente</h1>
        </Col>
        <Col md={2}>
          <GoBackButton />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <ClientForm
            client={client}
            action="edit"
            formRequest={handleUpdateRequest}
            companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
            country={currentCompany.country}
          />
        </Col>
      </Row>
    </>
  );
};

export default ClientEdit;
