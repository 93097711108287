import React from 'react';
import { Button } from 'react-bootstrap';
import { Minus } from 'react-feather';
const NotApplyButton = () => {
  return (
    <Button variant="dark" style={{ marginRight: '0.5rem', borderRadius: '3px' }}>
      <Minus /> No aplica
    </Button>
  );
};

export default NotApplyButton;
