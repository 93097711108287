import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { createChecklistRequest } from '../../requests/checklists';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import ChecklistForm from './ChecklistForm';

const checklistData = {
  id: '',
  name: '',
  checklistType: '',
  reviewGroupsAttributes: [
    {
      id: '',
      name: '',
      reviewType: '',
      position: 0,
      reviewsAttributes: [
        {
          id: '',
          name: '',
          position: 0
        }
      ]
    }
  ]
};

const ChecklistNew = () => {
  const [checklist, setChecklist] = useState(checklistData);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessCreate = response => {
    setChecklist(camelCaseEmptyStringRecursive(response.data));
    dispatch(sendAlert({ kind: 'success', message: 'Checklist creado con éxito' }));
    history.push('/checklists');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);

    createChecklistRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <Row>
      <Col md={{ span: 10, offset: 1 }}>
        <ChecklistForm checklist={checklist} action="new" submitVariant="success" formRequest={handleCreateRequest} />
      </Col>
    </Row>
  );
};

export default ChecklistNew;
