import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { InputRemoteSelect } from '../../components';
import { debounceIndexWorksRequest, indexWorksRequest } from '../../requests/works';

const WorkInput = ({ index, errors, touched, setFieldValue, values, templateWork }) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [works, setWorks] = useState([]);
  const currentValues = values.template.templateWorksAttributes[index];
  const inputName = `template[templateWorksAttributes][${index}]`;

  const fetchInitialWorks = useCallback(
    params => {
      indexWorksRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          for_selector: true,
          sort_column: 'name',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: response => {
          setWorks(response.data.data);
        }
      });
    },
    [stableDispatch]
  );

  const fetchWorks = callback => {
    debounceIndexWorksRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: response => callback(response.data.data)
    });
  };

  useEffect(fetchInitialWorks, [fetchInitialWorks]);

  return (
    <Col>
      <Row>
        <Col md={12}>
          <Field name={`${inputName}[workId]`}>
            {({ field }) => (
              <InputRemoteSelect
                {...field}
                isClearable
                defaultOptions={works}
                label="Trabajo"
                values={currentValues}
                model={[templateWork || {}, 'work']}
                placeholder="Seleccionar Trabajo"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                request={fetchWorks}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  );
};

export default WorkInput;
