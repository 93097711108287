import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import ComponentDataTable from '../../../components/Utils/DataTable';
import { DefaultModal, SimpleCenteredModal } from '../../../components';
import {
  debounceIndexIncidentsRequest,
  deleteIncidentRequest,
  updateIncidentRequest
} from '../../../requests/incidents';
import { sendAlert } from '../../../actions/utils';
import IncidentForm from './IncidentForm';
import IncidentShow from './IncidentShow';

const IncidentDataTable = props => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [incidents, setIncidents] = useState([]);
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const { columns, moreData, setMoreData } = props;

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseIncidents = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setIncidents(responseIncidents);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    const { sort_column: sortColumn, sort_incident_type: sortIncidentType, sort_reported_by: sortReportedBy } = params;
    const sortByColumn = !sortColumn && !sortIncidentType && !sortReportedBy;
    const newParams = sortByColumn ? { ...params, sort_column: 'created_at' } : params;

    setOnRequest(true);
    debounceIndexIncidentsRequest({
      dispatch,
      params: newParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Incidencia actualizada con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleUpdateRequest = values => {
    const {
      incident: { id }
    } = values;
    updateIncidentRequest(id, {
      dispatch,
      params: snakeCaseKeys(values, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        setCenteredModalShow(true);
        setModalTitle('Detalle Incidencia');
        setModalBody(<IncidentShow incident={camelCaseEmptyStringRecursive(item)} />);
        break;
      case 'edit':
        setCenteredModalShow(true);
        setModalBody(
          <IncidentForm
            action={action}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            submitVariant="success"
            incident={camelCaseEmptyStringRecursive(item)}
          />
        );
        setModalTitle('Editar Incidencia');
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalTitle('Eliminar Incidencia');
        setModalBody('¿Estás seguro que deseas eliminar esta incidencia?');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Incidencia eliminada con éxito' }));
    setMoreData(!moreData);
  };

  const handleModalConfirm = () => {
    const { id: incidentId } = modalItem;

    deleteIncidentRequest(incidentId, {
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'project_code':
        return { sort_column: 'project_id' };
      case 'incident_type':
        return { sort_incident_type: name };
      case 'reported_by':
        return { sort_reported_by: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={incidents}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
      {modalShow && (
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          handleClose={handleModalClose}
          handleConfirm={handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
    </>
  );
};

export default IncidentDataTable;
