import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { updateProjectRequest, createTemplateFromProjectRequest } from '../../requests/projects';
import ProjectWorkForm from './ProjectWorkForm';
import { SimpleCenteredModal } from '../../components';
import ProjectPublicContent from '../Project/Show/ProjectPublicContent';

const ProjectWorkEdit = ({ project, fromMechanicCard, setMoreData, isDragDisabled }) => {
  const { id } = project;
  const dispatch = useDispatch();
  const history = useHistory();
  const [sentProjectValues, setSentProjectValues] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [receptionImages, setReceptionImages] = useState([]);
  const [mechanicImages, setMechanicImages] = useState([]);
  const [preinspectionImages, setPreinspectionImages] = useState([]);

  const setImages = () => {
    const getImages = (attributes = [], reviewType) => {
      return attributes
        .filter(projectReview => projectReview.reviewType === reviewType)
        .map(projectReview => projectReview.imagesInfo, [])
        .flat();
    };
    const receptionImgs = getImages(project.projectReviewsAttributes, 'reception');
    const mechanicImgs = getImages(project.projectReviewsAttributes, 'mechanic');
    const preInspactionImgs = getImages(project.projectReviewsAttributes, 'pre_inspection');
    setReceptionImages(receptionImgs);
    setMechanicImages(mechanicImgs);
    setPreinspectionImages(preInspactionImgs);
  };

  useEffect(setImages, [project]);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Proyecto actualizado con éxito' }));
    setMoreData(m => !m);
  };

  const handleUpdateRequest = projectValues => {
    projectValues.project.projectWorksAttributes.forEach(work => {
      work.projectDetailsAttributes = work.projectDetailsAttributes.filter(workDetail => {
        return workDetail.categoryId !== '';
      });
    });
    if (projectValues.project.deskSell) {
      projectValues.project.projectWorksAttributes = projectValues.project.projectWorksAttributes.filter(work => {
        return !['PRODUCTOS','SERVICIOS','MANO DE OBRA'].includes(work.name) || work.projectDetailsAttributes.length > 0;
      });
    }
    if (sentProjectValues === JSON.stringify(projectValues)) return;
    updateProjectRequest(id, {
      dispatch,
      params: snakeCaseKeys(projectValues, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
    setSentProjectValues(JSON.stringify(projectValues));
  };

  const handleSuccessOnCreateTemplate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Template creado con éxito' }));
    history.push(`/templates`);
  };

  const handleCreateTemplate = () => {
    createTemplateFromProjectRequest(id, {
      dispatch,
      successCallback: handleSuccessOnCreateTemplate,
      failureCallback: handleFailureRequest
    });
  };

  const handlePreviewClick = () => {
    setModalBody(
      <ProjectPublicContent
        project={project}
        version={project.currentVersion}
        setMoreData={setMoreData}
        currentVersion={{ versionNumber: project.currentVersion, createdAt: project.createdAt, projectId: project.id }}
        receptionImages={receptionImages}
        mechanicImages={mechanicImages}
        preInspectionImages={preinspectionImages}
        privateProject={project}
        preview
      />
    );
    setModalShow(true);
  };

  return (
    <Container className="mt-2 bg-light p-2">
      <ProjectWorkForm
        project={project}
        formRequest={handleUpdateRequest}
        handleCreateTemplate={handleCreateTemplate}
        fromMechanicCard={fromMechanicCard}
        setMoreData={setMoreData}
        onPreviewClick={handlePreviewClick}
      />
      <SimpleCenteredModal
        closeButton
        title="Pre visualización"
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="90w"
      />
    </Container>
  );
};

export default ProjectWorkEdit;
