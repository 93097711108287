import React from 'react';
import { Form } from 'react-bootstrap';

const BasicTextArea = ({
  abbr,
  customClass,
  error,
  helpText,
  inputType,
  label,
  placeholder,
  row,
  size,
  touched,
  ...props
}) => (
  <Form.Group className="textarea-custom">
    {label && (
      <Form.Label>
        {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
      </Form.Label>
    )}

    <Form.Control
      as="textarea"
      rows={row}
      size={size}
      type={inputType}
      placeholder={placeholder}
      className={`${error && touched ? 'is-invalid' : ''} ${customClass || ''}`}
      {...props}
    />
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);

export default BasicTextArea;
