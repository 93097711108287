import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { format, startOfMonth } from "date-fns";
import camelCaseRecursive from "camelcase-keys-recursive";
import ComponentDataTable from '../../../../components/Utils/DataTable';
import {
  debounceindexProjectsInvoicesRequest,
  getDocumentRequest
} from '../../../../requests/projectInvoices';
import { sendAlert } from '../../../../actions/utils';
import downloadFile from '../../../../services/utils';
import { InputRemoteSelect } from "../../../../components";
import { debounceIndexClientsRequest } from "../../../../requests/clients";

const CreditNoteDataTable = ({ columns, moreData, setMoreData }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [projectInvoices, setProjectInvoices] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [clientId, setClientId] = useState('');
  const [clients, setClients] = useState([]);

  const handleDownloadInvoicePdf = (projectId, invoiceId) => {
    getDocumentRequest(projectId, invoiceId, {
      format: 'pdf',
      dispatch,
      successCallback: response => downloadFile(response),
      failureCallback: error => dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }))
    });
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseProjectInvoices = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setProjectInvoices(responseProjectInvoices);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      client_id: clientId,
      only_credit_notes: true
    }
    debounceindexProjectsInvoicesRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'project_code':
        return { sort_column: 'project_id' };
      case 'invoice_date':
        return { sort_column: 'created_at' };
      case 'parsed_amount':
        return { sort_column: 'amount' };
      case 'client_full_name':
        return { sort_by_client: name };
      default:
        return { sort_column: name };
    }
  };

  const fetchClients = (inputValue, callback) => {
    debounceIndexClientsRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.full_name,
        value: element.id
      };
    });
    setClients(camelCaseRecursive(tempArray));
    return tempArray;
  };

  const currentDate = new Date();

  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={columns(handleDownloadInvoicePdf)}
      handleSortCase={sortColumnCase}
      data={projectInvoices}
      totalRows={amount}
      moreData={moreData}
      startDate={format(startOfMonth(currentDate),'yyyy-MM-dd')}
      endDate={format(currentDate, 'yyyy-MM-dd')}
      withDate
      resourceRequest={response => {
        if (!onRequest) handleIndexRequest(response);
      }}
      firstCustomMediumSearch={
        <InputRemoteSelect
          isClearable
          placeholder="Cliente"
          defaultOptions={clients}
          value={clients.find(client => client.id === parseInt(clientId, 10))}
          onChange={data => {
            setClientId(data ? data.value : '');
            setMoreData(!moreData);
          }}
          request={fetchClients}
        />
      }
    />
  );
};

export default CreditNoteDataTable;
