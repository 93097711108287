import ApiService from '../services/apiService';

export const indexCalendarEventsRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.requestV2('get', `/calendar_events${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createCalendarEventsRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.requestV2('post', '/calendar_events', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const showCalendarEventRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.requestV2('get', `/calendar_events/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateCalendarEventsRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.requestV2('put', `/calendar_events/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteCalendarEventRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.requestV2('delete', `/calendar_events/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });
