/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { Eye } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import { LinkBtn } from '../../components';

const columns = memoize(() => [
  { name: 'ID', selector: 'id', sortable: true, grow: '0.5' },
  {
    name: 'FECHA DEL CREACIÓN',
    grow: '1.5',
    selector: 'log_date',
    sortable: true
  },
  {
    name: 'TIPO',
    selector: 'log_type_name',
    sortable: false,
    grow: '4'
  },
  {
    name: 'ID ENTIDAD',
    selector: 'entity_id',
    cell: item => (item.entity_id ? `${item.entity_name} ID ${item.entity_id}` : 'No existe registro'),
    sortable: false,
    grow: '1.5'
  },
  {
    name: 'REALIZADO POR',
    selector: 'responsable_name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'CONTACTO DEL RESPONSABLE',
    selector: 'responsable_email',
    sortable: true,
    grow: '2'
  },
  {
    name: '',
    cell: item => (
      <>
        <LinkBtn
          className="btn-circle mx-auto"
          variant="outline-info"
          data-tip
          data-for={`entity-${item?.entity_name}-show`}
          to={item.has_show ? `/${item?.entity_route}/${item?.entity_id}` : `/${item?.entity_route}`}
        >
          <Eye size={16} />
        </LinkBtn>
        <ReactTooltip id={`entity-${item.entity_name}-show`} place="top">
          Ir a {item?.entity_name}
        </ReactTooltip>
      </>
    ),
    sortable: false,
    grow: '1'
  }
]);

export default columns;
