import React, {useEffect, useState} from 'react';
import {withFormik, Form, Field, getIn} from 'formik';
import {Button, Col, Row, Alert} from 'react-bootstrap';
import {parse, addMonths, format} from 'date-fns';
import * as Yup from 'yup';
import {toInteger} from '../../../services/utils';
import {
  BasicTextArea,
  FormikDatePicker,
  FormikInput,
  FormikNumberFormat,
  FormikSelect,
  FormikCheckBox, SimpleCenteredModal
} from '../../../components';
import {
  invoiceStateTypes,
  otStateTypes,
  discountTypes,
  kanbanOt,
  kanbanOtEnviado,
  otQuoteStateTypes
} from './FormOptions';
import ConfirmRequest from "./ConfirmRequest";
import useCountryFactory from "../../../hooks/useCountryFactory";

const ProjectShowForm = props => {
  const {
    errors,
    project,
    setFieldValue,
    setFieldTouched,
    submitVariant,
    touched,
    from,
    isDeskSale,
    compact,
    isSubmitting,
    handleSubmit,
    values
  } = props;
  const {formatedPromiseDate, invoiceState, otState, priceDiscount, percentageDiscount, isQuote} = project;
  const [showAlert, setShowAlert] = useState(false);
  const countryFactory = useCountryFactory();
  const currentMaintenanceReminderDate = project?.vehicle?.formatedMaintenanceReminderDate || 'No especificada'
  const [maintenanceReminderDate, setMaintenanceReminderDate] = useState(currentMaintenanceReminderDate);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const {rememberMaintenance, projectDate, otState: currentOtState} = getIn(values, 'project');
  const parsedDate = parse(projectDate, 'dd/MM/yyyy HH:mm', new Date());
  const newMaintenanceReminderDate = project.company ? addMonths(parsedDate, project.company.maintenanceRecordatoryPeriod): null;
  const formattedNewMaintenanceReminderDate = newMaintenanceReminderDate ? format(newMaintenanceReminderDate, 'dd/MM/yyyy') : null;
  const parsedPromiseDate = formatedPromiseDate && parse(formatedPromiseDate, 'dd/MM/yyyy HH:mm', new Date());
  const [sendPromiseDate, setSendPromiseDate] = useState(parsedPromiseDate);
  const [discountType, setDiscountType] = useState('');
  const alertMessage = "Al cambiar estado a 'Entregado' se creará un Movimiento de Inventario a partir de esta OT";
  const alertMessageWarning = '(Sólo para Detalles de Trabajo que posean Cantidad y Valor Unitario)';

  const handleFormatting = e => {
    const formattedValue = e.target.value.replace(/\./g, '');
    setFieldValue(e.target.name, formattedValue);
  };

  const setDiscountTypeOnMount = () => {
    if (priceDiscount) {
      setDiscountType('price');
    }
    if (percentageDiscount) {
      setDiscountType('percentage');
    }
  };

  const handleChangeOfDiscountType = data => {
    setFieldValue('project[priceDiscount]', '');
    setFieldValue('project[percentageDiscount]', '');
    setDiscountType(data?.value);
  };

  const handleChangeOnMaintananceReminder = e => {
    if(e.target.checked) {
      setMaintenanceReminderDate(formattedNewMaintenanceReminderDate)
    } else {
      setMaintenanceReminderDate(currentMaintenanceReminderDate)
    }
    setFieldValue('project[rememberMaintenance]', e.target.checked);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleConfirmRequest = () => {
    setModalShow(false);
    setModalBody(
      <ConfirmRequest confirm={() => handleSubmit()} cancel={handleModalClose}/>
    );
    setModalShow(true);
  };

  const saveOtState = () => {
    if(currentOtState === 'budget_sent' && project.vehicle.formatedMaintenanceReminderDate === '' && !rememberMaintenance) {
      handleConfirmRequest();
    } else {
      handleSubmit();
    }
  };

  useEffect(setDiscountTypeOnMount, [priceDiscount, percentageDiscount]);

  return (
    <Form>
      <Row>
        {from === 'ProjectPromiseDateEdit' && (
          <Col md={12}>
            <Field name="project[promiseDate]">
              {({field}) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  label="Fecha estimada de entrega"
                  showTimeInput
                  timeInputLabel="Hora:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  selected={sendPromiseDate}
                  onChange={date => {
                    const assignDate = date || '';
                    setSendPromiseDate(assignDate);
                    setFieldValue(field.name, assignDate);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {from === 'KanbanModal' && (
          <>
            {showAlert && (
              <Alert variant="secondary">
                <p className="font-italic">{alertMessage}</p>
                <p className="font-weight-light mb-0">
                  <small>{alertMessageWarning}</small>
                </p>
              </Alert>
            )}
            <Col md={compact ? 6 : 12}>
              <Field name="project[promiseDate]">
                {({field}) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    label="Fecha estimada de entrega"
                    showTimeInput
                    timeInputLabel="Hora:"
                    dateFormat="dd/MM/yyyy HH:mm"
                    selected={sendPromiseDate}
                    onChange={date => {
                      const assignDate = date || '';
                      setSendPromiseDate(assignDate);
                      setFieldValue(field.name, assignDate);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={compact ? 6 : 12}>
              <Field name="project[mileage]">
                {({field}) => (
                  <FormikInput
                    {...field}
                    abbr={!isDeskSale}
                    inputType="number"
                    label={`Kilometraje${isDeskSale ? ' (opcional)' : ''}`}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={compact ? 6 : 12}>
              <Field name="project[otState]">
                {({field}) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Estado OT"
                    placeholder="Seleccionar Estado"
                    options={kanbanOt}
                    defaultValue={otState}
                    onChange={data => {
                      setShowAlert(false);
                      setFieldValue(field.name, data ? data.value : '');
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldTouched={() => setFieldTouched(field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={compact ? 6 : 12}>
              <Field name="project[rememberMaintenance]">
                {({field}) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="¿Recordar Mantención?"
                    onClick={handleChangeOnMaintananceReminder}
                    className="mt-3 mb-2"
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {from === 'kanbanSectionEnviado' && (
          <>
            {showAlert && (
              <Alert variant="secondary">
                <p className="font-italic">{alertMessage}</p>
                <p className="font-weight-light mb-0">
                  <small>{alertMessageWarning}</small>
                </p>
              </Alert>
            )}
            <Col md={compact ? 6 : 12}>
              <Field name="project[promiseDate]">
                {({field}) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    label="Fecha estimada de entrega"
                    showTimeInput
                    timeInputLabel="Hora:"
                    dateFormat="dd/MM/yyyy HH:mm"
                    selected={sendPromiseDate}
                    onChange={date => {
                      const assignDate = date || '';
                      setSendPromiseDate(assignDate);
                      setFieldValue(field.name, assignDate);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={compact ? 6 : 12}>
              <Field name="project[mileage]">
                {({field}) => (
                  <FormikInput
                    {...field}
                    abbr={!isDeskSale}
                    inputType="number"
                    label={`Kilometraje${isDeskSale ? ' (opcional)' : ''}`}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={compact ? 6 : 12}>
              <Field name="project[otState]">
                {({field}) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Estado OT"
                    placeholder="Seleccionar Estado"
                    options={kanbanOtEnviado}
                    defaultValue={otState}
                    onChange={data => {
                      setShowAlert(false);
                      setFieldValue(field.name, data ? data.value : '');
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldTouched={() => setFieldTouched(field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={compact ? 6 : 12}>
              <Field name="project[rememberMaintenance]">
                {({field}) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="¿Recordar Mantención?"
                    onClick={handleChangeOnMaintananceReminder}
                    className="mt-3 mb-2"
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {from === 'NavbarInfo' && (
          <>
            {showAlert && (
              <Alert variant="secondary">
                <p className="font-italic">{alertMessage}</p>
                <p className="font-weight-light mb-0">
                  <small>{alertMessageWarning}</small>
                </p>
              </Alert>
            )}
            <Col md={compact ? 6 : 12}>
              <Field name="project[promiseDate]">
                {({field}) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    label="Fecha estimada de entrega"
                    showTimeInput
                    timeInputLabel="Hora:"
                    dateFormat="dd/MM/yyyy HH:mm"
                    selected={sendPromiseDate}
                    onChange={date => {
                      const assignDate = date || '';
                      setSendPromiseDate(assignDate);
                      setFieldValue(field.name, assignDate);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={compact ? 6 : 12}>
              <Field name="project[mileage]">
                {({field}) => (
                  <FormikInput
                    {...field}
                    abbr={!isDeskSale}
                    inputType="number"
                    label={`Kilometraje${isDeskSale ? ' (opcional)' : ''}`}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={compact ? 6 : 12}>
              <Field name="project[otState]">
                {({field}) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Estado OT"
                    placeholder="Seleccionar Estado"
                    options={isQuote ? otQuoteStateTypes : otStateTypes}
                    defaultValue={otState}
                    onChange={data => {
                      setShowAlert(false);
                      setFieldValue(field.name, data ? data.value : '');
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldTouched={() => setFieldTouched(field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Alert variant="secondary">
                <p className="font-weight-bold mb-0">
                  Fecha de recordatorio de mantención:
                  <span className="font-weight-normal"> {maintenanceReminderDate}</span>
                </p>
                {!rememberMaintenance && (
                  <p className="font-weight-bold mb-0">Si activa este check la fecha cambiará a:
                    <span className="font-weight-normal"> {formattedNewMaintenanceReminderDate}</span>
                  </p>
                )}
              </Alert>
            </Col>
            <Col md={compact ? 6 : 12} className="position-relative" style={{zIndex: 0}}>
              <Field name="project[rememberMaintenance]">
                {({field}) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="¿Recordar Mantención?"
                    onClick={handleChangeOnMaintananceReminder}
                    className="ml-3 mb-2"
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {from === 'ProjectNotes' && (
          <Col md={12}>
            <Field name="project[notes]">
              {({field}) => (
                <BasicTextArea
                  {...field}
                  row="6"
                  label="Motivo / Notas"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {from === 'ProjectReceptionSecure' && (
          <>
            <Col md={12}>
              <Field name="project[sinisterNumber]">
                {({field}) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Número de Siniestro"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="project[ocNumber]">
                {({field}) => (
                  <FormikInput
                    {...field}
                    label="Número OC"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="project[deliveryDate]">
                {({field}) => (
                  <FormikDatePicker
                    {...field}
                    label="Fecha de Entrega"
                    selected={deliveryDate}
                    onChange={newDate => {
                      setDeliveryDate(newDate);
                      setFieldValue(field.name, newDate);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="project[orderNumber]">
                {({field}) => (
                  <FormikInput
                    {...field}
                    label="Número Pedido"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="project[notes]">
                {({field}) => (
                  <BasicTextArea
                    {...field}
                    row="6"
                    label="Motivo / Notas"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {from === 'ProjectNavbarBudget' && (
          <Col md={12}>
            <Field name="project[invoiceState]">
              {({field}) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Estado Cobro"
                  placeholder="Seleccionar Estado"
                  options={invoiceStateTypes}
                  defaultValue={invoiceState}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {from === 'ProjectRemarks' && (
          <Col md={12}>
            <Field name="project[remarks]">
              {({field}) => (
                <BasicTextArea
                  {...field}
                  row="6"
                  label="Observaciones"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {from === 'EditDiscount' && (
          <>
            <Col md={12} className="mb-2">
              <Alert variant="secondary">
                <p className="font-weight-bold mb-0">
                  Si agrega un descuento general, no podrá emitir comprobante detallado.
                </p>
              </Alert>
            </Col>
            <Col md={12}>
              <FormikSelect
                isClearable
                label="Tipo de descuento"
                placeholder="Seleccionar"
                options={discountTypes}
                defaultValue={discountTypes.find(type => type.value === discountType)}
                onChange={handleChangeOfDiscountType}
              />
            </Col>
            {discountType === 'price' && (
              <Col md={12}>
                <Field name="project[priceDiscount]">
                  {({field}) => (
                    <FormikNumberFormat
                      {...field}
                      leftAddon={countryFactory.getSymbol()}
                      label="Descuento [en pesos]"
                      onChange={handleFormatting}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
            {discountType === 'percentage' && (
              <Col md={12}>
                <Field name="project[percentageDiscount]">
                  {({field}) => (
                    <FormikNumberFormat
                      {...field}
                      leftAddon="%"
                      label="Descuento [porcentaje]"
                      onChange={handleFormatting}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
          </>
        )}
      </Row>

      <Row>
        <Col md={12}>
          <Button block variant={submitVariant} onClick={saveOtState} disabled={isSubmitting}>
            {isSubmitting ? 'Guardando...' : 'Guardar'}
          </Button>
        </Col>
      </Row>
      <SimpleCenteredModal
        size="md"
        title="Confirmar guardado"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </Form>
  );
};

const setInitialValues = props => {
  const {project} = props;
  const {formatedPromiseDate, percentageDiscount} = project;
  const parsedPromiseDate = formatedPromiseDate && parse(formatedPromiseDate, 'dd/MM/yyyy HH:mm', new Date());

  return {
    project: {...project, promiseDate: parsedPromiseDate, percentageDiscount: toInteger(percentageDiscount) || 0}
  };
};

const validationSchema = Yup.object().shape({
  project: Yup.object().shape({
    mileage: Yup.string().when('deskSale', {
      is: false,
      then: Yup.string().required('Debes ingresar un kilometraje')
    }),
    notes: Yup.string().nullable(),
    promiseDate: Yup.string().when('deskSale', {
      is: false,
      then: Yup.string().required('Debes ingresar una fecha estimada de entrega')
    }),
    otState: Yup.string().required('Debes seleccionar un estado OT'),
    percentageDiscount: Yup.number().test('is-percentage-valid', 'Porcentaje no válido', function isValidPercentage() {
      const {percentageDiscount} = this.parent;
      let isValid = true;
      if (percentageDiscount) {
        isValid = percentageDiscount < 100 && percentageDiscount > 0;
      }
      return isValid;
    }),
    priceDiscount: Yup.number().test(
      'is-discount-valid',
      'Descuento no válido. Descuento excede valor neto',
      function isValidDiscount() {
        const {priceDiscount, totalPrice} = this.parent;
        let isValid = true;
        if (priceDiscount) {
          isValid = parseInt(totalPrice, 10) - priceDiscount > 0;
        }
        return isValid;
      }
    )
  })
});

const validationSchemaReceptionSecure = Yup.object().shape({
  project: Yup.object().shape({
    sinisterNumber: Yup.string().required('Este campo es obligatorio')
  })
});

const handleSubmit = (values, {props, setSubmitting, isSubmitting}) => {
  if (!isSubmitting) {
    setSubmitting(true);
    const {formRequest} = props;
    formRequest(values);
  }
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema: ({from}) =>
    from === 'ProjectReceptionSecure' ? validationSchemaReceptionSecure : validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProjectShowForm);
