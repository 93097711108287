import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexPurchaseManagementsRequest = ({
  dispatch,
  format,
  params = {},
  successCallback,
  failureCallback
}) => {
  let formatt = '';
  if (format) formatt = `.${format}`;
  ApiService.request('get', `/purchase_managements${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showPurchaseManagementRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/purchase_managements/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createPurchaseManagementRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/purchase_managements', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updatePurchaseManagementRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/purchase_managements/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    showLeafArrayIndexes: true
  });

export const deletePurchaseManagementRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/purchase_managements/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const pendingDetailsPurchaseManagementsRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback
}) => {
  ApiService.request('get', '/purchase_managements/pending_details', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback
  });
};

export const debounceIndexPurchaseManagementsRequest = AwesomeDebouncePromise(indexPurchaseManagementsRequest, 300);
