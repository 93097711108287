import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { createProductRequest } from '../../requests/products';
import ProductForm from './ProductForm';
import basicProduct from './basicProduct';
import GoBackButton from "../../components/GoBackButton";

const ProductNew = () => {
  const modelUrl = '/products';

  const dispatch = useDispatch();
  const history = useHistory();

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessCreate = response => {
    const { id } = response.data;
    dispatch(sendAlert({ kind: 'success', message: 'Ítem creado con éxito' }));
    history.push(`${modelUrl}/${id}`);
  };

  const handleCreateRequest = values => {
    createProductRequest({
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <Row className="mt-4 mb-4">
            <Col>
              <h2>Crear Ítem</h2>
            </Col>
            <Col md={2}>
              <GoBackButton />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProductForm action="new" product={basicProduct} formRequest={handleCreateRequest} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProductNew;
