import React from 'react';
import { getIn, useFormikContext } from 'formik';

import ProjectWorkHourInput from './ProjectWorkHourInput';
import basicProjectWorkHour from './basicProjectWorkHour';
import ProjectWorkHoursNestedAttributes from '../../components/Utils/NestedAttributes/ProjectWorkHoursNestedAttributes';

const ProjectHoursAdd = ({ touched, errors, parentModel, users, projectWork }) => {
  const { setFieldValue, values } = useFormikContext();
  const modelName = `${parentModel}[projectWorkHoursAttributes]`;
  const projectWorkHoursAttributes = getIn(values, `[${parentModel}][projectWorkHoursAttributes]`);
  const mapResults = projectWorkHoursAttributes.map((projectWorkHour, index) => {
    if (projectWorkHour._destroy) {
      return undefined;
    }
    return (
      <ProjectWorkHourInput
        key={`project-work-hour-input-${index.toString()}`}
        index={index}
        modelName={modelName}
        errors={errors}
        touched={touched}
        users={users}
        projectWork={projectWork}
      />
    );
  });
  return (
    <ProjectWorkHoursNestedAttributes
      removeFirstItem
      mapInputs={mapResults}
      arrayValues={projectWorkHoursAttributes}
      setFieldValue={setFieldValue}
      valuePath={modelName}
      newAttributes={{ ...basicProjectWorkHour }}
      btnMessage="Agrega Hora de Trabajo"
    />
  );
};

export default ProjectHoursAdd;
