const basicProjectWorkHour = {
  id: '',
  accountableUserId: '',
  approved: false,
  date: '',
  details: '',
  hours: 0,
  minutes: 0,
  _destroy: ''
};

export default basicProjectWorkHour;
