import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { showProductRequest, updateProductRequest } from '../../requests/products';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicProduct from './basicProduct';
import ProductForm from './ProductForm';
import GoBackButton from "../../components/GoBackButton";

const ProductEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/products';

  const [product, setProduct] = useState(basicProduct);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Ítem actualizado con éxito' }));
    history.push(`${modelUrl}/${id}`);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response.data.message }));
  };

  const handleUpdateRequest = values => {
    updateProductRequest(id, {
      dispatch,
      params: snakeCaseKeys(values, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRequest = response => {
    const { data } = camelCaseEmptyStringRecursive(response);
    setProduct(data);
  };

  const fetchProduct = () => {
    showProductRequest(id, {
      dispatch,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchProduct, []);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col md={{ span: 8, offset: 2 }}>
          <h1>Editar Ítem</h1>
        </Col>
        <Col md={2}>
          <GoBackButton />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <ProductForm action="edit" product={product} formRequest={handleUpdateRequest} />
        </Col>
      </Row>
    </>
  );
};

export default ProductEdit;
