const manteinancePeriodOptions = [
  { label: '1 Mes', value: 1 },
  { label: '2 Meses', value: 2 },
  { label: '3 Meses', value: 3 },
  { label: '4 Meses', value: 4 },
  { label: '5 Meses', value: 5 },
  { label: '6 Meses', value: 6 },
  { label: '7 Meses', value: 7 },
  { label: '8 Meses', value: 8 },
  { label: '9 Meses', value: 9 },
  { label: '10 Meses', value: 10 },
  { label: '11 Meses', value: 11 },
  { label: '12 Meses', value: 11 }
];

export default manteinancePeriodOptions;
