import { shallowEqual, useSelector } from 'react-redux';

const useShallowEqualSelector = selector => useSelector(selector, shallowEqual);

export const useIsAdminOrWorkshopManager = () => {
  return useShallowEqualSelector(state => {
    const { user } = state.auth;
    const hasAdminRole = user?.role?.includes('admin');
    const hasWorkshopManagerRole = user?.role?.includes('workshop_manager');
    return hasAdminRole || hasWorkshopManagerRole;
  });
};
