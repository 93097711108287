import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Form, getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import * as Yup from 'yup';

import { showCompanyRequest } from '../../../requests/companies';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { indexVehiclesRequest } from '../../../requests/vehicles';
import ClientTab from '../../../components/Client/ClientTab';
import VehicleTab from '../../../components/Vehicle/VehicleTab';
import CalendarEventTab from './CalendarEventTab';
import basicCompany from '../../Company/basicCompany';
import basicVehicle from '../../Vehicle/basicVehicle';
import CalendarEventAdditionalInfoTab from "../../../components/CalendarEvent/CalendarEventAdditionalInfoTab";

const CalendarEventForm = props => {
  const {
    action,
    calendarEvent,
    errors,
    handleClose,
    handleRemove,
    onHide,
    setFieldValue,
    submitVariant,
    touched,
    values,
    setClassModalBody
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';
  const [clientDisabled, setClientDisabled] = useState(false);
  const [currentCompany, setCompany] = useState(basicCompany);
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [newClient, setNewClient] = useState(false);
  const [newVehicle, setNewVehicle] = useState(false);
  const [vehicleDisabled, setVehicleDisabled] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleSelected, setVehicleSelected] = useState('');
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const currentValues = getIn(values, 'calendarEvent');
  const currentClientId = currentValues.clientId;

  const resultFetchData = useCallback(response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.plate,
        value: element.id
      };
    });
    setVehicles(camelCaseRecursive(tempArray));
    return tempArray;
  }, []);

  const fetchInitialVehicles = useCallback(
    params => {
      indexVehiclesRequest(params.clientId, {
        dispatch: stableDispatch,
        params: {
          ...params,
          sort_column: 'plate',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: resultFetchData
      });
    },
    [stableDispatch, resultFetchData]
  );

  const fetchCurrentCompany = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(fetchCurrentCompany, []);

  useEffect(() => {
    if (currentValues.orderType === 'reception_secure') {
      if (currentValues.secondClientId) fetchInitialVehicles({ clientId: currentValues.secondClientId });
    } else if (currentClientId) fetchInitialVehicles({ clientId: currentClientId });
  }, [currentClientId, fetchInitialVehicles]);

  useEffect(() => {
    if (currentValues.vehicleId === calendarEvent.vehicleId) setCurrentVehicle(currentValues.vehicle);
  }, [currentValues, calendarEvent]);

  const handleClientChange = (value, isFirstClient=true) => {
    if (currentValues.orderType === 'reception_secure') {
      if (isFirstClient) {
        setFieldValue('calendarEvent[clientId]', value);
        setFieldValue('calendarEvent[vehicleId]', '');
      } else if (value !== '') fetchInitialVehicles({ clientId: value });
    } else {
      setFieldValue('calendarEvent[clientId]', value);
      setFieldValue('calendarEvent[vehicleId]', '');
      if (value !== '') fetchInitialVehicles({ clientId: value });
    }
  };

  const resetVehicle = () => {
    setCurrentVehicle({});
    setVehicleSelected('');
    if (currentValues) setFieldValue('calendarEvent[vehicleId]', '');
  };

  useEffect(() => {
    if (currentValues.orderType === 'reception_secure') {
      setClassModalBody('mh-640');
    } else {
      setClassModalBody('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues]);

  return (
    <>
      <Form>
        <Row className="change-modal-order mt-3">
          <Col md={3}>
            <CalendarEventTab
              calendarEvent={calendarEvent}
              setFieldValue={setFieldValue}
              currentValues={currentValues}
              edit={action === 'edit'}
              errors={errors}
              touched={touched}
            />
          </Col>
          <Col md={3} className="mb-3 d-md-none">
            <CalendarEventAdditionalInfoTab currentValues={currentValues} setFieldValue={setFieldValue} />
          </Col>
          <Col md={3} className={`${!newClient ? 'mobile-mt-3' : ''}`}>
            <ClientTab
              abbr={currentValues.orderType === 'desk_sell'}
              modelKey="calendarEvent"
              currentModel={calendarEvent}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
              newClient={newClient}
              setNewClient={setNewClient}
              clientDisabled={clientDisabled}
              companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
              setVehicleDisabled={setVehicleDisabled}
              handleClientChange={handleClientChange}
              newVehicle={newVehicle}
              resetVehicle={resetVehicle}
            />
          </Col>
          <Col md={3} className={`${!newVehicle ? 'mobile-mt-3' : ''}`}>
            <VehicleTab
              modelKey="calendarEvent"
              currentModel={calendarEvent}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
              companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
              currentVehicle={currentVehicle}
              setCurrentVehicle={setCurrentVehicle}
              newVehicle={newVehicle}
              setNewVehicle={setNewVehicle}
              vehicleDisabled={vehicleDisabled}
              setVehicleDisabled={setVehicleDisabled}
              vehicleSelected={vehicleSelected}
              setVehicleSelected={setVehicleSelected}
              setClientDisabled={setClientDisabled}
              vehicles={vehicles}
              newClient={newClient}
              resetVehicle={resetVehicle}
            />
          </Col>
          <Col md={3} className="mb-3 d-none d-md-block">
            <CalendarEventAdditionalInfoTab currentValues={currentValues} setFieldValue={setFieldValue} />
          </Col>
        </Row>

        {!(newClient || newVehicle) && (
          <Row className="d-flex justify-content-end mb-4">
            {handleRemove && (
              <Col md={3}>
                <Row>
                  <Col md={6}>
                    <Button className="btn" variant="danger" block onClick={() => handleRemove(calendarEvent.id)}>
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
            <Col md={{ span: 3, offset: 0 }} />
            <Col md={{ span: 3, offset: 3 }}>
              <Row>
                <Col md={6}>
                  <Button className="btn cancel mb-2" variant="primary" block onClick={handleClose}>
                    Cancelar
                  </Button>
                </Col>
                <Col md={6}>
                  <Button type="submit" variant={submitVariant} block onClick={onHide}>
                    {btnMessage}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
      {newClient && (
        <ClientTab
          createClient
          modelKey="calendarEvent"
          companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
          currentModel={calendarEvent}
          setFieldValue={setFieldValue}
          values={values}
          formSize={{ span: 3, offset: 3 }}
          formClassName="modal-new-form"
          setCurrentVehicle={setCurrentVehicle}
          setNewClient={setNewClient}
          setVehicleDisabled={setVehicleDisabled}
          resetVehicle={resetVehicle}
        />
      )}
      {newVehicle && (
        <VehicleTab
          createVehicle
          modelKey="calendarEvent"
          companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
          currentModel={calendarEvent}
          values={values}
          formSize={{ span: 3, offset: 6 }}
          formClassName="modal-new-form"
          fetchInitialVehicles={fetchInitialVehicles}
          setClientDisabled={setClientDisabled}
          currentVehicle={currentVehicle || { ...basicVehicle, vtype: 'vehicle' }}
          setCurrentVehicle={setCurrentVehicle}
          setNewVehicle={setNewVehicle}
          setVehicleDisabled={setVehicleDisabled}
          setVehicleSelected={setVehicleSelected}
          vehicles={vehicles}
          resetVehicle={resetVehicle}
        />
      )}
    </>
  );
};

const setInitialValues = ({ calendarEvent }) => {
  const { promiseDate } = calendarEvent;
  let promiseParseDate = '';
  let promiseTime = '';
  if (promiseDate) {
    promiseParseDate = moment(promiseDate, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY');
    promiseTime = moment(promiseDate, 'DD/MM/YYYY HH:mm').format('HH:mm');
  }
  return { calendarEvent: { ...calendarEvent, promiseParseDate, promiseTime } };
};

const validationSchema = Yup.object().shape({
  calendarEvent: Yup.object().shape({
    clientId: Yup.string()
      .nullable()
      .when('orderType', (orderType, schema) => {
        return orderType === 'desk_sell' ? schema.required('Debes seleccionar un Cliente') : schema;
      }),
    sinisterNumber: Yup.string()
      .nullable()
      .when('orderType', (orderType, schema) => {
        return orderType === 'reception_secure' ? schema.required('Este campo es obligatorio') : schema;
      }),
    endDate: Yup.string().required('Debes ingresar una hora de término'),
    notes: Yup.string().nullable(),
    promiseParseDate: Yup.string().nullable(),
    promiseTime: Yup.string()
      .nullable()
      .when('promiseParseDate', (promiseParseDate, schema) => {
        return promiseParseDate ? schema.required('Debes ingresar una hora de entrega') : schema;
      }),
    startDate: Yup.string()
      .required('Debes ingresar una hora de inicio')
      .when('endDate', (endDate, schema, element) => {
        const validation = moment(element.parent.startDate).isAfter(endDate);
        return (
          (validation && schema.min(endDate, 'La hora de inicio no puede ser mayor que la hora de término')) || schema
        );
      }),
    vehicleId: Yup.string().nullable()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  if (values.calendarEvent.promiseParseDate && values.calendarEvent.promiseTime) {
    values.calendarEvent.promiseDate = moment(
      `${values.calendarEvent.promiseParseDate} ${values.calendarEvent.promiseTime}`,
      'DD/MM/YYYY HH:mm'
    ).toDate();
  }
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CalendarEventForm);
