import React from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import RemoteSelect from './RemoteSelect';

const initialDefaultValue = props => {
  const { isMulti, model, values, value } = props;
  if (!model) return value;
  const [defaultModel, attribute] = model;
  if (values) {
    const { [`${attribute}${isMulti ? 'Ids' : 'Id'}`]: vAttributeId } = values;
    const { [`${attribute}${isMulti ? 'Ids' : 'Id'}`]: attributeId } = defaultModel;
    if (attributeId && vAttributeId && attributeId === vAttributeId) {
      const { [`${attribute}${isMulti ? 's' : ''}`]: atributes } = defaultModel;
      return atributes;
    }
    if ((isMulti && vAttributeId.length === 0) || vAttributeId === '') return null;
  }
  return undefined;
};

const InputRemoteSelect = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  error,
  helpText,
  label,
  request,
  tooltipText,
  touched,
  ...props
}) => (
  <Form.Group className={`${disabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''}`}>
    {label && (
      <Form.Label className="d-flex align-items-center">
        {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        {tooltipText && (
          <ButtonToolbar className="ml-2">
            <OverlayTrigger
              key={direction}
              placement={direction}
              delay={{ show: delayShow, hide: delayHide }}
              overlay={<Tooltip>{tooltipText}</Tooltip>}
            >
              ?
            </OverlayTrigger>
          </ButtonToolbar>
        )}
      </Form.Label>
    )}
    <RemoteSelect {...props} value={initialDefaultValue(props)} loadOptions={request} disabled={disabled} />
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);

InputRemoteSelect.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

InputRemoteSelect.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};

export default InputRemoteSelect;
