import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showWorkRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/works/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexWorksRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/works${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createWorkRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/works', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateWorkRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/works/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteWorkRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/works/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexWorksRequest = AwesomeDebouncePromise(indexWorksRequest, 300);
