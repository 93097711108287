const basicProduct = {
  id: '',
  active: true,
  code: '',
  companyId: '',
  cost: '',
  costQuotation: '',
  description: '',
  inventoryable: false,
  productCategory: {},
  productCategoryId: '',
  provider: {},
  providerCode: '',
  providerId: '',
  saleName: '',
  salePrice: '',
  sku: '',
  unit: '',
  vehicleBrand: {},
  vehicleBrandId: '',
  vehicleModel: {},
  vehicleModelId: ''
};

export default basicProduct;
