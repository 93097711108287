/* eslint-disable import/prefer-default-export */
// import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const updateProjectWorkRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/project_works/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

// export const debounceIndexProjectWorksRequest = AwesomeDebouncePromise(indexProjectWorkHoursRequest, 300);
