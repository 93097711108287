const columnsInvoiceMenu = [
    {
        name: 'Ingresos de Ventas',
        path: '/sales_income',
        can: 'ProjectInvoice'
    },
    {
        name: 'Libro de Venta',
        path: '/project_invoices',
        can: 'ProjectInvoice'
    },
    {
        name: 'Libros de Compra',
        path: '/purchase_books',
        can: 'ProjectInvoice'
    },
    {
        name: 'Notas de Crédito',
        path: '/credit_notes',
        can: 'ProjectInvoice'
    },
    {
        name: 'Listado de Cobranzas',
        path: '/collections',
        can: 'ProjectInvoice'
    },
    {
        name: 'Abonos',
        path: '/project_payments',
        can: 'ProjectPayment'
    }
];

export default columnsInvoiceMenu;