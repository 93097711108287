import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { HelpCircle } from 'react-feather';

import { sendAlert } from '../../../actions/utils';
import { formatToPrice } from '../../../services/utils';
import { SimpleCenteredModal } from '../../../components';
import { indexProjectsRequest } from '../../../requests/projects';
import { Can } from '../../../config/abilityContext';

import ColorGlossary from './ColorGlossary';
import MechanicCard from './MechanicCard';
import ProjectCard from './ProjectCard';

const ProjectVehicleReviewIndex = ({ match }) => {
  const viewType = match.url.split('_')[1];
  const isMechanicView = viewType === 'mechanic';
  const viewTitle = { mechanic: 'En Carpeta', vehicle: 'Revisión de Vehículos' };
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();

  const { user: currentUser } = useSelector(state => state.auth);
  const { currentBranchOffice } = currentUser;

  const translatedTitles = { inProcess: 'En proceso', budgetSent: 'Presupuesto enviado', working: 'En trabajo' };
  const allowedOtState = {
    inProcess: ['in_process'],
    budgetSent: ['budget_sent'],
    working: ['budget_approved', 'ot_completed', 'warranty', 'partial_budget']
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessRequest = response => {
    const { data } = response.data;
    setProjects(camelCaseRecursive(data));
    setOnRequest(false);
  };

  const fetchProjects = () => {
    setProjects([]);
    setOnRequest(true);
    const sendParams = {
      branch_office_id: currentBranchOffice.value,
      exclude_state: 'finalized',
      sort_column: 'code',
      sort_direction: 'desc',
      display_length: 1000
    };
    if (!isMechanicView) sendParams.exclude_project_progress = ['created', 'reviewed'];

    indexProjectsRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchProjects, [currentBranchOffice, viewType]);

  return (
    <Row>
      {onRequest && (
        <div className="containerSpinnerLoad position-absolute h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Col md={11} className="mx-auto" xs={12}>
        <Row className="my-4">
          <Col>
            <h2 className="text-uppercase">
              {viewTitle[viewType]}
              {isMechanicView && (
                <HelpCircle
                  role="button"
                  width="17px"
                  height="17px"
                  className="ml-1 mb-1"
                  onClick={() => setModalShow(true)}
                />
              )}
            </h2>
            <SimpleCenteredModal
              size="md"
              title="Estados"
              body={<ColorGlossary />}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </Col>
        </Row>
        <Row>
          {isMechanicView
            ? Object.entries(allowedOtState).map(([title, otStates], titleIndex) => {
                const groupedProjects = projects.filter(project => otStates.includes(project.otState));
                const value = groupedProjects.reduce((accum, project) => parseInt(project.totalPrice, 10) + accum, 0);
                return (
                  <Col md={4} key={`title-${titleIndex.toString()}`} className="text-uppercase">
                    <div>
                      <h3 className={`font-weight-bold mb-4 m-title-index-${titleIndex}`}>{translatedTitles[title]}</h3>
                      <Can I="manage" a="All">
                        <h3 className={`font-weight-bold mb-4 m-title-index-${titleIndex}`}>
                          Total neto: {formatToPrice(value)}
                        </h3>
                      </Can>
                    </div>
                    <Row className={`mechanic-card-index-${titleIndex} d-block`}>
                      {projects.map(
                        (project, index) =>
                          otStates.includes(project.otState) && (
                            <MechanicCard
                              key={`project-mechanic-review-${index.toString()}`}
                              project={project}
                              title={title}
                            />
                          )
                      )}
                    </Row>
                  </Col>
                );
              })
            : projects.map(
                (project, index) =>
                  !isMechanicView && (
                    <ProjectCard key={`project-vehicle-review-${index.toString()}`} project={project} />
                  )
              )}
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectVehicleReviewIndex;
