import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexIncidentTypesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/incident_types', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createIncidentTypeRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/incident_types', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateIncidentTypeRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/incident_types/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteIncidentTypeRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/incident_types/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexIncidentTypesRequest = AwesomeDebouncePromise(indexIncidentTypesRequest, 300);
