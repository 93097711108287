import React from 'react';
import DatePicker from 'react-datepicker';
import { es } from "date-fns/locale";
import { format, parseISO } from "date-fns";

const InputDate = ({placeholderText, selected, onChange}) => {
  return (
    <DatePicker
      placeholderText={placeholderText}
      dateFormat="dd-MM-yyyy"
      className="form-control"
      showYearDropdown
      selected={selected ? parseISO(selected) : ''}
      onChange={date => {
        const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
        onChange(formattedDate);
      }}
      locale={es}
    />
  );
}
export default InputDate;