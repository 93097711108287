import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { InputRemoteSelect } from '../Utils/Select';
import { FormikInput } from '../Utils/Input';
import { createVehicleRequest, indexVehiclesRequest } from '../../requests/vehicles';
import VehicleForm from '../../screens/Vehicle/VehicleForm';
import basicVehicle from '../../screens/Vehicle/basicVehicle';
import { getHistoricVehicleRequest } from '../../requests/historicVehicles';

const newBasicVehicle = {
  ...basicVehicle,
  vtype: 'vehicle'
};

const VehicleTab = props => {
  const {
    abbr,
    currentModel,
    modelKey,
    createVehicle,
    companyRequiredFieldAttributes,
    formSize,
    newVehicle,
    setNewVehicle,
    vehicleSelected,
    setVehicleSelected,
    currentVehicle,
    setCurrentVehicle,
    vehicleDisabled,
    setVehicleDisabled,
    setClientDisabled,
    vehicles,
    fetchInitialVehicles,
    resetVehicle,
    newClient,
    formClassName
  } = props;
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const currentValues = getIn(values, modelKey);
  const clientId = currentValues && currentValues.clientId;
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [inputValue, setInputValue] = useState('');

  const btnCreateVehicle = useCallback(() => {
    setInputValue('');
    setNewVehicle(true);
    setClientDisabled(true);
    if (!currentModel.vehicleId || currentValues.vehicleId !== currentModel.vehicleId) {
      resetVehicle();
    }
  }, [currentModel.vehicleId, currentValues.vehicleId, resetVehicle, setClientDisabled, setNewVehicle]);

  const setFirstVehiclesOption = array => {
    if (array.length === 0 || array[0].label !== 'Crear Vehículo')
      array.unshift({ label: 'Crear Vehículo', plate: '', value: 0, id: 0, custom: true, isFixed: true });
    return camelCaseRecursive(array);
  };

  const handleSuccessFetch = useCallback(
    response => {
      const vehicleData = camelCaseRecursive(response.data);
      setCurrentVehicle({
        ...newBasicVehicle,
        plate: vehicleData.plate,
        year: vehicleData.year,
        vehicleBrand: vehicleData.vehicleBrand,
        vehicleModel: vehicleData.vehicleModel,
        vehicleBrandId: vehicleData.vehicleBrand.id,
        vehicleModelId: vehicleData.vehicleModel.id,
        vehicleBrandName: vehicleData.vehicleBrand.name,
        vehicleModelName: vehicleData.vehicleModel.name
      });
    },
    [setCurrentVehicle]
  );

  const handleEmptyFetch = useCallback(() => {
    setCurrentVehicle({ ...newBasicVehicle, plate: inputValue });
  }, [setCurrentVehicle, inputValue]);

  const newCurrentVehicle = useCallback(
    (modelKeyValues, allVehicles) => {
      if (modelKeyValues) {
        const currentVehicleId = vehicleSelected || modelKeyValues.vehicleId;
        const filteredVehicle = allVehicles.filter(v => v.id === currentVehicleId);
        if (filteredVehicle.length && modelKeyValues.vehicleId !== vehicleSelected) {
          if (filteredVehicle[0].label === 'Crear Vehículo') {
            if (inputValue !== '') {
              getHistoricVehicleRequest(inputValue, {
                dispatch,
                successCallback: handleSuccessFetch,
                failureCallback: handleEmptyFetch
              });
            }
            btnCreateVehicle();
          } else {
            setCurrentVehicle(filteredVehicle[0]);
            if (currentVehicle) setFieldValue(`${modelKey}[vehicleId]`, currentVehicle.id);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modelKey, setFieldValue, vehicleSelected, currentVehicle, setCurrentVehicle]
  );

  useEffect(() => {
    if (vehicles.length) return newCurrentVehicle(currentValues, vehicles);

    if (currentValues.vehicleId) return setCurrentVehicle(currentValues.vehicle);
    return undefined;
  }, [currentValues, vehicles, newCurrentVehicle, setCurrentVehicle]);

  useEffect(() => {
    if(currentValues?.orderType === 'reception_secure'){
      if (!newClient) setVehicleDisabled(!currentValues.secondClientId);
    } else if (!newClient) setVehicleDisabled(!currentValues.clientId);
  }, [currentValues, newClient, setVehicleDisabled]);

  const resultFetchVehicles = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.plate,
        value: element.id
      };
    });
    tempArray.unshift({ label: 'Crear Vehículo', plate: '', value: 0, id: 0, custom: true, isFixed: true });
    return camelCaseRecursive(tempArray);
  };

  const fetchVehicles = (value, callback) => {
    if (clientId !== '') {
      setInputValue(value.toUpperCase());
      indexVehiclesRequest(clientId, {
        dispatch: stableDispatch,
        params: {
          query: value,
          for_selector: true,
          sort_column: 'plate',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: data => callback(resultFetchVehicles(data))
      });
    }
  };

  const handleSuccessRequest = response => {
    resetVehicle();
    setClientDisabled(false);
    setVehicleSelected(response.data.id);
    fetchInitialVehicles({ clientId });
    dispatch(sendAlert({ kind: 'success', message: 'Vehículo creado con éxito' }));
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = params => {
    const sendParams = snakeCaseKeys(params);
    sendParams.vehicle.client_id = clientId;
    createVehicleRequest(clientId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };
  const vehicleValue = () => {
    if (currentVehicle) {
      return { label: currentVehicle.label, value: currentVehicle.id };
    }
    return undefined;
  };

  return (
    <>
      {createVehicle ? (
        <VehicleForm
          vehicleTab
          companyRequiredFieldAttributes={companyRequiredFieldAttributes}
          modelKey={modelKey}
          vehicle={currentVehicle}
          action="new"
          formSize={formSize}
          formClassName={formClassName}
          formRequest={handleCreateRequest}
          onHide={() => {
            setInputValue('');
            setNewVehicle(false);
            setClientDisabled(false);
            resetVehicle();
          }}
        />
      ) : (
        !newVehicle && (
          <Row>
            <Col md={12}>
              <Field name={`${modelKey}[vehicleId]`}>
                {({ field }) => (
                  <InputRemoteSelect
                    {...field}
                    abbr={abbr}
                    label="Vehículo"
                    placeholder="Seleccionar Vehículo"
                    isDisabled={vehicleDisabled}
                    defaultOptions={setFirstVehiclesOption(vehicles)}
                    value={vehicleValue()}
                    onChange={data => {
                      setVehicleSelected('');
                      setCurrentVehicle(camelCaseRecursive(data));
                      setFieldValue(field.name, data ? data.id : '');
                    }}
                    request={fetchVehicles}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
              {currentValues.orderType === 'desk_sell' && (
                <div className="mt-2">
                  <Field name={`${modelKey}[mileage]`}>
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        inputType="number"
                        label="Kilometraje (opcional)"
                        disabled={vehicleDisabled}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </div>
              )}
            </Col>
            {currentVehicle && Object.keys(currentVehicle).length !== 0 && (
              <>
                <Col md={12}>
                  <p>{currentVehicle.plate}</p>
                </Col>
                <Col md={12}>
                  <p>{currentVehicle.vehicleBrandName}</p>
                </Col>
                <Col md={12}>
                  <p>{currentVehicle.vehicleModelName}</p>
                </Col>
                <Col md={12}>
                  <p>{currentVehicle.vin}</p>
                </Col>
                <Col md={12}>
                  <p>{currentVehicle.year}</p>
                </Col>
              </>
            )}
          </Row>
        )
      )}
    </>
  );
};

export default VehicleTab;
