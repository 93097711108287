import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

const FormikNumberFormat = ({ abbr, disabled, error, helpText, label, leftAddon, rightAddon, touched, ...props }) => (
  <Form.Group className={error && touched ? 'is-invalid' : ''}>
    {label && (
      <Form.Label>
        {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
      </Form.Label>
    )}

    <InputGroup>
      {leftAddon && (
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">{leftAddon}</InputGroup.Text>
        </InputGroup.Prepend>
      )}
      <NumberFormat
        allowEmptyFormatting
        className="form-control"
        disabled={disabled}
        allowNegative={false}
        customInput={Form.Control}
        decimalScale={0}
        decimalSeparator=","
        thousandSeparator="."
        {...props}
      />
      {rightAddon && (
        <InputGroup.Append>
          <InputGroup.Text id="basic-addon2">{rightAddon}</InputGroup.Text>
        </InputGroup.Append>
      )}
    </InputGroup>

    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);

export default FormikNumberFormat;
