import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTemplateRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/templates${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createTemplateRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/templates', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateTemplateRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/templates/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteTemplateRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/templates/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexTemplatesRequest = AwesomeDebouncePromise(indexTemplateRequest, 300);
