import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { SimpleCenteredModal } from '../../components';
import BulkUploadDataTable from './BulkUploadDataTable';
import columns from './Columns';
import { sendAlert } from '../../actions/utils';
import { createBulkUploadRequest } from '../../requests/bulkUploads';
import BulkUploadForm from './BulkUploadForm';

const basicBulkUpload = {
  model: 'product',
  file: undefined
};

const BulkUpload = () => {
  const dispatch = useDispatch();

  const [modalBody, setModalBody] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Carga masiva registrada con éxito' }));
    setMoreData(!moreData);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    handleModalClose();
    createBulkUploadRequest({
      dispatch,
      params: values,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  const handleModalCreate = () => {
    setModalBody(
      <BulkUploadForm
        action="new"
        dispatch={dispatch}
        formRequest={handleCreateRequest}
        handleModalClose={handleModalClose}
        submitVariant="success"
        bulkUpload={basicBulkUpload}
      />
    );
    setModalShow(true);
    setModalTitle('Crear carga masiva');
  };

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">Cargas masivas</h2>
            </Col>
            <Col md={2}>
              <Button variant="primary" block onClick={handleModalCreate}>
                Crear
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <BulkUploadDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />
            </Col>
          </Row>
          <SimpleCenteredModal
            title={modalTitle}
            body={modalBody}
            show={modalShow}
            onHide={handleModalClose}
            centered={false}
            closeButton
          />
        </Col>
      </Row>
    </>
  );
};

export default BulkUpload;
