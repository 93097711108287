import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

const BasicDropdown = ({ variant, titleDrop, items, noArrow, right, small, classColor }) => {
  const basicClassNames = `${noArrow && 'dropdown-no-arrow'} ${right && 'float-right'} ${small && 'btn-sm'}`;
  return (
    <Dropdown>
      <Dropdown.Toggle variant={variant} id="dropdown-basic" className={`${basicClassNames} ${classColor || ''}`}>
        {titleDrop}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map(item => (
          <Dropdown.Item href={item.path} key={item.key} onClick={item.onClick} {...item.props}>
            {item.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

BasicDropdown.propTypes = {
  variant: PropTypes.string,
  titleDrop: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired
};

BasicDropdown.defaultProps = {
  variant: ''
};

export default BasicDropdown;
