/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  { name: 'ID', selector: 'id', sortable: true, grow: '1' },
  { name: 'NOMBRE', selector: 'name', sortable: true, grow: '6' },
  { name: 'NETO', selector: 'parsed_net', sortable: true, grow: '1' },
  { name: 'IVA', selector: 'parsed_iva', sortable: true, grow: '1' },
  { name: 'TOTAL', selector: 'parsed_total', sortable: true, grow: '1' },
  { name: 'CREACIÓN', selector: 'created_at', sortable: true, grow: '1' },
  {
    cell: item => <BasicActionBtns item={item} clickHandler={clickHandler} withShow={false} editClick />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '140px'
  }
]);

export default columns;
