import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import ProductInventoryCompanyDataTable from './ProductInventoryCompanyDataTable';
import columns from './Columns';
import { companyIndexProductInventoriesRequest } from '../../requests/productInventories';
import downloadFile from '../../services/utils';

const ProductInventoryCompanyIndex = () => {
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const [queries, setQueries] = useState({});

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const downloadIndex = (format, params) => {
    companyIndexProductInventoriesRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload
    });
  };

  return (
    <>
      <Row>
        <Col md={11} className="mx-auto" xs={12}>
          <Row className="mt-4">
            <Col md={6}>
              <h2 className="text-uppercase">Inventario Empresa</h2>
            </Col>
            <Col md={{ span: 2, offset: 4 }}>
              <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
                Exportar
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ProductInventoryCompanyDataTable
                columns={columns}
                moreData={moreData}
                setMoreData={setMoreData}
                setQueries={setQueries}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProductInventoryCompanyIndex;
