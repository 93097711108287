import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HelpCircle } from 'react-feather';
import Search from './search/Search';
import FilterBySeller from './filters/FilterBySeller';
import FilterByUser from './filters/FilterByUser';
import FilterByPromise from './filters/FilterByPromise';
import { indexUsersRequest } from '../../../../app/requests/user';
import ColorGlossary from '../../../../app/screens/Project/VehicleReview/ColorGlossary';
import { SimpleCenteredModal } from '../../../../app/components';

const KanbanHeader = ({ onSearchChange, onFilterChange }) => {
  const [modalShow, setModalShow] = useState(false);

  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  const resultFetchUsers = response => {
    const result = response.data.data;
    setUsers(result);
    return result;
  };

  const fetchInitialUsers = useCallback(
    params => {
      indexUsersRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          sort_user_by_fullname: 'ASC',
          display_length: 50,
          for_selector: true
        },
        successCallback: response => {
          setUsers(resultFetchUsers(response));
        }
      });
    },
    [stableDispatch]
  );

  useEffect(() => {
    fetchInitialUsers();
  }, [fetchInitialUsers]);

  return (
    <div>
      <h3 className="kanban-tittle-principal">
        Torre de control
        <HelpCircle role="button" width="17px" height="17px" className="ml-1 mb-1" onClick={() => setModalShow(true)} />
      </h3>
      <SimpleCenteredModal
        size="md"
        title="Estados"
        body={<ColorGlossary />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="kanban-header-filters">
        <div className="search-seller">
          <Search onChange={onSearchChange} />
          <FilterBySeller users={users} onFilterChange={onFilterChange} />
        </div>
        <div className="search-users">
          <FilterByUser users={users} onFilterChange={onFilterChange} />
          <FilterByPromise onFilterChange={onFilterChange} />
        </div>
      </div>
    </div>
  );
};

export default KanbanHeader;
