import React from 'react';
import NestedAttributes from '../../components/Utils/NestedAttributes/NestedAttributes';
import WorkInput from './WorkInput';

const TemplateWorkAdd = ({ touched, errors, setFieldValue, values, templateWorks }) => {
  const {
    template: { templateWorksAttributes }
  } = values;

  const mapResults = templateWorksAttributes.map((body, index) => {
    if (body._destroy) {
      return undefined;
    }

    return (
      <WorkInput
        key={`templateWork-${index.toString()}`}
        index={index}
        touched={touched}
        errors={errors}
        setFieldValue={setFieldValue}
        templateWork={templateWorks[index]}
        values={values}
      />
    );
  });

  return (
    <NestedAttributes
      removeFirstItem
      mapInputs={mapResults}
      arrayValues={templateWorksAttributes}
      setFieldValue={setFieldValue}
      valuePath="template[templateWorksAttributes]"
      newAttributes={{ workId: '' }}
      btnMessage="Agregar nuevo trabajo"
    />
  );
};

export default TemplateWorkAdd;
