/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { Plus } from 'react-feather';
import { BasicActionBtns, ButtonTooltip } from '../../components';

const columns = memoize((clickHandler, countryFactory) => [
  { name: countryFactory.getLabelForPersonalIdentification(), selector: 'national_identification', sortable: true, grow: '1' },
  { name: 'NOMBRE', selector: 'full_name', sortable: false, grow: '2' },
  { name: 'EMAIL', selector: 'email', sortable: true, grow: '2' },
  { name: 'CREACIÓN', selector: 'created_at', sortable: true, grow: '1' },
  { name: 'ACTIVO', selector: 'active', cell: item => (item.active ? 'Sí' : 'No'), sortable: true, grow: '1' },
  { name: 'VENDEDOR', selector: 'seller', cell: item => item.seller?.label, sortable: true, grow: '1' },
  {
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'vehicleCreate')}
          variant="outline-success"
          className="btn-circle table-actions-btn"
          size="sm"
          text="Crear Vehículo"
        >
          <Plus width="15px" height="15px" />
        </ButtonTooltip>
        <BasicActionBtns item={item} clickHandler={clickHandler} showClick editClick />
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '3',
    minWidth: '240px'
  }
]);

export default columns;
