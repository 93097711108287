import React from 'react';
import { withFormik, Form } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';

import { LinkBtn, NestedAttributes } from '../../components';
import ReviewGroupInput from './inputs/ReviewGroupInput';

const ReviewGroupForm = props => {
  const { action, errors, onHide, setFieldValue, submitVariant, touched, values, type } = props;
  const { reviewGroupsAttributes } = values.company;
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar Checklist';

  const newData = {
    name: '',
    position: 0,
    reviewType: type,
    reviewsAttributes: [
      {
        imageRequired: false,
        name: '',
        position: 0,
        workRequired: false
      }
    ]
  };

  const addReviewGroupInputs = () => {
    const mapResults = reviewGroupsAttributes.map((reviewGroup, index) => {
      if (reviewGroup._destroy) {
        return undefined;
      }
      return (
        <ReviewGroupInput
          key={`review-group-input-${index.toString()}`}
          index={index}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          reviewGroup={reviewGroup}
          fromType={type}
        />
      );
    });

    return (
      <NestedAttributes
        removeFirstItem
        mapInputs={mapResults}
        arrayValues={reviewGroupsAttributes}
        setFieldValue={setFieldValue}
        valuePath="company[reviewGroupsAttributes]"
        newAttributes={newData}
        btnMessage="Agregar nueva sección"
      />
    );
  };

  return (
    <Form>
      <Row className="d-flex justify-content-end mb-5">
        <Col>
          <h1>Editar Checklist</h1>
        </Col>
        <Col md={3}>
          <Button block type="submit" variant={submitVariant} onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
        <Col md={3}>
          <LinkBtn block variant="light-darker" to="/review_groups/mechanic" className="cancel">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      {addReviewGroupInputs()}
    </Form>
  );
};

const setInitialValues = props => {
  const { company } = props;
  return {
    company
  };
};

const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    reviewGroupsAttributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Debes ingresar un nombre'),
        reviewsAttributes: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required('Debes ingresar un nombre')
          })
        )
      })
    )
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ReviewGroupForm);
