import React, {useState} from "react";
import { Button } from "react-bootstrap";

const ConfirmRequest = ({ confirm, cancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmClick = () => {
    setIsLoading(true);
    confirm();
  };

  return (
    <div>
      <h3>Confirmar Documento</h3>
      <div className={"mb-3"}>
        <div className={"mb-2"}>
          Al confirmar este documento se validará con la agencia fiscal de Chile
        </div>
        <div className={"font-weight-bold"}>
          ¿Estás seguro continuar?
        </div>
        <div>
          Esta acción no se puede deshacer
        </div>
      </div>
      <div className={"float-right"}>
        <Button className={"mr-2"} onClick={!isLoading ? handleConfirmClick : null} disabled={isLoading}>
          {isLoading ? 'Generando documento...' : 'Aceptar'}
        </Button>
        <Button variant={"danger"} onClick={cancel} disabled={isLoading}>
          Cancelar
        </Button>
      </div>
    </div>
  );
};

export default ConfirmRequest;