import React from 'react';
import { Row, Col } from 'react-bootstrap';
import VehicleCompanyDataTable from './VehicleCompanyDataTable';
import columns from './Columns';

const VehicleCompanyIndex = () => (
  <>
    <Row>
      <Col md={11} className="mx-5">
        <Row className="mt-4">
          <Col md={10}>
            <h2 className="text-uppercase">Vehículos</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <VehicleCompanyDataTable columns={columns} />
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export default VehicleCompanyIndex;
