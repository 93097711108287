import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { SimpleCenteredModal } from '../../components';
import ProjectDeskSellForm from './ProjectDeskSellForm';
import { createProjectRequest } from '../../requests/projects';
import { sendAlert } from '../../actions/utils';

const initialProjectQuote = {
  calendarEventId: '',
  clientId: '',
  isQuote: true,
  mileage: '',
  notes: '',
  projectDate: '',
  projectReviewsAttributes: [],
  promiseDate: '',
  vehicleId: ''
};

const ProjectQuote = () => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = params => {
    createProjectRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: response => {
        dispatch(sendAlert({ kind: 'success', message: 'Presupuesto creado con éxito' }));
        const projectId = response.data.id;
        history.push(`/projects/${projectId}/edit`);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleDeskSellClick = () => {
    setModalShow(true);
    setModalBody(
      <ProjectDeskSellForm
        action="new"
        isQuote
        project={initialProjectQuote}
        formRequest={handleCreateRequest}
        submitVariant="success"
        handleClose={handleModalClose}
      />
    );
  };

  return (
    <>
      <Col md={2}>
        <Button variant="light-darker" block onClick={handleDeskSellClick}>
          Crear Presupuesto
        </Button>
      </Col>
      <SimpleCenteredModal
        closeButton
        dialogClassName="modal-90w"
        title="CREAR PRESUPUESTO"
        body={modalBody}
        show={modalShow}
        size=""
        onHide={handleModalClose}
      />
    </>
  );
};

export default ProjectQuote;
