import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Trash } from 'react-feather';
import { Draggable } from 'react-beautiful-dnd';
import './style.scss';
import { isMobile } from '../../../services/utils';

const handleDelete = (arrayValues, setFieldValue, valuePath, index) => {
  const arrayValuesLength = arrayValues.length;
  const deleteValue = arrayValues[index];
  const newValues = [...arrayValues.slice(0, index), ...arrayValues.slice(index + 1, arrayValuesLength)];

  if (deleteValue.id && deleteValue !== '') {
    deleteValue._destroy = 'true';
    newValues.push(deleteValue);
  }

  setFieldValue(`${valuePath}`, newValues);
};

const addRow = (arrayValues, setFieldValue, valuePath, newAttributes) => {
  const newValues = [...arrayValues, newAttributes];
  setFieldValue(`${valuePath}`, newValues);
};

const buttonRemoveItem = (arrayValues, setFieldValue, valuePath, index) => (
  <Button
    variant="outline-danger"
    className="btn-circle"
    size="sm"
    onClick={() => handleDelete(arrayValues, setFieldValue, valuePath, index)}
  >
    <Trash width="15px" height="15px" />
  </Button>
);

const NestedBody = ({additionalNested, arrayValues, body, deleteLastOne, index, inputLength, removeFirstItem,
                      setFieldValue, shownLength, valuePath}) => (
  <>
    {body && (
      <>
        <Row className="checklist-section-container">
          {body}
          <Col xs={2} className="pt-4">
            {deleteLastOne
              ? index === inputLength - 1 &&
              inputLength !== 0 &&
              shownLength !== 0 &&
              buttonRemoveItem(arrayValues, setFieldValue, valuePath, index)
              : (removeFirstItem || (inputLength !== 0 && shownLength > 1)) &&
              buttonRemoveItem(arrayValues, setFieldValue, valuePath, index)}
          </Col>
        </Row>
        {!additionalNested && index !== inputLength && <hr />}
      </>
    )}
  </>
);

const NestedAttributes = ({
  additionalNested,
  arrayValues,
  deleteLastOne,
  mapInputs,
  btnMessage,
  newAttributes,
  removeFirstItem,
  setFieldValue,
  valuePath,
  isDraggable = false
}) => {
  const inputLength = mapInputs.length - 1;
  const shownLength = mapInputs.filter(e => e !== undefined).length - 1;

  const nestedBody = mapInputs.map((body, index) => {
    const recognizer = body?.props?.workDetail?.id || body?.props?.workDetail?.key;
    return isDraggable ? (
      <Draggable key={recognizer} draggableId={String(recognizer)} index={index}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <NestedBody
              key={`nested-${index}`}
              arrayValues={arrayValues}
              body={body}
              deleteLastOne={deleteLastOne}
              removeFirstItem={removeFirstItem}
              index={index}
              inputLength={inputLength}
              setFieldValue={setFieldValue}
              shownLength={shownLength}
              valuePath={valuePath}
              newAttributes={newAttributes}
              additionalNested={additionalNested}
            />
          </div>
        )}
      </Draggable>
    ) : (
      <NestedBody
        key={`nested-${index}`}
        arrayValues={arrayValues}
        body={body}
        deleteLastOne={deleteLastOne}
        removeFirstItem={removeFirstItem}
        index={index}
        inputLength={inputLength}
        setFieldValue={setFieldValue}
        shownLength={shownLength}
        valuePath={valuePath}
        newAttributes={newAttributes}
        additionalNested={additionalNested}
      />
    );
  });

  nestedBody.push(
    <Row key={`btn-add-row-${shownLength.toString()}`} className={!additionalNested ? 'my-3' : ''}>
      <Col md={5}>
        <Button
          className="mb-4"
          block={!isMobile()}
          variant={isMobile() ? 'link' : 'secondary'}
          onClick={() => addRow(arrayValues, setFieldValue, valuePath, newAttributes)}
        >
          {btnMessage}
        </Button>
      </Col>
    </Row>
  );

  return nestedBody;
};

export default NestedAttributes;
