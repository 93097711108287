import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PlanCard from './PlanCard';
import { sendAlert } from '../../actions/utils';
import { debounceIndexPlansRequest } from '../../requests/plans';

const PlanIndex = () => {
  const [plans, setPlans] = useState([]);
  const { settings } = useSelector(state => state.utils);
  const { plansFooterText, plansBanner = '/assets/images/default-wide.png' } = settings;
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessRequest = response => {
    setPlans(response.data);
  };

  const handleIndexRequest = () => {
    debounceIndexPlansRequest({
      dispatch,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(handleIndexRequest, []);

  return (
    <>
      <Row>
        <Col>
          <Row className="mb-5">
            <Col className="plans-banner-container">
              <img alt="Plans Banner" src={plansBanner} width="100%" height="auto" />
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <Row className="mt-5 mb-5">
                <Col className="d-flex" md={{ span: 2, offset: 1 }}>
                  <h4 className="plans-title">Planes disponibles</h4>
                </Col>
                <Col className="d-flex plans-contact-container" md={{ span: 3, offset: 5 }}>
                  <p>Si tienes dudas</p>
                  <Button className="plans-contact" type="submit" as={Link} to="/contact">
                    Contáctanos
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 10, offset: 1 }}>
                  <Row className="plan-cards-container">
                    {plans.map(plan => (
                      <PlanCard key={plan.id} plan={plan} />
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={6} className="mx-auto">
                  <p className="plans-bottom-text">{plansFooterText}</p>
                </Col>
              </Row>
            </Col>
            <div
              style={{
                backgroundColor: '#668cb5',
                height: '900px',
                width: '100%',
                position: 'absolute',
                top: '0',
                left: '0',
                zIndex: '-1'
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PlanIndex;
