import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { validateToken } from '../../actions/auth';
import { LinkBtn } from '../../components';
import columns from './Columns';
import PurchaseManagementDataTable from './PurchaseManagementDataTable';

const PurchaseManagementIndex = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.auth);
  const { id, currentBranchOffice } = currentUser;

  const userData = () => {
    if (id === 0) dispatch(validateToken());
  };

  useEffect(userData, []);

  return (
    <Row className="nn-purchase-management">
      <Col md={11} className="mx-auto" xs={12}>
        <Row className="mt-4">
          <Col md={10}>
            <h2 className="text-uppercase mb-4">Gestión de Compra</h2>
          </Col>
          <Col md={2}>
            <LinkBtn variant="primary" block to="/purchase_managements/new">
              Nuevo listado de compra
            </LinkBtn>
          </Col>
        </Row>
        {Object.keys(currentBranchOffice).length > 0 && (
          <PurchaseManagementDataTable columns={columns} currentBranchOfficeId={currentBranchOffice.value} />
        )}
      </Col>
    </Row>
  );
};

export default PurchaseManagementIndex;
