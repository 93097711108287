const reviewTypeValues = [
  { label: 'Mecánico', value: 'mechanic' },
  { label: 'Recepción', value: 'reception' },
  { label: 'Pre-Inpección', value: 'pre_inspection' },
  { label: 'Salida', value: 'output' }
];

export const reviewTypes = {
  reception: 'Checklist Recepción',
  mechanic: 'Checklist Inspección técnica',
  pre_inspection: 'Checklist Pre Inspección',
  output: 'Checklist Salida'
};
export default reviewTypeValues;
