import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '../../components';
import VehicleDataTable from './VehicleDataTable';
import columns from './Columns';

const VehicleIndex = ({ match }) => {
  const { client_id: clientId } = match.params;

  return (
    <>
      <Row>
        <Col md={11} className="mx-auto">
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase">Vehículos Cliente</h2>
            </Col>
            <Col md={2}>
              <LinkBtn variant="primary" block to={`/clients/${clientId}/vehicles/new`}>
                Crear
              </LinkBtn>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <VehicleDataTable columns={columns} clientId={clientId} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default VehicleIndex;
