import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Check, X, HelpCircle } from 'react-feather';
import { getIn } from 'formik';
import ReactTooltip from "react-tooltip";

const ApproveButtons = ({ field, handleButtonsAction, errors }) => {
    return (
        <ToggleButtonGroup
            {...field}
            onChange={handleButtonsAction}
            error={getIn(errors, field.name)}
        >
            <ToggleButton
                className="btn"
                value="approved"
                variant="outline-success"
            >
                <Check size={16}/>
            </ToggleButton>
            <ToggleButton
                className="btn"
                variant="outline-warning"
                value="doubt"
            >
                <HelpCircle 
                  size={16}
                  data-tip
                  data-for="tooltip"
                />
                <ReactTooltip id="tooltip" place="top">
                  Dudas con este trabajo
                </ReactTooltip>
            </ToggleButton>
            <ToggleButton
                className="btn"
                variant="outline-danger"
                value="rejected"
            >
                <X size={16}/>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

export default ApproveButtons;
