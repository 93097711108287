import * as Yup from 'yup';

const projectWorkHoursSchema = {
  accountableUserId: Yup.string().required('Debes elegir un responsable'),
  date: Yup.string().required('Debes elegir una fecha para agregar horas'),
  hours: Yup.string().required('Debes ingresar una cantidad de horas'),
  minutes: Yup.string().required('Debes ingresar una cantidad de minutos')
};

export default projectWorkHoursSchema;
