import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexProvidersRequest = ({ dispatch, format, params = {}, successCallback, failureCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/providers${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showProviderRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/providers/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createProviderRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/providers', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateProviderRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/providers/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProviderRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/providers/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexProvidersRequest = AwesomeDebouncePromise(indexProvidersRequest, 300);
