import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';

import './styles.scss';

const ButtonTooltip = ({ toolbarVariant, direction, text, variant, children, delayShow, delayHide, ...props }) => (
  <div className={toolbarVariant}>
    <Button {...props} variant={variant} type="button" data-tip data-for={`registerTip-${text}`}>
      {children}
    </Button>

    <ReactTooltip id={`registerTip-${text}`} delay={{ show: delayShow, hide: delayHide }} place={direction}>
      {text}
    </ReactTooltip>
  </div>
);
ButtonTooltip.propTypes = {
  direction: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

ButtonTooltip.defaultProps = {
  direction: 'top',
  variant: 'info',
  delayShow: 250,
  delayHide: 0,
  toolbarVariant: ''
};

export default ButtonTooltip;
