import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ComponentDataTable from '../../components/Utils/DataTable';
import { sendAlert } from '../../actions/utils';
import {debounceIndexAssignedTaskRequest} from "../../requests/assignedTasks";
import {FormikSelect} from "../../components";

const AssignedTaskDataTable = props => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [bulkUploads, setNpsAnswers] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [responseTable, setResponseTable] = useState({date_from: "", date_to: "", display_length: 25, display_start: 0,
    query: "", sort_column: "", sort_direction: "desc"});
  const [done, setDone] = useState('');
  const { columns, moreData, setMoreData } = props;
  const { user: currentUser } = useSelector(state => state.auth);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const data = response.data.data;
    const { amount: responseAmount } = response.data.meta;
    setNpsAnswers(data);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexAssignedTaskRequest({
      dispatch,
      params: {
        ...params,
        q: {
          assigned_user_id_eq: currentUser.id,
          filtered: params?.query || '',
          done_eq: done
        },
        assigned_tasks_view: true
      },
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleState = (data) => {
    const newState = data ? data.value : '';
    setMoreData(!moreData);
    setDone(newState);
  };

  const defaultStates = [
    { label: 'Todas las tareas', value: '' },
    { label: 'Tareas Realizadas', value: 'true' },
    { label: 'Tareas Pendientes', value: 'false' }
  ];

  useEffect(()=> {
    handleIndexRequest(responseTable);
  }, [currentUser]);

  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={columns}
      data={bulkUploads}
      totalRows={amount}
      moreData={moreData}
      resourceRequest={response => {
        setResponseTable(response);
        if (!onRequest) handleIndexRequest(response);
      }}
      firstCustomMediumSearch={
        <FormikSelect
          placeholder="Estado"
          options={defaultStates}
          defaultValue={defaultStates.find(state => state.value === done)}
          onChange={handleState}
        />
      }
    />
  );
};

export default AssignedTaskDataTable;
