import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexClientsRequest = ({ dispatch, companyCode, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/public/companies/${companyCode}/clients.json`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debouncePublicIndexClientsRequest = AwesomeDebouncePromise(indexClientsRequest, 300);
