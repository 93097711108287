/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Column from './Columns';
import { CreateColumnModal } from './components/modal/kanban-modals';
import { useIsAdminOrWorkshopManager } from '../../app/selectors';
import { formatAmount } from "../../app/utils/utils";
import useCountryFactory from "../../app/hooks/useCountryFactory";

const Sections = ({ section, allSections, filters, branch_office_id }) => {
  const canShowTotalPrices = useIsAdminOrWorkshopManager();
  const countryFactory = useCountryFactory();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  function openModal(type) {
    setModalType(type);
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
    setModalType(null);
  }

  return (
    <Row className="px-1 py-3 my-0 mx-1 d-flex flex-wrap rounded kanban-section-row">
      <Col>
        <title className="d-flex p-1 mx-1  align-items-center kanban-section-tittle">
          <div className="p-0 justify-content-center d-flex w-100 kanban-tittle-container">
            <span className="kanban-section-name">
              {section.name} ({section.projects_count})
            </span>
            {canShowTotalPrices && <span className="ml-1 kanban-section-total">Total: {countryFactory.getSymbol()} {formatAmount(section.total_amount)}</span>}
          </div>
          <i className="bi bi-plus-lg kanban-icon-dropdown" onClick={() => openModal('create')} />
          {modalType === 'create' && (
            <CreateColumnModal
              showModal={showModal}
              closeModal={closeModal}
              setShowModal={setShowModal}
              selectedSectionId={section.id}
            />
          )}
        </title>
        <div className="d-flex p-0" style={{}}>
          {section?.columns?.map(col => (
            <Column
              key={col.id}
              sections={allSections}
              column={col}
              filters={filters}
              branch_office_id={branch_office_id}
              sectionCode={section.code}
            />
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default Sections;
