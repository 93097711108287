import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, getIn } from 'formik';
import { Col } from 'react-bootstrap';

import camelCaseRecursive from "camelcase-keys-recursive";
import { debounceIndexAutopartsRequest } from '../../requests/autoparts';
import { FormikSelect } from '../Utils/Select';

const AutopartSelect = ({
  onAutopartChange,
  errors,
  touched
}) => {
  const [autoparts, setAutoparts] = useState([]);
  const [autopartSelector, setAutopartSelector] = useState('');
  const [autopartCategorySelector, setAutopartCategorySelector] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    debounceIndexAutopartsRequest({
      dispatch,
      successCallback: response => setAutoparts(camelCaseRecursive(response.data))
    });
  }, [dispatch]);

  const getCurrentAutoparts = () => {
    let response;
    if (autopartCategorySelector === '') {
      response = autoparts.reduce((a, b) => {
        return [...a, ...b.children.map(item => {
          return {label: item.name, value: item.id}
        })]
      }, []);
    } else {
      response = autoparts.filter(cat => {return cat.id === autopartCategorySelector})[0].children.map(item => {return {label: item.name, value: item.id}});
    }
    return response;
  };

  const fetchAutopartCategories = () => {
    const response = autoparts.map(cat => {return {label: cat.name, value: cat.id}});
    return response;
  };

  const fetchAutoparts = () => {
    const response = getCurrentAutoparts();
    return response;
  };

  return (
    <>
      <Col md={6}>
        <Field>
          {({ field }) => (
            <FormikSelect
              {...field}
              isClearable
              label={'Categoría'}
              placeholder="Seleccionar..."
              value={autopartCategorySelector}
              onChange={data => {
                const newValue = data ? data.value : '';
                setAutopartCategorySelector(newValue);
              }}
              options={fetchAutopartCategories()}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field>
          {({ field }) => (
            <FormikSelect
              {...field}
              isClearable
              label="Repuesto"
              placeholder="Seleccionar..."
              value={autopartSelector}
              onChange={data => {
                onAutopartChange(data);
                setAutopartSelector('');
                setAutopartCategorySelector('');
              }}
              options={fetchAutoparts()}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default AutopartSelect;
