import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { LinkBtn } from '../../components';
import { showProductRequest } from '../../requests/products';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import MovementDetailDataTable from '../MovementDetail/MovementDetailDataTable';
import columns from '../MovementDetail/Columns';
import basicProduct from './basicProduct';
import ProductInfo from './ProductInfo';
import GoBackButton from "../../components/GoBackButton";

const ProductShow = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/products';

  const [product, setProduct] = useState(basicProduct);
  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    const { data } = camelCaseEmptyStringRecursive(response);
    setProduct(data);
  };

  const fetchProduct = () => {
    showProductRequest(id, {
      dispatch,
      successCallback: handleSuccessRequest
    });
  };

  useEffect(fetchProduct, []);

  return (
    <>
      <Row className="mt-3 mb-5">
        <Col md={{ span: 6, offset: 1 }}>
          <h2>{product.saleName}</h2>
        </Col>
        <Col md={2}>
          <LinkBtn block variant="primary" to={`${modelUrl}/${id}/edit`}>
            Editar
          </LinkBtn>
        </Col>
        <Col md={2}>
          <GoBackButton />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={{ span: 5, offset: 1 }}>
          <ProductInfo product={product} />
        </Col>
        <Col md={{ span: 4, offset: 1 }}>
          <MovementDetailDataTable columns={columns} productId={id} />
        </Col>
      </Row>
    </>
  );
};

export default ProductShow;
