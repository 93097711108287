import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { InputRemoteSelect } from '../Utils/Select';
import { SimpleCenteredModal, ModalConfirmationButton } from '../index';
import ProviderForm from '../../screens/Provider/ProviderForm';
import { debounceIndexProvidersRequest, indexProvidersRequest, createProviderRequest } from '../../requests/providers';

const providerForm = {
  active: true,
  address: '',
  businessActivity: '',
  cellphone: '',
  communeId: '',
  contactsAttributes: [],
  email: '',
  nationalIdentification: '',
  legalName: '',
  name: '',
  notes: '',
  phone: '',
  regionId: '',
  website: ''
};

const ProviderTab = props => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const stableDispatch = useCallback(dispatch, []);
  const { errors, touched, setFieldValue } = props;
  const [providers, setProviders] = useState([]);
  const [currentProvider, setCurrentProvider] = useState(undefined);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [moreData, setMoreData] = useState(false);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchInitialProviders = useCallback(
    params => {
      indexProvidersRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          for_selector: true,
          sort_column: 'name',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: response => {
          setProviders(camelCaseRecursive(response.data.data));
        }
      });
    },
    [stableDispatch]
  );

  const fetchProviders = (inputValue, callback) => {
    debounceIndexProvidersRequest({
      dispatch,
      params: {
        query: inputValue,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleSuccessRequest = ({ data: { id: value, name: label } }) => {
    setCurrentProvider({ label, value });
    setFieldValue('productMovement[providerId]', value);
    setMoreData(!moreData);
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Proveedor creado con éxito' }));
  };

  const handleCreateRequest = params => {
    createProviderRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleProviderChange = data => {
    if (data) {
      setCurrentProvider({
        label: data.label,
        value: data.value
      });
    } else setCurrentProvider(undefined);
  };

  const handleModalConfirmationConfirm = () => {
    history.push(`/providers`);
    handleModalClose();
  };

  const btnOpenProviderFormModal = () => {
    setModalShow(true);
    setModalBody(
      <>
        <Row className="justify-content-end">
          <Col md={3}>
            <ModalConfirmationButton
              handleModalConfirm={handleModalConfirmationConfirm}
              modalTitle="CONFIRMAR IR A PROVEEDORES"
              modalBody="Al realizar esta acción puede perder cambios no guardados. Confirmar para continuar"
              buttonText="Ver Proveedores"
              variant="secondary"
              className="w-100"
            />
          </Col>
        </Row>
        <ProviderForm
          action="new"
          formRequest={handleCreateRequest}
          provider={providerForm}
          dispatch={() => dispatch}
          country={user.companyCountry}
        />
      </>
    );
  };

  useEffect(fetchInitialProviders, [fetchInitialProviders, moreData]);

  return (
    <>
      <Row>
        <Col md={12}>
          <Button className="btn float-right p-0" variant="link" onClick={() => btnOpenProviderFormModal()}>
            Crear
          </Button>
          <Field name="productMovement[providerId]">
            {({ field }) => (
              <InputRemoteSelect
                {...field}
                isClearable
                label="Proveedor"
                placeholder="Seleccionar Proveedor"
                defaultOptions={providers}
                value={!currentProvider ? undefined : currentProvider}
                onChange={data => {
                  handleProviderChange(data);
                  setFieldValue(field.name, data ? data.value : '');
                }}
                request={fetchProviders}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <SimpleCenteredModal
        title="CREAR PROVEEDOR"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </>
  );
};

export default ProviderTab;
