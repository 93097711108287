import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isMobile } from '../../../services/utils';

import PrincipalNavbar from './PrincipalNavbar';

const NavbarTop = props => {
  const { shortNavbar } = props;
  const [navExpanded, setNavExpanded] = useState(false);

  return (
    <PrincipalNavbar expanded={navExpanded} setNavExpanded={setNavExpanded}>
      {isMobile() ? (
        <Navbar.Collapse id="basic-navbar-nav" className="responsive-navbar bg-white">
          <Nav className="mr-auto m-auto" />
          <Nav className="accordion-options-bg navbar-gestioncar">
            {!shortNavbar && (
              <ul>
                <li>
                  <a className="" href="#home">
                    Inicio
                  </a>
                </li>
                <li>
                  <a className="" href="#benefits">
                    Beneficios
                  </a>
                </li>
                <li>
                  <a className="" href="#success-stories">
                    Casos de éxito
                  </a>
                </li>
                <li>
                  <a className="" href="#Faqs">
                    Preguntas frecuentes
                  </a>
                </li>
                <li>
                  <a className="" href="#pricing">
                    Planes
                  </a>
                </li>
              </ul>
            )}
            <Nav.Link as={Link} to="/contact" className="btn btn-primary-blue-outline__landing button-font-navbar ml-2">
              Contáctanos
            </Nav.Link>
            <Nav.Link as={Link} to="/login" className="btn btn-primary-blue__landing button-font-navbar ml-2">
              Iniciar Sesión
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      ) : (
        <Navbar.Collapse id="basic-navbar-nav bg-white">
          <Nav className="mr-auto m-auto navbar-gestioncar">
            {!shortNavbar && (
              <ul>
                <li>
                  <a className="" href="#home">
                    Inicio
                  </a>
                </li>
                <li>
                  <a className="" href="#benefits">
                    Beneficios
                  </a>
                </li>
                <li>
                  <a className="" href="#success-stories">
                    Casos de éxito
                  </a>
                </li>
                <li>
                  <a className="" href="#Faqs">
                    Preguntas frecuentes
                  </a>
                </li>
                <li>
                  <a className="" href="#pricing">
                    Planes
                  </a>
                </li>
              </ul>
            )}
          </Nav>
          <Nav>
            <Nav.Link as={Link} to="/contact" className="btn btn-primary-blue-outline__landing button-font-navbar ml-2">
              Contáctanos
            </Nav.Link>
            <Nav.Link as={Link} to="/login" className="btn btn-primary-blue__landing button-font-navbar ml-2">
              Iniciar Sesión
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      )}
    </PrincipalNavbar>
  );
};

export default NavbarTop;
