/* eslint-disable camelcase */
import memoize from 'memoize-one';

import reviewTypeValues from './Constants';

const columns = memoize(() => [
  { name: 'Nombre', selector: 'name', sortable: true, grow: '1' },
  {
    name: 'Tipo',
    selector: 'review_type',
    cell: item =>
      reviewTypeValues.filter(obj => {
        return obj.value === item.review_type;
      })[0]?.label,
    sortable: true,
    grow: '1'
  },
  { name: 'Posición', selector: 'position', sortable: true, grow: '1' }
]);

export default columns;
