import React, {useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from "snakecase-keys";
import {useDispatch} from "react-redux";
import columns from './Columns';
import AssignedTaskDataTable from "./AssignedTaskDataTable";
import {DefaultModal} from "../../components";
import {updateProjectDetailRequest} from "../../requests/projectDetails";
import {sendAlert} from "../../actions/utils";

const AssignedTaskIndex = () => {
  const [moreData, setMoreData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();

  const checkDone = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleSuccessUpdateRequest = () => {
    setMoreData(!moreData);
    handleClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
    handleClose();
  };

  const handleConfirm = () => {
    const params = {
      done: true,
      doneAt: new Date(),
      doneById: selectedRow?.assigned_user_id
    };
    updateProjectDetailRequest(selectedRow.id, {
      dispatch,
      params: {project_detail: snakeCaseKeys(params)},
      formData: true,
      successCallback: handleSuccessUpdateRequest,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <Row>
      <Col md={11} className="mx-auto" xs={12}>
        <Row className="mt-4">
          <Col>
            <h2 className="text-uppercase">Tareas Asignadas</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <AssignedTaskDataTable
              columns={columns(checkDone)}
              moreData={moreData}
              setMoreData={setMoreData}
            />
          </Col>
        </Row>
      </Col>
      <DefaultModal
        title="Marcar como Realizado"
        body={`¿Está seguro que desea marcar como realizado el trabajo ${selectedRow?.label}?`}
        show={showModal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </Row>
  );
};

export default AssignedTaskIndex;