import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showClientRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/clients/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexClientsRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/clients${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createClientRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/clients', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateClientRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/clients/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteClientRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/clients/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexClientsRequest = AwesomeDebouncePromise(indexClientsRequest, 300);
