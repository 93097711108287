import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import ProjectDataTable from '../ProjectDataTable';
import { parseQuery } from '../../../services/utils';
import columns from '../Columns';

const ProjectMechanicList = ({ location }) => {
  const { user: currentUser } = useSelector(state => state.auth);
  const { currentBranchOffice } = currentUser;
  const { search: urlSearch } = location;
  const urlQueries = parseQuery(urlSearch);

  return (
    <Row>
      <Col md={11} className="mx-auto" xs={12}>
        <Row className="mt-4">
          <Col>
            <h2 className="text-uppercase">Listado</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {currentBranchOffice.value && (
              <ProjectDataTable
                fromMechanicList
                columns={columns}
                currentBranchOfficeId={currentBranchOffice.value}
                urlQueries={urlQueries}
                rowsPerPage={25}
                filterId="ProjectMechanicList"
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectMechanicList;
