import React from 'react';
import { Button } from 'react-bootstrap';
import { Minus } from 'react-feather';

const PercentageButton = ({ customStateValues }) => {
  const extractPercentageValue = () => {
    const underscoreIndex = customStateValues.indexOf('_');
    return underscoreIndex !== -1 ? customStateValues.slice(underscoreIndex + 1) : customStateValues;
  };

  const percentageValue = parseInt(extractPercentageValue(), 10);

  return (
    <Button
      variant={percentageValue <= 25 ? 'danger' : percentageValue === 50 ? 'secondary' : 'success'}
      style={{ marginRight: '0.5rem', borderRadius: '3px' }}
    >
      <Minus /> {percentageValue}%
    </Button>
  );
};

export default PercentageButton;
