/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { Link } from 'react-router-dom';
import { BasicActionBtns } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE / CÓDIGO',
    selector: 'code',
    sortable: true,
    grow: '2',
    cell: item => <Link to={`/products/${item.id}`}>{item.code}</Link>
  },
  { name: 'NOMBRE VENTA', selector: 'sale_name', sortable: true, grow: '2' },
  {
    name: 'CATEGORÍA',
    selector: 'category_name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'SUBCATEGORÍA',
    selector: 'product_category_name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'INVENTARIABLE',
    selector: 'inventoryable',
    cell: item => (item.inventoryable ? 'Sí' : 'No'),
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => (item.active ? 'Sí' : 'No'),
    sortable: true,
    grow: '1'
  },
  {
    name: 'SKU',
    selector: 'sku',
    cell: item => (item.inventoryable ? item.sku : ''),
    sortable: true,
    grow: '1'
  },
  { name: 'UNIDAD', selector: 'translated_unit', sortable: true, grow: '1' },
  {
    name: 'COSTO',
    selector: 'cost',
    cell: item => item.parsed_cost,
    sortable: true,
    grow: '1'
  },
  {
    name: 'PRECIO',
    selector: 'sale_price',
    cell: item => item.parsed_sale_price,
    sortable: true,
    grow: '1'
  },
  {
    cell: item => (
      <>
        <BasicActionBtns item={item} clickHandler={clickHandler} showClick editClick />
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '140px'
  }
]);

export default columns;
