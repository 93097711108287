/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

import { ButtonTooltip } from '../../components/Utils/Tooltips';
import { Can } from '../../config/abilityContext';

const columns = memoize(() => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'full_name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'EMAIL',
    selector: 'email',
    sortable: true,
    grow: '2'
  },
  {
    name: 'ROLES',
    selector: 'roles',
    cell: item => item.roles.map(role => role.label).join(', '),
    sortable: false,
    grow: '2'
  },
  {
    name: 'SUCURSALES',
    selector: 'branchOffices',
    cell: item => item.branch_offices.map(branchOffice => branchOffice.label).join(', '),
    sortable: false,
    grow: '2'
  },
  {
    name: 'CREACIÓN',
    selector: 'created_at',
    cell: item => item.parsed_created_at,
    sortable: true,
    grow: '2'
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => (item.active ? 'Sí' : 'No'),
    sortable: true,
    grow: '1'
  },
  {
    cell: item => (
      <Can I="create" a="User">
        <ButtonTooltip
          to={`/users/${item.id}/edit`}
          variant="outline-warning"
          className="btn-circle"
          size="sm"
          text="Editar"
          as={Link}
        >
          <Edit width="15px" height="15px" />
        </ButtonTooltip>
      </Can>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '50px'
  }
]);

export default columns;
