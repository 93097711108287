import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexProductCategoriesRequest = ({ dispatch, params = {}, successCallback, failureCallback }) => {
  ApiService.request('get', `/product_categories`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showProductCategoryRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/product_categories/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createProductCategoryRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/product_categories', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateProductCategoryRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/product_categories/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProductCategoryRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/product_categories/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const createInsuranceCompanyProductCategoriesRequest = ({ dispatch, insuranceCompanyId, params, failureCallback, successCallback }) =>
  ApiService.request('post', `/product_categories/insurance_company/${insuranceCompanyId}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexProductCategoriesRequest = AwesomeDebouncePromise(indexProductCategoriesRequest, 300);
