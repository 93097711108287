import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import VehicleInfo from './VehicleInfo';
import { LinkBtn } from '../../components';
import { showVehicleRequest } from '../../requests/vehicles';
import columns from './LogColumns';
import projectColumns from '../Project/Columns';
import CompanyLogsDataTable from '../Log/CompanyLogsDataTable';
import ProjectDataTable from '../Project/ProjectDataTable';
import basicVehicle from './basicVehicle';

const VehicleShow = ({ match }) => {
  const [vehicle, setVehicle] = useState(basicVehicle);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();
  const { id: vehicleId, client_id: clientId } = match.params;
  const { vehicleBrandName, vehicleModelName, plate } = vehicle;

  const handleSuccessShow = response => {
    setVehicle(camelCaseRecursive(response.data));
  };

  const fetchVehicle = () => {
    showVehicleRequest(clientId, vehicleId, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  useEffect(fetchVehicle, [plate]);

  return (
    <>
      <Row className="mt-3 mb-5">
        <Col md={{ span: 6, offset: 1 }}>
          <h2>{`${vehicleBrandName} - ${vehicleModelName} / ${plate}`}</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="primary" block to={`/clients/${clientId}/vehicles/${vehicleId}/edit`}>
            Editar
          </LinkBtn>
        </Col>
        <Col md={2}>
          <LinkBtn className="cancel" variant="light-darker" block to={`/clients/${clientId}/vehicles`}>
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col md={{ span: 7, offset: 1 }}>
          <VehicleInfo vehicle={vehicle} />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <h2>PROYECTOS ASOCIADOS A VEHÍCULO</h2>
          {plate !== '' && (
            <ProjectDataTable columns={projectColumns} urlQueries={{ plate }} externalView adminView/>
          )}
        </Col>
        <Col className="mt-5" md={{ span: 6, offset: 1 }}>
          <h2>HISTORIAL DE CAMBIOS</h2>
        </Col>
        <Col md={{ span: 10, offset: 1 }}>
          <CompanyLogsDataTable
            columns={columns}
            setMoreData={setMoreData}
            moreData={moreData}
            vehicleId={vehicleId}
            fromVehicleShow
          />
        </Col>
      </Row>
    </>
  );
};

export default VehicleShow;
