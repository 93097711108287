import React, { useEffect } from 'react';
import {Route, Redirect, useLocation, useParams} from 'react-router-dom';
import { Container, NavDropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { isMobile } from '../services/utils';

import API_CONFIG from './configurations';
import { requestSignOut } from '../actions/auth';
import { NavbarTop, NavbarLogged, Footer, PrincipalNavbar, Footerlogged } from '../components';
import GcFooter from '../components/Utils/GcFooter';

export const LoggedLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [toggled, setToggled] = React.useState(false);
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged sidebar={{ toggled, setToggled }}>
              <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</NavDropdown.Item>
            </NavbarLogged>
            <Container fluid className={isMobile() ? 'pb-1' : ''}>
              {/* <GcSidebar toggled={toggled} setToggled={ setToggled } /> */}
              <div className="inter-space">
                <Component {...props} />
              </div>
            </Container>
            {isMobile() ? <GcFooter /> : <></>}
            {/* <Footerlogged /> */}
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export const WideViewLayout = ({ component: Component, ...rest }) => {
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <PrincipalNavbar />
            <Container fluid>
              <Component {...props} />
            </Container>
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export const NonLoggedLayout = ({ component: Component, ...rest }) => {
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
        ) : (
          <>
            <NavbarTop shortNavbar={pathname === '/login'} />
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        )
      }
    />
  );
};

export const PublicLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged>
              <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</NavDropdown.Item>
            </NavbarLogged>
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        ) : (
          <>
            <NavbarTop shortNavbar={pathname === '/contact'} />
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        )
      }
    />
  );
};

export const PublicProjectLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { companyCode } = rest.computedMatch.params;
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged>
              <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</NavDropdown.Item>
            </NavbarLogged>
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footerlogged />
          </>
        ) : (
          <>
            <PrincipalNavbar isPublicView companyCode={companyCode}/>
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footerlogged />
          </>
        )
      }
    />
  );
};
