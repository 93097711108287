import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';

const MovementShow = ({ movement: { movementDetailsAttributes }, isStocktake }) => {
  const tableBody = movementDetail => {
    const { categoryName, code, productCategoryName, sku } = movementDetail.product;
    return (
      <>
        <td>{categoryName}</td>
        <td>{productCategoryName}</td>
        <td>{code}</td>
        <td>{sku}</td>
        <td>{movementDetail.quantity || 0}</td>
        <td>{isStocktake ? 'No aplica' : movementDetail.parsedSalePrice}</td>
        <td>{isStocktake ? 'No aplica' : movementDetail.parsedTotalAmount}</td>
      </>
    );
  };

  return (
    <Row>
      <Col md={12} className="mb-3">
        <strong>Detalles de Movimiento</strong>
      </Col>
      <Col md={12}>
        <Table responsive borderless hover size="sm">
          <thead className="border-top-borderless-table">
            <tr className="header-border-bottom">
              <th>Categoría</th>
              <th>Subcategoría</th>
              <th>Ítem</th>
              <th>SKU</th>
              <th>Cantidad</th>
              <th>Valor Unitario</th>
              <th>Valor Neto</th>
            </tr>
          </thead>
          <tbody>
            {movementDetailsAttributes.map((movementDetail, index) =>
              index === 0 ? (
                <tr key={`movement-product-${index.toString()}`} className="border-top-borderless-table">
                  {tableBody(movementDetail)}
                </tr>
              ) : (
                <tr key={`movement-product-${index.toString()}`}>{tableBody(movementDetail)}</tr>
              )
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default MovementShow;
