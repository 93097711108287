import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { Button } from 'react-bootstrap';
import { format, parse } from 'date-fns';
import {useAbility} from "@casl/react";

import { AbilityContext, Can } from '../../config/abilityContext';

import {
  indexCalendarEventsRequest,
  createCalendarEventsRequest,
  showCalendarEventRequest,
  updateCalendarEventsRequest,
  deleteCalendarEventRequest
} from '../../requests/calendarEvents';

import { sendAlert, saveCurrentClient, saveSecondCurrentClient } from '../../actions/utils';
import { SimpleCenteredModal } from '../../components/Utils/Modal';
import { createProjectRequest, deleteProjectRequest } from '../../requests/projectsV2';
import { isMobile, shortText, camelCaseEmptyStringRecursive } from '../../services/utils';
import CalendarEventForm from './Form/CalendarEventForm';
import ProjectInfo from '../Project/ProjectInfo';
import CalendarFilters from './Filters/CalendarFilters';
import { ProjectMechanisStateColors } from '../../constants';
import { createProjectChecklistRequest } from '../../requests/projectChecklists';
import ProjectSelectChecklistForm from '../Project/Form/ProjectSelectChecklistForm';
import { reviewTypes } from '../ReviewGroup/Constants';

const initialCalendarEvent = {
  id: '',
  clientId: '',
  secondClientId: null,
  endDate: '',
  notes: '',
  promiseDate: '',
  startDate: '',
  vehicleId: '',
  orderType: 'reception',
  mileage: '',
  sinisterNumber: null,
  ocNumber: null,
  deliveryDate: null,
  orderNumber: null
};

const CalendarIndex = () => {
  const {
    user: { companyAttributes }
  } = useSelector(state => state.auth);
  const startTime = companyAttributes?.startTime || '00:00';
  const endTime = companyAttributes?.endTime || '24:00';
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [endDateCalendar, setEndDateCalendar] = useState('');
  const [filterIndex, setFilterIndex] = useState('');
  const [filterState, setFilterState] = useState('');
  const [hiddenDays, setHiddenDays] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalCentered, setModalCentered] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [startDateCalendar, setStartDateCalendar] = useState('');
  const [classModalBody, setClassModalBody] = useState('');
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();
  const ability = useAbility(AbilityContext);

  const handleHiddenDays = useCallback(company => {
    const disabledDays = [
      company.worksSunday,
      company.worksMonday,
      company.worksTuesday,
      company.worksWednesday,
      company.worksThursday,
      company.worksFriday,
      company.worksSaturday
    ].reduce((out, bool, index) => (bool ? out : out.concat(index)), []);
    if (disabledDays.length === 7) {
      disabledDays.shift();
    } // hiddenDays can't receive all days

    setHiddenDays(disabledDays);
  }, []);

  useEffect(() => {
    if (companyAttributes !== undefined) handleHiddenDays(companyAttributes);
  }, [handleHiddenDays, companyAttributes]);

  const loadEvents = useCallback(
    (params = {}) => {
      if (!startDateCalendar || !endDateCalendar) return;
      indexCalendarEventsRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          q: {
            startDateGteq: startDateCalendar,
            endDateLteq: endDateCalendar
          },
          displayLength: 1000
        },
        successCallback: response => setCalendarEvents(camelCaseRecursive(response.data))
      });
    },
    [stableDispatch, startDateCalendar, endDateCalendar]
  );

  const handleRequestWithFilters = () => {
    const params = {};
    if (filterState) params.state_filtered = filterState;
    if (filterIndex) params.promise_index = true;
    loadEvents(params);
  };

  useEffect(handleRequestWithFilters, [filterState, filterIndex, startDateCalendar, endDateCalendar]);

  useEffect(() => {
    if (calendarEvents.length && !calendarEvents[0]?.color) {
      const setColors = calendarEvents.map(calendarEvent => ({
        ...calendarEvent,
        color: ProjectMechanisStateColors[calendarEvent.state] || '#8e8e8e'
      }));
      setCalendarEvents(setColors);
    }
  }, [calendarEvents, setCalendarEvents]);

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    handleRequestWithFilters();
    setClassModalBody('');
    handleModalClose();
  };

  const handleSuccessDeskSell = (code) => {
    setClassModalBody('');
    handleModalClose();
    const seeOtUrl = `/projects/${code}/edit`;
    history.push(seeOtUrl)
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = params => {
    createCalendarEventsRequest({
      dispatch,
      params: snakeCaseKeys(params, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: params.calendarEvent.orderType !== 'desk_sell' ? (
        () => {
          handleSuccessAction('Cita agendada con éxito');
        }
      ) : (
        (response) => {
          handleSuccessDeskSell(response.data.code);
        }
      ),
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateRequest = params => {
    const calendarEventId = params.calendarEvent.id;
    updateCalendarEventsRequest(calendarEventId, {
      dispatch,
      params: snakeCaseKeys(params, { exclude: ['_destroy'] }),
      successCallback: () => handleSuccessAction('Cita actualizada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const handleRemoveRequest = calendarEventId => {
    deleteCalendarEventRequest(calendarEventId, {
      dispatch,
      successCallback: () => handleSuccessAction('Cita eliminada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const handleDeleteProject = otCode => {
    deleteProjectRequest(otCode, {
      dispatch,
      successCallback: () => handleSuccessAction('Recepción de cliente deshecha con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const setModal = (title, body, size = null, centered = false) => {
    dispatch(saveCurrentClient({}));
    dispatch(saveSecondCurrentClient({}));
    setClassModalBody('');
    setModalTitle(title);
    setModalBody(body);
    setModalSize(size);
    setModalCentered(centered);
    setModalShow(true);
  };

  const handleActions = (model, event = null) => {
    const editMode = event && event.id !== '';
    let title;
    let body;

    switch (model) {
      case 'CalendarEvent':
        title = editMode ? 'Editar Cita' : 'Crear Cita';
        body = editMode ? (
          <CalendarEventForm
            calendarEvent={event}
            action="edit"
            formRequest={handleUpdateRequest}
            submitVariant="success"
            handleClose={handleModalClose}
            handleRemove={handleRemoveRequest}
            setClassModalBody={setClassModalBody}
          />
        ) : (
          <CalendarEventForm
            calendarEvent={event}
            action="new"
            formRequest={handleCreateRequest}
            submitVariant="success"
            handleClose={handleModalClose}
            setClassModalBody={setClassModalBody}
          />
        );
        setModal(title, body, '');
        break;
      case 'DeskSell':
        title = 'Crear Venta Mesón';
        body = (
          <CalendarEventForm
            calendarEvent={event}
            action="new"
            formRequest={handleCreateRequest}
            submitVariant="success"
            handleClose={handleModalClose}
            setClassModalBody={setClassModalBody}
          />
        );
        setModal(title, body, '');
        break;

      default:
        break;
    }
  };

  const handleCreateProjectChecklistRequest = (code, values, checklistTypeCode) => {
    createProjectChecklistRequest(code, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: () => history.push(`/projects/${code}/${checklistTypeCode}`)
    });
  };

  const handleShowChecklist = (project, checklistTypeCode) => {
    const code = project.otCode || project.id;
    const checklistData = project.checklistAttributes?.find(item => item?.checklistType === checklistTypeCode);
    if (checklistData?.checklistId !== '') {
      history.push(`/projects/${code}/${checklistTypeCode}`);
    } else {
      const ttlle = `SELECCIONAR ${  reviewTypes[checklistTypeCode]}`;
      const body = (
        <ProjectSelectChecklistForm
          checklistTypeCode={checklistTypeCode}
          formRequest={values => handleCreateProjectChecklistRequest(code, values, checklistTypeCode)}
        />
      );
      setModal(ttlle, body, 'lg', true);
    }
  };

  const handleCreateProject = calendarEvent => {
    const params = {
      project: {
        calendar_event_id: calendarEvent.id
      }
    };
    const nextCheckList = {
      reception_secure: 'pre_inspection',
      reception: 'reception'
    };
    createProjectRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: response => {
        handleShowChecklist(camelCaseEmptyStringRecursive(response.data), nextCheckList[response.data.order_type]);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleEventActions = (event = null) => {
    const title = event.otCode
      ? event.orderType === 'reception_secure'
        ? 'Información de pre inspección'
        : 'Información de Recepción'
      : `Cita #${event.id}`;
    const seeOtUrl = ability.can('manage', 'Project') ?`/projects/${event.otCode}`: `/project_mechanic_reviews/${event.otCode}`
    let body;

    switch (event.state) {
      case 'pending':
        body = (
          <>
            <Button variant="primary" block onClick={() => handleActions('CalendarEvent', event)}>
              Editar Cita
            </Button>
            {event.orderType === 'reception_secure' ? (
              <Button variant="success" block onClick={() => handleCreateProject(event)}>
                Pre inspección
              </Button>
            ) : (
              <Button variant="success" block onClick={() => handleCreateProject(event)}>
                Recepcionar Cliente
              </Button>
            )}
          </>
        );
        break;
      case 'created':
        body = (
          <>
            <ProjectInfo event={event} />
            {event.orderType === 'reception_secure' && (
              <Button variant="success" block onClick={() => handleShowChecklist(event, 'pre_inspection')}>
                Continuar Pre inspección
              </Button>
            )}
            {event.orderType !== 'desk_sell' && <Button variant="success" block onClick={() => handleShowChecklist(event, 'reception')}>
              Continuar Recepción
            </Button>}
            <Can I="create" a="ProjectWork">
              <Button variant="primary" block onClick={() => history.push(seeOtUrl)}>
                Ver OT
              </Button>
            </Can>
          </>
        );
        break;
      case 'pre_inspected':
        body = (
          <>
            <ProjectInfo event={event} />
            <Button variant="success" block onClick={() => handleShowChecklist(event, 'reception')}>
              Realizar recepción
            </Button>
            <Button variant="primary" block onClick={() => history.push(seeOtUrl)}>
              Ver OT
            </Button>
          </>
        );
        break;
      case 'received':
        body = (
          <>
            <ProjectInfo event={event} />
            <Button variant="danger" block onClick={() => handleDeleteProject(event.otCode)}>
              Deshacer Recepción
            </Button>
            <Can I="create" a="ProjectWork">
              <Button variant="success" block onClick={() => handleShowChecklist(event, 'mechanic')}>
                Revisión de Vehículo
              </Button>
              <Button variant="primary" block onClick={() => history.push(seeOtUrl)}>
                Ver OT
              </Button>
            </Can>
          </>
        );
        break;
      default:
        body = (
          <>
            <ProjectInfo event={event} />
            <Button variant="primary" block onClick={() => history.push(seeOtUrl)}>
              Ver OT
            </Button>
          </>
        );
        break;
    }
    setModal(title, body, 'md', true);
  };

  const validateTimeDifferenceMinutes = (start, end) => {
    const parsedStart = parse(start, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());
    const parsedEnd = parse(end, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());
    const differenceInMinutes = (parsedEnd - parsedStart) / (1000 * 60);
    let suggestedEndDate = parsedEnd;
    if (differenceInMinutes < 90) {
      parsedStart.setMinutes(parsedStart.getMinutes() + 100);
      suggestedEndDate = parsedStart;
    }
    return format(suggestedEndDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
  };

  const handleDateSelect = selectInfo => {
    const suggestedEndDate = validateTimeDifferenceMinutes(selectInfo.startStr, selectInfo.endStr);
    const event = {
      ...initialCalendarEvent,
      startDate: selectInfo.startStr,
      endDate: suggestedEndDate
    };
    handleActions('CalendarEvent', event);
  };

  const handleCreateDate = () => {
    const startDate = new Date();
    // Add 90 minutes to startDate
    const endDate = new Date(startDate.getTime() + 90 * 60 * 1000);
    const event = {
      ...initialCalendarEvent,
      startDate,
      endDate
    };
    handleActions('CalendarEvent', event);
  };

  const handleCreateDeskSell = () => {
    const startDate = new Date();
    // Add 90 minutes to startDate
    const endDate = new Date(startDate.getTime() + 90 * 60 * 1000);
    const event = {
      ...initialCalendarEvent,
      startDate,
      endDate
    };
    event.orderType = 'desk_sell';
    handleActions('DeskSell', event);
  };

  const loadEvent = id => {
    showCalendarEventRequest(id, {
      dispatch,
      successCallback: reponse => handleEventActions(camelCaseEmptyStringRecursive(reponse.data))
    });
  };

  const handleEventClick = clickInfo => loadEvent(clickInfo.event.id);

  const handleEventChange = eventWrapper => {
    const event = {
      calendarEvent: {
        id: eventWrapper.event.id,
        startDate: eventWrapper.event.startStr,
        endDate: eventWrapper.event.endStr
      }
    };

    handleUpdateRequest(event);
  };

  const handleDatesSet = dateInfo => {
    setStartDateCalendar(dateInfo.startStr);
    setEndDateCalendar(dateInfo.endStr);
  };

  const handleEventContent = element => {
    const copyElement = element;
    const { event, timeText } = element;
    const { _def: eventInfo, extendedProps } = event;
    const { client, clientId, notes, otCode, translatedState, vehicle, vehicleId, secondClient } = extendedProps;
    const colorText = extendedProps?.state !== 'pending' ? 'text-dark' : 'text-white';

    const newTimeText = (
      <div className="event-header text-uppercase d-flex justify-content-between">
        <span>{otCode ? `OT #${otCode}` : `Cita #${eventInfo.publicId}`}</span>
        <span>{translatedState}</span>
        <span>{timeText}</span>
      </div>
    );
    const newTitle = (
      <div className={`text-uppercase small mt-1 ${colorText}`}>
        {clientId && (
          <p className="mb-0">
            <strong>Cliente:</strong> {client.fullName}
          </p>
        )}
        {secondClient && (
          <p className="mb-0">
            <strong>Cliente 2:</strong> {secondClient.fullName}
          </p>
        )}
        {vehicleId && <p>{`${vehicle.brand.name} ${vehicle.model.name} ${vehicle.plate}`}</p>}
        {notes && !clientId && !vehicleId && (
          <p className={`mb-0 ${colorText}`}>
            <strong>Notas: </strong> {shortText(notes, 95)}
          </p>
        )}
      </div>
    );

    copyElement.timeText = newTimeText;
    eventInfo.title = newTitle;
  };

  return (
    <>
      <CalendarFilters
        setFilterState={setFilterState}
        setFilterIndex={setFilterIndex}
        onOpenCreateModal={handleCreateDate}
        onOpenCreateModalDeskSell={handleCreateDeskSell}
      />
      <FullCalendar
        ref={calendarRef}
        height="auto"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        firstDay={1}
        buttonText={{ today: 'Hoy', month: 'Mes', week: 'Semana', day: 'Día', list: 'Lista' }}
        locale="es"
        initialView={isMobile() ? 'timeGridDay' : 'timeGridWeek'}
        weekends
        editable
        selectable
        selectMirror
        dayMaxEvents
        allDaySlot={false}
        hiddenDays={hiddenDays}
        slotMinTime={startTime}
        slotMaxTime={endTime}
        slotLabelFormat={{ hour: 'numeric', minute: '2-digit' }}
        events={calendarEvents}
        eventColor="#668CB5"
        eventContent={handleEventContent}
        eventClick={handleEventClick}
        eventChange={handleEventChange}
        datesSet={handleDatesSet}
        select={handleDateSelect}
        longPressDelay={200}
      />
      <SimpleCenteredModal
        closeButton
        dialogClassName="modal-90w"
        classNameBody={classModalBody}
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        size={modalSize}
        onHide={handleModalClose}
        centered={modalCentered}
      />
    </>
  );
};

export default CalendarIndex;
