import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexIncidentsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/incidents', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createIncidentRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/incidents', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateIncidentRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/incidents/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteIncidentRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/incidents/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const deleteIncidentImageRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/incidents/${id}/destroy_incident_image`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexIncidentsRequest = AwesomeDebouncePromise(indexIncidentsRequest, 300);
