import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexBranchOfficesRequest = ({ dispatch, params = {}, successCallback, failureCallback }) => {
  ApiService.request('get', `/branch_offices`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createBranchOfficeRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/branch_offices', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateBranchOfficeRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/branch_offices/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteBranchOfficeRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/branch_offices/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexBranchOfficesRequest = AwesomeDebouncePromise(indexBranchOfficesRequest, 300);
