import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { parse } from 'date-fns';
import moment from 'moment';
import { FormikDatePicker, FormikInput, FormikSelect } from '../../../components';
import { showCompanyRequest } from '../../../requests/companies';

const orderTypes = [
  { label: 'Orden con Recepción', value: 'reception' },
  { label: 'Venta de Mesón', value: 'desk_sell' }
];

const CalendarEventTab = props => {
  const { calendarEvent, errors, setFieldValue, touched, currentValues, edit, disabledDate = true } = props;

  const { startDate, orderType } = calendarEvent;
  const baseDate = moment(startDate).format('YYYY-MM-DD');
  const dateSelected = startDate && moment(startDate).format('DD/MM/YYYY');
  const [startTime, setStartTime] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (orderTypes.filter(ot => ot.value === 'reception_secure').length === 0) {
      showCompanyRequest({
        dispatch,
        successCallback: response => {
          if (response.data.works_with_insurance_companies) {
            orderTypes.push({ label: 'Orden con Recepción y Seguro', value: 'reception_secure' });
          }
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    setStartTime(moment(startDate).toDate());
  }, [startDate]);

  return (
    <Row>
      <Col md={12}>
        <Field name="calendarEvent[orderType]">
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Tipo de Orden"
              options={orderTypes}
              defaultValue={orderType}
              placeholder="Tipo"
              isDisabled={edit}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
            />
          )}
        </Field>
      </Col>
      {currentValues.orderType !== 'desk_sell' && (
        <>
          <Col md={12}>
            <Field name="calendarEvent[dateSelected]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  label="Fecha"
                  disabled={disabledDate}
                  dateFormat="dd/MM/yyyy"
                  selected={dateSelected ? parse(dateSelected, 'dd/MM/yyyy', new Date()) : null}
                  onChange={date => {
                    setFieldValue(field.name, moment(date).format('DD/MM/YYYY'));
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="calendarEvent[startDate]">
              {({ field }) => (
                <FormikDatePicker
                  abbr
                  dateFormat="H:mm"
                  showTimeSelect
                  showTimeSelectOnly
                  label="Hora Cita"
                  timeIntervals={30}
                  selected={startTime}
                  onChange={date => {
                    setStartTime(date);
                    setFieldValue(field.name, `${baseDate}T${moment(date).format('HH:mm')}`);
                    setFieldValue(
                      'calendarEvent[endDate]',
                      `${baseDate}T${moment(date)
                        .add(100, 'minutes')
                        .format('HH:mm')}`
                    );
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="calendarEvent[promiseParseDate]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  dateFormat="dd/MM/yyyy"
                  onChange={date => {
                    setFieldValue(field.name, moment(date).format('DD/MM/YYYY'));
                  }}
                  label="Fecha estimada de entrega"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="calendarEvent[promiseTime]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  type="time"
                  label="Hora de entrega estimada"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </>
      )}
    </Row>
  );
};

export default CalendarEventTab;
