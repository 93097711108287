import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import PlaceHolder from '../../../assets/images/placeholder-card.webp';
import InfoModal from './ModalSection';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { debounceIndexSuccessfulCasesRequest } from '../../../requests/successful_cases';

const CardSuccess = () => {
  const [successfulCases, setSuccessfulCases] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessFetchSuccessfulCases = response => {
    const responseSuccessfulCases = camelCaseEmptyStringRecursive(response.data);
    setSuccessfulCases(responseSuccessfulCases);
  };

  const fetchSuccessfulCases = () => {
    debounceIndexSuccessfulCasesRequest({
      dispatch,
      params: {
        display_limit: 3
      },
      successCallback: handleSuccessFetchSuccessfulCases
    });
  };

  useEffect(fetchSuccessfulCases, []);

  return (
    <>
      {successfulCases.map((successfulCase, index) => (
        <Col md={4} xs={12} id="CardSuccess" key={`successful-case-key-${index.toString()}`}>
          <Card className="card-margin" style={{ width: '95%', margin: 'auto' }}>
            <Card.Img
              className="lazyload"
              variant="top"
              alt="Cliente taller logo"
              style={{ aspectRatio: '2 / 1', objectFit: 'cover' }}
              src={Object.keys(successfulCase.fileInfo).length !== 0 ? successfulCase.fileInfo.fileUrl : PlaceHolder}
            />
            <Card.Body>
              <Card.Title>{successfulCase.branchOfficeName}</Card.Title>
              <Card.Text className="blue-text">{`${successfulCase.clientName}, ${successfulCase.clientRole}`}</Card.Text>
              <Card.Text>{`"${successfulCase.clientQuote}"`}</Card.Text>
              <InfoModal successfulCase={successfulCase} />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default CardSuccess;
