import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexBulkUploadRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/bulk_uploads${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createBulkUploadRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/bulk_uploads', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const debounceIndexBulkUploadsRequest = AwesomeDebouncePromise(indexBulkUploadRequest, 300);
