import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { createPurchaseManagementRequest } from '../../requests/purchaseManagements';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import PurchaseManagementForm from './Form/PurchaseManagementForm';

const basicPurchaseManagement = {
  notes: '',
  purchaseManagementDetailsAttributes: [],
  user: {},
  userId: ''
};

const PurchaseManagementNew = () => {
  const [purchaseManagement, setPurchaseManagement] = useState(basicPurchaseManagement);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessCreate = response => {
    setPurchaseManagement(camelCaseEmptyStringRecursive(response.data));
    dispatch(sendAlert({ kind: 'success', message: 'Listado de compra creado con éxito' }));
    history.push('/purchase_management');
  };

  const handleCreateRequest = values => {
    createPurchaseManagementRequest({
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row className="my-4">
        <Col className="mx-5">
          <h2 className="text-uppercase">Nuevo listado de compra</h2>
        </Col>
      </Row>
      <Row>
        <Col className="mx-5">
          <PurchaseManagementForm
            action="new"
            purchaseManagement={purchaseManagement}
            submitVariant="primary"
            formRequest={handleCreateRequest}
          />
        </Col>
      </Row>
    </>
  );
};

export default PurchaseManagementNew;
