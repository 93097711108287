const mechanicAbility = can => {
  can('create', 'CalendarEvent');
  can('create', 'Client');
  can('create', 'Project');
  can('create', 'Vehicle');
  can('destroy_document', 'User');
  can('read', 'CalendarEvent');
  can('read', 'Client');
  can('read', 'Company');
  can('read', 'Commune');
  can('read', 'Project');
  can('read', 'Province');
  can('read', 'Region');
  can('read', 'Setting');
  can('read', 'Slide');
  can('read', 'User');
  can('read', 'Vehicle');
  can('read', 'VehicleBrand');
  can('read', 'VehicleModel');
  can('update', 'CalendarEvent');
  can('update', 'Client');
  can('update', 'Project');
  can('delete', 'CalendarEvent');
  can('update_password', 'User');
  can('update', 'User');
};

export default mechanicAbility;
