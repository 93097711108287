import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ComponentDataTable from '../../components/Utils/DataTable';
import { debounceMovementDetailsIndexRequest } from '../../requests/products';
import { sendAlert } from '../../actions/utils';

const MovementDetailDataTable = ({ columns, productId }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [movementDetails, setMovementDetails] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);

  const handleSuccessRequest = response => {
    const { amount: responseAmount } = response.data.metadata;
    setMovementDetails(response.data.data);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    const {
      data: { message }
    } = error.response;
    dispatch(sendAlert({ kind: 'error', message }));
    setOnRequest(false);
  };

  const handleIndexRequest = prevParams => {
    setOnRequest(true);
    const params = {
      ...prevParams
    };
    debounceMovementDetailsIndexRequest(productId, {
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'product_movement_type':
        return { sort_product_movement_type: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={columns}
      handleSortCase={sortColumnCase}
      data={movementDetails}
      totalRows={amount}
      moreData={moreData}
      resourceRequest={response => {
        if (!onRequest) handleIndexRequest(response);
      }}
      noSearch
    />
  );
};

export default MovementDetailDataTable;
