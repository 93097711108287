import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import CalendarIcon from '../../../assets/images/calendar-icon.png';
import DashboardIcon from '../../../assets/images/dashboard-icon.png';
import ImgIcon from '../../../assets/images/img-icon.png';
import ChecklistIcon from '../../../assets/images/checklist-icon.png';
import DocIcon from '../../../assets/images/doc-icon.png';
import WspIcon from '../../../assets/images/wsp-icon.png';
import InvoiceIcon from '../../../assets/images/invoice-icon.png';
import BoxesIcon from '../../../assets/images/boxes-icon.png';
import StorieIcon from '../../../assets/images/storie-icon.png';
import MecanicIcon from '../../../assets/images/mecanic-icon.png';

const FuntionSection = () => {
  return (
    <>
      <div id="funtions">
        <Fade up>
          <Row className="landing-image-section row-section-benefits max-width-2000">
            <Col className="order-sm-2">
              <h3 className="mb-4 text-center">Funcionalidades principales</h3>
            </Col>
          </Row>

          <Row className="justify-content-center mb-2 max-width-2000">
            <Col lg={2} md={2} xs={6} className="text-center">
              <img
                className="pb-3"
                alt="Icono calendario"
                title="Icono calendario"
                src={CalendarIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Agenda de citas</p>
            </Col>
            <Col lg={2} md={2} xs={6} className="text-center">
              <img
                className="pb-3"
                alt="Icono Dashboard"
                title="Icono Dashboard"
                src={DashboardIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Panel de control</p>
            </Col>
            <Col lg={2} md={2} xs={6} className="text-center">
              <img
                className="pb-3"
                alt="Icono Imagen Recepción"
                title="Icono Imagen Recepción"
                src={ImgIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Recepción con fotos</p>
            </Col>
            <Col lg={2} md={2} xs={6} className="text-center">
              <img
                className="pb-3"
                alt="Icono Checklist"
                title="Icono Checklist"
                src={ChecklistIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Check list de diagnóstico</p>
            </Col>
            <Col lg={2} md={2} xs={6} className="text-center">
              <img
                className="pb-3"
                alt="Icono Documento"
                title="Icono Documento"
                src={DocIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Presupuestos automatizados</p>
            </Col>
            <Col lg={2} md={2} xs={6} className="text-center d-block d-md-none">
              <img
                className="pb-3"
                alt="Icono Productividad"
                title="Icono Productividad"
                src={MecanicIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Productividad mecánicos</p>
            </Col>
          </Row>

          <Row className="justify-content-center max-width-2000">
            <Col lg={2} md={2} xs={6} className="text-center">
              <img
                className="pb-3"
                alt="Icono whatsapp"
                title="Icono whatsapp"
                src={WspIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Notificación por mail y whatsapp</p>
            </Col>
            <Col lg={2} md={2} xs={6} className="text-center">
              <img
                className="pb-3"
                alt="Icono Boleta"
                title="Icono Boleta"
                src={InvoiceIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Integración con Facturación</p>
            </Col>
            <Col lg={2} md={2} xs={6} className="text-center">
              <img
                className="pb-3"
                alt="Icono Inventario"
                title="Icono Inventario"
                src={BoxesIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Inventarios</p>
            </Col>
            <Col lg={2} md={2} xs={6} className="text-center">
              <img
                className="pb-3"
                alt="Icono Historial"
                title="Icono Historial"
                src={StorieIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Historial de trabajos</p>
            </Col>
            <Col lg={2} md={2} xs={6} className="text-center d-none d-md-block">
              <img
                className="pb-3"
                alt="Icono Productividad"
                title="Icono Productividad"
                src={MecanicIcon}
                width="90%"
                height="auto"
              />
              <p className="text-center">Productividad mecánicos</p>
            </Col>
          </Row>
        </Fade>
      </div>
    </>
  );
};

export default FuntionSection;
