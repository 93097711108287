import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import ComponentDataTable from '../../components/Utils/DataTable';
import { DefaultModal, SimpleCenteredModal } from '../../components';
import { debounceIndexWorksRequest, deleteWorkRequest, updateWorkRequest } from '../../requests/works';
import { sendAlert } from '../../actions/utils';
import WorkForm from './WorkForm';

const WorkDataTable = props => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [works, setWorks] = useState([]);
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const { columns, moreData, setMoreData } = props;

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Trabajo actualizado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleUpdateRequest = values => {
    const {
      work: { id }
    } = values;
    updateWorkRequest(id, {
      dispatch,
      params: snakecaseKeys(values, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRequest = response => {
    const responseWorks = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setWorks(responseWorks);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexWorksRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'edit':
        setCenteredModalShow(true);
        setModalBody(
          <WorkForm
            action={action}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            submitVariant="success"
            work={camelCaseEmptyStringRecursive(item)}
          />
        );
        setModalTitle('Editar Trabajo');
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas eliminar este trabajo?');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Trabajo eliminado con éxito' }));
    setMoreData(!moreData);
  };

  const removeWork = workId => {
    deleteWorkRequest(workId, {
      dispatch,
      successCallback: () => handleSuccessRemove(workId)
    });
  };

  const handleModalConfirm = () => {
    removeWork(modalItem.id);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'vehicle_brand':
        return { sort_vehicle_brand: name };
      case 'vehicle_model':
        return { sort_vehicle_model: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={works}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
      {modalShow && (
        <DefaultModal
          title="Eliminar Trabajo"
          body={modalBody}
          show={modalShow}
          handleClose={handleModalClose}
          handleConfirm={handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
    </>
  );
};

export default WorkDataTable;
