import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Alert } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { useQueryClient } from 'react-query';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { camelCaseEmptyStringRecursive } from '../../../../app/services/utils';
import { DefaultModal, RichEditor, SimpleCenteredModal } from '../../../../app/components';
import { sendAlert } from '../../../../app/actions/utils';
import { sendEmailRequest, updateProjectRequest } from '../../../../app/requests/projects';
import ProjectShowForm from '../../../../app/screens/Project/Form/ProjectShowForm';
import { showCompanyRequest } from '../../../../app/requests/companies';

export function SendModal({ project, isOpen, onClose }) {
  const dispatch = useDispatch();
  const { id, desk_sell: isDeskSale, formated_promise_date: formatedPromiseDate } = project;

  const [progressOtStateEmailBody, setProgressOtStateEmailBody] = React.useState('');
  const [companyInfo, setCompanyInfo] = React.useState({});

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Orden de trabajo actualizada con éxito' }));
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    updateProjectRequest(id, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateEmailBodyContent = () => {
    const { progressOtStateEmailBody: emailBody } = companyInfo;
    const promiseDateText = `<p>La fecha y hora prevista para la entrega de su vehículo es ${formatedPromiseDate}.
    Para más información acerca del estado de su vehiculo, puede revisar el estado de los trabajo a continuación</p>`;
    const body = emailBody && promiseDateText ? emailBody.concat(promiseDateText) : '';
    setProgressOtStateEmailBody(body);
  };

  const handleShowCompanySuccess = response => {
    setCompanyInfo(camelCaseRecursive(response.data));
  };
  const handleGetCompanyData = () => {
    showCompanyRequest({
      dispatch,
      successCallback: handleShowCompanySuccess,
      failureCallback: handleFailureRequest
    });
  };
  React.useEffect(handleGetCompanyData, []);
  React.useEffect(handleUpdateEmailBodyContent, [companyInfo, project]);

  const modalBody = (
    <>
      <Col md={12} className="mb-2">
        <Alert variant="secondary">
          <p className="font-weight-bold">
            A partir de esta acción, se generará una nueva versión de la Orden de Trabajo (OT).
          </p>

          <p className="mt-3 font-italic ">
            Un PDF será generado y enviado al cliente a partir de esta versión, además de un link público para revisar
            el estado general de la OT
          </p>
        </Alert>
      </Col>
      <Col md={11} className="mx-auto">
        <ProjectShowForm
          from="kanbanSectionEnviado"
          isDeskSale={isDeskSale}
          project={camelCaseEmptyStringRecursive(project)}
          action="edit"
          submitVariant="success"
          formRequest={handleUpdateRequest}
          compact
        />
      </Col>
      <Col md={12} className="mt-4">
        <Alert variant="warning">
          <p className="font-weight-bold text-center">Debe guardar cambios en OT antes de Confirmar</p>
          <p className="mt-3 font-italic ">
            De lo contrario, cambios no guardados NO se reflejarán en Presupuesto ni en la OT
          </p>
        </Alert>
      </Col>
      <Col md={12}>
        <RichEditor
          label="Cuerpo email"
          data={progressOtStateEmailBody}
          setFieldValue={() => {}}
          setData={setProgressOtStateEmailBody}
        />
      </Col>
    </>
  );

  const handleSuccessSendReception = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Presupuesto ha sido enviado exitosamente' }));
  };

  const sendEmail = type => {
    const sendParams = {
      email: progressOtStateEmailBody
    };
    sendEmailRequest({
      id,
      type,
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: () => handleSuccessSendReception(type),
      failureCallback: handleFailureRequest
    });
  };

  const handleConfirm = () => {
    sendEmail('quote');
    if (onClose) {
      onClose();
    }
  };

  return (
    <DefaultModal
      title="Cambiar Enviar Presupuesto"
      body={modalBody}
      show={isOpen}
      handleClose={onClose}
      handleConfirm={handleConfirm}
      titleBtnClose="Cancelar"
      titleBtnSave="Confirmar"
    />
  );
}

export function SendModalSimple({ project, showModal, setModalShow, buildQueryParams }) {
  const { id } = project;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSuccessRequest = async values => {
    dispatch(sendAlert({ kind: 'success', message: 'Fecha estimada de entrega actualizada con éxito' }));
    setModalShow(false);

    queryClient.setQueryData(['kanban_search_projects', buildQueryParams], oldData => {
      if (!oldData) return oldData;
      const newData = [...oldData];
      const projectIndex = newData.findIndex(proj => proj.id === id);
      if (projectIndex !== -1) {
        newData[projectIndex] = {
          ...newData[projectIndex],
          ot_state: values.data.ot_state
        };
      }
      return newData;
    });
    await queryClient.invalidateQueries(['kanban_search_projects']);
    await queryClient.invalidateQueries('kanban_sections');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    updateProjectRequest(id, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const modalBody = (
    <ProjectShowForm
      from="KanbanModal"
      project={camelCaseEmptyStringRecursive(project)}
      action="edit"
      submitVariant="success"
      formRequest={handleUpdateRequest}
    />
  );

  return (
    <SimpleCenteredModal
      closeButton={false}
      size="sm"
      title="EDITAR ORDEN DE TRABAJO"
      body={modalBody}
      show={showModal}
      onHide={() => setModalShow(false)}
    />
  );
}
