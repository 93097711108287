import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Minus } from 'react-feather';
import { getIn } from 'formik';

const PercentageReviewButtons = ({ isDisabled, field, handleIsOpen, handleButtonsAction, errors, touched }) => {
  return (
    <ToggleButtonGroup
      {...field}
      onChange={value => {
        handleIsOpen(false);
        handleButtonsAction(value);
      }}
      disabled={isDisabled}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    >
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="not_apply"
        variant="outline-dark"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
      >
        <Minus /> No aplica
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="percentage_0"
        variant="outline-danger"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
      >
        <Minus /> 0%
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="percentage_25"
        variant="outline-danger"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
      >
        <Minus /> 25%
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="percentage_50"
        variant="outline-secondary"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
      >
        <Minus /> 50%
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="percentage_75"
        variant="outline-success"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
      >
        <Minus /> 75%
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        variant="outline-success"
        value="percentage_100"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
      >
        <Minus /> 100%
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PercentageReviewButtons;
