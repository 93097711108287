/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { Image, Form, Row, Col } from 'react-bootstrap';
import { Trash } from 'react-feather';
import defaultImg from '../../../assets/images/default.png';
import { ButtonTooltip } from '../Tooltips';
import getImage from '../../../utils/getImage';
import './styles.scss';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      imageUrl: props.imageUrl || '',
      name: props.name || ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setState(prev => ({
        ...prev,
        name: this.props.name,
        imageUrl: getImage(this.props.imageUrl, 200, 200, 'inside')
      })); // eslint-disable-line
    }
  }

  handleClick = () => {
    this.imageInput.click();
  };

  handleOnDelete = () => {
    const { deleteField, destroyRequest, destroyRequestData } = this.props;
    deleteField();
    this.setState(prev => ({ ...prev, url: '', name: '', imageUrl: '' }));
    if (this.state.url === '') {
      destroyRequest({ document_type: destroyRequestData.imageType, document_id: destroyRequestData.imageCode });
    }
  };

  mdHandle = () => {
    if (this.props.onTop) {
      return 12;
    }
    if (this.props.onLeft) {
      return 6;
    }
    return 3;
  };

  _handleImageChange(e) {
    const { onChange } = this.props;
    e.preventDefault();
    if (e.target.files.length === 0) {
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        url: reader.result,
        name: file.name
      });
    };
    reader.readAsDataURL(file);
    onChange(file);
  }

  render() {
    const { url, imageUrl, name } = this.state;
    const {
      abbr,
      clearable,
      clearableText,
      circleImage,
      customClass,
      error,
      helpText,
      label,
      onTop,
      touched
    } = this.props;
    return (
      <div className="form-group mb-0">
        <Row className="align-items-center">
          <Col md={this.mdHandle()}>
            {clearable && name && (
              <div className="clear-image">
                <ButtonTooltip
                  onClick={this.handleOnDelete}
                  variant="outline-danger"
                  className="btn-circle table-actions-btn"
                  size="sm"
                  text={clearableText}
                >
                  <Trash width="15px" height="15px" />
                </ButtonTooltip>
              </div>
            )}
            <div
              className={`custom-file-img ${circleImage ? 'circle-image' : ''} ${onTop ? 'on-top' : ''}`}
              onClick={this.handleClick}
            >
              <div className="content">
                <Image
                  src={getImage(url, 200, 200, 'inside') || getImage(imageUrl, 200, 200, 'inside') || defaultImg}
                  fluid
                />
              </div>
            </div>
          </Col>
          <Col>
            {label && (
              <Form.Label>
                {label} {abbr && <abbr className="text-danger">*</abbr>}
              </Form.Label>
            )}
            <div className={`input-group ${customClass || ''}`}>
              <div className={`custom-file ${onTop ? 'on-top' : ''}`}>
                <input
                  type="file"
                  className="custom-file-input secondary"
                  id="inputGroupFile01"
                  ref={ref => {
                    this.imageInput = ref;
                  }}
                  aria-describedby="inputGroupFileAddon01"
                  onChange={e => this._handleImageChange(e)}
                  accept="image/*"
                />
                <label className="custom-file-label text-truncate" htmlFor="inputGroupFile01" data-browse="Buscar">
                  {name}
                </label>
              </div>
              {error && touched && <Form.Text className="text-danger mb-3">{error}</Form.Text>}
            </div>
            {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
          </Col>
        </Row>
      </div>
    );
  }
}

UploadImage.defaultProps = {
  onChange: () => {}
};

export default UploadImage;
