import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showVehicleRequest = (clientId, id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/clients/${clientId}/vehicles/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexVehiclesRequest = (clientId, { dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/clients/${clientId}/vehicles${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const companyIndexVehiclesRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/vehicles${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createVehicleRequest = (clientId, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', `/clients/${clientId}/vehicles`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateVehicleRequest = (clientId, id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/clients/${clientId}/vehicles/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const updateVehicleOwnerRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/vehicles/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteVehicleRequest = (clientId, id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/clients/${clientId}/vehicles/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexVehiclesRequest = AwesomeDebouncePromise(indexVehiclesRequest, 300);

export const debounceCompanyIndexVehiclesRequest = AwesomeDebouncePromise(companyIndexVehiclesRequest, 300);
