import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Row, Col } from 'react-bootstrap';
import ClientForm from './ClientForm';
import { showCompanyRequest } from '../../requests/companies';
import { createClientRequest } from '../../requests/clients';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import basicCompany from '../Company/basicCompany';
import GoBackButton from "../../components/GoBackButton";

const basicClient = {
  active: true,
  address: '',
  addressNumber: '',
  businessActivity: '',
  cellphone: '',
  communeId: '',
  contactsAttributes: [],
  email: '',
  firstLastName: '',
  legalName: '',
  insuranceCompanyId: '',
  name: '',
  nationalIdentification: '',
  notes: '',
  phone: '',
  regionId: '',
  secondLastName: '',
  sellerId: '',
  isCompany: false,
  country: 'cl'
};

const ClientNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentCompany, setCompany] = useState(basicCompany);

  const fetchCurrentCompany = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(fetchCurrentCompany, []);

  const handleSuccessCreate = response => {
    const { id } = response.data;
    dispatch(sendAlert({ kind: 'success', message: 'Cliente creado con éxito' }));
    history.push(`/clients/${id}`);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createClientRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-3">
            <Col className="mb-4">
              <h2>Crear Cliente</h2>
            </Col>
            <Col md={2}>
              <GoBackButton />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ClientForm
                client={basicClient}
                formRequest={handleCreateRequest}
                companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
                country={currentCompany.country}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ClientNew;
