import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { animateScroll } from 'react-scroll';
import '../../services/yupCustomMethods';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FormikSelect, FormikNumberFormat } from '../../components';
import { DefaultModal, SimpleCenteredModal } from '../../components/Utils/Modal';
import { indexUsersRequest } from '../../requests/user';
import ProjectWorkInput from './ProjectWorkInput';
import ProjectWorkTab from '../Project/Form/ProjectWorkTab';
import { uuid } from '../../utils/utils';
import useCountryFactory from "../../hooks/useCountryFactory";

const defaultProjectWork = {
  done: false,
  name: '',
  projectDetailsAttributes: [],
  userId: '',
  workHours: 0,
  workMinutes: 0
};

const ProjectWorkForm = ({
  errors,
  fromMechanicCard,
  handleCreateTemplate,
  project,
  setFieldValue,
  setFieldTouched,
  setMoreData,
  touched,
  values,
  onPreviewClick
}) => {
  const { company } = project;
  const countryFactory = useCountryFactory();
  const modelKey = 'project[projectWorksAttributes]';
  const { projectWorksAttributes, totalPrice } = values.project;
  const [changeSubtotal, setChangeSubtotal] = useState(false);
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [totalPriceTax, setTotalPriceTax] = useState(0);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  const fetchUsers = () => {
    indexUsersRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_column: 'email',
        display_length: 100
      },
      successCallback: response => setUsers(response.data.data)
    });
  };

  useEffect(fetchUsers, []);

  const calculatedPrice = projectDetailsAttributes => {
    const projectDetails = projectDetailsAttributes.filter(pd => !pd._destroy);
    return projectDetails.reduce((accum, current) => {
      const parsedPrice = parseFloat(current.price) || 0;
      return accum + parsedPrice;
    }, 0);
  };

  const calculatedTotalPrice = () => {
    const projectWorks = projectWorksAttributes.filter(pw => !pw._destroy);
    const vTotalPrice = projectWorks.reduce((acc, projectWork) => {
      const totalProjectDetailsPrice = calculatedPrice(projectWork.projectDetailsAttributes);
      return acc + totalProjectDetailsPrice;
    }, 0);
    setFieldValue('project[totalPrice]', vTotalPrice);
    return vTotalPrice;
  };

  const handlePrices = () => {
    const vTotalPrice = calculatedTotalPrice();
    const taxRate = company?.taxRate || 0;
    const taxRatePercentage = taxRate / 100 + 1;
    setTotalPriceTax(vTotalPrice * taxRatePercentage);
  };

  useEffect(handlePrices, [changeSubtotal, totalPrice]);

  const handleAddWorkClick = () => {
    const newProjectWork = { ...defaultProjectWork, key: uuid() };
    const newValues = [...projectWorksAttributes, newProjectWork];
    setFieldValue(modelKey, newValues);
    animateScroll.scrollToBottom({ duration: 1500, delay: 50 });
  };

  const handleOnDragEnd = result => {
    if (!result.destination) return;

    const items = Array.from(projectWorksAttributes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFieldValue(modelKey, items);
  };

  const defaultDeskSellWorks = () => {
    return [
      { ...defaultProjectWork, key: uuid(), name: 'Productos' }, 
      { ...defaultProjectWork, key: uuid(), name: 'Servicios' }, 
      { ...defaultProjectWork, key: uuid(), name: 'Mano de obra' }
    ];
  };

  const defaultDypWorks = () => {
    return [
      { ...defaultProjectWork, key: uuid(), name: 'DESABOLLADURA' }, 
      { ...defaultProjectWork, key: uuid(), name: 'PINTURA' }, 
      { ...defaultProjectWork, key: uuid(), name: 'CAMBIO' }, 
      { ...defaultProjectWork, key: uuid(), name: 'DESMONTAR / MONTAR' }, 
      { ...defaultProjectWork, key: uuid(), name: 'REPUESTOS SEGURO' }
    ];
  };

  const addProjectWorkInputs = () => {
    if (projectWorksAttributes.length === 0 && project.orderType === 'desk_sell') {
      projectWorksAttributes.push(...defaultDeskSellWorks());
    }
    const mapResults = projectWorksAttributes.map((projectWork, index) => {
      const recognizer = projectWork?.id ? projectWork.id : projectWork.key;
      if (projectWork._destroy) {
        return undefined;
      }
      return (
        <Draggable key={recognizer} draggableId={String(recognizer)} index={index} isDragDisabled={fromMechanicCard}>
          {provided => (
            <div ref={provided.innerRef} {...provided.draggableProps} className='mb-2'>
              <ProjectWorkInput
                key={`project-work-input-${recognizer.toString()}`}
                index={index}
                projectWork={projectWork}
                projectWorksAttributes={projectWorksAttributes}
                modelKey={modelKey}
                fromMechanicCard={fromMechanicCard}
                project={project}
                setMoreData={setMoreData}
                setChangeSubtotal={setChangeSubtotal}
                calculatedPrice={calculatedPrice}
                fromProject
                providedProjectWork={provided}
              />
            </div>
          )}
        </Draggable>
      );
    });

    return mapResults;
  };

  const handdleAddDefaultDypWorks = () => {
    projectWorksAttributes.push(...defaultDypWorks());
    setFieldValue(modelKey, projectWorksAttributes);
  };

  useEffect(() => {
    if (projectWorksAttributes.length === 0 && project.orderType === 'reception_secure' && project.client.insuranceCompany) {
      projectWorksAttributes.push(...defaultDypWorks());
      setFieldValue(modelKey, projectWorksAttributes);
    }
  }, [projectWorksAttributes, project, setFieldValue]);

  const assignProjectWork = () => {
    const data = (
      <ProjectWorkTab
        projectWorksAttributes={projectWorksAttributes}
        modelKey={modelKey}
        setFieldValue={setFieldValue}
        onHide={() => setModalShow(false)}
      />
    );
    if (projectWorksAttributes) setModalBody(data);
  };

  const handleModalClose = () => {
    setDefaultModalShow(false);
    setDefaultModalBody('');
  };

  useEffect(assignProjectWork, [projectWorksAttributes]);

  return (
    <Form>
      <Row className="mt-3 mb-3 d-flex justify-content-between">
        <Col md={2}>
          <h1>Trabajos</h1>
        </Col>
        {!fromMechanicCard && (
          <>
          <Col md={2}>
            <Button className="float-left mr-0" variant="success" block onClick={onPreviewClick}>
              Pre visualizar
            </Button>
          </Col>
          <Col md={2}>
            <Button type="submit" block>
              Actualizar
            </Button>
          </Col>
          </>
        )}
      </Row>
      <Row className="mb-3">
        <Col md={2}>
          <Field name="project[sellerId]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Vendedor"
                placeholder="Seleccionar"
                options={users}
                defaultValue={project.sellerId}
                isDisabled={fromMechanicCard}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                setFieldTouched={() => setFieldTouched(field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="project[userId]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Responsable general"
                placeholder="Seleccionar"
                options={users}
                defaultValue={project.userId}
                isDisabled={fromMechanicCard}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                setFieldTouched={() => setFieldTouched(field.name)}
              />
            )}
          </Field>
        </Col>
        {!fromMechanicCard && (
          <>
            <Col md={3}>
              <Row>
                <Col>
                  <Field name="project[totalPrice]">
                    {({ field }) => (
                      <FormikNumberFormat
                        {...field}
                        disabled
                        decimalScale={2}
                        leftAddon={countryFactory.getSymbol()}
                        label="Valor Neto"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col>
                  <Field name="project[totalPriceTax]">
                    {({ field }) => (
                      <FormikNumberFormat
                        {...field}
                        disabled
                        decimalScale={2}
                        leftAddon={countryFactory.getSymbol()}
                        label="Total"
                        value={totalPriceTax}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Col>
          </>
        )}
        {!fromMechanicCard && (
          <>
            <Col md={5}>
              <Row className='mt-4 justify-content-end'>
                {/* <Col md={11}>
                  <Row> */}
                    <Col md={4}>
                      <Button className="float-left mr-0" variant="secondary" block onClick={handdleAddDefaultDypWorks}>
                        Agregar Trabajos DyP
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Button className="float-left mr-0" variant="secondary" block onClick={handleAddWorkClick}>
                        Agregar Trabajo
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Button className="float-left mr-0" variant="secondary" block onClick={() => setModalShow(true)}>
                        Buscar Trabajo
                      </Button>
                    </Col>
                  {/* </Row>
                </Col> */}
                <SimpleCenteredModal
                  size="sm"
                  title="Buscar trabajo"
                  body={modalBody}
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                <DefaultModal
                  title="Crear Template"
                  body={defaultModalBody}
                  show={defaultModalShow}
                  handleClose={handleModalClose}
                  handleConfirm={handleCreateTemplate}
                  titleBtnClose="Cancelar"
                  titleBtnSave="Confirmar"
                />
              </Row>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="projectWorks">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {addProjectWorkInputs()}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
      {!fromMechanicCard && (
        <Row className="d-flex justify-content-between mb-3 mt-3 border-top pt-2">
          <Col md={2}>
            <Button className="float-left mr-0" variant="secondary" block onClick={handleAddWorkClick}>
              Agregar Trabajo
            </Button>
          </Col>
          <Col md={2}>
            <Button className="float-left mr-0" variant="success" block onClick={onPreviewClick}>
              Pre visualizar
            </Button>
          </Col>
          <Col md={2}>
            <Button type="submit" block>
              Actualizar
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

const setInitialValues = ({ project }) => {
  return { project };
};

const validationSchema = Yup.object().shape({
  project: Yup.object().shape({
    userId: Yup.string().nullable(),
    projectWorksAttributes: Yup.array().of(
      Yup.object().shape({
        done: Yup.boolean(),
        name: Yup.string().when('_destroy', {
          is: undefined,
          then: Yup.string().required('Debes ingresar un nombre'),
          otherwise: Yup.string().nullable(),
        }),
        userId: Yup.string().nullable(),
        workHours: Yup.number().nullable(),
        projectDetailsAttributes: Yup.array().of(
          Yup.object().shape({
            categoryId: Yup.string().nullable(),
            hidden: Yup.boolean().nullable(),
            price: Yup.number().nullable(),
            productCategoryId: Yup.string().nullable(),
            productId: Yup.string().nullable(),
            productNameCustom: Yup.string().nullable(),
            quantity: Yup.number().nullable(),
            unitPrice: Yup.number().nullable()
          })
        )
      })
    )
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: true,
  validateOnChange: false
})(ProjectWorkForm);
