import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';

const InputMultiAddonRight = ({ placeholder, ariaLabel, addon1, addon2 }) => (
  <InputGroup className="mb-3">
    <FormControl placeholder={placeholder} aria-label={ariaLabel} />
    <InputGroup.Append>
      <InputGroup.Text>{addon1}</InputGroup.Text>
      <InputGroup.Text>{addon2}</InputGroup.Text>
    </InputGroup.Append>
  </InputGroup>
);

InputMultiAddonRight.propTypes = {
  addon1: PropTypes.string.isRequired,
  addon2: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string
};

InputMultiAddonRight.defaultProps = {
  placeholder: null,
  ariaLabel: null
};

export default InputMultiAddonRight;
