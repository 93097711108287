import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { validateToken } from '../../actions/auth';
import { sendAlert } from '../../actions/utils';
import { LinkBtn } from '../../components';
import { showUserRequest, updateUserRequest } from '../../requests/user';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import UserForm from './UserForm';

const basicUser = {
  id: '',
  active: true,
  avatarInfo: {},
  branchOfficeIds: [],
  branchOffices: [],
  email: '',
  lastName: '',
  name: '',
  roleIds: [],
  roles: []
};

const UserEdit = props => {
  const [user, setUser] = useState(basicUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    match: { params }
  } = props;

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchUser = () => {
    showUserRequest(params.id, {
      dispatch,
      successCallback: response => setUser(camelCaseEmptyStringRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchUser, [params]);

  const handleSuccessUpdate = () => {
    dispatch(validateToken());
    dispatch(sendAlert({ kind: 'success', message: 'Usuario actualizado con éxito' }));
    history.push('/users');
  };

  const handleUpdateRequest = values => {
    const { avatar } = values.user;
    const sendParams = snakeCaseKeys(values);
    sendParams.user.avatar = avatar;

    updateUserRequest(params.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col md={{ span: 8, offset: 1 }}>
          <h2 className="text-uppercase">Editar Usuario</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="light-darker" block to="/users" className="cancel">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <UserForm user={user} action="edit" submitVariant="success" formRequest={handleUpdateRequest} />
        </Col>
      </Row>
    </>
  );
};

export default UserEdit;
