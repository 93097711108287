import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Col, Row } from 'react-bootstrap';
import { sendAlert } from '../../actions/utils';
import { validateToken } from '../../actions/auth';
import { showCompanyRequest, updateCompanyRequest } from '../../requests/companies';
import CompanyForm from './CompanyForm';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicCompany from './basicCompany';
import { createGoogleAuthRequest } from '../../requests/googleAuth';
import GoBackButton from "../../components/GoBackButton";

const CompanyEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [company, setCompany] = useState(basicCompany);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(validateToken());
    dispatch(sendAlert({ kind: 'success', message: 'Empresa actualizada con éxito' }));
    history.push('/company_profile');
  };

  const handleUpdateRequest = values => {
    const { logo, maintenanceFlyer, technicalReviewFlyer } = values.company;
    const sendParams = snakeCaseKeys(values);
    sendParams.company.logo = logo;
    sendParams.company.maintenance_flyer = maintenanceFlyer;
    sendParams.company.technical_review_flyer = technicalReviewFlyer;

    updateCompanyRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchCompany = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  const createGoogleAuth = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
      createGoogleAuthRequest({
        dispatch,
        params: { code: params.get('code') },
        successCallback: response => {
          dispatch(sendAlert({ kind: 'success', message: response?.data?.message }));
          fetchCompany();
          history.push('/company_profile');
        },
        failureCallback: handleFailureRequest
      });
    }
  };

  useEffect(fetchCompany, []);
  useEffect(createGoogleAuth, []);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col md={{ span: 8, offset: 1 }}>
          <h1>Perfil Empresa</h1>
        </Col>
        <Col md={2}>
          <GoBackButton />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <CompanyForm company={company} setCompany={setCompany} formRequest={handleUpdateRequest} />
        </Col>
      </Row>
    </>
  );
};

export default CompanyEdit;
