import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Field, FieldArray, getIn, useFormikContext } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import {useCreateClient} from '../../../hooks/usePublicClients';
import { sendAlert, getClientType, saveCurrentClient } from '../../../actions/utils';
import { FormikSelect, InputRemoteSelect } from '../../../components/Utils/Select';
import { capitalizeFirstLetter } from "../../../services/utils";
import ClientForm from "../../Client/ClientForm";
import {debouncePublicIndexClientsRequest} from "../../../requests/publicClients";
import {useFindCompany} from "../../../hooks/usePublicCompanies";

const clientForm = {
  active: true,
  address: '',
  addressNumber: '',
  businessActivity: '',
  cellphone: '',
  communeId: '',
  contactsAttributes: [],
  email: '',
  firstLastName: '',
  isCompany: false,
  legalName: '',
  name: '',
  nationalIdentification: '',
  notes: '',
  phone: '',
  regionId: '',
  secondLastName: '',
  sellerId: ''
};

const ClientTab = props => {
  const {
    abbr,
    clientDisabled,
    createClient,
    companyRequiredFieldAttributes,
    formClassName,
    formSize,
    handleClientChange,
    modelKey,
    newClient,
    newVehicle,
    resetVehicle,
    setNewClient,
    setVehicleDisabled
  } = props;
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const [contactableOptions, setContactableOptions] = useState([]);
  const currentValues = getIn(values, modelKey);
  const {projectContactsAttributes} = currentValues;
  const [clients, setClients] = useState([]);
  const dispatch = useDispatch();
  const { currentClient } = useSelector(state => state.utils);
  const placeholderText = 'Seleccionar Cliente';
  const { companyCode } = useParams();
  const { company } = useFindCompany(companyCode);
  const { create: createNewClient } = useCreateClient(companyCode);
  const handleContactableOptions = () => {
    if (currentClient?.contactsAttributes?.length > 0) {
      let options = [{
        id: currentClient.id,
        label: currentClient.projectContactableLabel,
        value: currentClient.id,
        contactableType: 'Client'
      }];
      currentClient.contactsAttributes.forEach(contact => {
        options = [...options, {
          id: contact.id,
          label: contact.projectContactableLabel,
          value: contact.id,
          contactableType: 'Contact'
        }];
      });
      setContactableOptions(options);
    } else {
      setContactableOptions([]);
    }
  }
  useEffect(handleContactableOptions, [currentClient]);

  const handleSuccessRequest = response => {
    resetVehicle();
    dispatch(sendAlert({ kind: 'success', message: 'Cliente creado con éxito' }));
    const data = camelCaseRecursive(response);
    setClients([{ ...data, label: data.fullName, value: data.id }]);
    setVehicleDisabled(false);
    dispatch(saveCurrentClient(data));
    handleClientChange(data ? data.id : '');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateClientRequest = params => {
    createNewClient(params, { onSuccess: handleSuccessRequest, onError: handleFailureRequest });
  };

  const btnCreateClient = type => {
    dispatch(getClientType(type));
    setNewClient(true);
    setVehicleDisabled(true);
    resetVehicle();
  };

  const btnOnHide = () => setNewClient(false);

  const resultFetchData = response => {
    const result = [...response.data];
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.full_name,
        value: element.id
      };
    });
    setClients(camelCaseRecursive(tempArray));
    return tempArray;
  };

  const inputRequestClients = (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      return;
    }
    debouncePublicIndexClientsRequest({
      dispatch,
      companyCode,
      params: {
        filtered: inputValue,
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  }

  const selectedContactable = (index, data) => {
    setFieldValue(`${modelKey}[projectContactsAttributes][${index}][contactableType]`, capitalizeFirstLetter(data.contactableType));
  };

  return (
    <>
      {createClient ? (
        <ClientForm
          clientTab
          modelKey={modelKey}
          client={clientForm}
          companyRequiredFieldAttributes={companyRequiredFieldAttributes}
          action="new"
          formSize={formSize}
          formClassName={formClassName}
          formRequest={handleCreateClientRequest}
          onHide={btnOnHide}
          country={company.country}
          isPublic
        />
      ) : (
        !newClient && (
          <Row>
            <Col md={12}>
              {!newVehicle && (
                <Col md={{ span: 4, offset: 8 }} className="modal-new-form-btn text-right">
                  <Button className="btn mb-n4" onClick={() => btnCreateClient('client1')}>
                    Crear cliente
                  </Button>
                </Col>
              )}
              <Field name={`${modelKey}[clientId]`}>
                {({ field }) => (
                  <InputRemoteSelect
                    {...field}
                    abbr={abbr}
                    isClearable
                    label="Cliente"
                    placeholder={placeholderText}
                    isDisabled={clientDisabled}
                    options={clients}
                    value={clients.find(client => client.id === currentValues.clientId)}
                    onChange={data => {
                      resetVehicle();
                      setVehicleDisabled(false);
                      dispatch(saveCurrentClient(camelCaseRecursive(data)));
                      handleClientChange(data ? data.id : '');
                    }}
                    request={inputRequestClients}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            {currentClient && Object.keys(currentClient).length !== 0 && (
              <>
                <Col md={12}>
                  <p>{currentClient.nationalIdentification}</p>
                </Col>
                <Col md={12}>
                  <p>{currentClient.cellphone}</p>
                </Col>
                <Col md={12}>
                  <p>{currentClient.email}</p>
                </Col>
                <Col md={12}>
                  <p>{currentClient.address}</p>
                </Col>
              </>
            )}
            {contactableOptions.length > 0 && (
                <>
                  <Col md={12}>
                    <h4>Notificar a:</h4>
                  </Col>
                  <Col md={12}>
                    <FieldArray name={`${modelKey}[projectContactsAttributes]`}>
                      {({ push }) => (
                        <>
                          {projectContactsAttributes?.map((contact, index) => {
                            return !contact._destroy && (
                              <Row key={`contact${index}`}>
                                <Col xs={10}>
                                  <Field name={`${modelKey}[projectContactsAttributes][${index}][contactableId]`}>
                                    {({ field }) => (
                                      <FormikSelect
                                        {...field}
                                        abbr
                                        placeholder="Seleccione contacto"
                                        options={contactableOptions}
                                        defaultValue={projectContactsAttributes[index].value}
                                        onChange={(data) => {
                                          selectedContactable(index, data);
                                          setFieldValue(field.name, data ? data.value : '');
                                        }}
                                        error={getIn(errors, field.name)}
                                        touched={getIn(touched, field.name)}
                                      />
                                    )}
                                  </Field>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-end">
                                  <Button
                                    variant="link"
                                    onClick={() => {
                                      setFieldValue(`${modelKey}[projectContactsAttributes][${index}][_destroy]`, true);
                                    }}
                                  >
                                    Eliminar
                                  </Button>
                                </Col>
                              </Row>
                            );
                          })}
                          <Button onClick={() => push({ contactableId: '', contactableType: '' })}>
                            Agregar notificado
                          </Button>
                        </>
                      )}
                    </FieldArray>
                  </Col>
                </>
              )
            }
          </Row>
        )
      )}
    </>
  );
};

export default ClientTab;
