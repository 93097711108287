import React from 'react';
import { Modal, ModalTitle, ModalBody, ModalFooter, Button } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { useCreateColumn, useDeleteKanbanColumn, useUpdateKanbanColumn } from '../../service';

export const DeleteColumnModal = ({ showModal, closeModal, selectedColumnId, setShowModal }) => {
  const { mutate: deleteColumn } = useDeleteKanbanColumn();

  const onSubmit = async () => {
    try {
      await deleteColumn(selectedColumnId);
      setShowModal(false);
    } catch (error) {
      console.error('Error al eliminar la columna:', error);
    }
  };
  return (
    <Modal show={showModal} onHide={closeModal}>
      <ModalHeader closeButton className="p-2">
        <ModalTitle style={{ fontSize: '14px', color: '#6390e5', fontWeight: '400' }}>Eliminar columna</ModalTitle>
      </ModalHeader>
      <ModalBody style={{ height: '100px' }}>
        <h1 className="my-2" style={{ fontWeight: '500', fontSize: '12px' }}>
          ¿Estas seguro de eliminar columna?
        </h1>
        <br />
        <h1
          className="mt-1 py-1 px-3"
          style={{
            fontWeight: '500',
            fontSize: '12px',
            backgroundColor: '#fff3cd',
            color: '#94761d',
            borderRadius: '5px'
          }}
        >
          Los proyectos se moverán a la primera columna
        </h1>
      </ModalBody>
      <ModalFooter>
        <Button
          className="py-1 px-3 mx-3"
          onClick={closeModal}
          style={{ backgroundColor: '#868383', borderRadius: '5px' }}
        >
          Cancelar
        </Button>
        <Button
          className="py-1 px-3 mx-3"
          type="submit"
          style={{ backgroundColor: '#dc3545', borderRadius: '5px' }}
          onClick={onSubmit}
        >
          Eliminar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ChangeColumnNameModal = ({ showModal, closeModal, selectedColumnId, setShowModal }) => {
  const { mutate: changeName } = useUpdateKanbanColumn();

  const initialValues = {
    columnName: ''
  };

  const validationSchema = Yup.object().shape({
    columnName: Yup.string().required('El nombre de la columna es requerido')
  });

  const onSubmit = async values => {
    const { columnName } = values;
    setShowModal(false);
    try {
      await changeName({ id: selectedColumnId, name: columnName });
    } catch (error) {
      console.error('Error al editar el nombre de la columna:', error);
    }
  };
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting, handleBlur }) => (
          <Form>
            <ModalHeader closeButton className="p-2">
              <ModalTitle style={{ fontSize: '14px', color: '#6390e5', fontWeight: '400' }}>Editar nombre</ModalTitle>
            </ModalHeader>
            <ModalBody className="mx-3 px-3" style={{ height: '150px' }}>
              <ModalTitle className="my-1 mt-0 ">Nombre</ModalTitle>
              <Field
                type="text"
                name="columnName"
                placeholder="Nombre de la columna"
                className={`form-control ${errors.columnName && touched.columnName ? 'is-invalid' : ''}`}
                onBlur={handleBlur}
                style={{
                  fontSize: '12px',
                  borderRadius: '5px',
                  width: '400px',
                  borderColor: '#dcdcdc',
                  padding: '3px'
                }}
              />
              {errors.columnName && touched.columnName ? (
                <div className="invalid-feedback">{errors.columnName}</div>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button
                className="py-1 px-3 mx-3"
                onClick={closeModal}
                style={{ backgroundColor: '#868383', borderRadius: '5px' }}
              >
                Cancelar
              </Button>
              <Button
                className="py-1 px-3 mx-3"
                type="submit"
                disabled={isSubmitting}
                style={{ backgroundColor: '#0340b7', borderRadius: '5px' }}
              >
                Editar
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const CreateColumnModal = ({ showModal, closeModal, setShowModal, selectedSectionId }) => {
  const { mutate, error: errorNewColumn } = useCreateColumn();
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (showModal) {
      inputRef.current.focus();
    }
  }, [showModal]);

  const validationSchema = Yup.object({
    name: Yup.string().required('El título de la sección es requerido')
  });

  const initialValues = {
    name: ''
  };
  
  const onSubmit = async values => {
    await new Promise(r => setTimeout(r, 500));
    const { name } = values;
    const can_delete = true;
    try {
      await mutate({ can_delete, selectedSectionId, name });
      setShowModal(false);
    } catch (error) {
      console.error('Failed to create column:', error);
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, handleBlur, errors }) => (
          <Form>
            <ModalHeader closeButton className="p-2">
              <ModalTitle style={{ fontSize: '14px', color: '#6390e5', fontWeight: '400' }}>
                Crear nueva columna
              </ModalTitle>
            </ModalHeader>
            <ModalBody className="mx-3 px-3" style={{ height: '120px' }}>
              <ModalTitle className="my-1 mt-0 ">Nombre</ModalTitle>
              <Field
                style={{
                  fontSize: '12px',
                  borderRadius: '5px',
                  width: '400px',
                  borderColor: '#dcdcdc'
                }}
                className="p-1"
                type="text"
                name="name"
                placeholder="Ingrese nombre"
                onBlur={handleBlur}
                innerRef={inputRef}
              />
              {errors.name && <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name="name" component="div" />}
            </ModalBody>
            <ModalFooter style={{ marginTop: '40px' }} className="p-1">
              {errorNewColumn && <div style={{ color: 'red', fontSize: '12px' }}>{errorNewColumn.message}</div>}
              <Button
                className="py-1 px-3 mx-3"
                onClick={closeModal}
                style={{ backgroundColor: '#868383', borderRadius: '5px' }}
              >
                Cancelar
              </Button>
              <Button
                className="py-1 px-3 mx-3"
                type="submit"
                disabled={isSubmitting}
                style={{ backgroundColor: '#0340b7', borderRadius: '5px' }}
              >
                Crear
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
