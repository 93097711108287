import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

const GoBackButton = ({className}) => {
  const history = useHistory();
  return (
    <Button
      block
      className={`cancel ${className}`}
      variant="light-darker"
      onClick={() => history.goBack()}
    >
      Volver
    </Button>
  )
}
export default GoBackButton