import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import { Button, Col, Dropdown, Row, Table } from 'react-bootstrap';
import {Download, Trash} from 'react-feather';
import snakeCaseKeys from 'snakecase-keys';
import {DefaultModal, SimpleCenteredModal} from '../../../components';
import AddProjectInvoice from './AddProjectInvoice';
import AssociateProjectInvoice from './AssociateProjectInvoice';
import EditProjectInvoiceForm from './EditProjectInvoiceForm';
import {sendAlert} from '../../../actions/utils';
import {
  deleteProjectInvoiceRequest,
  updateProjectInvoiceRequest,
  getDocumentRequest
} from '../../../requests/projectInvoices';
import downloadFile from '../../../services/utils';
import CreditNoteToDelete from "./CreditNoteToDelete";
import ConfirmRequest from "./ConfirmRequest";

const ProjectInvoicesIndex = ({project, setMoreData, version}) => {
  const {projectInvoicesAttributes} = project;
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
    setDefaultModalShow(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({kind: 'success', message: 'Factura actualizada con éxito'}));
    setMoreData(m => !m);
    handleModalClose();
  };

  const handleSuccessDelete = response => {
    const {id, invoice_number: invoiceNumber, project_code: projectCode, json_response: jsonResponse} = response.data;
    setMoreData(m => !m);
    handleModalClose();
    if (invoiceNumber) {
      dispatch(
        sendAlert({
          kind: 'success',
          message: 'Documento eliminado con éxito'
        })
      );
      handleDownloadDocument(projectCode, id, 'pdf');
    } else {
      const errorMessages = {
        'does not have numbers available, check sii caf': 'No hay números de folio disponibles, por favor contacte a soporte.',
        'The sii caf used is expired, you must request and upload a new one': 'El SII CAF utilizado está vencido, debe solicitar y subir uno nuevo.',
        'This document type has the following client attributes required: city,code,municipality/district,address': 'El cliente seleccionado no tiene registrado RUT, ciudad, comuna o dirección.',
      }
      dispatch(
        sendAlert({
          kind: 'error',
          message: errorMessages[jsonResponse.error] || jsonResponse.error
        })
      );
    }
  }

  const handleConfirmRequest = values => {
    setModalShow(false);
    setModalBody(
      <ConfirmRequest confirm={() => handleDeleteRequest(values)} cancel={handleModalClose}/>
    );
    setModalShow(true);
  };

  const handleDeleteRequest = (params = {}) => {
    const idToDelete = params.id || modalItem.id;
    deleteProjectInvoiceRequest(project.id, idToDelete, {
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: handleSuccessDelete,
      failureCallback: handleFailureRequest
    });
  };

  const handleEditRequest = params => {
    const {
      projectInvoice: {id: invoiceId}
    } = params;
    updateProjectInvoiceRequest(project.id, invoiceId, {
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const handleDownloadDocument = (projectId, invoiceId, format) => {
    getDocumentRequest(projectId, invoiceId, {
      format,
      dispatch,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'downloadPdf':
        handleDownloadDocument(item.projectCode, item.id, 'pdf');
        break;
      case 'downloadXml':
        handleDownloadDocument(item.projectCode, item.id, 'xml');
        break;
      case 'edit':
        setModalShow(true);
        setModalTitle('EDITAR ESTADO FACTURA');
        setModalItem(item);
        setModalBody(
          <EditProjectInvoiceForm
            action="edit"
            submitVariant="success"
            projectInvoice={item}
            formRequest={handleEditRequest}
            onHide={handleModalClose}
          />
        );
        break;
      case 'destroy':
        if (item.canDelete) {
          setDefaultModalShow(true);
          setModalBody('¿Estás seguro que deseas eliminar este abono?');
        } else {
          setModalTitle('EMITIR NOTA DE CRÉDITO');
          setModalShow(true);
          setModalBody(
            <CreditNoteToDelete
              projectInvoiceToDelete={{id: item.id, motive: '', projectCode: item.projectCode, issueDate: new Date()}}
              formRequest={handleConfirmRequest}
              handleModalClose={handleModalClose}
              dispatch={dispatch}
            />
          );
        }
        setModalItem(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const mapProjectInvoices = projectInvoicesAttributes.map(projectInvoice => {
    const {
      id,
      clientFullName,
      invoiceDate,
      parsedAmount,
      translatedState,
      canDelete,
      parsedTotal,
      state,
      shortName,
      parsedBalance,
      type,
      paymentsTypes
    } = projectInvoice;
    return (
      <tr key={`project-payment-${id.toString()}`}>
        <td>{shortName}</td>
        <td>{clientFullName}</td>
        <td>{invoiceDate}</td>
        <td>{parsedAmount}</td>
        <td>{parsedTotal}</td>
        <td>{parsedBalance}</td>
        <td>{paymentsTypes}</td>
        <td>{translatedState}</td>
        <td className="text-center d-flex align-items-center justify-content-center">
          <>
            {
              canDelete ? (
                <Trash
                  width="18px"
                  height="18px"
                  className="clickable delete-item text-danger"
                  onClick={() => handleButtonClick(projectInvoice, 'destroy')}
                />
              ) : (
                <>
                  <Dropdown>
                    <Dropdown.Toggle className="btn-circle mr-2" variant="outline-success">
                      <Download size={16}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleButtonClick(projectInvoice, 'downloadPdf')}>
                        Descargar PDF
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleButtonClick(projectInvoice, 'downloadXml')}>
                        Descargar XML
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {type !== 'CreditNoteDocument' && state !== 'deleted' && (
                    canDelete ? (
                      <Trash
                        width="18px"
                        height="18px"
                        className="clickable delete-item text-danger"
                        onClick={() => handleButtonClick(projectInvoice, 'destroy')}
                      />
                    ) : (
                      <Button className="p-0" variant="link" onClick={() => handleButtonClick(projectInvoice, 'destroy')}>
                        NC
                      </Button>
                    )
                  )}
                </>
              )
            }
          </>
        </td>
      </tr>
    );
  });

  return (
    <>
      <Row className="mb-3">
        <Col className="section-header d-flex justify-content-between">
          <strong>DOCUMENTOS</strong>
          <Col md={6} className="justify-content-end d-flex">
            <AssociateProjectInvoice project={project} version={version} setMoreData={setMoreData}/>
            {project?.company.billingConfigured && <AddProjectInvoice project={project} version={version} setMoreData={setMoreData}/>}
          </Col>
        </Col>
      </Row>
      <Table striped bordered hover size="sm" responsive>
        <thead>
        <tr className="text-uppercase fit-content">
          <th>Doc.</th>
          <th>Cliente</th>
          <th>Fecha Ingreso</th>
          <th>Neto</th>
          <th>Total</th>
          <th>Saldo</th>
          <th>Tipo de abono</th>
          <th>Estado</th>
          <th style={{width: '100px'}}/>
        </tr>
        </thead>
        <tbody>{mapProjectInvoices}</tbody>
      </Table>
      {defaultModalShow && (
        <DefaultModal
          title="Eliminar abono"
          body={modalBody}
          show={defaultModalShow}
          handleClose={handleModalClose}
          handleConfirm={() => handleDeleteRequest()}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </>
  );
};

export default ProjectInvoicesIndex;
