import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sendAlert } from '../../actions/utils';
import { DefaultModal } from '../../components';
import ComponentDataTable from '../../components/Utils/DataTable';
import { indexPurchaseManagementsRequest, deletePurchaseManagementRequest } from '../../requests/purchaseManagements';

const PurchaseManagementDataTable = ({ columns, currentBranchOfficeId, fromAdmin, setQueries }) => {
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [purchaseManagement, setPurchaseManagement] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const { data, metadata } = response.data;
    setPurchaseManagement(data);
    setAmount(metadata.amount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = { ...params, sort_column: params.sort_column || 'id' };
    if (!fromAdmin) sendParams.branch_office_id = currentBranchOfficeId;
    indexPurchaseManagementsRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Listado de compra eliminado con éxito' }));
    setMoreData(!moreData);
    setModalShow(false);
  };

  const handleDeleteRequest = () => {
    deletePurchaseManagementRequest(modalItem.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleFailureRequest
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/purchase_managements/${item.id}`);
        break;
      case 'edit':
        history.push(`/purchase_managements/${item.id}/edit`);
        break;
      case 'destroy':
        setModalBody('¿Estás seguro que deseas eliminar este listado de compra?');
        setModalItem(item);
        setModalShow(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ComponentDataTable
        withDate
        onRequest={onRequest}
        columns={columns(handleActions, fromAdmin)}
        data={purchaseManagement}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (setQueries) setQueries(response);
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <DefaultModal
        title="Eliminar listado de compra"
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={handleDeleteRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default PurchaseManagementDataTable;
