import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

import './styles.scss'; // Importa los estilos

const Accordion = ({ title: Title, content, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  React.useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={toggleAccordion}>
        <div className="accordion-title">{Title({ isOpen })} </div>
        <div className="accordion-icon">{isOpen ? <ChevronUp /> : <ChevronDown />}</div>
      </div>
      {isOpen && <div className="accordion-content">{content(setIsOpen)}</div>}
    </div>
  );
};

export default Accordion;
