import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { withFormik, Form, getIn } from 'formik';
import { parse } from 'date-fns';
import moment from 'moment';
import * as Yup from 'yup';
import CalendarEventTab from '../../Calendar/Form/CalendarEventTab';

const CalendarEventForm = props => {
  const { calendarEvent, errors, handleClose, onHide, setFieldValue, submitVariant, touched, values } = props;
  const currentValues = getIn(values, 'calendarEvent');
  const btnMessage = 'Guardar';
  return (
    <>
      <Form>
        <Row className="change-modal-order mt-3">
          <Col>
            <CalendarEventTab
              disabledDate={false}
              calendarEvent={calendarEvent}
              setFieldValue={setFieldValue}
              currentValues={currentValues}
              edit={false}
              errors={errors}
              touched={touched}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col md={6}>
                <Button className="btn cancel" variant="primary" block onClick={handleClose}>
                  Cancelar
                </Button>
              </Col>
              <Col md={6}>
                <Button type="submit" variant={submitVariant} block onClick={onHide}>
                  {btnMessage}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = ({ calendarEvent }) => {
  const dateSelected = moment(calendarEvent.startDate).format('DD/MM/YYYY');
  const startDate = moment(calendarEvent.startDate).format('YYYY-MM-DDTHH:mm');
  const endDate = moment(calendarEvent.startDate)
    .add(100, 'minutes')
    .format('YYYY-MM-DDTHH:mm');
  return { calendarEvent: { orderType: 'reception', dateSelected, startDate, endDate } };
};

const validationSchema = Yup.object().shape({
  calendarEvent: Yup.object().shape({
    promiseDate: Yup.date().when('endDate', (endDate, schema) => {
      const formatEnd = moment(endDate).format('L');
      const minEnd = parse(formatEnd, 'dd/MM/yyyy', new Date());
      return endDate && schema.min(minEnd, 'Debe ser mayor o igual a la fecha de inicio');
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: true
})(CalendarEventForm);
