import AwesomeDebouncePromise from 'awesome-debounce-promise';
import MarketplaceService from '../services/marketplaceService';
import ApiService from '../services/apiService';

export const autopartsCategories = ({ dispatch, parent = null, failureCallback, successCallback }) => {
  // MarketplaceService.request('get', `/categories${parent ? '/' + parent : ''}`, {
  //   dispatch,
  //   failureCallback,
  //   successCallback
  // });
  ApiService.requestV2('get', `/autopart_categories`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const amCategoryId = (categoryId, { dispatch, failureCallback, successCallback }) => {
  ApiService.requestV2('get', `/autopart_categories/${categoryId}`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const amVehicleParams = (plate, { dispatch, failureCallback, successCallback }) => {
  ApiService.request('get', `/am_params/${plate}`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const automarcoProducts = ({ dispatch, params, failureCallback, successCallback }) => {
  MarketplaceService.requestAutomarco('post', `/products`, {
    params,
    dispatch,
    failureCallback,
    successCallback
  });
};

export const automarcoProductsList = ({ dispatch, categories, plate = null, failureCallback, successCallback, matchCallback, noMatchCallback, noProductsCallback }) => {
  if (plate) {
    amVehicleParams(plate, { dispatch, failureCallback, successCallback: response => {
      const data = response.data;
      if (data.no_match) {
        noMatchCallback();
      } else {
        matchCallback();
        categories.forEach(cat => {
          amCategoryId(cat, { dispatch, failureCallback, successCallback: category => {
            if (category.data.am_id) {
              automarcoProducts({ dispatch, params: { ...data, cla_id: category.data.am_id }, failureCallback, successCallback: products => {
                if (products.data.length) {
                  const prods = products.data.map(prod => {
                    return { name: prod.prod_nombre, 
                      code: prod.id_prov, 
                      brand: prod.marca_producto, 
                      origin: prod.origen, 
                      units: prod.unidades_caja, 
                      position: prod.ubi_nombre,
                      price: Math.round(prod.prod_precio * 1.2),
                      img: prod.producto_imagen && prod.producto_imagen !== "" ? prod.producto_imagen : null,
                      prod_id: prod.prod_id, 
                      __price: Math.round(prod.prod_precio * 1.2),
                      deliveryTime: prod.empresa === 'GABTEC' ? 'AM/PM' : '48 hrs'
                    };
                  });
                  successCallback(prods, cat);
                } else {
                  noProductsCallback(cat);
                }                
              }});
            } else {
              noProductsCallback(cat);
            }
          }});
        });
      }
    }});
  } else {
    categories.forEach(cat => {
      amCategoryId(cat, { dispatch, failureCallback, successCallback: response => {
        if (response.data.am_id) {
          automarcoProducts({ dispatch, params: { cla_id: response.data.am_id }, failureCallback, successCallback });
        } else {
          noProductsCallback(response.data.name);
        }
      }});
    });
  }
};

export const createAutopartOrder = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.requestV2('post', `/autopart_orders`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexAutopartsRequest = AwesomeDebouncePromise(autopartsCategories, 300);
