import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-feather';

import { sendAlert } from '../../actions/utils';
import { showProjectRequest } from '../../requests/projects';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicProject from './Show/basicProject';
import ProjectImages from './ProjectImages';
import ProjectLinkButtons from './ProjectLinkButtons';
import ProjectNavbarInfo from './ProjectNavbarInfo';
import ProjectProgressBars from './ProjectProgressBars';
import ProjectAutopartsList from './ProjectAutopartsList';
import ProjectVehicle from './ProjectVehicle';
import ProjectWorkEdit from '../ProjectWork/ProjectWorkEdit';
import getImage from '../../utils/getImage';
import ProjectComments from './ProjectComments';
import ProjectTitle from './ProjectTitle';
import {Can} from "../../config/abilityContext";

const ProjectEdit = ({ match }) => {
  const [moreData, setMoreData] = useState(false);
  const [project, setProject] = useState(basicProject);
  const [notesExpanded, setNotesExpanded] = useState(false);
  const [vehicleExpanded, setVehicleExpanded] = useState(false);
  const [progressExpanded, setProgressExpanded] = useState(false);
  const [autopartsExpanded, setAutopartsExpanded] = useState(false);
  const [imagesExpanded, setImagesExpanded] = useState(false);
  const { deskSell: isDeskSale, isQuote, orderType } = project;
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { id } = match.params;

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessShow = response => {
    setProject(camelCaseEmptyStringRecursive(response.data));
  };

  const fetchProject = () => {
    showProjectRequest(id, {
      dispatch,
      params: { screen: 'ProjectEdit' },
      successCallback: handleSuccessShow,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchProject, [moreData]);

  const images = projectReviewType => {
    return project.projectReviewsAttributes
      .filter(projectReview => projectReview.reviewType === projectReviewType)
      .map(projectReview => {
        return projectReview.imagesInfo.map(image => {
          return {
            original: image.fileUrl,
            thumbnail: getImage(image.key, 100, 100, 'inside')
          };
        });
      })
      .flat();
  };

  const toggleVehicleExpanded = () => {
    setVehicleExpanded(!vehicleExpanded);
  }

  const toggleProgressExpanded = () => {
    setProgressExpanded(!progressExpanded);
  }

  const toggleAutopartsExpanded = () => {
    setAutopartsExpanded(!autopartsExpanded);
  }

  return (
    <Can I="manage" a="Project">
      <Container>
        <ProjectTitle isQuote={isQuote} orderType={orderType} id={id}/>
        <ProjectLinkButtons id={id} isDeskSale={isDeskSale} isQuote={isQuote} clientNumber={project.client.cellphone}/>
        <ProjectNavbarInfo fromProjectEdit project={project} setMoreData={setMoreData} isQuote={isQuote}/>
        <Row className="mb-3">
          <Col className="project-section mr-2 pt-3 pb-0" style={{ height: !vehicleExpanded ? 'fit-content' : '' }}>
            <ProjectVehicle fromProjectEdit project={project} toggleVehicleExpanded={toggleVehicleExpanded} collapsible/>
          </Col>
          <Col className="project-section ml-2 pt-3 pb-0" style={{ height: !notesExpanded ? 'fit-content' : '' }}>
            <Row>
              <Col md={12}>
                <strong>Motivo / Notas</strong>
              </Col>
              { notesExpanded &&
              <>
                <Col md={12} className="pt-2">
                  <pre>{project.notes}</pre>
                </Col>
                <Col>{id && <ProjectComments projectCode={id}/>}</Col>
              </>
              }
            </Row>
            <Row>
              <button className='w-100 btn btn-light' onClick={() => setNotesExpanded(!notesExpanded)}>
                { notesExpanded ? <ChevronUp size={15}/> : <ChevronDown size={15}/>}
              </button>
            </Row>
          </Col>
        </Row>
        {!isDeskSale && !isQuote && (
          <Row className="mb-3 gx-5">
            <Col md={4} className="project-section mr-2 pt-3 pb-0" style={{ height: !progressExpanded ? 'fit-content' : '' }}>
              <ProjectProgressBars fromProjectEdit project={project} id={id} setMoreData={setMoreData} toggleProgressExpanded={toggleProgressExpanded}/>
            </Col>
            {user.companyCountry === 'cl' && <Col md={5} className="project-section mx-2 pt-3 pb-0" style={{ height: !autopartsExpanded ? 'fit-content' : '' }}>
              <ProjectAutopartsList project={project} id={id} setMoreData={setMoreData} toggleAutopartsExpanded={toggleAutopartsExpanded}/>
            </Col>}
            <Col className="project-section ml-2 pt-3 pb-0" style={{ height: !imagesExpanded ? 'fit-content' : '' }}>
              <Row>
                <Col md={12}>
                  <strong>Imágenes</strong>
                </Col>
                { imagesExpanded &&
                <>
                  {orderType === 'reception_secure' && (
                    <Col md={12}>
                      <ProjectImages edit images={images('pre_inspection')} title="Checklist de Pre Inspección"/>
                    </Col>
                  )}
                  <Col md={12}>
                    <ProjectImages edit images={images('reception')} title="Checklist recepción"/>
                  </Col>
                  <Col md={12} className="mb-2">
                    <ProjectImages edit images={images('mechanic')} title="Checklist mecánico"/>
                  </Col>
                </>
                }
              </Row>
              <Row>
                <button className='w-100 btn btn-light' onClick={() => setImagesExpanded(!imagesExpanded)}>
                  { imagesExpanded ? <ChevronUp size={15}/> : <ChevronDown size={15}/>}
                </button>
              </Row>
            </Col>
          </Row>
        )}
        <Row className="mt-2 bg-light pr-4 pl-4 pt-2">
          <Col>
            <ProjectWorkEdit project={project} setMoreData={setMoreData}/>
          </Col>
        </Row>
      </Container>
    </Can>
  );
};

export default ProjectEdit;
