import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {withFormik, Field, Form, getIn, FieldArray, useFormikContext} from 'formik';
import {Button, Row, Col} from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import {Dropzone, FormikDatePicker, FormikSelect} from "../../components";
import {createDropZoneFileRequest, deleteDropZoneFileRequest} from "../../requests/dropzone";
import {capitalizeFirstLetter} from "../../services/utils";
import {indexNotifiablesRequest} from "../../requests/projectDetails";
import {sendAlert} from "../../actions/utils";

const PurchaseReceivedForm = ({ errors, handleModalClose, onHide, purchaseReceived: initialPurchaseReceived,
                                setFieldValue, submitVariant, touched, disabled, isSubmitting, projectId }) => {
  const dispatch = useDispatch();
  const {imagesInfo, purchaseReceivedDate} = initialPurchaseReceived;
  const [date, setDate] = useState(purchaseReceivedDate);
  const maxAvatarSize = 20971520;
  const [documentCode, setDocumentCode] = useState(Date.now());
  const [documentKeys, setDocumentKeys] = useState([]);
  const [imageCounter, setImageCounter] = useState(imagesInfo.length);
  const [uploadImage, setUploadImage] = useState(false);
  const [contactsList, setContactsList] = useState([]);
  const [userContacts, setUserContacts] = useState([]);
  const [purchaseReceived, setPurchaseReceived] = useState({
    ...initialPurchaseReceived,
    currentImages: [],
    persistedImages: imagesInfo
  });
  const { values } = useFormikContext();
  const currentValues = getIn(values, "projectDetail");
  const {projectContactsAttributes} = currentValues;

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
  };

  const handleSuccessRequest = (data) => {
    const users = data.data.filter(item => item.contactable_type === "User");
    const others = data.data.filter(item => item.contactable_type !== "User");
    setUserContacts(users.map(contact => ({
      ...contact,
      label: contact.project_contactable_label,
      value: contact.id,
      contactableType: contact.contactable_type
    })));
    setContactsList(others.map(contact => ({
      ...contact,
      label: contact.project_contactable_label,
      value: contact.id,
      contactableType: contact.contactable_type
    })));
  };

  const getProjectContactsAttributes = () => {
    indexNotifiablesRequest({
      dispatch,
      params: {project_id: projectId},
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleOnDrop = (code, documents) => {
    setUploadImage(true);
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'purchase_received_images',
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => {
        const {data} = response;
        const newDocumentKeys = documentKeys;
        data.map(element => newDocumentKeys.push(element.document_key));
        setDocumentKeys(newDocumentKeys);
        return response;
      }
    });
  };

  const handleUpdateImages = images => {
    setUploadImage(false);
    const {persistedImages} = purchaseReceived;
    const updatedState = {
      ...purchaseReceived,
      currentImages: [...images],
      persistedImages: [...persistedImages]
    };

    setFieldValue('projectDetail[purchaseReceivedImageKeys]', documentKeys);
    setImageCounter(persistedImages.length + images.length);
    setPurchaseReceived(updatedState);
    setDocumentCode(Date.now());
  };

  const handleOnDelete = image => {
    deleteDropZoneFileRequest(image.id, {
      dispatch,
      params: {dropZoneFile: {documentKey: image.code}},
      successCallback: () => {
        const newDocumentKeys = documentKeys.filter(element => element !== image.code);
        setDocumentKeys(newDocumentKeys);
        setImageCounter(imageCounter - 1);
      }
    });
  };

  const selectedContactable = (index, data) => {
    setFieldValue(`projectDetail[projectContactsAttributes][${index}][contactableType]`, capitalizeFirstLetter(data.contactableType));
  };

  useEffect(getProjectContactsAttributes, []);

  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="projectDetail[purchaseReceivedDate]">
            {({field}) => (
              <FormikDatePicker
                {...field}
                abbr
                label="Fecha de Recepción de Compra"
                dateFormat="dd/MM/yyyy"
                selected={date}
                disabled={disabled}
                onChange={newDate => {
                  setDate(newDate);
                  setFieldValue(field.name, newDate);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between mt-3">
        <Col md={6}>
          <Dropzone
            modalPreview
            id={documentCode}
            fileAccept="image/*"
            documentCounter={imageCounter}
            maxSize={maxAvatarSize} // 20MB
            btnText="Seleccionar imágenes"
            persistedFiles={purchaseReceived.persistedImages}
            files={purchaseReceived.currentImages}
            onDrop={handleOnDrop}
            onDropUploaded={images => handleUpdateImages(images)}
            onDelete={image => handleOnDelete(image)}
            name="projectDetail[purchaseReceivedImageKeys]"
            error={getIn(errors, 'projectDetail[purchaseReceivedImageKeys]')}
            touched={getIn(touched, 'projectDetail[purchaseReceivedImageKeys]')}
            disabled={disabled}
          />
        </Col>
        {(contactsList.length > 0 || userContacts.length > 0) && (
          <Col md={12} className="mt-2">
            <h3>Notificar a:</h3>
          </Col>
        )}
        {contactsList.length > 0 && (
          <>
            <Col md={12} className="mb-3">
              <h4>Clientes y contactos:</h4>
              <FieldArray name="projectDetail[projectContactsAttributes]">
                {({ push }) => (
                  <>
                    {projectContactsAttributes?.map((contact, index) => {
                      return !contact._destroy  && contact.contactableType !== "User" && (
                        <Row key={`contact${index}`}>
                          <Col xs={10}>
                            <Field name={`projectDetail[projectContactsAttributes][${index}][contactableId]`}>
                              {({ field }) => (
                                <FormikSelect
                                  {...field}
                                  abbr
                                  isDisabled={disabled}
                                  placeholder="Seleccione contacto"
                                  options={contactsList}
                                  defaultValue={projectContactsAttributes[index].value}
                                  onChange={(data) => {
                                    selectedContactable(index, data);
                                    setFieldValue(field.name, data ? data.value : '');
                                  }}
                                  error={getIn(errors, field.name)}
                                  touched
                                />
                              )}
                            </Field>
                          </Col>
                          <Col xs={2} className="d-flex justify-content-end">
                            {!disabled && <Button
                              variant="link"
                              onClick={() => {
                                setFieldValue(`projectDetail[projectContactsAttributes][${index}][_destroy]`, true);
                              }}
                            >
                              Eliminar
                            </Button>}
                          </Col>
                        </Row>
                      );
                    })}
                    {!disabled && <Button style={{width: '170px'}} onClick={() => push({ contactableId: '', contactableType: '' })}>
                      Agregar cliente o contacto
                    </Button>}
                  </>
                )}
              </FieldArray>
            </Col>
          </>
        )}
        {userContacts.length > 0 && (
          <>
            <Col md={12}>
              <h4>Usuarios del taller:</h4>
              <FieldArray name="projectDetail[projectContactsAttributes]">
                {({ push }) => (
                  <>
                    {projectContactsAttributes?.map((contact, index) => {
                      return !contact._destroy  && contact.contactableType === "User" && (
                        <Row key={`contact${index}`}>
                          <Col xs={10}>
                            <Field name={`projectDetail[projectContactsAttributes][${index}][contactableId]`}>
                              {({ field }) => (
                                <FormikSelect
                                  {...field}
                                  abbr
                                  isDisabled={disabled}
                                  placeholder="Seleccione contacto"
                                  options={userContacts}
                                  defaultValue={projectContactsAttributes[index].value}
                                  onChange={(data) => {
                                    selectedContactable(index, data);
                                    setFieldValue(field.name, data ? data.value : '');
                                  }}
                                  error={getIn(errors, field.name)}
                                  touched
                                />
                              )}
                            </Field>
                          </Col>
                          <Col xs={2} className="d-flex justify-content-end">
                            {!disabled && <Button
                              variant="link"
                              onClick={() => {
                                setFieldValue(`projectDetail[projectContactsAttributes][${index}][_destroy]`, true);
                              }}
                            >
                              Eliminar
                            </Button>}
                          </Col>
                        </Row>
                      );
                    })}
                    {!disabled && <Button style={{width: '170px'}} onClick={() => push({ contactableId: '', contactableType: 'User' })}>
                      Agregar usuario
                    </Button>}
                  </>
                )}
              </FieldArray>
            </Col>
          </>
        )}
        {!disabled && <Col md={12} className="my-2">
          <Row className="float-right">
            <Col md={6}>
              <Button style={{width: '100px'}} className="btn" variant="light-darker" block
                      onClick={handleModalClose}>
                Cancelar
              </Button>
            </Col>
            <Col md={6}>
              <Button type="submit" variant={submitVariant} block onClick={onHide} style={{width: '100px'}}
                      disabled={uploadImage || isSubmitting}>Guardar</Button>
            </Col>
          </Row>
        </Col>}
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {purchaseReceived} = props;
  return {projectDetail: purchaseReceived};
};

const validationSchema = Yup.object().shape({
  projectDetail: Yup.object().shape({
    purchaseReceivedDate: Yup.string().required('Debes seleccionar una Fecha de Recepción de Compra'),
    projectContactsAttributes: Yup.array().of(
      Yup.object().shape({
        contactableId: Yup.string().required('Debes seleccionar un contacto')
          .test('unique-by-contactable-type', 'Cada contacto debe ser único en su tipo', function (value) {
            const { projectContactsAttributes } = this.options.context.projectDetail;
            const { contactableType } = this.parent;
            const duplicates = projectContactsAttributes.filter(contact =>
              Number(contact.contactableId) === Number(value) && contact.contactableType === contactableType && !contact._destroy
            );
            return duplicates.length < 2;
          }),
        contactableType: Yup.string().required('Debes seleccionar un tipo de contacto')
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting, isSubmitting }) => {
  if (!isSubmitting) {
    setSubmitting(true);
    const {formRequest} = props;
    formRequest(values.projectDetail);
  }
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(PurchaseReceivedForm);
