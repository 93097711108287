import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useInvalidateQuery } from '../../../app/hooks/useQuery';
import api from '../../../app/services/api';
import API_CONFIG from '../../../app/config/configurations';

const { url } = API_CONFIG;

//= >-----------------Kanban Services-----------------<=//

const getSections = branch_office_id => {
  try {
    const data = api.get(`/branch_office_kanban/${branch_office_id}`, url('v2')).then(res => res.json());
    return data;
  } catch (error) {
    console.error('Error fetching sections:', error);
    throw error;
  }
};

const createColumn = async (can_delete, kanban_section_id, name) => {
  try {
    const response = await api.post(
      '/kanban_columns',
      {
        kanban_column: { can_delete, kanban_section_id, name }
      },
      url('v2')
    );

    return response.data;
  } catch (error) {
    console.error('Error creating column:', error);
    throw error;
  }
};

const updateKanbanColumn = async body => {
  try {
    const response = await api.put(`/kanban_columns/${body.id}`, body, url('v2'));
    return response.data;
  } catch (error) {
    console.error('Error editing column:', error);
    throw error;
  }
};

const updateKanbanColumnOrder = async body => {
  try {
    const response = await api.put(`/kanban_columns/${body.id}/order`, body, url('v2'));
    return response.data;
  } catch (error) {
    console.error('Error editing column:', error);
    throw error;
  }
};

const updateKanbanColumnPosition = async body => {
  try {
    const response = await api.put(`/kanban_columns/${body.columnId}/change_position`, body, url('v2'));
    return response.data;
  } catch (error) {
    console.error('Error editing column:', error);
    throw error;
  }
};

const updateKanbanChangeColumn = async body => {
  try {
    const response = await api.put(`/kanban_columns/change_column`, body, url('v2'));
    return response.data;
  } catch (error) {
    console.error('Error editing column:', error);
    throw error;
  }
};

const postProjects = query => {
  try {
    const response = api.post(`/projects/list?items=500`, query, url('v2')).then(res => res.json());
    return response;
  } catch (error) {
    console.error('Error posting project:', error);
    throw error;
  }
};
// Add delete services
const deleteKanbanColumn = async kanbanColumnId => {
  try {
    const response = await api.delete(`/kanban_columns/${kanbanColumnId}`, url('v2'));
    return response.data;
  } catch (error) {
    console.error('Error editing column:', error);
    throw error;
  }
};

//= >-----------------Hooks-----------------<=//

// Post to get all projects using ransack
export const usePostProjects = (query, columnId) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ['kanban_search_projects', columnId, query],
    queryFn: () => postProjects(query),
    enabled: Boolean(query.q.branch_office_id_eq)
  });

  return { data, error, isLoading };
};

// Get all kanban information (sectionn, columns, columns order)
export const useGetSections = branch_office_id => {
  const queryClient = useQueryClient();
  const sectionsCache = queryClient.getQueryData(['kanban_sections']);
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: ['kanban_sections'],
    queryFn: () => getSections(branch_office_id),
    enabled: Boolean(branch_office_id)
  });
  const sections = data?.sections;
  return { sections, isLoading, error, sectionsCache, isFetching };
};

// Create column
export const useCreateColumn = () => {
  const invalidateQueries = useInvalidateQuery();
  const reFetch = () => invalidateQueries(['kanban_sections']);

  const { mutate, isLoading, error } = useMutation({
    onSuccess: reFetch,
    mutationFn: a => {
      return createColumn(a.can_delete, a.selectedSectionId, a.name);
    }
  });

  return { mutate, isLoading, error };
};

export const useUpdateKanbanColumnOrder = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation({
    mutationFn: el => {
      return updateKanbanColumnOrder(el);
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['kanban_sections'], context.previousKanbanColumns);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries('kanban_sections');
    }
  });
  return { mutate, isLoading, error };
};

export const useUpdateKanbanColumnPosition = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation({
    mutationFn: el => {
      return updateKanbanColumnPosition(el);
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['kanban_sections'], context.previousKanbanColumns);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries('kanban_sections');
    }
  });
  return { mutate, isLoading, error };
};

export const useUpdateKanbanChangeColumn = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation({
    mutationFn: el => {
      return updateKanbanChangeColumn(el);
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['kanban_sections'], context.previousKanbanColumns);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries('kanban_sections');
    }
  });
  return { mutate, isLoading, error };
};

// Update kanban column (name, order)
export const useUpdateKanbanColumn = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation({
    onMutate: async newColumn => {
      await queryClient.cancelQueries('kanban_sections');
      const previousKanbanColumns = queryClient.getQueryData(['kanban_sections']);
      queryClient.setQueryData(['kanban_sections'], old => {
        const newOld = old;
        const sections = newOld?.sections?.map(sec => {
          const updatedSec = { ...sec };
          updatedSec.columns = updatedSec?.columns?.map(col => {
            if (col?.id === newColumn?.sectionId) {
              const updatedCol = { ...col };
              updatedCol.name = newColumn?.sectionTitle || col.name;
              updatedCol.order = newColumn?.order || col.order;
              return updatedCol;
            }
            return col;
          });
          return updatedSec;
        });
        return {
          ...old,
          sections
        };
      });
      return { previousKanbanColumns };
    },
    mutationFn: el => {
      return updateKanbanColumn(el);
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(['kanban_sections'], context.previousKanbanColumns);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries('kanban_sections');
    }
  });
  return { mutate, isLoading, error };
};

// Delete column
export const useDeleteKanbanColumn = () => {
  const invalidateQueries = useInvalidateQuery();
  const reFetch = () => invalidateQueries(['kanban_sections']);

  const { mutate, isLoading, error } = useMutation({
    onSuccess: reFetch,
    mutationFn: a => {
      return deleteKanbanColumn(a);
    }
  });

  return { mutate, isLoading, error };
};
