import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';
import React from 'react';

const columns = memoize(clickHandler => [
  { name: 'ID', selector: 'id', sortable: false, grow: '1' },
  { name: 'Tipo', selector: 'type', sortable: true, grow: '1' },
  { name: 'Usuario', selector: 'user.full_name', sortable: true, grow: '1' },
  { name: 'Fecha', selector: 'date', sortable: true, grow: '1' },
  { name: 'Estado', selector: 'status_translated', sortable: true, grow: '1' },
  { name: 'Filas recorridas', selector: 'rows_count', sortable: false, grow: '1' },
  { name: 'Filas con errores', selector: 'errors_count', sortable: false, grow: '1' },
  {
    cell: item => (
      <BasicActionBtns item={item} clickHandler={clickHandler} showClick withEdit={false} withDestroy={false} />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '140px'
  }
]);

export default columns;
