/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns } from '../../components';

const columns = memoize((clickHandler, fromAdmin) => {
  const tempArray = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      grow: '1'
    },
    {
      name: 'FECHA DE CREACIÓN',
      selector: 'created_at',
      sortable: true,
      grow: '2'
    },
    {
      name: 'RESPONSABLE',
      selector: 'full_name',
      cell: item => item.user.full_name,
      sortable: false,
      grow: '2'
    },
    {
      name: 'CANTIDAD DE ÍTEMS',
      selector: 'total_items',
      sortable: false,
      grow: '2'
    },
    {
      name: 'COSTO TOTAL ESTIMADO',
      selector: 'parsed_total_price',
      sortable: false,
      grow: '2'
    },
    {
      name: 'ESTADO',
      selector: 'translated_state',
      sortable: false,
      grow: '1'
    }
  ];
  if (fromAdmin) {
    tempArray.push({
      name: 'SUCURSAL',
      selector: 'branch_office',
      cell: item => item.branch_office?.name,
      sortable: true,
      grow: '1'
    });
  }
  tempArray.push({
    cell: item =>
      item.state === 'completed' ? (
        <BasicActionBtns item={item} clickHandler={clickHandler} showClick withEdit={false} withDestroy={false} />
      ) : (
        <BasicActionBtns item={item} clickHandler={clickHandler} showClick editClick />
      ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '150px'
  });

  return tempArray;
});

export default columns;
