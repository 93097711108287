import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Container, Row } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import Sections from './Sections';
import { useGetSections } from './service';
import { debounce } from '../../app/utils/utils';
import './styles.scss';

import KanbanHeader from './components/KanbanHeader/KanbanHeader';

const KanbanContainer = () => {
  const { user: currentUser } = useSelector(state => state.auth);
  const { currentBranchOffice } = currentUser;
  const branch_office_id = currentBranchOffice.value;

  if (!branch_office_id) {
    return <Skeleton count={10} />;
  }

  return <Kanban2 branch_office_id={branch_office_id} />;
};

const Kanban2 = ({ branch_office_id }) => {
  const { sections } = useGetSections(branch_office_id);
  const [filters, setFilters] = useState({ name: '', code: '', sellerId: '', userId: '', promiseDateOverdue: false });
  const debouncedSetFilters = debounce(value => {
    setFilters(prevFilters => ({
      ...prevFilters,
      name: value,
      code: value
    }));
  }, 300);

  const handleFilterChange = filterData => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...filterData
    }));
  };

  const onChange = e => {
    const { value } = e.target;
    debouncedSetFilters(value);
  };

  return (
    <ErrorBoundary fallback={<div>Something is wrong, reload page</div>}>
      <KanbanHeader onSearchChange={onChange} onFilterChange={handleFilterChange} />
      <Container className="kanban-container">
        <DndProvider backend={HTML5Backend}>
          <Row className="d-flex flex-nowrap rounded-lg">
            {sections?.map(sec => (
              <Sections
                key={sec.id}
                section={sec}
                allSections={sections}
                filters={filters}
                branch_office_id={branch_office_id}
              />
            ))}
          </Row>
        </DndProvider>
      </Container>
    </ErrorBoundary>
  );
};

export default KanbanContainer;
