/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../../components';

const columns = memoize(clickHandler => [
  { name: 'ID', selector: 'id', sortable: true, grow: '1' },
  { name: 'NOMBRE', selector: 'name', sortable: true, grow: '2' },
  {
    name: 'INCIDENCIAS ASOCIADAS',
    selector: 'incidents_count',
    cell: item => item.incidents_count || 0,
    sortable: true,
    grow: '2'
  },
  {
    cell: item => <BasicActionBtns item={item} clickHandler={clickHandler} withShow={false} editClick />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '140px'
  }
]);

export default columns;
