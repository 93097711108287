import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import ComponentDataTable from '../../components/Utils/DataTable';
import { InputRemoteSelect, SimpleCenteredModal } from '../../components';
import {
  debounceIndexProductInventoriesRequest,
  updateProductInventoryRequest
} from '../../requests/productInventories';
import { sendAlert } from '../../actions/utils';
import ProductInventoryForm from './ProductInventoryForm';
import conditionalRowStyles from './RowStyles';
import { debounceIndexProductCategoriesRequest, indexProductCategoriesRequest } from '../../requests/productCategories';
import { Col, Row } from 'react-bootstrap';

const ProductInventoryDataTable = ({ columns, moreData, setMoreData, setQueries }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [productInventories, setProductInventories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const [subcategoryId, setSubcategoryId] = useState('');
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const [metadata, setMetadata] = useState({});

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalTitle('');
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Inventario actualizado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleUpdateRequest = values => {
    const {
      productInventory: { id }
    } = values;
    updateProductInventoryRequest(id, {
      dispatch,
      params: snakecaseKeys(values),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRequest = response => {
    setProductInventories(response.data.data);
    setMetadata(response.data.metadata);
    setAmount(response.data.metadata.amount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = prevparams => {
    setOnRequest(true);
    const params = {
      ...prevparams,
      category_id: categoryId,
      subcategory_id: subcategoryId
    };
    debounceIndexProductInventoriesRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'edit':
        setCenteredModalShow(true);
        setModalBody(
          <ProductInventoryForm
            action={action}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            submitVariant="success"
            productInventory={camelCaseEmptyStringRecursive(item)}
          />
        );
        setModalTitle('Editar Ítem');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'branch_office_name':
        return { sort_branch_office_name: name };
      case 'category_name':
        return { sort_category_name: name };
      case 'product_category_name':
        return { sort_product_category_name: name };
      case 'product_cost':
        return { sort_product_cost: name };
      case 'product_name':
        return { sort_product_name: name };
      case 'product_sale_price':
        return { sort_product_sale_price: name };
      case 'product_sku':
        return { sort_product_sku: name };
      default:
        return { sort_column: name };
    }
  };

  const resultFetchData = response => {
    return response.data.data;
  };

  // Fetch Categories
  const fetchCategories = (inputValue, callback) => {
    debounceIndexProductCategoriesRequest({
      dispatch,
      params: {
        query: inputValue,
        filter_parents: true,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const handleInitialFetch = () => {
    indexProductCategoriesRequest({
      dispatch,
      params: {
        filter_parents: true,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 100
      },
      successCallback: data => setCategories(resultFetchData(data))
    });
  };

  // Fetch Subcategories
  const fetchSubcategories = (inputValue, callback) => {
    debounceIndexProductCategoriesRequest({
      dispatch,
      params: {
        query: inputValue,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        category_id: categoryId,
        display_length: 20
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const handleInitialSubcategoryFetch = () => {
    indexProductCategoriesRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        category_id: categoryId,
        display_length: 100
      },
      successCallback: data => setSubcategories(resultFetchData(data))
    });
  };

  useEffect(handleInitialFetch, [dispatch]);
  useEffect(handleInitialSubcategoryFetch, [dispatch, categoryId]);

  return (
    <>
      <Row>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Suma precio de costo:</div>
          <div>{metadata.sum_products_cost}</div>
        </Col>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Suma precio de venta:</div>
          <div>{metadata.sum_products_sale_price}</div>
        </Col>
      </Row>
      <Row>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Suma stock potencial: </div>
          <div>{metadata.sum_potential_stock}</div>
        </Col>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Suma stock crítico: </div>
          <div>{metadata.sum_minimum_stock}</div>
        </Col>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Suma stock actual: </div>
          <div>{metadata.sum_current_stock}</div>
        </Col>
      </Row>
      <ComponentDataTable
        conditionalRowStyles={conditionalRowStyles}
        onRequest={onRequest}
        columns={columns(handleButtonClick, false)}
        handleSortCase={sortColumnCase}
        data={productInventories}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries({ ...response, category_id: categoryId });
          if (!onRequest) handleIndexRequest(response);
        }}
        firstCustomMediumSearch={
          <InputRemoteSelect
            isClearable
            defaultOptions={categories}
            placeholder="Filtro categorías"
            onChange={data => {
              setCategoryId(data ? data.value : '');
              if (!data) setSubcategoryId('');
              setMoreData(!moreData);
            }}
            request={fetchCategories}
          />
        }
        secondCustomMediumSearch={
          <InputRemoteSelect
            isClearable
            defaultOptions={subcategories}
            placeholder="Filtrar subcategoría"
            disabled={!categoryId}
            onChange={data => {
              setSubcategoryId(data ? data.value : '');
              setMoreData(!moreData);
            }}
            request={fetchSubcategories}
          />
        }
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton
      />
    </>
  );
};

export default ProductInventoryDataTable;
