import React, { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';
import {Row, Col, Button} from 'react-bootstrap';
import { startOfMonth, format } from 'date-fns';

import camelCaseRecursive from "camelcase-keys-recursive";
import snakeCaseKeys from "snakecase-keys";
import ComponentDataTable from '../../../components/Utils/DataTable';
import {
  indexProjectsInvoicesRequest,
  debounceindexProjectsInvoicesRequest,
  deleteProjectInvoiceRequest,
  getDocumentRequest
} from '../../../requests/projectInvoices';
import {sendAlert} from '../../../actions/utils';
import downloadFile from '../../../services/utils';
import {DefaultModal, FormikSelect, InputRemoteSelect, SimpleCenteredModal} from "../../../components";
import {debounceIndexClientsRequest} from "../../../requests/clients";
import CreditNoteToDelete from "./CreditNoteToDelete";
import ConfirmRequest from "./ConfirmRequest";
import documentsType from "./Constants";
import useSavedFilter from "../../../hooks/useSavedFilter";

const ProjectInvoiceDataTable = ({columns, moreData, setMoreData, onlySaleBook = false, filterId}) => {
  const dispatch = useDispatch();
  const {setFilter, getFilter} = useSavedFilter(filterId);
  const [amount, setAmount] = useState(0);
  const [projectInvoices, setProjectInvoices] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [queries, setQueries] = useState({});
  const [clientId, setClientId] = useState(getFilter('client_id'));
  const [documentType, setDocumentType] = useState(getFilter('document_type_id'));
  const [clients, setClients] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const handleDownloadDocument = (projectId, invoiceId, format) => {
    getDocumentRequest(projectId, invoiceId, {
      format,
      dispatch,
      successCallback: response => downloadFile(response),
      failureCallback: error => dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}))
    });
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseProjectInvoices = response.data.data;
    setMetadata(response.data.metadata);
    const {amount: responseAmount} = response.data.metadata;
    setProjectInvoices(responseProjectInvoices);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      client_id: clientId,
      only_sale_book: onlySaleBook,
      document_type: documentType,
      sort_project: 'code'
    }
    debounceindexProjectsInvoicesRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'project_code':
        return {sort_column: 'project_id'};
      case 'invoice_date':
        return {sort_column: 'created_at'};
      case 'parsed_amount':
        return {sort_column: 'amount'};
      case 'client_full_name':
        return {sort_by_client: name};
      default:
        return {sort_column: name};
    }
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    indexProjectsInvoicesRequest({
      dispatch,
      params: {...params, only_sale_book: onlySaleBook, display_length: 100000},
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const fetchClients = (inputValue, callback) => {
    debounceIndexClientsRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.full_name,
        value: element.id
      };
    });
    setClients(camelCaseRecursive(tempArray));
    return tempArray;
  };

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setDefaultModalShow(false);
  };

  const handleSuccessDelete = response => {
    const {id, invoice_number: invoiceNumber, project_code: projectCode} = response.data;
    setMoreData(m => !m);
    handleModalClose();
    if (invoiceNumber) {
      dispatch(
        sendAlert({
          kind: 'success',
          message: 'Nota de Crédito creada con éxito'
        })
      );
      handleDownloadDocument(projectCode, id, 'pdf');
    } else {
      dispatch(
        sendAlert({
          kind: 'success',
          message: 'Documento anulado con éxito.'
        })
      );
    }
  }

  const handleCreditNoteRequest = (params = {}) => {
    deleteProjectInvoiceRequest(params.projectCode, params.id, {
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: handleSuccessDelete,
      failureCallback: handleFailureRequest
    });
  };

  const handleConfirmRequest = values => {
    setModalShow(false);
    setModalBody(
      <ConfirmRequest confirm={() => handleCreditNoteRequest(values)} cancel={handleModalClose}/>
    );
    setModalShow(true);
  };

  const handleCreditNote = (item) => {
    setModalShow(true);
    setModalBody(
      <CreditNoteToDelete
        projectInvoiceToDelete={{id: item.id, motive: '', projectCode: item.project_code, issueDate: new Date()}}
        formRequest={handleConfirmRequest}
        handleModalClose={handleModalClose}
        dispatch={dispatch}
      />
    );
  };

  useEffect(() => {
    if(getFilter('client_id') !== '') {
      fetchClients(getFilter('client_id'))
    }
  }, []);

  const currentDate = new Date();

  return (
    <>
      <Row>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Venta Neta:</div>
          <div>{metadata.sum_net_amount}</div>
        </Col>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Venta Total:</div>
          <div>{metadata.sum_total_amount}</div>
        </Col>
      </Row>
      <Row>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Total Abonado:</div>
          <div>{metadata.sum_payments_amount}</div>
        </Col>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Deuda Total:</div>
          <div>{metadata.sum_unpaid_amount}</div>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        filterId={filterId}
        onRequest={onRequest}
        columns={columns(handleDownloadDocument, handleCreditNote)}
        handleSortCase={sortColumnCase}
        data={projectInvoices}
        totalRows={amount}
        moreData={moreData}
        startDate={format(startOfMonth(currentDate),'yyyy-MM-dd')}
        endDate={format(currentDate, 'yyyy-MM-dd')}
        withDate
        resourceRequest={response => {
          setQueries({
            ...response,
            client_id: clientId,
            document_type: documentType
          });
          if (!onRequest) handleIndexRequest(response);
        }}
        firstCustomMediumSearch={
          <InputRemoteSelect
            isClearable
            placeholder="Cliente"
            defaultOptions={clients}
            value={clients.find(client => client.value === clientId)}
            onChange={data => {
              setFilter('client_id', data?.value);
              setClientId(data ? data.value : '');
              setMoreData(!moreData);
            }}
            request={fetchClients}
          />
        }
        secondCustomMediumSearch={
          <FormikSelect
            isClearable
            options={documentsType}
            placeholder="Tipo de Documento"
            defaultValue={documentsType.find(document => document.value === documentType)}
            onChange={data => {
              setFilter('document_type_id', data?.value);
              setDocumentType(data ? data.value : '');
              setMoreData(!moreData);
            }}
          />
        }
      />
      <DefaultModal
        title="Eliminar abono"
        body={modalBody}
        show={defaultModalShow}
        handleClose={handleModalClose}
        handleConfirm={() => handleCreditNoteRequest()}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        size="md"
        title="EMITIR NOTA DE CRÉDITO"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </>
  );
};

export default ProjectInvoiceDataTable;
