import React from 'react';
import { Edit } from 'react-feather';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Can } from "../../config/abilityContext";

const projectInfo = ({ event }) => {
  const {
    promiseDate,
    parsedMileage,
    projectDate,
    receptionDate,
    reviewDate,
    translatedState,
    vehicle,
    client
  } = event;

  return (
    <>
      <div className="mb-3 info-box">
        <p className="info">
          <span>Estado:</span> {translatedState}
        </p>
        {projectDate && (
          <p className="info">
            <span>Fecha de Ingreso:</span> {projectDate}
          </p>
        )}
        {receptionDate && (
          <p className="info">
            <span>Fecha de Recepción:</span> {receptionDate}
          </p>
        )}
        {reviewDate && (
          <p className="info">
            <span>Fecha de Revisión:</span> {reviewDate}
          </p>
        )}
        {promiseDate && (
          <p className="info">
            <span>Fecha estimada de entrega:</span> {promiseDate}
          </p>
        )}
        <div className="info">
          <span>Nombre del Cliente:</span> {client?.fullName}
          <Can I="update" a="Client">
            <Link data-tip data-for={`edit-client-${client?.id}`} className="ml-1" to={`/clients/${client?.id}/edit`}>
              <Edit className="mb-1" size={12} />
            </Link>
            <ReactTooltip id={`edit-client-${client?.id}`} place="top">
              Editar Cliente
            </ReactTooltip>
          </Can>
        </div>
        <p className="info">
          <span>Patente/Código del Vehículo:</span> {vehicle?.plate}
        </p>
        {parsedMileage && (
          <p className="info">
            <span>Kilometraje:</span> {parsedMileage}
          </p>
        )}
      </div>
    </>
  );
};

export default projectInfo;
