import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showProductRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/products/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const scanProductRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/products/scan`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexProductsRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/products${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createProductRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/products', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateProductRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/products/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProductRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/products/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const movementDetailsIndexRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/products/${id}/movement_details`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexProductsRequest = AwesomeDebouncePromise(indexProductsRequest, 300);
export const debounceMovementDetailsIndexRequest = AwesomeDebouncePromise(movementDetailsIndexRequest, 300);
