import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import IncidentTypeDataTable from './IncidentTypeDataTable';
import IncidentTypeForm from './IncidentTypeForm';
import columns from './Columns';
import { SimpleCenteredModal } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import { createIncidentTypeRequest } from '../../../requests/incidentTypes';

const basicIncidentType = {
  name: ''
};

const IncidentTypeIndex = () => {
  const dispatch = useDispatch();

  const [modalBody, setModalBody] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Tipo de Incidencia creado con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createIncidentTypeRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  const handleModalCreate = () => {
    setModalBody(
      <IncidentTypeForm
        action="new"
        dispatch={dispatch}
        formRequest={handleCreateRequest}
        handleModalClose={handleModalClose}
        submitVariant="success"
        incidentType={basicIncidentType}
      />
    );
    setModalShow(true);
    setModalTitle('Crear Tipo de Incidencia');
  };

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">TIPOS DE INCIDENCIA</h2>
            </Col>
            <Col md={2}>
              <Button variant="primary" block onClick={handleModalCreate}>
                Crear
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <IncidentTypeDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />
            </Col>
          </Row>
          <SimpleCenteredModal
            title={modalTitle}
            body={modalBody}
            show={modalShow}
            onHide={handleModalClose}
            centered={false}
            closeButton={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default IncidentTypeIndex;
