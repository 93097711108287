import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import Scheduling from '../../../assets/images/scheduling.webp';

const SchedulingSection = () => {
  return (
    <>
      <div id="scheduling" className="max-width-2000-xxl">
        <Fade up>
          <Row className="landing-image-section bg-gray-scheduling row-section-benefits">
            <Col md={6} xs={12} className="order-sm-2">
              <h3 className="mb-4">GestionCar te ayudará a organizarte mejor. Podemos ahorrarte tiempo y dinero.</h3>
              <Link className="btn button-font btn-primary-yellow__landing  w-100-mobile" to="/contact">
                ¡Programa una entrevista!
              </Link>
            </Col>
            <Col md={6} xs={12} className="image-left order-sm-1 text-sm-center">
              <img
                className="lazyload"
                alt="Agendamiento GestionCar"
                title="Agendamiento GestionCar"
                src={Scheduling}
                width="90%"
                height="auto"
              />
            </Col>
          </Row>
        </Fade>
      </div>
    </>
  );
};

export default SchedulingSection;
