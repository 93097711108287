import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ComponentDataTable from '../../components/Utils/DataTable';
import { DefaultModal } from '../../components';
import { indexClientsRequest, debounceIndexClientsRequest, deleteClientRequest } from '../../requests/clients';
import { sendAlert } from '../../actions/utils';
import downloadFile from '../../services/utils';
import useCountryFactory from "../../hooks/useCountryFactory";

const ClientDataTable = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [amount, setAmount] = useState(0);
  const [clients, setClients] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const { columns } = props;
  const [queries, setQueries] = useState({});
  const countryFactory = useCountryFactory();

  const handleSuccessRequest = response => {
    const responseClients = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setClients(responseClients);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    const {
      data: { message }
    } = error.response;
    dispatch(sendAlert({ kind: 'error', message }));
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexClientsRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'vehicleCreate':
        history.push(`/clients/${item.id}/vehicles/new`);
        break;
      case 'show':
        history.push(`/clients/${item.id}`);
        break;
      case 'edit':
        history.push(`/clients/${item.id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas eliminar este cliente?');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Cliente eliminado con éxito' }));
    setMoreData(!moreData);
  };

  const removeClient = clientId => {
    deleteClientRequest(clientId, {
      dispatch,
      successCallback: () => handleSuccessRemove(clientId)
    });
  };

  const handleModalConfirm = () => {
    removeClient(modalItem.id);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'seller':
        return { sort_seller: name };
      default:
        return { sort_column: name };
    }
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const downloadIndex = (format, params) => {
    indexClientsRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload
    });
  };

  return (
    <>
      <Row className="justify-content-end mt-2">
        <Col md={2}>
          <Button variant="secondary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, countryFactory)}
        handleSortCase={sortColumnCase}
        data={clients}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries(response);
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <DefaultModal
        title="Eliminar Cliente"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default ClientDataTable;
