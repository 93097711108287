import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { publicShowNpsFormsRequest } from '../../requests/npsForms';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik, getIn } from 'formik';
import RangeInput from './RangeInput';
import * as Yup from 'yup';
import { publicUpdateProjectRequest } from '../../requests/projects';
import snakeCaseKeys from 'snakecase-keys';
import { sendAlert } from '../../actions/utils';
import { useHistory } from 'react-router-dom';
import { BasicTextArea } from '../../components';

const NpsIndex = ({ match }) => {
  const { token } = match.params;
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([]);
  const [description, setDescription] = useState('');
  const [initialValues, setInitialValues] = useState({ project: { npsAnswersAttributes: [] } });
  const history = useHistory();

  const fetchQuestions = () => {
    publicShowNpsFormsRequest(token, {
      dispatch,
      successCallback: ({ data }) => {
        setDescription(data.nps_form_description);
        setQuestions(data.nps_questions_attributes);
        setInitialValues({
          project: {
            npsAnswersAttributes: data.nps_questions_attributes.map(question => ({
              answer: '',
              npsQuestionId: question.id,
              kind: question.kind
            }))
          }
        });
      }
    });
  };

  const validationSchema = Yup.object().shape({
    project: Yup.object().shape({
      npsAnswersAttributes: Yup.array().of(
        Yup.object().shape({
          answer: Yup.mixed().test('answer-validation', 'El campo es requerido', function(value) {
            const {
              parent: { kind }
            } = this;
            if (kind === 'ranking') {
              return Yup.number()
                .required()
                .min(0)
                .max(10)
                .isValidSync(value);
            } else if (kind === 'text') {
              return Yup.string()
                .required()
                .isValidSync(value);
            }
            return false;
          })
        })
      )
    })
  });

  const handleSubmit = values => {
    const project = snakeCaseKeys(values);
    publicUpdateProjectRequest(token, {
      dispatch,
      params: project,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Encuesta enviada correctamente' }));
        history.push('/');
      }
    });
  };

  useEffect(fetchQuestions, []);

  return (
    <Container className="py-5">
      <Row className="py-5">
        <Col className="pt-5">
          <h2 className="text-uppercase">Encuesta de Satisfacción</h2>
          <h3 style={{ whiteSpace: 'break-spaces' }}>{description}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Form>
              {questions.map((question, index) => (
                <Row className="my-4" key={question.id}>
                  <Col>
                    {question.kind === 'ranking' && (
                      <RangeInput name={`project.npsAnswersAttributes[${index}].answer`} {...question} />
                    )}
                    {question.kind === 'text' && (
                      <Field name={`project.npsAnswersAttributes[${index}].answer`}>
                        {({ field, form }) => (
                          <BasicTextArea
                            {...field}
                            {...question}
                            abbr
                            error={getIn(form.errors, field.name)}
                            touched={getIn(form.touched, field.name)}
                          />
                        )}
                      </Field>
                    )}
                  </Col>
                </Row>
              ))}
              <p className="font-weight-bold text-primary text-big">!Muchas gracias por tu opinión!</p>
              <Button type="submit">Enviar</Button>
            </Form>
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};
export default NpsIndex;
