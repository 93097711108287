/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import { CategoryProductCategorySelect, FormikInput, InputRemoteSelect } from '../../components';
import { debounceIndexProductsRequest, indexProductsRequest } from '../../requests/products';

const ProductInventoryForm = ({
  action,
  errors,
  handleModalClose,
  onHide,
  productInventory,
  setFieldValue,
  submitVariant,
  touched,
  values
}) => {
  const isNew = action === 'new';
  const inputsDivId = 'productInputsDiv';
  const btnMessage = isNew ? 'Activar' : 'Guardar';
  const { user } = useSelector(state => state.auth);
  const {
    currentBranchOffice: { value: branchOfficeId }
  } = user;
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [productsDisabled, setProductsDisabled] = useState(true);
  const [products, setProducts] = useState([]);
  const inputsDivElement = document.getElementById(inputsDivId);

  const fetchProducts = (inputValue, callback) => {
    debounceIndexProductsRequest({
      dispatch,
      params: {
        query: inputValue,
        branch_office_id: branchOfficeId,
        for_selector: true,
        sort_column: 'code',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const fetchInitialProducts = useCallback(
    params => {
      indexProductsRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          for_selector: true,
          branch_office_id: branchOfficeId,
          sort_column: 'code',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: response => {
          setProducts(response.data.data);
        }
      });
      setProductsDisabled(false);
    },
    [stableDispatch, branchOfficeId]
  );

  const clearSelectedProduct = () => {
    inputsDivElement.classList.add('hidden');
    setFieldValue('productInventory[productId]', '');
    setFieldValue('productInventory[productName]', '');
  };

  const handleSubCategoryChange = value => {
    clearSelectedProduct();
    if (value) {
      fetchInitialProducts({ productCategoryId: value });
    } else {
      setProductsDisabled(true);
    }
  };

  const handleParentCategoryChange = () => {
    clearSelectedProduct();
    setProductsDisabled(true);
    setProducts([]);
  };

  useEffect(() => {
    if (productInventory.productCategoryId) {
      fetchInitialProducts({ productCategoryId: productInventory.productCategoryId });
    }
  }, [productInventory.productCategoryId, fetchInitialProducts]);

  return (
    <Form>
      {isNew && (
        <Row>
          <Col md={8}>
            <Row>
              <CategoryProductCategorySelect
                categoryAbbr
                productCategoryAbbr
                modelKey="productInventory"
                currentModel={productInventory}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onParentCategoryChange={handleParentCategoryChange}
                onSubCategoryChange={handleSubCategoryChange}
              />
            </Row>
          </Col>
          <Col md={4}>
            <Field name="productInventory[productId]">
              {({ field }) => (
                <InputRemoteSelect
                  {...field}
                  abbr
                  disabled={productsDisabled}
                  defaultOptions={products}
                  label="Buscar Ítem"
                  values={values.productInventory}
                  model={[productInventory, 'product']}
                  placeholder="Buscar"
                  onChange={data => {
                    setFieldValue(field.name, data ? data.value : '');
                    inputsDivElement.classList.remove('hidden');
                  }}
                  request={fetchProducts}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      )}
      <div id={inputsDivId} className={`pt-2 px-2 bg-light${isNew ? ' hidden' : ''}`}>
        <Row>
          {!isNew && (
            <Col>
              <label>
                <strong>Ítem</strong>
              </label>
              <p>
                {productInventory.productName}
                <br />
                SKU {productInventory.productId}
              </p>
            </Col>
          )}
          <Col>
            <Field name="productInventory[potentialStock]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Stock Potencial"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col>
            <Field name="productInventory[minimumStock]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Stock Crítico"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {isNew && (
            <Col>
              <Field name="productInventory[currentStock]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="number"
                    label="Stock Actual"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          <Col>
            <Field name="productInventory[location]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Ubicación"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </div>
      <Row className="d-flex justify-content-end mt-3">
        <Col md={2}>
          <Button className="btn mb-3" variant="primary" block onClick={handleModalClose}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ productInventory }) => {
  return { productInventory };
};

const validationSchema = Yup.object().shape({
  productInventory: Yup.object().shape({
    categoryId: Yup.number().required('Debes seleccionar una categoría'),
    currentStock: Yup.number().required('Debes ingresar stock actual'),
    minimumStock: Yup.number().required('Debes ingresar stock mínimo'),
    potentialStock: Yup.number().required('Debes ingresar stock potencial'),
    productCategoryId: Yup.number().required('Debes seleccionar una subcategoría'),
    productId: Yup.number().required('Debes seleccionar un ítem')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProductInventoryForm);
