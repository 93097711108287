import { create } from 'zustand';

export const useCompany = create(set => ({
  website: '',
  setWebsite: website => set({ website }),
  whatsappPhoneNumber: '',
  email: '',
  setEmail: email => set({ email }),
  phoneNumber: '',
  setPhoneNumber: phoneNumber => set({ phoneNumber }),
  setWhatsappPhoneNumber: whatsappPhoneNumber => set({ whatsappPhoneNumber })
}));
