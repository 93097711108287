import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import { FormikInput } from '../../components';
import TemplateWorkAdd from './TemplateWorkAdd';

const TemplateForm = ({
  action,
  errors,
  handleModalClose,
  onHide,
  template,
  setFieldValue,
  submitVariant,
  touched,
  values
}) => {
  const { templateWorksAttributes } = template;
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';

  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="template[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre Template"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="text-uppercase mt-3 mb-4">Trabajos Asociados al Template</h4>
          <TemplateWorkAdd
            templateWorks={templateWorksAttributes}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3">
        <Col md={2}>
          <Button className="btn mb-3" variant="primary" block onClick={handleModalClose}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { template } = props;
  return { template };
};

// eslint-disable-next-line func-names
Yup.addMethod(Yup.array, 'unique', function(message, mapper = a => a) {
  // eslint-disable-next-line func-names
  return this.test('unique', message, function(list) {
    if (list.length === new Set(list.map(mapper)).size) {
      return true;
    }
    return this.createError({
      message: 'Un template no puede tener dos o más trabajos iguales',
      path: 'template[name]'
    });
  });
});

const validationSchema = Yup.object().shape({
  template: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre para este Template'),
    templateWorksAttributes: Yup.array()
      .of(
        Yup.object().shape({
          workId: Yup.number().required('Debes seleccionar al menos un trabajo para continuar')
        })
      )
      .unique('Los trabajos no deben ser iguales', a => a.workId)
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(TemplateForm);
