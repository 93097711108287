import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexUsersRequest = ({ dispatch, format, params = {}, successCallback, failureCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/users${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showUserRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/users/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createUserRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/users', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateUserRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/users/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const userExceededProjectWorkHours = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('get', `/users/${id}/exceeded_project_hours`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteDocumentRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/users/${id}/destroy_document`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updatePasswordRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/change_password`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const status2Chat = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/user/status_2chat`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const qr2Chat = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/user/qr_2chat`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexUsersRequest = AwesomeDebouncePromise(indexUsersRequest, 300);
