import React from 'react';
import MovementDetailInput from './MovementDetailInput';
import StockTakeDetailInput from '../ProductMovement/Stocktake/StockTakeDetailInput';

const MovementDetailAdd = ({
  createProductModal,
  fromStocktake,
  modelKey,
  movementDetailsAttributes,
  projectDetails,
  setProjectDetails,
  selectedProject
}) => {
  const mapResults = movementDetailsAttributes.map((movementDetail, index) => {
    if (movementDetail._destroy) {
      return undefined;
    }
    return (
      <React.Fragment key={`fragment-${index.toString()}`}>
        {fromStocktake ? (
          <StockTakeDetailInput
            createProductModal={createProductModal}
            key={`stocktakeMovementDetail-${index.toString()}`}
            index={index}
            movementDetail={movementDetail}
            movementDetailsAttributes={movementDetailsAttributes}
            modelKey={modelKey}
          />
        ) : (
          <MovementDetailInput
            createProductModal={createProductModal}
            key={`movementDetail-${index.toString()}`}
            index={index}
            movementDetail={movementDetail}
            movementDetailsAttributes={movementDetailsAttributes}
            modelKey={modelKey}
            selectedProject={selectedProject}
            projectDetails={projectDetails}
            setProjectDetails={setProjectDetails}
          />
        )}
      </React.Fragment>
    );
  });

  return mapResults;
};

export default MovementDetailAdd;
